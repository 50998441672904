import React from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

export const SkeletonComponent = ({rows = 1, columns = 1, skeletonClass = "", color = "#F3F6F9", highlightColor = "#FFFFFF", height = 25, count = 1}) => {

  return (Array(rows).fill().map((_, indexRow) => (
        Array(columns).fill().map((_, indexColumn) => (
          <div className={skeletonClass} key={indexRow + indexColumn}>
            <SkeletonTheme color={color} highlightColor={highlightColor}>
                <Skeleton count={count} height={height}/>
            </SkeletonTheme>
          </div>
        ))
    )
  ))
}
