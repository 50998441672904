import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import OTPInput, { ResendOTP } from "otp-input-react";
import * as auth from "../_redux/authRedux";
import { resendOTP, validateOTP } from "../_redux/authCrud";
import { hubConnection } from "../../../../_custom/_helpers";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const renderTime = ({remainingTime}) => {
  if (remainingTime === 0) {
    return;
  }
  return (
    <div className="timer">
      <div className="value" style={{fontSize: "25px"}}>{remainingTime}</div>
    </div>
  );
};

function TwoFactorAuthentication(props) {
  const {intl} = props;
  const [otp, setOtp] = useState("");
  const [loginPaused, setLoginPaused] = useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onResend = () => {
    resendOTP(otp)
      .then((params) => {
        console.log(params);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const onVerify = () => {
    enableLoading();
    validateOTP(otp)
      .then((params) => {
        disableLoading();
        const {status, data} = params;
        if (status !== 200) {
          setError(data?.message || intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN"}));
        } else {
          if (data?.passwordExpired) {
            let payload = {
              passwordExpired: data?.passwordExpired,
              accessToken: data?.access_token,
              userId: data?.user_id,
              uniqueIdentifier: data.uniqueIdentifier
            }
            props.changePassword({...payload});
            history.push('/auth/change-password');
          } else {
            if (data.multi_user_login) {
              setLoginPaused(true)
              setTimeout(function () {
                hubConnection(data?.access_token, data?.user_id)
                  .then(() => {
                    props.login(data);
                  })
                  .catch((err) => {
                    console.log('error while connecting signalR..', err);
                  });
              }, 10000)
            } else {
              hubConnection(data?.access_token, data?.user_id)
                .then(() => {
                  props.login(data);
                })
                .catch((err) => {
                  console.log('error while connecting signalR..', err);
                });
            }
          }
        }
      })
      .catch(error => {
        disableLoading();
        setLoginPaused(false)
        setError(error?.response?.data?.message || intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN"}));
      });
  }

  useEffect(()=>{
    if(otp?.length === 6){
      onVerify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp])

  return (
    <>
      <div className="login-forgot" style={{display: "block"}}>
        {loginPaused ? (
          <div className="text-center mb-5 mb-10 mb-lg-20">
            <p className="font-size-h4 font-weight-bold">
              Please wait for
            </p>
            <div className="d-flex justify-content-center">
              <CountdownCircleTimer
                isPlaying
                duration={10}
                size={65}
                strokeWidth={5}
                colors={[["#3699ff", 1]]}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
            <p className="font-size-h4 font-weight-bold mt-2">
              seconds.
            </p>

            <p className="text-muted font-size-h5 font-weight-bold">
              We are logging you out from another browser.
            </p>
          </div>
        ) : (
          <>
            <div className="text-center mb-10">
              <h3 className="font-size-h1">Two-step Verification</h3>
              <div className="text-muted font-weight-bold">
                An OTP has been sent to your registered email address, please verify it below.
              </div>
            </div>
            <div className="fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
              {error ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {error}
                  </div>
                </div>
              ) : null}

              <div className="fv-plugins-icon-container mb-5">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  className="otp-input-root"
                  disabled={false}
                  secure
                />
                <p>
                  Didn't Receive OTP?
                  <ResendOTP
                    maxTime={60}
                    className="otp-resend-root"
                    renderTime={() => React.Fragment}
                    onResendClick={onResend}
                    renderButton={(buttonProps) => {
                      return (
                        <button {...buttonProps}>
                          {buttonProps.remainingTime !== 0 ? ` ${buttonProps.remainingTime} sec` : " Resend"}
                        </button>
                      );
                    }}
                  />
                </p>
              </div>

              <div className="d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={otp?.length !== 6 || loading}
                  onClick={onVerify}>
                  Verify
                  {loading && <span className="ml-3 spinner spinner-white"/>}
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(TwoFactorAuthentication));
