import * as requestFromServer from "./fieldsCrud";
import { callTypes, fieldsSlice } from "./fieldsSlice";

const { actions } = fieldsSlice;
export const fetchFields = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllFields(params)
    .then(response => {
      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(actions.fieldsFetched({ totalCount, entities }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch fields";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchFieldsWithDetails = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getFieldsWithDetails(params)
    .then(response => {
      const entities = response?.data;
      const totalCount = response?.data?.length || 0;
      dispatch(
        actions.fieldsFetched({ totalCount, entities, isWithDetailsCall: true })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch fields";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const setFieldsFromStaticDataToStore = entities => dispatch => {
  dispatch(actions.appendFields({ entities, isWithDetailsCall: false }));
};

export const appendFieldsWithDetails = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getFieldsWithDetails(params)
    .then(response => {
      const entities = response?.data;
      const totalCount = response?.data?.length || 0;
      dispatch(
        actions.appendFields({ totalCount, entities, isWithDetailsCall: true })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch fields";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearFieldsList = () => dispatch => {
  dispatch(actions.clearFieldsList());
};

export const clearIndividualField = () => dispatch => {
  dispatch(actions.clearIndividualField());
};

export const fetchField = id => dispatch => {
  if (!id) {
    return dispatch(actions.fieldFetched({ fieldForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getFieldById(id)
    .then(response => {
      const field = response.data;
      if (id?.type === "PrevState") {
        dispatch(actions.prevFieldFetched({ fieldForEdit: field }));
      } else {
        dispatch(actions.fieldFetched({ fieldForEdit: field }));
      }

      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find field";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteField = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteField(queryParams)
    .then(response => {
      if (!queryParams?.isBulk) {
        dispatch(actions.fieldDeleted({ queryParams }));
      }
      if (queryParams?.isBulk && queryParams?.lastCall) {
        dispatch(actions.fieldDeleted({ queryParams }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete field";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createField = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createField(queryParams)
    .then(response => {
      const { field } = response?.data || {};
      dispatch(actions.fieldCreated({ field }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create field";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateField = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateField(queryParams)
    .then(response => {
      dispatch(actions.fieldUpdated(queryParams));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update field";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const actOnDraft = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams)
    .then(response => {
      const { field } = response.data;
      dispatch(actions.actionDrafted({ field }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const auditFields = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditFields(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetched({ auditLogs }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch trails";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAuditsById = params => dispatch => {
  console.log({ params });
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAuditsById(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetchedById({ auditLogs, params }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch trails";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
