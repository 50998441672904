import { sortByAlphabeticalOrder } from "./common";

export const getNavbarModules = (modulesList, permissionsDictionary) => {
  // const sortModules = (list, sortKey) => list.sort(function(a, b){
  //   if(a[sortKey] < b[sortKey]) { return -1; }
  //   if(a[sortKey] > b[sortKey]) { return 1; }
  //   return 0;
  // })

  const createModulesTree = modules => {
    const module = Object.create(null);
    (modules || []).forEach(aModule => {
      const permissions = permissionsDictionary?.[aModule?.code];
      if (permissions)
        module[aModule?.code] = { ...aModule, permissions, children: [] };
    });

    let dataTree = [];
    (modules || []).forEach(aModule => {
      const permissions = permissionsDictionary?.[aModule?.code];
      const parentPermission = permissionsDictionary?.[aModule?.parentCode];

      if (aModule?.parentCode !== null && permissions && parentPermission) {
        module[aModule?.parentCode].children.push(module[aModule?.code]);
      } else if (permissions) {
        dataTree.push(module[aModule?.code]);
      }
    });

    dataTree = sortByAlphabeticalOrder(dataTree, "name");
    return dataTree;
  };

  // let modulesHierarchy = createModulesTree(modulesList);

  // split modules with children and without
  // let indepModule = modulesHierarchy.filter(module => !module.children?.length);
  // let withDepModule = modulesHierarchy.filter(module => module.children?.length);
  //
  // console.log({indepModule})

  // sort both alphabetically
  // sortModules(modulesHierarchy, "name");
  // sortModules(withDepModule, "name");
  // join them, first non child, then with child
  // modulesHierarchy = [...indepModule, ...withDepModule];
  return createModulesTree(modulesList);
};
