import React, { useEffect, useState } from 'react';
import debounce from "lodash/debounce";

import { Filter } from "./Grid";

export const FilterAdvanced = ({filters = [], uiProps = {}, keyword, masked = false, placeholderOverride = "", stateOverride, stateVariable, setStateVariable}, key = 0) => {

  const [searchValues, setSearchValues] = useState(uiProps ? {...uiProps.queryParams} : stateVariable ? {...stateVariable} : {});
  useEffect(() => {
    // uiProps.setSelectedItems([]);
    if (stateOverride) {
      setStateVariable({...searchValues})
    } else {
      if (uiProps)
        uiProps.setQueryParams({...searchValues})
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues]);

  const onChangeFilters = debounce(
    (filterCode, filterValue) => {
      setSearchValues({...uiProps.queryParams, [filterCode]: filterValue === "" ? undefined : filterValue});
    },
    500,
  );
  return (
    <Filter key={key} masked={masked} placeholderOverride={placeholderOverride} filters={filters} keyword={keyword} onChange={onChangeFilters}/>
  )
}
