import React from "react";
import { Overlay } from "../../controls";
import moment from "moment";

export const DocumentGroupFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { groupList, checkList }
) => {
  let group;
  if (groupList?.length > 0)
    group =
      (groupList || []).find(item => item?.code === rowData?.groupCode)?.name ||
      (rowData?.code?.includes("checklist")
        ? checkList[rowData?.additionalInformation]?.name ||
          checkList[rowData?.additionalInformation]
        : "N/A");
  // console.log(group)
  return (
    <span>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column font-weight-bold">
          <div className="d-flex flex-row">
            <span
              className="text-dark text-hover-primary mb-1 font-size-lg custom-span-quick-panel-deviation"
              style={{ maxWidth: "7rem" }}
            >
              {group}
            </span>
            <Overlay popoverContent={group}>
              <i
                className={"fas fa-info-circle icon-nm text-hover-primary"}
                style={{ padding: "5px" }}
              />
            </Overlay>
          </div>
        </div>
      </div>
    </span>
  );
};

export const DocumentStageFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { stageList }
) => {
  const stage = (stageList || []).find(
    stageObj => stageObj?.code === rowData?.stageCode
  );

  return (
    <span>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column font-weight-bold">
          <div className="d-flex flex-row">
            <span
              className="text-dark text-hover-primary mb-1 font-size-lg custom-span-quick-panel-deviation"
              style={{ maxWidth: "7rem" }}
            >
              {stage?.name}
            </span>
            <Overlay popoverContent={stage?.name}>
              <i
                className={"fas fa-info-circle icon-nm text-hover-primary"}
                style={{ padding: "5px" }}
              />
            </Overlay>
          </div>
        </div>
      </div>
    </span>
  );
};

export const DocumentCreatedFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let creator =
    (allUsers || []).find(item => item?.id === row?.createdBy)?.name || "N/A";

  return (
    <div className="d-flex align-items-center" style={{ maxWidth: "13rem" }}>
      <div className="d-flex flex-column" style={{ maxWidth: "8rem" }}>
        <span className="text-success text-right font-weight-bold font-size-md mr-2">
          {moment(row?.createdAt).format("DD MMMM YYYY, hh:mm a")}
        </span>
      </div>
      <span className="bullet bullet-bar bg-success align-self-stretch" />
      <div className="d-flex flex-column" style={{ maxWidth: "5rem" }}>
        <Overlay popoverContent={creator}>
        <span
          className="text-dark-75 text-hover-success text-light cursor-pointer font-weight-bold font-size-md ml-2"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {creator}
        </span>
        </Overlay>
      </div>
    </div>
  );
};

export const DocumentLastUpdatedFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let updater =
    (allUsers || []).find(item => item?.id === row?.updatedBy)?.name || "N/A";

  return (
    <div className="d-flex align-items-center" style={{ maxWidth: "13rem" }}>
      <div className="d-flex flex-column" style={{ maxWidth: "8rem" }}>
        <span className="text-primary text-right font-weight-bold font-size-md mr-2">
          {moment(row?.lastUpdated).format("DD MMMM YYYY, hh:mm a")}
        </span>
      </div>
      <span className="bullet bullet-bar bg-primary align-self-stretch" />
      <div className="d-flex flex-column" style={{ maxWidth: "5rem" }}>
        <Overlay popoverContent={updater}>
          <span
            className="text-dark-75 text-hover-success text-light cursor-pointer font-weight-bold font-size-md ml-2"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {updater}
          </span>
        </Overlay>
      </div>
    </div>
  );
};
