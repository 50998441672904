import { createSlice } from "@reduxjs/toolkit";

const initialSetsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  setDeleted: false,
  allSets: undefined
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "sets",
  initialState: initialSetsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.setForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    setsFetched: (state, action) => {
      const {totalCount, entities, paginationOverride} = action.payload;
      state.listLoading = false;
      state.error = null
      state.makerCheckerAction = false;
      state.setDeleted = false;
      if (paginationOverride) {
        state.allSets = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearSetsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    setCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    setUpdated: (state, action) => {
      const {set} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = set;
    },
    setDeleted: (state) => {
      state.setDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
  }
});
