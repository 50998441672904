import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange } from "../../../_metronic/_helpers";
import { getNumberInCurrencyFormat } from "../../../_custom/_helpers/common";
// import { sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { getSelectRow } from "../../_helpers";

export function Collections({listData, listLoading, uiProps, totalCount, user, permissionsObject = {}, allProducts, gridType}) {
  // Getting ALl Users from State
  const {allUsers} = useSelector(
    (state) => ({
      allUsers: state.users.allUsers,
    }),
    shallowEqual
  );

  const [openedRow, setOpenedRow] = useState(null);
  let columns = [];
  if (gridType === 'orchestrate') {
    columns = [
      {
        dataField: "cnic",
        text: "National ID",
        // sort: true,
        // sortCaret: sortCaret,
        formatter: columnFormatters.NameColumnFormatter,
        formatExtraData: {
          parameters: {
            identifier: "accountId",
            level1: "cnic",
            isHideOptions: true
          },
          onViewPage: uiProps.startEngine,
          openedRow: openedRow,
          setOpenedRow: setOpenedRow,
          permissionsObject: permissionsObject
        }
      },
      {
        dataField: "cardCustID",
        text: "Card Cust ID",
      },
      {
        dataField: "custName",
        text: "Name",
      },
      {
        dataField: "product",
        text: "Product",
        formatter: columnFormatters.GenericDynamicFormatter,
        formatExtraData: {
          parameters: {
            identifier: "product",
            allItems: allProducts,
            isSearchFromArray: true
          },
        }
      },
      {
        dataField: "assignTo",
        text: "Assigned to",
        formatter: columnFormatters.AssignToFormatter,
        formatExtraData: {
          parameters: {
            identifier: "assignTo",
            allUsers: allUsers,
          },
        }
      },
      // {
      //   dataField: "strategy",
      //   text: "Strategy",
      //   formatter: columnFormatters.GenericDynamicFormatter,
      //   formatExtraData: {
      //     parameters: {
      //       identifier: "strategy"
      //     },
      //   }
      // },
      // {
      //   dataField: "queue",
      //   text: "Queue",
      //   formatter: columnFormatters.GenericDynamicFormatter,
      //   formatExtraData: {
      //     parameters: {
      //       identifier: "queue"
      //     },
      //   }
      // },
      {
        dataField: "totalDPD",
        text: "DPD (Days)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "totalDPD"
          },
          // unit: "Days",
          classes: "text-center text-danger"
        },
      },
      {
        dataField: "totalOverDue",
        text: "Total Over Due (PKR)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "totalOverDue"
          },
          formatter: getNumberInCurrencyFormat,
          formatterParam: "number",
          // unit: "PKR",
          classes: "text-right text-danger"
        },
      },
      {
        dataField: "amountDue",
        text: "Total Amount Due (PKR)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "amountDue"
          },
          formatter: getNumberInCurrencyFormat,
          formatterParam: "number",
          // unit: "PKR",
          classes: "text-right text-danger"
        },
      },
      {
        dataField: "cycleCut",
        text: "Cycle Cut",
      },
      {
        dataField: "ageCode",
        text: "Age Code",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: columnFormatters.AccountStatusColumnFormatter,
        formatExtraData: {
          user: user,
        },
      },
    ];
  } else {
    columns = [
      {
        dataField: "cnic",
        text: "National ID",
        // sort: true,
        // sortCaret: sortCaret,
        formatter: columnFormatters.NameColumnFormatter,
        formatExtraData: {
          parameters: {
            identifier: "accountId",
            level1: "cnic",
            isHideOptions: true
          },
          onViewPage: uiProps.startEngine,
          openedRow: openedRow,
          setOpenedRow: setOpenedRow,
          permissionsObject: permissionsObject
        }
      },
      {
        dataField: "cardCustID",
        text: "Card Cust ID",
      },
      {
        dataField: "custName",
        text: "Name",
      },
      {
        dataField: "product",
        text: "Product",
        formatter: columnFormatters.GenericDynamicFormatter,
        formatExtraData: {
          parameters: {
            identifier: "product",
            allItems: allProducts,
            isSearchFromArray: true
          },
        }
      },
      // {
      //   dataField: "strategy",
      //   text: "Strategy",
      //   formatter: columnFormatters.GenericDynamicFormatter,
      //   formatExtraData: {
      //     parameters: {
      //       identifier: "strategy"
      //     },
      //   }
      // },
      // {
      //   dataField: "queue",
      //   text: "Queue",
      //   formatter: columnFormatters.GenericDynamicFormatter,
      //   formatExtraData: {
      //     parameters: {
      //       identifier: "queue"
      //     },
      //   }
      // },
      {
        dataField: "totalDPD",
        text: "DPD (Days)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "totalDPD"
          },
          // unit: "Days",
          classes: "text-center text-danger"
        },
      },
      {
        dataField: "totalOverDue",
        text: "Total Over Due (PKR)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "totalOverDue"
          },
          formatter: getNumberInCurrencyFormat,
          formatterParam: "number",
          // unit: "PKR",
          classes: "text-right text-danger"
        },
      },
      {
        dataField: "amountDue",
        text: "Total Amount Due (PKR)",
        formatter: columnFormatters.QuantitativeColumnFormatter,
        formatExtraData: {
          parameters: {
            level1: "amountDue"
          },
          formatter: getNumberInCurrencyFormat,
          formatterParam: "number",
          // unit: "PKR",
          classes: "text-right text-danger"
        },
      },
      {
        dataField: "cycleCut",
        text: "Cycle Cut",
      },
      {
        dataField: "ageCode",
        text: "Age Code",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: columnFormatters.AccountStatusColumnFormatter,
        formatExtraData: {
          user: user,
        },
      },
    ];
  }


  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      ...uiHelpers.sizePerPageList,
      {text: "5000", value: 5000},
    ],
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };

  return (
    <DataGrid
      data={listData}
      listLoading={listLoading}
      columns={columns}
      paginationOptions={paginationOptions}
      type={uiProps.queryParams.type}
      onTableParamsChange={getHandlerTableChange(uiProps.setQueryParams)}
      isCheckbox={true}
      keyField="accountId"
      select={getSelectRow({
        identifier: "accountId",
        entities: listData,
        selectedItems: [...uiProps.selectedItems],
        setSelectedItems: uiProps.setSelectedItems,
      })}
    />
  );
}
