import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { imageExtensions } from "../../../_helpers/enums";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FileViewer from "react-file-viewer-multi";

export function Preview({
  viewingDocumentId,
  viewDocument,
  viewingDocumentExtension,
  viewType,
  item,
  handleOpenDocumentInNewTab,
  handleOpenDocumentInNewWindow,
  documentVersion,
  showFullScreenButton = true,
  showNewTabButton = true,
  showNewWindowButton = true
}) {
  const [zoom, setZoom] = useState({});
  const [rotation, setRotation] = useState({});
  const [flag, setFlag] = useState(false);
  const [fullScreen, setFullScreen] = useState({});

  const onZoom = type => {
    let currentScale = Number(zoom[viewingDocumentId] || 1);
    if (type === "In") {
      currentScale += 0.5;
    } else {
      currentScale -= 0.5;
    }
    let zooms = {
      [viewingDocumentId]: currentScale < 1 ? 1 : currentScale
    };
    setZoom(zooms);
  };

  const FullScreen = () => {
    setFlag(!flag);
    const fullSize = {
      [item ? item.viewId : viewingDocumentId]: !flag
    };
    setFullScreen(fullSize);
  };

  const onRotate = () => {
    const currentRotation =
      Number(rotation[item ? item.viewId : viewingDocumentId] || 0) + 90;
    const rotations = {
      [item ? item.viewId : viewingDocumentId]:
        currentRotation === 360 ? 0 : currentRotation
    };
    setRotation(rotations);
  };

  const scaleValue = zoom[viewingDocumentId] ? zoom[viewingDocumentId] : "";

  return (
    <div
      className="card card-custom card-border w-100 mb-5"
      style={
        fullScreen[item ? item.viewId : viewingDocumentId] === true
          ? { gridColumn: "1/3" }
          : {}
      }
    >
      <div className="card-header">
        <div
          className="card-title"
          style={{ maxWidth: "65%", minHeight: "70px" }}
        >
          {item ? (
            <h3
              className="card-label "
              style={{
                display: "inline-block",
                minWidth: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%"
              }}
            >
              {item?.name}
            </h3>
          ) : (
            <h3
              className="card-label"
              style={{
                display: "inline-block",
                minWidth: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%"
              }}
            >
              {viewDocument?.group}
              <br />
              <small>{viewDocument?.name}</small>
              {documentVersion && (
                <span className="label label-inline label-light-primary ml-2">
                  Version {documentVersion}
                </span>
              )}
            </h3>
          )}
        </div>
        <div className="card-toolbar">
          {showFullScreenButton && (
            <OverlayTrigger
              overlay={<Tooltip id="fullscreen-tooltip">Full Screen</Tooltip>}
            >
              <div
                onClick={() => FullScreen()}
                className="btn btn-xs btn-icon btn-success  mr-2"
              >
                {fullScreen[item ? item.viewId : viewingDocumentId] ? (
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                ) : (
                  <i className="fas fa-expand"></i>
                )}
              </div>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            overlay={<Tooltip id="ZoomIn-tooltip">Zoom In</Tooltip>}
          >
            <div
              onClick={() => onZoom("In")}
              className="btn btn-xs btn-icon btn-success  mr-2"
            >
              <i className="fa fa-search-plus" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={<Tooltip id="ZoomOut-tooltip">Zoom Out</Tooltip>}
          >
            <div
              onClick={() => onZoom("out")}
              className="btn btn-xs btn-icon btn-info  mr-2"
            >
              <i className="fa fa-search-minus" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={<Tooltip id="rotate-tooltip">Rotate</Tooltip>}
          >
            <div
              onClick={() => onRotate()}
              className="btn btn-xs btn-icon btn-danger"
            >
              <i className="fas fa-sync" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          {showNewTabButton && (
            <OverlayTrigger
              overlay={
                <Tooltip id="fullscreen-tooltip">Open in new tab</Tooltip>
              }
            >
              <div
                onClick={() =>
                  handleOpenDocumentInNewTab(viewDocument, documentVersion)
                }
                className="btn btn-xs btn-icon btn-primary ml-2"
              >
                <i className="fas fa-external-link-alt ml-1"></i>
              </div>
            </OverlayTrigger>
          )}

          {showNewWindowButton && (
            <OverlayTrigger
              overlay={
                <Tooltip id="fullscreen-tooltip">Open in new window</Tooltip>
              }
            >
              <div
                onClick={() =>
                  handleOpenDocumentInNewWindow(viewDocument, documentVersion)
                }
                className="btn btn-xs btn-icon btn-warning ml-2"
              >
                <i className="fas fa-window-restore ml-1"></i>
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {viewType === "multiView" && item ? (
        <ScrollContainer className="scroll-container">
          <div
            className={`card-body rotate${
              !imageExtensions.includes(item?.viewExtension)
                ? `-${item?.viewExtension}`
                : ""
            }-${rotation[item?.viewId] || 0}`}
            style={
              scaleValue === 1
                ? {}
                : {
                    transform: `scale(${scaleValue}, ${scaleValue})`,
                    transformOrigin: "left top"
                  }
            }
          >
            {imageExtensions.includes(item?.viewExtension) ? (
              <div style={{ height: "50%" }}>
                <img
                  className="card-img"
                  src={item?.viewId}
                  alt=""
                  style={{ margin: "70px 0" }}
                />
              </div>
            ) : (
              <FileViewer
                fileType={item?.viewExtension}
                filePath={item?.viewId}
                onError={error => {
                  console.log(error);
                }}
              />
            )}
          </div>
        </ScrollContainer>
      ) : (
        <ScrollContainer className="scroll-container">
          <div
            className={`card-body rotate${
              !imageExtensions.includes(viewingDocumentExtension)
                ? `-${viewingDocumentExtension}`
                : ""
            }-${rotation[viewingDocumentId] || 0}`}
            style={
              scaleValue === 1
                ? {}
                : {
                    transform: `scale(${scaleValue}, ${scaleValue})`,
                    transformOrigin: "left top"
                  }
            }
          >
            {imageExtensions.includes(viewingDocumentExtension) ? (
              <div style={{ height: "50%" }}>
                <img className="card-img" src={viewingDocumentId} alt="" />
              </div>
            ) : (
              <FileViewer
                fileType={viewingDocumentExtension}
                filePath={viewingDocumentId}
                onError={error => {
                  console.log(error);
                }}
              />
            )}
          </div>
        </ScrollContainer>
      )}
    </div>
  );
}
