import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { TestUserModal } from "./TestUserModal";
import { errorNotification } from "../../../_custom/_partials/notifications/NotificationHandler";

export const ErrorHandler = () => {
  const [modalShow, setModalShow] = useState(false);
  const {errors} = useSelector(
    (state) => ({
      errors: state.errors.errors || [],
    }),
    shallowEqual
  );

  useEffect(() => {
    if(errors?.length) {
      let message = errors[errors.length - 1].data?.message
      if(message === true){
      setModalShow(true)
      }
      errorNotification(message || "Unexpected error occurred");
    }
  }, [errors])


  return (
    <TestUserModal show={modalShow}  onHide={() => setModalShow(false)}/>
  )
}
