import React from "react";
import InputMask from "react-input-mask";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { Overlay } from "../../controls/forms/Overlay";

const getFieldCSSClasses = (touched, errors, isHideValidations) => {
  const classes = ["form-control"];

  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }

  return classes.join(" ");
};

export function MaskedInput({
  form,
  field, // { name, value, onChange, onBlur }
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  defaultValue,
  description,
  meta,
  disabled,
  isReadOnly,
  classes,
  showDescription,
  setFieldValue,
  maxLength,
  ...props
}) {
  const { touched, error } = meta;

  return (
    <>
      {label && <label>{label}</label>}
      {props.required ? <span className="text-danger"> *</span> : null}
      {showDescription ? (
        <Overlay popoverContent={description}>
          <i
            className={
              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
            }
          />
        </Overlay>
      ) : null}
      {(props?.tags || []).find(
        tag => tag === "maskedCNIC" || tag.code === "maskedCNIC"
      ) && (
        <InputMask
          mask="99999-9999999-9"
          maskChar={null}
          className={
            getFieldCSSClasses(touched, error, props.isHideValidations) +
            " " +
            classes +
            `${type === "number" ? " noscroll" : ""}`
          }
          {...field}
          {...props}
          disabled={
            isReadOnly ||
            disabled ||
            field.disabled ||
            (props?.name === "defaultValue" &&
              form?.values["defaultValueType"] === 1)
          }
          value={
            (props?.name ? props.overrideDefaultValues?.[props?.name] : null) ||
            field.value
          }
          onChange={val => {
            if (props?.copyValueTo && props?.setOverrideDefaultValues) {
              props.setOverrideDefaultValues({
                ...(props?.overrideDefaultValues || {}),
                [props.copyValueTo]: val?.target?.value
              });
            }

            setFieldValue(field.name, val?.target?.value);
          }}
        />
      )}
      {(props?.tags || []).find(
        tag => tag === "oldCNIC" || tag.code === "oldCNIC"
      ) && (
        <InputMask
          mask="999-99-999999"
          maskChar={null}
          className={
            getFieldCSSClasses(touched, error, props.isHideValidations) +
            " " +
            classes +
            `${type === "number" ? " noscroll" : ""}`
          }
          {...field}
          {...props}
          disabled={
            isReadOnly ||
            disabled ||
            field.disabled ||
            (props?.name === "defaultValue" &&
              form?.values["defaultValueType"] === 1)
          }
          value={
            (props?.name ? props.overrideDefaultValues?.[props?.name] : null) ||
            field.value
          }
          onChange={val => {
            if (props?.copyValueTo && props?.setOverrideDefaultValues) {
              props.setOverrideDefaultValues({
                ...(props?.overrideDefaultValues || {}),
                [props.copyValueTo]: val?.target?.value
              });
            }

            setFieldValue(field.name, val?.target?.value);
          }}
        />
      )}{" "}
      {!props.isHideValidations && withFeedbackLabel && touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
