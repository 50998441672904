import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { FieldFeedbackLabel } from "../../../../_custom/_partials/controls/forms/FieldFeedbackLabel";
import { sortDropdownsOptions } from "../../../_helpers";

const animatedComponents = makeAnimated();

export function ReactCreatableSelect(props) {
  const { label, disabled, isReadOnly, touched, error, isMulti, value } = props;

  const [maxToShow, setMaxToShow] = useState(Number(value?.length));

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      background: "#E6E6E6",
      borderRadius: "2px",
      fontSize: "85%",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
      pointerEvents: "auto"
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `${length} item${length !== 1 ? "s" : ""} selected`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    let overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);

    const title = [props.children].join(", ");

    return (
      <div>
        {maxToShow !== 0 ? (
          <div title={title}>
            <components.MultiValue {...props} />
          </div>
        ) : index === maxToShow ? (
          <div>
            <MoreSelectedBadge items={overflow} />
          </div>
        ) : null}
      </div>
    );
  };

  const customStyle = {
    control: styles => ({
      ...styles,
      // border: "2px solid #3699FF",
      maxHeight: "200px",
      scrollX: "scroll",
      overflowY: "auto",
      pointerEvents: "auto"
    }),
    valueContainer: styles => ({
      ...styles,
      pointerEvents: disabled || isReadOnly ? "none" : "auto"
    }),
    multiValue: (styles, { data }) => {
      // console.log("data: ", data)
      return {
        ...styles,
        backgroundColor: data.bgColor ? data.bgColor : "#E6E6E6"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.bgColor,
      color: data.color,
      fontWeight: 500,
      maxWidth: "125px",
      pointerEvents: "auto"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2 // Set your desired zIndex value here
    })
  };

  const handleExpandCollapse = () => {
    if (maxToShow !== 0) {
      setMaxToShow(0);
    } else {
      setMaxToShow(Number(value?.length));
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: 1 }}>
          <CreatableSelect
            {...props}
            isDisabled={disabled || isReadOnly}
            styles={customStyle}
            components={{
              animatedComponents,
              MultiValue
            }}
            closeMenuOnSelect={false}
            openMenuOnClick={maxToShow === 0 || !value?.length}
            options={sortDropdownsOptions(props.options)}
            isMulti={isMulti}
          />
        </div>
        <button
          type="button"
          style={{
            display: value !== undefined && value.length > 0 ? "flex" : "none",
            marginLeft: "5px",
            boxSizing: "border-box",
            border: "1px solid hsl(0,0%,80%)",
            borderRadius: "4px",
            background: "white",
            padding: "18.34px"
          }}
          className="btn btn-sm btn-icon btn-light btn-hover-light"
          onClick={handleExpandCollapse}
        >
          {maxToShow !== 0 ? (
            <i className="fas fa-compress" title={"minimize items"} />
          ) : (
            <i className="fas fa-expand" title={"show selected items"} />
          )}
        </button>
      </div>
      {error && !props.isHideValidations && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label.toUpperCase()}
        />
      )}
    </>
  );
}
