// App.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
// not importing bootstrap css because its conflicting with App css
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  deleteDynamicFormGridRow,
  getDeletedGridRows,
  uploadDocument
} from "../../../_redux/actions";
import {
  dropDownOptions,
  extensionTypes
} from "../../../../../../_custom/_helpers/staticFields";
import {
  createPaginationData,
  onChangePage
} from "../../../../../../_metronic/_helpers";
import {
  errorNotification,
  successNotification
} from "../../../../../../_custom/_partials/notifications";
import {
  Button,
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { ActionsFormatter } from "./ActionsFormatter";
import { ImportExcelErrorDialog } from "./ImportExcelErrorDialog";
import { Dialog } from "../../../../../../_custom/_partials/Dialog";
import GridFieldModalForm from "./GridFieldModalForm";
import {
  Export,
  SkeletonComponent
} from "../../../../../../_custom/_partials/controls";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import {
  getPermissionsObject,
  sortByAlphabeticalOrder
} from "../../../../../../_custom/_helpers";
import { useCurrentModulePermission } from "../../../../../../_custom/_hooks";
import { DataGrid } from "../../../../../../_metronic/_partials/lists";
import { PrimaryKeyColumnFormatter } from "./PrimaryKeyColumnFormatter";
import { toBase64 } from "../../../../../helper/functions";
import { ApplicationDynamicFormatter } from "../../../../../../_custom/_partials/grids/column-formatters";

const defaultPaginationOptions = {
  custom: true,
  data: [],
  totalSize: 0,
  sizePerPageList: [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  sizePerPage: 5,
  page: 1
};

function ListFieldGrid({
  gridName,
  mode,
  isValidating,
  meta,
  errors,
  keyField,
  data,
  formData,
  columns,
  sortedBy,
  subFields,
  onChangeGrid,
  required,
  disabled,
  isRequired,
  isReadOnly,
  tags,
  stageConditions,
  hidden,
  shouldHideActionFormatter,
  productCode,
  identifier,
  isImportAllowed,
  onCopyToRoot,
  allRecords,
  productName = "",
  isHideValidations,
  uniqueSubFields,
  customers,
  setCustomerModalMode,
  widget,
  activeStageCode,
  fieldCode,
  gridCode
}) {
  const urlParams = useParams();
  const dispatch = useDispatch();

  const [pageOptions, setPageOptions] = useState(defaultPaginationOptions);
  const [gridData, setGridData] = useState([]);
  const [openedRow, setOpenedRow] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [modalValues, setModalValues] = useState({});
  const [modalCurrentIndex, setModalCurrentIndex] = useState(-1);
  const [importLoader, setImportloader] = useState(false);
  const [importData, setImportData] = useState([]);
  const [failedFields, setFailedFields] = useState([]);
  const [errorDialog, setErrorDialog] = useState(false);
  const [exportParameters, setExportParameters] = useState([]);
  const [hideActionsOnCustomerRow, setHideActionsOnCustomerRow] = useState(
    false
  );
  const [permissionTags, setPermissionTags] = useState([]);
  const [expandableMinimumLimit] = useState(8);
  const [formikInstance, setFormikInstance] = useState(null);
  const [isShowDeletedGridRows, setIsShowDeletedGridRows] = useState(false);
  const [deletedData, setDeletedData] = useState([]);
  const {
    fieldsConfig,
    permissionsDictionary,
    allFields,
    allEntities,
    recordsState,
    fieldsDictionary,
    modalShow,
    actionsLoading
  } = useSelector(
    state => ({
      fieldsConfig: state.fields?.fieldsWithDetails,
      permissionsDictionary: state.groups.permissionsDictionary,
      allFields: state.fields.entities,
      allEntities: state.entities.entities,
      recordsState: state.records,
      fieldsDictionary: state.fields.fieldsDictionary,
      modalShow: state.modal.show,
      actionsLoading: state.workflows.actionsLoading
    }),
    shallowEqual
  );

  const getField = code => fieldsConfig.find(field => field.code === code);
  let subFieldsConfiguration = subFields?.map(fld =>
    fieldsConfig?.find(field => field?.code === fld)
  );
  const [permissionsObject] = useState(
    getPermissionsObject(
      useCurrentModulePermission(permissionsDictionary, "workflows")
    )
  );

  useEffect(() => {
    if (isShowDeletedGridRows) {
      dispatch(
        getDeletedGridRows({
          productCode: productCode,
          identifier: identifier,
          stageCode: activeStageCode,
          gridCode: gridCode
        })
      ).then(res => {
        setDeletedData(res?.data || []);
      });
    }
    // eslint-disable-next-line
  }, [isShowDeletedGridRows]);

  useEffect(() => {
    const gridPermissions = [
      "AllowAddRowInGrid",
      "AllowEditRowInGrid",
      "AllowDeleteRowInGrid",
      "AllowCloneRowInGrid"
    ];
    setPermissionTags(
      gridPermissions.filter(item =>
        (tags || []).some(obj => obj.code === item)
      )
    );
  }, [tags]);

  useEffect(() => {
    if (data?.length) {
      if (typeof data === "string") {
        try {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          data = JSON.parse(data);
        } catch (error) {
          data = [];
        }
      }

      let tempExportParameters = [];
      for (const [key] of Object.entries(data?.[0] || {})) {
        let hidden =
          (fieldsConfig || []).find(item => item?.code === key)?.isHidden ||
          (fieldsConfig || []).find(item => item?.value === key)?.hidden;
        if (!hidden) {
          tempExportParameters.push({
            name: fieldsDictionary[key]?.name
              ? `${fieldsDictionary[key]?.name}`
              : key,
            code: key
          });
        }
      }

      setExportParameters(tempExportParameters);
    }

    setGridData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setPageOptions(currOptions =>
      createPaginationData({
        ...currOptions,
        allData: isShowDeletedGridRows ? deletedData : gridData
      })
    );
  }, [gridData, deletedData, isShowDeletedGridRows]);

  const onTableParamsChange = (type, newState) => {
    if (type === "pagination")
      setPageOptions(currOptions =>
        onChangePage({
          ...currOptions,
          page: newState.page === undefined ? 1 : newState.page,
          sizePerPage: newState.sizePerPage || currOptions.sizePerPage
        })
      );
  };

  // const getSortedDropdownOptions = dropdownOptions => {
  //   let sortedDropdownOptions = { ...dropdownOptions };
  //   Object.keys(dropdownOptions).forEach(item => {
  //     sortedDropdownOptions[item] = sortByAlphabeticalOrder(
  //       [...(dropdownOptions[item]?.records || [])],
  //       "name"
  //     );
  //   });
  //   return sortedDropdownOptions;
  // };

  // sorted data types and other static fields values
  let sortedDropdownOptions = { ...dropDownOptions };
  Object.keys(sortedDropdownOptions).forEach(
    item =>
      (sortedDropdownOptions[item] = sortByAlphabeticalOrder(
        sortedDropdownOptions[item],
        "name"
      ))
  );

  useEffect(() => {
    if (failedFields.length !== 0) {
      setErrorDialog(true);
    }
  }, [failedFields]);

  useEffect(() => {
    if (fieldsConfig?.length && importData.length !== 0) {
      let updatedImportData = [...importData];
      for (let i = importData.length - 1; i >= 0; i--) {
        // eslint-disable-next-line array-callback-return
        Object.keys(importData[i]).some(function(key, index) {
          if (getField(key)?.dataType === 7) {
            importData[i][key] = moment(
              importData[i][key],
              "DD/MMM/YYYY"
            ).format();
          }
          if (getField(key)?.dataType === 1) {
            importData[i][key] = Number(
              importData[i][key].replace(/,/g, "").trim()
            );
          }
          if (
            getField(key)?.isRequired === true &&
            importData[i][key].length === 0
          ) {
            setFailedFields(prevArray => [...prevArray, i]);
            updatedImportData.splice(i, 1);
            return true;
          }
        });
      }
      let currentGridData = [...(gridData || [])];
      (updatedImportData || []).forEach((data, index) => {
        currentGridData.push(data);
        setGridData(currentGridData);
        onChangeGrid(currentGridData);
        if (index + 1 === updatedImportData.length) {
          setImportloader(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsConfig, importData]);

  const importExcel = file => {
    setImportloader(true);
    var fileExtension = file?.name?.split(".").pop();
    if (["xlsx", "xls", "csv"].includes(fileExtension)) {
      uploadFile(file);
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);

        fileReader.onload = e => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, {
            type: "binary",
            cellDates: true,
            dateNF: "dd/mm/yyyy"
          });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { raw: false, defval: "" });
          resolve(data);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });

      promise.then(dataList => {
        dataList.shift();
        setImportData(dataList);
      });
    } else {
      errorNotification("The Selected file is Invalid.");
      setImportloader(false);
    }
  };

  const uploadFile = async file => {
    let result = await toBase64(file).catch(e => Error(e));
    let code = file?.name?.replace(/[^a-zA-Z 0-9]/g, "") + "_" + uuidv4();
    if (result) {
      let extension =
        extensionTypes.find(fld => file?.type?.split("/")[1] === fld?.mimeType)
          ?.extension ||
        file?.type?.split("/")[1] ||
        file?.name?.split(".")?.[1];
      let payload = {
        bucketCode: productCode?.toLowerCase(),
        identifier: identifier,
        name: file.name,
        code: code,
        description: "",
        file: result.split(",")[1],
        extension: extension,
        isComplete: true,
        groupCode: ""
      };
      dispatch(uploadDocument(payload)).then(res => {
        if (res?.status === 200) {
          successNotification("Document uploaded successfully.");
        } else {
          errorNotification("Document not uploaded.");
        }
      });
    }
  };

  const onDelete = (rowCode, rowData, rowIndex) => {
    let currentGridData = [...gridData];
    const gridConfiguration = fieldsDictionary[fieldCode];

    if (gridConfiguration?.tags?.includes("shouldCallDeleteApi")) {
      const gridIdentifierCode = gridConfiguration?.uniqueSubFields[0];
      const gridIdentifierValue = rowData[gridIdentifierCode];

      const payload = {
        productCode,
        identifier,
        gridCode: fieldCode,
        stageCode: activeStageCode,
        gridIdentifierCode,
        gridIdentifierValue
      };

      dispatch(deleteDynamicFormGridRow(payload)).then(res => {
        if (res?.status === 200) {
          currentGridData.splice(rowIndex, 1);
          if (rowData.primaryKey === "True" && currentGridData?.length) {
            currentGridData[0].primaryKey = "True";
          }
          updateIndexKeys(currentGridData);
          setGridData(currentGridData);
          onChangeGrid(currentGridData);
          successNotification(
            "Record successfully deleted. Please press validate button."
          );
        } else {
          errorNotification("Unable to delete record.");
        }
      });
    } else {
      currentGridData.splice(rowIndex, 1);
      if (rowData.primaryKey === "True" && currentGridData?.length) {
        currentGridData[0].primaryKey = "True";
      }
      let dataWithUpdatedIndex = updateIndexKeys(currentGridData);
      setGridData(dataWithUpdatedIndex);
      onChangeGrid(dataWithUpdatedIndex);
    }
  };

  const updateIndexKeys = data => {
    (data || []).forEach((item, index) => {
      item.serialNumber = (index + 1).toString();
    });
    return data;
  };
  const getCurrentColumns = activeColumns => {
    if (!activeColumns?.length) return activeColumns;

    let allColumns = [...activeColumns];

    let firstColumn;

    const fieldProperties = (activeColumns || []).map(
      column => column?.formatExtraData?.parameters?.field
    );

    const firstIndexWithoutHideColumnTag = (fieldProperties || []).findIndex(
      property => !property?.tags?.includes("hideColumnFromGrid")
    );

    if (firstIndexWithoutHideColumnTag !== -1) {
      firstColumn = activeColumns?.[firstIndexWithoutHideColumnTag];
    } else {
      firstColumn = activeColumns[0];
    }
    let serialNumberColumn = {
      key: "serialNumber",
      dataField: "serialNumber",
      text: "Serial No",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        let serialIndex = row?.serialNumber;
        if (gridData?.[serialIndex]?.serialNumber && !isShowDeletedGridRows) {
          return (
            <span>{gridData[serialIndex - 1]?.serialNumber || "N/A"}</span>
          );
        }
        return <span>{serialIndex || "N/A"}</span>;
      }
    };

    let primaryColumn;
    if (subFields?.includes("primaryKey")) {
      primaryColumn = {
        key: "",
        dataField: "",
        text: "",
        sort: false,
        formatter: PrimaryKeyColumnFormatter,
        formatExtraData: {
          onChangeKey
        }
      };
    }

    firstColumn = {
      ...firstColumn,
      formatter: ActionsFormatter,
      formatExtraData: {
        ...firstColumn.formatExtraData,
        onView: (rowCode, rowData, rowIndex) => {
          setModalOpen(true);
          setModalMode("view");
          setModalValues(rowData);
          setModalCurrentIndex(rowIndex);
        },
        onEdit: (rowCode, rowData, rowIndex) => {
          setModalOpen(true);
          setModalMode("edit");
          setModalValues(rowData);
          setModalCurrentIndex(rowIndex);
        },
        onClone: (rowCode, rowData) => {
          let { primaryKey, ...cloneData } = rowData;
          if (subFields?.includes("primaryKey") && uniqueSubFields?.length) {
            for (let i = 0; i < uniqueSubFields?.length; i++) {
              delete cloneData[uniqueSubFields[i]];
            }
          }
          setModalOpen(true);
          setModalMode("clone");
          setModalValues(cloneData);
          setModalCurrentIndex(gridData.length || 0);
        },
        onDelete: onDelete,
        onCopyToRoot: (rowCode, rowData, rowIndex) => {
          onCopyToRoot(rowData);
        },
        openedRow,
        setOpenedRow,
        shouldHideActionFormatter,
        mode: disabled || isReadOnly ? "view" : mode,
        isShowCopyButton:
          urlParams.mode !== "view" && permissionsObject?.copytoform,
        hideActionsOnCustomerRow,
        setHideActionsOnCustomerRow,
        gridData,
        checkTagBasedPermission,
        isShowDeletedGridRows
      }
    };

    if (firstIndexWithoutHideColumnTag !== -1) {
      allColumns[firstIndexWithoutHideColumnTag] = firstColumn;
    } else {
      allColumns[0] = firstColumn;
    }
    allColumns.unshift(serialNumberColumn);
    (allColumns || []).forEach((column, i) => {
      if (i !== 0) {
        allColumns[i] = {
          ...column,
          formatExtraData: {
            ...column?.formatExtraData,
            setHideActionsOnCustomerRow
          }
        };
      }
    });

    let finalColumns = [];

    if (primaryColumn) {
      finalColumns = [primaryColumn, ...allColumns];
    } else {
      finalColumns = allColumns;
    }

    return finalColumns.filter(
      item =>
        !((fieldsDictionary[item.key] || {})?.tags || []).includes(
          "hideColumnFromGrid"
        )
    );
  };

  const onChangeKey = (row, rowIndex) => {
    let currentGridData = [...(gridData || [])];
    if (row.primaryKey !== "True") {
      for (let i = 0; i < currentGridData.length; i++) {
        if (i === rowIndex) {
          currentGridData[i] = { ...currentGridData[i], primaryKey: "True" };
        } else {
          currentGridData[i] = { ...currentGridData[i], primaryKey: "False" };
        }
      }
      setGridData(currentGridData);
      onChangeGrid(currentGridData);
    }
  };

  const onSubmitModal = values => {
    const currentUpdatedIndex =
      pageOptions.sizePerPage * (pageOptions.page - 1) + modalCurrentIndex;
    let currentGridData = [...(gridData || [])];
    let updatedValues = {};

    subFields.forEach(subField => {
      if (values[subField] === undefined) {
        updatedValues[subField] = null;
      } else {
        updatedValues[subField] = values[subField];
      }
    });

    if (modalMode === "edit") {
      let newIndex = modalCurrentIndex + 1;
      currentGridData[currentUpdatedIndex] = {
        ...values,
        serialNumber: newIndex?.toString()
      };
    } else {
      let newIndex = currentGridData?.length + 1;
      if (
        subFields?.includes("primaryKey") &&
        (!gridData || gridData?.length === 0)
      ) {
        currentGridData.push({
          ...updatedValues,
          primaryKey: "True",
          serialNumber: newIndex?.toString()
        });
      } else {
        currentGridData.push({
          ...updatedValues,
          serialNumber: newIndex?.toString()
        });
      }
    }

    setGridData(currentGridData);
    onChangeGrid(currentGridData);

    setModalOpen(false);
    setModalMode("add");
    setModalValues({});
    setModalCurrentIndex(-1);
  };

  const onCancelModal = () => {
    setModalOpen(false);
    setModalMode("add");
    setModalValues({});
    setModalCurrentIndex(-1);
  };

  const errorsCount = formik => {
    return Object.keys(formik?.errors || {})?.length || 0;
  };

  const renderExpandedData = row => {
    const extraColumns = getCurrentColumns(columns).slice(
      expandableMinimumLimit,
      columns.length + 1
    );

    return (
      <div className="row expandable-grid-items">
        {extraColumns.map(column => {
          const field = fieldsDictionary[column.key] || {};
          if (field.isHidden) {
            return null;
          }
          let itemValue = ApplicationDynamicFormatter(row[column.key], row, 1, {
            parameters: {
              withSpan: false,
              identifier: column.key,
              ...field,
              allRecords: {
                ...(recordsState || {}),
                ...(fieldsDictionary || {})
              },
              field: fieldsDictionary[column.key] || {}
            },
            setHideActionsOnCustomerRow
          });

          return (
            <div className="col-md-2">
              <div className="d-flex flex-column text-dark-75 expandable-grid-item">
                <span className="font-size-xs" title={column.text}>
                  {column.text}
                </span>
                <span
                  className="font-weight-bolder font-size-md expandable-grid-item-value"
                  title={
                    typeof itemValue.props?.title !== "number" &&
                    !String(itemValue.props?.title || "").trim()
                      ? "N/A"
                      : itemValue
                  }
                >
                  {typeof itemValue.props?.title !== "number" &&
                  !String(itemValue.props?.title || "").trim()
                    ? "N/A"
                    : itemValue}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const expandComponent = {
    renderer: row => renderExpandedData(row),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="icon-md far fa-window-minimize" />;
      }
      return <i className="icon-md fas fa-angle-right" />;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <i className="icon-md fas fa-angle-down" />;
      }
      return <i className="icon-md fas fa-angle-right" />;
    }
  };

  const checkTagBasedPermission = action => {
    if (permissionTags.length) {
      if (action === "add") {
        return permissionTags.includes("AllowAddRowInGrid");
      } else if (action === "edit") {
        return permissionTags.includes("AllowEditRowInGrid");
      } else if (action === "delete") {
        return permissionTags.includes("AllowDeleteRowInGrid");
      } else if (action === "clone") {
        return permissionTags.includes("AllowCloneRowInGrid");
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return !columns ? (
    <>
      <h6>Loading grid... please wait</h6>
      <div className="row mb-2">
        <SkeletonComponent rows={1} columns={1} skeletonClass="col-md-4" />
        <SkeletonComponent rows={1} columns={1} skeletonClass="col-md-4" />
        <SkeletonComponent rows={1} columns={1} skeletonClass="col-md-4" />
      </div>
    </>
  ) : columns?.length === 0 ? (
    <></>
  ) : (
    <>
      <DataGrid
        wrapperClasses="table-responsive application-form-tables"
        classes={`table ${
          isShowDeletedGridRows ? "table-head-danger" : "table-head-custom"
        } table-vertical-center table-hover overflow-hidden`}
        caption={
          <>
            <label
              className={`font-weight-normal ${
                meta.error?.length ? "font-weight-bolder" : ""
              }`}
            >
              {gridName}{" "}
              {required || isRequired ? (
                <span className={"text-danger"}> *</span>
              ) : null}
              {meta.error?.length && !isHideValidations ? (
                <span className="font-weight-normal text-danger ml-5">
                  {meta.error}
                </span>
              ) : null}{" "}
              {isShowDeletedGridRows ? (
                <span className="text-muted font-weight-bold">
                  (Currently, You are viewing deleted rows)
                </span>
              ) : null}
              {/*{ isError?.length ? <span className="font-weight-normal text-danger ml-5">{gridName} must contain at least 1 row</span> :*/}
              {/*  errors?.length ? <span className="font-weight-normal text-danger ml-5">{errors}</span> : null*/}
              {/*}*/}
            </label>

            <div style={{ position: "absolute", right: "10px", top: "2px" }}>
              {!isShowDeletedGridRows ? (
                gridData?.length ? (
                  <Export
                    names={`${
                      productName ? `${productName} -` : ""
                    } ${gridName}${identifier ? `(${identifier})` : ""}`}
                    overrideSheetName={`${identifier}`}
                    lists={gridData}
                    overrideData={{
                      override: true,
                      smallButton: true,
                      exportParameters: exportParameters,
                      fieldsConfig: fieldsConfig,
                      fieldsDictionary: fieldsDictionary,
                      allRecords: allRecords,
                      formattedList: true
                    }}
                  />
                ) : null
              ) : deletedData?.length ? (
                <Export
                  names={`${productName ? `${productName} -` : ""} ${gridName}${
                    identifier ? `(${identifier})` : ""
                  }`}
                  overrideSheetName={`${identifier}`}
                  lists={deletedData}
                  overrideData={{
                    override: true,
                    smallButton: true,
                    exportParameters: exportParameters,
                    fieldsConfig: fieldsConfig,
                    fieldsDictionary: fieldsDictionary,
                    allRecords: allRecords,
                    formattedList: true
                  }}
                />
              ) : null}

              {tags?.find(
                tag =>
                  tag === "shouldCallDeleteApi" ||
                  tag?.code === "shouldCallDeleteApi"
              ) &&
              tags?.find(
                tag =>
                  tag === "maintainHistory" || tag?.code === "maintainHistory"
              ) ? (
                !isShowDeletedGridRows ? (
                  <div className="btn-group btn-group-sm ml-2">
                    <Button
                      variant="danger"
                      disabled={isValidating || actionsLoading}
                      onClick={() => setIsShowDeletedGridRows(true)}
                    >
                      Show deleted rows(s)
                    </Button>
                  </div>
                ) : (
                  <div className="btn-group btn-group-sm ml-2">
                    <Button
                      variant="primary"
                      disabled={isValidating || actionsLoading}
                      onClick={() => setIsShowDeletedGridRows(false)}
                    >
                      Hide deleted rows(s)
                    </Button>
                  </div>
                )
              ) : null}

              {mode !== "view" &&
              !(isReadOnly || disabled) &&
              checkTagBasedPermission("add") &&
              !isShowDeletedGridRows ? (
                <>
                  <input
                    type="file"
                    className="d-none"
                    id="getFile"
                    accept=".xls, .xlsx, .csv"
                    onChange={e => {
                      const file = e.target.files[0];
                      importExcel(file);
                      e.target.value = null;
                    }}
                  />
                  <Dropdown
                    as={ButtonGroup}
                    size="sm"
                    className="float-right mr-n3 ml-2"
                    alignRight
                  >
                    <Button
                      variant="primary"
                      disabled={isValidating || actionsLoading}
                      onClick={() => setModalOpen(true)}
                    >
                      Add
                    </Button>
                    {isImportAllowed ? (
                      <>
                        <Dropdown.Toggle
                          split
                          variant="primary"
                          id="dropdown-split-basic"
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={event => {
                              event.preventDefault();
                              document.getElementById("getFile").click();
                            }}
                            disabled={importLoader || isValidating || modalShow}
                          >
                            Import from Excel
                            {importLoader ? (
                              <div
                                className="spinner-border spinner-border-sm align-middle"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : null}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </>
                    ) : null}
                  </Dropdown>
                </>
              ) : null}
            </div>
          </>
        }
        paginationOptions={pageOptions}
        rowKey={"id" || columns[0]?.key || keyField}
        keyField={"id" || columns[0]?.key || keyField}
        data={
          !isShowDeletedGridRows
            ? (pageOptions?.data || []).map(item => ({
                ...item,
                id: uuidv4()
              }))
            : (deletedData || []).map(item => ({
                ...item,
                id: uuidv4()
              }))
        }
        columns={getCurrentColumns(columns).slice(0, expandableMinimumLimit)}
        onTableParamsChange={onTableParamsChange}
        isExpandableRow={columns.length > expandableMinimumLimit}
        expandRow={
          columns.length > expandableMinimumLimit &&
          (tags || []).find(tag => tag.code === "disableCaret") === undefined
            ? expandComponent
            : undefined
        }
      />

      <Dialog
        size="xl"
        backdrop={"static"}
        isCentered={true}
        show={isModalOpen}
        onHide={onCancelModal}
        modalTitle={
          <div className="d-flex">
            {gridName}
            {Boolean(errorsCount(formikInstance)) ? (
              <OverlayTrigger
                rootClose={true}
                trigger={["hover", "focus"]}
                onExit={() => {}}
                delay={{ show: 150, hide: 500 }}
                placement={"right"}
                overlay={props => (
                  <Tooltip id="button-tooltip" {...props}>
                    {(subFieldsConfiguration || [])
                      .filter(field => formikInstance.errors[field.code])
                      ?.map(fld => (
                        <React.Fragment key={fld.code}>
                          <span className={"text-danger text-align-left"}>
                            {formikInstance.errors[fld.code] || ""}
                          </span>
                          <br />
                        </React.Fragment>
                      ))}
                  </Tooltip>
                )}
              >
                <span
                  className={`label label-md label-danger font-weight-bold label-inline rounded-2 cursor-pointer ml-5 mt-1`}
                >
                  {Number(errorsCount(formikInstance))} field(s) have error(s)
                </span>
              </OverlayTrigger>
            ) : null}
          </div>
        }
        modalBody={
          <GridFieldModalForm
            values={modalValues}
            formData={formData}
            mode={modalMode}
            formMode={mode}
            fields={subFields}
            dropdownOptions={{
              // ...getSortedDropdownOptions(recordsState || fieldsDictionary),
              ...(recordsState || fieldsDictionary),
              ...sortedDropdownOptions,
              dependentFields: allFields,
              masterDataTypes: allEntities,
              fields: allFields
            }} // (pick out required fields from state.fields.entities and return an object with [code]: values pairs)
            stageConditions={stageConditions}
            gridData={isShowDeletedGridRows ? deletedData : gridData}
            uniqueSubFields={uniqueSubFields}
            onSubmit={onSubmitModal}
            onCancel={onCancelModal}
            customers={customers}
            setCustomerModalMode={setCustomerModalMode}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            modalCurrentIndex={modalCurrentIndex}
            widget={widget}
            hideCodeInDropdownLabel={true}
            setFormikInstance={setFormikInstance}
            gridCode={fieldCode}
          />
        }
        modalBodyClasses={""}
        modalFooter={null}
        noFooter={true}
        isLoading={false}
      />

      <ImportExcelErrorDialog
        show={errorDialog}
        failedFields={failedFields}
        allFields={fieldsConfig}
        importData={importData}
        setErrorDialog={setErrorDialog}
        setFailedFields={setFailedFields}
      />
    </>
  );
}

export default ListFieldGrid;
