import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { chunk } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Preview } from "./Preview";
import { downloadDocumentVersion } from "../../../../app/modules/Workflows/_redux/actions";
import { DocumentUploadCard } from "./DocumentUploadCard";

export function DocumentsUpload({
  documents,
  loadMore,
  handleFileSelected,
  uploadingFile,
  isAllowUpload,
  viewType,
  acceptFileType,
  maxSizeOfFile,
  viewingDocumentId,
  setViewingDocumentId
}) {
  const [combinedDocuments, setCombinedDocuments] = useState([]);
  const [viewDocument, setViewDocument] = useState({});
  const [viewingDocumentExtension, setViewingDocumentExtension] = useState(
    null
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (documents?.length) {
      setCombinedDocuments(chunk(documents, 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const handleView = async (data, versionCount) => {
    if (versionCount) {
      const params = {
        versionCount: versionCount,
        identifier: data?.id
      };
      const response = await dispatch(downloadDocumentVersion(params));

      if (response?.status === 200) {
        const downloadLink = response.data;

        setViewDocument(data);
        const documentExtension = downloadLink
          ?.split("?")?.[0]
          ?.split(".")
          ?.reverse()?.[0];
        setViewingDocumentId(downloadLink);
        setViewingDocumentExtension(documentExtension);
      }
    }
  };

  return (
    <>
      {viewingDocumentId ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "38px"
          }}
        >
          <Preview
            viewDocument={viewDocument}
            viewingDocumentId={viewingDocumentId}
            viewingDocumentExtension={viewingDocumentExtension}
            viewType={viewType}
          />
        </div>
      ) : (
        <>
          {" "}
          {!(documents?.length > 0) ? (
            <div className="text-center">
              <CircularProgress className="spinner-lg" />
            </div>
          ) : (
            <div>
              <InfiniteScroll
                dataLength={combinedDocuments.length}
                next={loadMore}
                hasMore={true}
                loader={false}
                scrollableTarget="scrollableDiv"
              >
                <div className="row">
                  {combinedDocuments?.map(row => (
                    <>
                      {row?.map(document => {
                        return (
                          <DocumentUploadCard
                            document={document}
                            handleView={handleView}
                            isAllowUpload={isAllowUpload}
                            acceptFileType={acceptFileType}
                            handleFileSelected={handleFileSelected}
                            uploadingFile={uploadingFile}
                            maxSizeOfFile={maxSizeOfFile}
                          />
                        );
                      })}
                    </>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          )}{" "}
        </>
      )}
    </>
  );
}
