import { uppercaseFirstLetter } from "./common";

export const onNavigateBack = ({url, pendingList, goToPending, navigationCallback}) => {
  const splitUrl = url.split("/");
  let redirectUrl = "/" + splitUrl[1];
  if (pendingList) {
    let pendingIndex = splitUrl.findIndex(item => item === "pending");
    redirectUrl = "/" + splitUrl.slice(1, pendingIndex + 1).join("/");
  }
  else {
    // let modeIndex = splitUrl.findIndex(item => item === "mode");
    let modeIndex = splitUrl.findIndex(item => ['add', 'edit', 'view', 'new', 'clone'].includes(item));
    redirectUrl = "/" + splitUrl.slice(1, modeIndex).join("/");
    if (goToPending === true) redirectUrl += "/pending";
  }
   navigationCallback(redirectUrl);
};

export const onNavigateEditPage = (history, setViewType, setTitle, name, url) => {
  setViewType("edit");
  setTitle(`${uppercaseFirstLetter('edit')} ${name}`);
  let editUrl = url.replace("view", "edit")
  history.push(editUrl);
}
