import axios from "axios";

const TemplateURL = "/friday/api/v1.0/NotificationsTemplate";
const ProductStagesURL = "/friday/api/v1.0/products";
export function createTemplate({ template }) {
  return axios.post(TemplateURL, { ...template });
}

export function getAllTemplates(params) {
  return axios.get(TemplateURL, {
    params
  });
}

export function getTemplateById({ id, category }) {
  return axios.get(TemplateURL + `/${id}?category=${category}`);
}

export function updateTemplate({ template, userType, id }) {
  return axios.put(
    `${TemplateURL}${userType === 0 ? `/${template.code}` : `/Drafts/` + id}`,
    { ...template }
  );
}

export function deleteTemplate({ id, isDraft = false }) {
  return axios.delete(`${TemplateURL}/${isDraft ? "Drafts/" + id : id}`);
}

export function actOnDraft({ id, body }) {
  return axios.post(TemplateURL + `/Drafts/${id}/act`, { ...body });
}

export const getProductStages = ({ id }) => {
  return axios.get(ProductStagesURL + `/${id}/Schema`);
};
