import { createSlice } from "@reduxjs/toolkit";

const initialInterviewsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  interviewForEdit: undefined,
  interviewStatus: null,
  lastError: null,
  sendOtpStatus: false,
  verifyOtpStatus: false,
  buttonLoader: false,
  questionsForEdit: undefined,
  endInterviewResponse: undefined,
  interviewScheduled: null,
  validationResult: null,
  recentEntities: undefined
};
export const callTypes = {
  list: "list",
  action: "action",
  buttonLoader: "buttonLoader"
};

export const slice = createSlice({
  name: "interviews",
  initialState: initialInterviewsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.buttonLoader = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.sendOtpStatus = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else {
        state.buttonLoader = true;
      }
    },
    // getInterviewByCode
    interviewFetched: (state, action) => {
      state.actionsLoading = false;
      state.interviewForEdit = action.payload.interviewForEdit;
      state.error = null;
      state.sendOtpStatus = false;
      state.verifyOtpStatus = false;
    },
    // startInterview
    startInterview: (state, action) => {
      state.actionsLoading = false;
      state.buttonLoader = false;
      state.interviewStatus = action.payload.interviewStatus;
      state.error = null;
      state.recentEntities = undefined;
    },
    // scheduleInterview
    interviewScheduled: (state, action) => {
      state.actionsLoading = false;
      state.interviewScheduled = action.payload.interviewScheduled;
      state.error = null;
    },
    // scheduleInterview
    validationResult: (state, action) => {
      state.actionsLoading = false;
      state.validationResult = action.payload.validationResult;
      state.error = null;
    },
    // findInterviews
    interviewsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.interviewForEdit = null;
      state.sendOtpStatus = false;
      state.verifyOtpStatus = false;
      state.endInterviewResponse = null;
      state.interviewScheduled = null;
    },
    // createInterview
    interviewCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.interviewForEdit = { ...(action.payload.interview || {}) };
    },
    // updateInterview
    interviewUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.interviewForEdit = { ...(action.payload.interview || {}) };
    },
    // deleteInterview
    interviewDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteInterviews
    interviewsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // interviewsUpdateState
    interviewsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.sendOtpStatus = false;
      state.verifyOtpStatus = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // sendOtp
    sendOtp: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sendOtpStatus = action.payload?.sendOtpStatus;
      state.buttonLoader = false;
    },
    // verifyOtp
    verifyOtp: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.verifyOtpStatus = action.payload?.verifyOtpStatus;
      state.buttonLoader = false;
    },
    // fetchInterviewQuestions
    fetchQuestions: (state, action) => {
      state.actionsLoading = false;
      state.questionsForEdit = action.payload.questionsForEdit;
      state.error = null;
      state.sendOtpStatus = false;
      state.verifyOtpStatus = false;
      state.buttonLoader = false;
    },
    // endingCall
    endInterviewCall: (state, action) => {
      state.actionsLoading = false;
      state.endInterviewResponse = action.payload.endInterviewResponse;
      state.error = null;
      state.sendOtpStatus = false;
      state.verifyOtpStatus = false;
      state.buttonLoader = false;
    },
    // Reset Start Interview State
    resetStartInterviewState: (state, action) => {
      state.actionsLoading = false;
      state.interviewStatus = false;
      state.interviewScheduled = null;
      state.error = null;
    },
    // For Header Recent Interview
    interviewRecentFetchState: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = false;
      state.recentEntities = entities;
    },
    // For Resetting interview grid
    resetInterviewGrid: (state, action) => {
      state.entities = null;
      state.totalCount = 0;
    },
    submitExcel: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    getPDF: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    agentsFetched: state => {
      state.actionsLoading = false;
      state.listLoading = false;
    },
    agentTimeSlotsFetched: state => {
      state.actionsLoading = false;
      state.listLoading = false;
    }
  }
});
