/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { queryBuilderQueryTypeEnums } from "../../../_helpers/staticFields";
import { inputTypes } from "../../../_helpers/commonStaticVariables";
import {
  getFormattedNumber,
  getNumberInCurrencyFormat
} from "../../../_helpers";
import {OverlayTrigger, Popover, Tooltip} from "react-bootstrap";

export const ApplicationDynamicFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    parameters: {
      identifier = "",
      dataType = 0,
      field = {},
      masterDataType = "",
      allRecords = {},
      withSpan = true,
      showNameOnly = true,
      isCountColumn = false,
      isCopyToForm = false,
      onOverrideFieldValue,
      copyValue,
      setCopyValue,
      FieldsSelect,
      customers
    },
    setHideActionsOnCustomerRow
  }
) => {
  const overrideFieldToFormRef = createRef();

  const toggleOverlay = () => overrideFieldToFormRef.current?.click();
  let level1 = identifier;
  let level2 = "";

  if (identifier.includes("[")) {
    identifier = identifier.split("[");
    level1 = identifier[0];
    level2 = identifier[1].replace("]", "");
  }

  let displayData = row[identifier]
    ? row[identifier]
    : row[identifier] === 0
    ? 0
    : "";

  if (level2) {
    displayData = row[level1]?.[level2]
      ? row[level1]?.[level2]
      : row[level1]?.[level2] === 0
      ? 0
      : "";
  } else {
    displayData = row[level1] ? row[level1] : row[level1] === 0 ? 0 : "";
  }

  let title = displayData;
  let fieldType = inputTypes.find(field => field.id === dataType)?.type;
  if (isCountColumn) {
    displayData = (cellContent || [])?.length;
  } else if (fieldType === "checkbox") {
    displayData = Boolean(cellContent) ? "Yes" : "No";
  } else if (fieldType === "conditionBuilder") {
    displayData =
      (cellContent?.representations || []).find(
        rep => rep.representationType === queryBuilderQueryTypeEnums["SQL"]
      )?.representationValue || "N/A";
  } else if (["select", "multiselect"].includes(fieldType)) {
    let allFieldRecords =
      (allRecords || [])?.[field?.code]?.records ||
      (allRecords || [])?.[masterDataType]?.records ||
      (allRecords || [])?.[masterDataType]?.recordsForGrid ||
      (allRecords || [])?.[masterDataType]?.[field?.code] ||
      (Array.isArray(allRecords?.[masterDataType])
        ? allRecords?.[masterDataType]
        : []);

    if (fieldType === "select") {
      displayData = (allFieldRecords || []).find(
        rec => rec?.code === cellContent
      )?.name;
      if (showNameOnly === false) {
        displayData =
          (displayData || "N/A") +
          `${cellContent ? " (" + cellContent + ")" : ""}`;
      }
    } else if (fieldType === "multiselect") {
      while (cellContent && typeof cellContent !== "object") {
        try {
          cellContent = JSON.parse(cellContent);
        } catch (e) {
          // console.log(e)
        }
      }
      let filteredRecords = (allFieldRecords || []).filter(rec =>
        (cellContent || []).find(item => item === rec?.code)
      );
      if (isCountColumn) {
        displayData = (filteredRecords || []).length;
      } else {
        displayData = (filteredRecords || []).map(
          (eachRec, index) =>
            `${eachRec.name}${index < filteredRecords?.length - 1 ? ", " : ""}`
        );
      }
    }
  } else if (fieldType === "datePicker") {
    displayData =
      displayData && moment(displayData)?.isValid()
        ? moment(displayData)
            .format("LL")
            ?.toString()
        : "";
  } else if (["timePicker", "dateTimePicker"].includes(fieldType)) {
    displayData =
      displayData && moment(displayData)?.isValid()
        ? moment
            .utc(displayData)
            .toDate()
            .toString()
        : "";
  } else if (typeof displayData === "object") {
    displayData = displayData?.toString() || "";
  } else if (fieldType === "richTextBox") {
    displayData = ReactHtmlParser(cellContent || "N/A");
    const tempElement = document.createElement('div');
    tempElement.innerHTML = cellContent || '';
    const textContent = tempElement?.textContent || tempElement?.innerText || '';
    title = textContent?.trim() || "N/A";
  }

  if (fieldType === "conditionBuilder" || withSpan) {
    displayData = displayData === 0 ? 0 : displayData || "N/A";
  }

  let { tags = [] } = field;

  if (!tags) tags = [];

  if ((tags || []).find(tg => tg === "maskedCNIC")) {
    displayData = displayData || "N/A";
  } else {
    if ((tags || []).find(tg => tg === "FormattedNumber")) {
      displayData = getFormattedNumber(displayData);
    }
    if ((tags || []).find(tg => tg === "amount")) {
      displayData = getNumberInCurrencyFormat({
        number: displayData,
        appendCurrency: true,
        isWholeNumber: (tags || []).find(item => item === "WholeNumber")
          ? true
          : false
      });
    }

    if ((tags || []).find(tg => tg === "Customers")) {
      setHideActionsOnCustomerRow(true);
      displayData = (customers || []).find(
        cus => cus?.cnic === cellContent?.replaceAll("-", "")
      )?.name;
    }
  }

  const popover = (
    <Popover id="copy-popover">
      <Popover.Content className="col-lg-12" style={{ width: "350px" }}>
        {Boolean(isCopyToForm && row[identifier]) ? (
          <div style={{ width: "calc(100% - 78px)", display: "inline-block" }}>
            <FieldsSelect />
          </div>
        ) : null}
        <button
          className="btn btn-success btn-sm ml-2"
          style={{ height: "38px" }}
          onClick={() => {
            onOverrideFieldValue({ copyKey: copyValue });
            toggleOverlay();
          }}
        >
          Proceed
        </button>
      </Popover.Content>
    </Popover>
  );

  return (
    <span
      title={typeof displayData === "string" ? displayData : "Click on View"}
      className={fieldType === "richTextBox" ? "comment-text" : null}
    >
      <OverlayTrigger overlay={
        <Tooltip id={title || displayData}>
          {title || displayData}
        </Tooltip>
      }>
        {withSpan ? <span title="">{displayData}</span> : <>{displayData}</>}
      </OverlayTrigger>


      {Boolean(isCopyToForm && row[identifier]) ? (
        <OverlayTrigger
          rootClose
          trigger="click"
          overlay={popover}
          placement="top"
        >
          <span
            className="overlay-menu-action"
            ref={overrideFieldToFormRef}
            onClick={() => setCopyValue(row[identifier])}
          >
            <i className="fas fa-exchange-alt ml-3 mt-1 cursor-pointer" />
          </span>
        </OverlayTrigger>
      ) : null}
    </span>
  );
};
