import { createSlice } from "@reduxjs/toolkit";

const initialModulesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  prevEntity: undefined,
  auditLogs: [],
  lastError: null,
  makerCheckerAction: false,
  moduleDeleted: false,
  allModules: undefined,
  parentModules: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const modulesSlice = createSlice({
  name: "modules",
  initialState: initialModulesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    moduleFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.entityForEdit = action.payload.moduleForEdit;
      state.error = null;
      state.makerCheckerAction = false;
    },
    prevModuleFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.prevEntity = action.payload.moduleForEdit;
      state.error = null;
      state.makerCheckerAction = false;
    },
    modulesFetched: (state, action) => {
      const { totalCount, entities, isHeader } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.makerCheckerAction = false;
      state.moduleDeleted = false;
      state.prevEntity = undefined;
      state.auditLogs = [];
      state.parentModules = null;
      if (isHeader) {
        state.allModules = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
      }
    },
    parentModulesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.makerCheckerAction = false;
      state.moduleDeleted = false;
      state.prevEntity = undefined;
      state.parentModules = entities;
      state.totalCount = totalCount;
    },
    clearModulesList: state => {
      state.entities = null;
      state.entityForEdit = null;
      state.totalCount = 0;
    },
    clearModulesPermissions: state => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = 0;
      state.entities = null;
      state.entityForEdit = undefined;
      state.lastError = null;
      state.makerCheckerAction = false;
      state.moduleDeleted = false;
      state.allModules = undefined;
    },
    moduleCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      state.error = null;
    },
    moduleUpdated: (state, action) => {
      const { module } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = module;
    },
    moduleDeleted: state => {
      state.moduleDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    auditLogsFetched: (state, action) => {
      const { auditLogs } = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const { auditLogs, params } = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state.actionsLoading = false;
      state.makerCheckerAction = false;
      state.entityForEdit = toLog?.payload;
      state.prevEntity = fromLog?.payload;
    }
  }
});
