import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import {
  Aside,
  Header,
  HeaderMobile,
  SubHeader
} from "../../../_custom/_layout";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
// import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import ErrorPage from "../../../app/modules/ErrorBoundary/errorPage";

function ErrorFallback({ error, resetErrorBoundary }) {
  console.log(error);
  return (
    <>
      <ErrorPage resetErrorBoundary={resetErrorBoundary} />
    </>
  );
}
// function ErrorModulesFallback({error, resetErrorBoundary}) {
//   return (
//     <>
//       <p>Some thing went wrong !</p>
//       <button onClick={resetErrorBoundary}>go to home</button>
//     </>
//   )
// }

export function Layout({ children }) {
  let history = useHistory();
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      {/*<ErrorBoundary FallBackComponent={ErrorFallback}>*/}
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  history.push("/");
                  // reset the state of your app so the error doesn't happen again
                }}
              >
                {layoutProps.subheaderDisplay && <SubHeader />}
                {/*begin::Entry*/}
                {!layoutProps.contentExtended && (
                  <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className={layoutProps.contentContainerClasses}>
                      {children}
                    </div>
                    {/*end::Container*/}
                  </div>
                )}

                {layoutProps.contentExtended && { children }}
                {/*end::Entry*/}
              </ErrorBoundary>
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/*<QuickUser/>*/}
      <QuickPanel />
      <ScrollTop />
      {/*<StickyToolbar/>*/}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
