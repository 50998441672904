import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  UpdateToken: "[UpdateToken] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  ChangePassword: "[ChangePassword] Action",
  TwoFactorAuthentication: "[TwoFactorAuthentication] Action",
  SetRedirectURL: "[Set Redirect URL] Action",
  GetRedirectURL: "[Get Redirect URL] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  passwordExpiryCounter: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: "cascade",
    whitelist: [
      "user",
      "authToken",
      "refreshToken",
      "passwordExpiryCounter",
      "inactivityTimeout",
      "expiresin",
      "uniqueIdentifier",
      "user_id",
      "redirectURL"
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        let {
          access_token,
          refresh_token,
          pass_expiry_day_counter,
          inactivity_timeout,
          expires_in
        } = action.payload.oAuthModel;

        const authToken = access_token;
        const refreshToken = refresh_token;
        const passwordExpiryCounter = pass_expiry_day_counter;
        const inactivityTimeout = inactivity_timeout;
        const expiresin = expires_in;

        return {
          authToken,
          refreshToken,
          passwordExpiryCounter,
          inactivityTimeout,
          expiresin,
          user: null,
          ...action.payload.oAuthModel
        };
      }

      case actionTypes.UpdateToken: {
        const authToken = action.payload.access_token;
        const refreshToken = action.payload.refresh_token;
        return { ...state, authToken, refreshToken, ...action.payload };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.ChangePassword: {
        return {
          ...state,
          changePassword: {
            ...(state.changePassword || {}),
            ...(action?.payload || {})
          }
        };
      }

      case actionTypes.TwoFactorAuthentication: {
        const { user_id, currentPassword } = action.payload;
        return { ...state, user_id, changePassword: { currentPassword } };
      }

      case actionTypes.SetRedirectURL: {
        const redirectURL = action.payload;
        return { ...state, redirectURL };
      }

      case actionTypes.GetRedirectURL: {
        return { ...state };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: payload => ({ type: actionTypes.Login, payload }),
  changePassword: payload => ({ type: actionTypes.ChangePassword, payload }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
  setUserId: payload => ({
    type: actionTypes.TwoFactorAuthentication,
    payload
  }),
  setRedirectURL: payload => ({ type: actionTypes.SetRedirectURL, payload }),
  getRedirectURL: () => ({ type: actionTypes.GetRedirectURL })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(actions.requestUser(payload?.loginUser));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({
    payload
  }) {
    // const { data: user } = yield getUserByToken(payload?.user_code);

    yield put(actions.fulfillUser(payload?.user));
  });
}
