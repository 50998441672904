import React from 'react';
import { EntityActionCssClasses, EntityActionTitles } from '../UIHelpers';

export const EntityActionFormatter = (cellContent, row, rowIndex) => {
  return (
    <span className={`label label-lg label-light-${EntityActionCssClasses[row.lastAction]} label-inline`}>
      {EntityActionTitles[row.lastAction]}
				</span>
  )
};
