/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dialog } from "../../_partials/Dialog";
import { Relations } from "../grids";
import { useCurrentModulePermission } from "../../_hooks";
import { getPermissionsObject } from "../../_helpers";
import * as actions from "../../../app/modules/MasterData/_redux/records/recordsActions";


export const CustomerDialog = ({show, onHide, queryParams, setQueryParams, openCollection, uiEvents}) => {
  let entityCode = 'Relations';
  const {currentState, permissionsDictionary} = useSelector(
    (state) => ({
      currentState: state?.records[entityCode],
      // userAuth: state.auth.user
      permissionsDictionary: state.groups.permissionsDictionary,
    }),
    shallowEqual
  );
  const currentModulePermissions = useCurrentModulePermission(permissionsDictionary, 'customers');
  const permissionsObject = getPermissionsObject(currentModulePermissions);

  const {records, listLoading} = currentState || {};
  const totalCount = currentState?.paging?.totalItems || 0;

  // Records Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    if (entityCode && queryParams?.CardCustID && show) {
      dispatch(actions.clearIndividualRecord({entityCode}));
      dispatch(actions.fetchRecords({...queryParams, entityCode}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, queryParams]);


  return (
    <Dialog
      size={'xl'}
      isCentered={false}
      show={show}
      onHide={onHide}
      modalTitle={
        "Relations"
      }
      isLoading={listLoading}
      modalBody={
        <>
          <Relations uiEvents={uiEvents} totalCount={totalCount} permissionsObject={permissionsObject} listData={records || []} listLoading={listLoading} queryParams={queryParams} setQueryParams={setQueryParams}/>
        </>
      }
      modalBodyClasses={'text-justify overflow-auto document-body'}
      // modalFooter={}
    />
  )
}
