import React, { useEffect, useState} from "react"
import { Dialog } from "../../../../../../_custom/_partials/Dialog";

export const ImportExcelErrorDialog = ({show, failedFields, allFields, importData, setErrorDialog, setFailedFields}) => {

  const [errorMessages, setErrorMessages] = useState([]);
  const [sortedFailedFields, setSortedFailedFields] = useState(failedFields?.sort());

  const onCancel = () => {
    setErrorDialog(false)
    setFailedFields([])
    setErrorMessages([])
  }

  useEffect(() => {
    setSortedFailedFields(failedFields?.sort())
  },[failedFields])

  const getField = (code) => allFields.find(field => field.code === code);

  useEffect(() => {
    if(sortedFailedFields.length > 0 && importData.length > 0) {
      sortedFailedFields.forEach((failedField, index) => {
        let err = {
          rowNo: failedField+3,
          missingFields: []
        }
        Object.keys(importData[failedField]).forEach((key) => {
          if(getField(key)?.isRequired === true && importData[failedField][key].length === 0) {
            err.missingFields.push(getField(key)?.name)
          }
        })
        setErrorMessages(prevArray => [...prevArray, err])
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortedFailedFields, importData])

  const displayErrorMsg = (missingFields) => {
      let tempArr = [...missingFields];
      let lastField = tempArr.pop();
      return `${tempArr.join(", ")} and ${lastField} are required.`
  }

	return (
		<Dialog
      size={'md'}
      isCentered={true}
      show={show}
      onHide={onCancel}
      modalTitle="Error log"
      modalBody={
				<div className="mx-auto mt-4 import-modal-table">
        <p className="text-center"> Following rows of excel are failed to import. </p>
        <table className="table mb-0">
          <thead className="">
            <tr className="text-center">
              <th> Row No </th>
              <th> Missing Fields </th>
            </tr>
          </thead>
          <tbody>
            {
              errorMessages.map((errorMsg, index) => (
                <tr className="text-center" key={index}>
                  <td> { errorMsg.rowNo } </td>
                  <td>
                    {
                      errorMsg.missingFields.length === 1 ?
                      <span> {`${errorMsg.missingFields[0]} is required.`} </span>
                      : <span> {displayErrorMsg(errorMsg.missingFields)} </span>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      }
      modalBodyClasses="p-0"
      modalFooter={(
          <button type="button" className="btn btn-light" onClick={onCancel}>
            Close
          </button>
        )}
    />
	)
}
