import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch, connect } from "react-redux";
import * as auth from "./modules/Auth/_redux/authRedux";
import { errorNotification } from "././../_custom/_partials/notifications/NotificationHandler";

function ProtectedRoute({component, ...props}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {location} = props;

  const {redirectURL} = useSelector(
    ({auth}) => ({
      redirectURL: auth.redirectURL
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(props.getRedirectURL());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      // If user tried to edit workflow in enquiry mode
      // (enquiry/{ProductCode}/edit/{WorkflowIdentifier})
      (
        (location.pathname || "").includes("/enquiry") &&
        (location.pathname || "").includes("/edit")
      ) ||

      // If user tried to change route from enquiry view to workflows view
      // (enquiry/{ProductCode}/view/{WorkflowIdentifier}  ==>  workflows/{ProductCode}/view/{WorkflowIdentifier})
      (
        (redirectURL || "").includes("/enquiry") &&
        (redirectURL || "").includes("/view") &&
        (location.pathname || "").includes("/workflows") &&
        (location.pathname || "").includes("/view")
      ) ||

      // If user tried to change route from enquiry view to workflows edit
      // (enquiry/{ProductCode}/view/{WorkflowIdentifier}  ==>  workflows/{ProductCode}/edit/{WorkflowIdentifier})
      (
        (redirectURL || "").includes("/enquiry") &&
        (redirectURL || "").includes("/view") &&
        (location.pathname || "").includes("/workflows") &&
        (location.pathname || "").includes("/edit")
      ) ||

      // If user tried to change route from workflows view to workflows edit
      // (workflows/{ProductCode}/view/{WorkflowIdentifier}  ==>  workflows/{ProductCode}/edit/{WorkflowIdentifier})
      (
        (redirectURL || "").includes("/workflows") &&
        (redirectURL || "").includes("/view") &&
        (location.pathname || "").includes("/workflows") &&
        (location.pathname || "").includes("/edit")
      )
    ) {
      errorNotification("You are not allowed to access this route");
      history.push(redirectURL)
    } else {
      dispatch(props.setRedirectURL(location.pathname));
    }
    // eslint-disable-next-line
  }, [location, redirectURL]);

  return (
    <Route {...props}>
      {routeProps => {
        // console.log(routeProps)
        if (!routeProps.match) {
          return null;
        }

        if (component) {
          return React.createElement(component, routeProps);
        }

        return null;
      }}
    </Route>
  );
}

export default connect(null, auth.actions)(ProtectedRoute);
