import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import groupBy from "lodash/groupBy";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import jsonLogic from "../../../../../helper/logic";
import debounce from "lodash/debounce";
import { Utils as QbUtils } from "react-awesome-query-builder";

import { inputTypes } from "../../../../../../_custom/_helpers/commonStaticVariables";
import {
  dropDownOptions,
  inputTypesDictionary,
  queryBuilderDefaultExpressions,
  queryBuilderQueryTypeEnums,
  staticCodes
} from "../../../../../../_custom/_helpers/staticFields";
import {
  getFieldsWithValidation,
  getOutcomeValueInDesiredFormat,
  getRestrictions,
  IsJsonString, isValueEmpty,
  replaceNotNumberValueWithZero,
  setDefaultValues,
  validateColorCode
} from "../../../../../../_custom/_helpers/common";
import {
  CodeInput,
  ColorPicker,
  DatePickerField,
  DocumentUploadInput,
  Input,
  MaskedInput,
  NumberFormatter,
  Overlay,
  Phone,
  ReactCreatableSelect,
  ReactSelect,
  ReactSelectControlled,
  ReactSelectDraggable,
  RichTextBox,
  Switch
} from "../../../../../../_custom/_partials/controls";
import { fetchRecords } from "../../../../MasterData/_redux/records/recordsActions";
import moment from "moment";
import { QueryBuilder } from "../../../../../../_custom/_partials/controls/forms/QueryBuilder";
import { evaluate } from "mathjs";
import {
  errorNotification,
  warningNotification
} from "../../../../../../_custom/_partials/notifications";
import { clone } from "lodash";
import { fieldLayoutTitles } from "../../../../../../_custom/_partials/grids/UIHelpers";
import { useCurrentUserFieldsPermission } from "../../../../../../_custom/_hooks/FieldsPermission";
import { useLocation } from "react-router-dom";

const validateJSONLogic = (logic, data) => jsonLogic.apply(logic, data);

const getHumanReadableQuery = (tree, config) =>
  QbUtils.queryString(tree, config, true);
const getSQLQuery = (tree, config) => QbUtils.sqlFormat(tree, config);
const getMongoDBQuery = (tree, config) => QbUtils.mongodbFormat(tree, config);
const getExpressionTreeQuery = (tree, config) =>
  QbUtils.queryString(tree, config);
const getJSONLogicQuery = (tree, config) =>
  JSON.stringify(QbUtils.jsonLogicFormat(tree, config)?.logic || {});

export default function GridFieldModalForm({
  values,
  formData,
  mode,
  formMode,
  fields,
  stageConditions,
  onSubmit,
  onCancel,
  allFields,
  dropdownOptions,
  gridData,
  uniqueSubFields,
  customers,
  setCustomerModalMode,
  isModalOpen,
  setModalOpen,
  modalCurrentIndex,
  widget,
  hideCodeInDropdownLabel,
  setFormikInstance,
  gridCode
}) {
  const [renderableFields, setRenderableFields] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [groupedByHeaderFields, setGroupedByHeaderFields] = useState({});
  const [conditionsStatus, setConditionsStatus] = useState({});
  const [fieldsOriginalState, setFieldsState] = useState({});
  const [formValues, setFormValues] = useState(values || {});
  const [isInitialValueMounted, setInitialValueMounted] = useState(false);
  const [computations, setComputations] = useState(null);
  const [isValidatingForm, setIsValidatingForm] = useState(true);
  const [dependencyArray, setDependencyArray] = useState({});
  const [dataFetched, setDataFetched] = useState({});
  // const [isCustomerField, setIsCustomerField] = useState(false);

  const {
    recordsState,
    fieldsConfig,
    fieldsDictionary,
    workflowsState,
    userFieldsMapping,
    userAuth,
    currentProduct
  } = useSelector(
    state => ({
      fieldsConfig: state.fields?.fieldsWithDetails || state.fields?.entities,
      recordsState: state.records,
      fieldsDictionary: state.fields?.fieldsDictionary,
      workflowsState: state.workflows,
      userFieldsMapping: state.records?.userFieldsMapping?.records,
      userAuth: state.auth.user,
      currentProduct: state.products.productForEdit
    }),
    shallowEqual
  );

  const workflow = workflowsState.workflow;
  const { pathname } = useLocation();
  const fieldsToShow = useCurrentUserFieldsPermission(
    userFieldsMapping,
    currentProduct,
    userAuth
  );
  const isEnquiry = pathname.split("/").includes("enquiry");

  // create dependency status based on entityFields
  const updateDependencyStatus = entityFieldsList => {
    let dropdownFieldsDependencyDictionary = {};

    entityFieldsList.forEach(item => {
      if (
        inputTypesDictionary[item.dataType]?.type === "select" ||
        inputTypesDictionary[item.dataType]?.type === "multiselect"
      ) {
        const dependents = item.dependentField?.split("!").filter(Boolean);

        if (dependents?.length) {
          dependents.forEach(dependent => {
            dropdownFieldsDependencyDictionary[dependent] = item.code;
          });
        }
      }
    });

    setDependencyArray(dropdownFieldsDependencyDictionary);

    return dropdownFieldsDependencyDictionary;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (fields && fieldsConfig?.length) {
      // const filteredFields = fieldsConfig.filter(fld => fields.includes(fld.code));
      let filteredFields = fields.map(fld =>
        fieldsConfig.find(field => field.code === fld)
      );
      let updatedFilteredFields = [...filteredFields];
      let tags = recordsState?.["tags"]?.records;
      let dataFetchedTemp = { ...dataFetched };
      // const dropdownFieldsDependencyDictionary = updateDependencyStatus(
      //   filteredFields
      // );

      filteredFields.forEach((field, index) => {
        // if (
        //   (inputTypesDictionary[field.dataType]?.type === "multiselect" ||
        //     inputTypesDictionary[field.dataType]?.type === "select") &&
        //   !recordsState?.[field.masterDataType]?.records &&
        //   !dataFetched[field.masterDataType] &&
        //   !dropdownFieldsDependencyDictionary[field.code]
        // ) {
        //   // dispatch(
        //   //   fetchRecords({
        //   //     paginationOverride: true,
        //   //     entityCode: field.masterDataType
        //   //   })
        //   // );
        //   dataFetchedTemp[field.masterDataType] = true;
        // }

        if (tags && field.tags) {
          let tempField = { ...field };
          tempField.tags = tempField.tags
            .map(tg => (tags || []).find(tag => tag.code === tg))
            .filter(Boolean)
            .map(tag => ({ code: tag.code, ...(tag?.values || {}) }));
          updatedFilteredFields[index] = { ...tempField };
        }

        // Commit code for Not applicable handling for Customers field in Form
        // if (field?.tags?.includes("Customers")) {
        //   setIsCustomerField(true);
        // }
      });
      setDataFetched(dataFetchedTemp);
      updateDependencyStatus(updatedFilteredFields);
      setRenderableFields(getFieldsWithValidation(updatedFilteredFields, mode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsConfig, fields, mode]);

  useEffect(() => {
    if (renderableFields.fields) {
      let stageFields = [];
      if (fieldsToShow?.length && isEnquiry) {
        (renderableFields?.fields || []).forEach(stageField => {
          if (fieldsToShow?.includes(stageField?.code)) {
            stageFields.push(stageField);
          }
        });
      } else {
        stageFields = renderableFields?.fields;
      }
      let tempGroupedByHeaderFields = groupBy(stageFields, "header");

      if (!isInitialValueMounted) {
        let initialFieldValues = {};
        let gridCopyValues = {};
        let gridCopyData = recordsState?.["GridCopy"]?.records;
        gridCopyData = (gridCopyData || []).filter(
          item =>
            workflow?.gridFields?.[item?.values?.SourceGrid] ||
            (!item?.values?.SourceGrid && !item?.values?.SourceRow)
        );

        (gridCopyData || []).forEach(item => {
          let fieldExistsInStage = item?.values?.DestinationGrid === gridCode;

          if (fieldExistsInStage) {
            if (item?.values?.SourceGrid) {
            } else {
              gridCopyValues[item?.values?.DestinationField] =
                item?.values?.IsOverride === "True"
                  ? formData?.[item?.values?.SourceField]
                  : values[item?.values?.DestinationField] ||
                    formData?.[item?.values?.SourceField];
            }
          }
        });
        renderableFields.fields.forEach(fld =>
          inputTypesDictionary[fld.dataType]?.type !== "list"
            ? (initialFieldValues[fld.code] = [null, undefined].includes(
                formValues[fld.code]
              )
                ? null
                : formValues[fld.code] === 0
                ? formValues[fld.code]
                : formValues[fld.code] || null)
            : null
        );
        setInitialValueMounted(true);

        let allFields = [...(renderableFields?.fields || [])];
        let updatedTagValues = {};
        let defaultValues = {};
        (allFields || []).forEach(fld => {
          let alwaysUpdatedCurrentDate =
            fld?.tags?.find(tag => tag?.SetAlwaysUpdatedCurrentDate)
              ?.SetAlwaysUpdatedCurrentDate === "True";
          let currentDateUpdateOnce =
            fld?.tags?.find(tag => tag?.SetUpdatedCurrentDateOnlyOnce)
              ?.SetUpdatedCurrentDateOnlyOnce === "True";
          let setNextNDate = fld?.tags?.find(tag => tag?.SetNextNDate)
            ?.SetNextNDate;
          let setLastNDate = fld?.tags?.find(tag => tag?.SetLastNDate)
            ?.SetLastNDate;
          if (alwaysUpdatedCurrentDate) {
            if (setNextNDate) {
              updatedTagValues = {
                ...updatedTagValues,
                [fld?.code]: moment()
                  .startOf("day")
                  .add(setNextNDate, "d")
                  .toDate()
              };
            } else if (setLastNDate) {
              updatedTagValues = {
                ...updatedTagValues,
                [fld?.code]: moment()
                  .startOf("day")
                  .subtract(setLastNDate, "d")
                  .toDate()
              };
            } else {
              updatedTagValues = {
                ...updatedTagValues,
                [fld?.code]: moment()
                  .startOf("day")
                  .toDate()
              };
            }
          }
          if (currentDateUpdateOnce) {
            let data = formValues?.[fld?.code]
              ? formValues?.[fld?.code]
              : new Date();
            updatedTagValues = { ...updatedTagValues, [fld?.code]: data };
          }

          if (currentProduct?.customerConfiguration) {
            if (mode === "add") {
              const loopIndex = gridData
                ? Math.min(gridData.length, customers.length)
                : 0;
              if (loopIndex >= 0) {
                for (let i = 0; i <= loopIndex; i++) {
                  const customer = customers[i];
                  Object.keys(currentProduct.customerConfiguration).forEach(
                    key => {
                      if (
                        fld.code === currentProduct.customerConfiguration[key]
                      ) {
                        if (key === "nameFieldCode") {
                          initialFieldValues[fld.code] = customer?.name;
                        }
                        if (key === "dateOfBirthFieldCode") {
                          initialFieldValues[fld.code] = customer?.dateOfBirth;
                        }
                        if (key === "mobileNumberFieldCode") {
                          initialFieldValues[fld.code] = customer?.mobileNumber;
                        }
                        if (key === "nationalIdentifierFieldCode") {
                          initialFieldValues[fld.code] = customer?.cnic;
                        }
                      }
                    }
                  );
                }
              }
            } else if (mode === "edit") {
              const customer = customers[modalCurrentIndex];
              Object.keys(currentProduct.customerConfiguration).forEach(key => {
                if (fld.code === currentProduct.customerConfiguration[key]) {
                  if (key === "nameFieldCode") {
                    initialFieldValues[fld.code] = customer?.name;
                  }
                  if (key === "dateOfBirthFieldCode") {
                    initialFieldValues[fld.code] = customer?.dateOfBirth;
                  }
                  if (key === "mobileNumberFieldCode") {
                    initialFieldValues[fld.code] = customer?.mobileNumber;
                  }
                  if (key === "nationalIdentifierFieldCode") {
                    initialFieldValues[fld.code] = customer?.cnic;
                  }
                }
              });
            }
          }

          defaultValues = {
            ...defaultValues,
            ...setDefaultValues(fld, { ...formData, ...initialFieldValues })
          };
        });

        setFormValues(prevValues => ({
          ...prevValues,
          ...initialFieldValues,
          ...updatedTagValues,
          ...defaultValues,
          ...gridCopyValues
        }));

        let isAnyConditionChanged = (stageConditions || []).some(cond => {
          let jsonQuery = JSON.parse(
            (cond?.expression?.representations || []).find(
              rep =>
                queryBuilderQueryTypeEnums["JSONLogic"] ===
                rep.representationType
            )?.representationValue || "{}"
          );
          const validationStatus = validateJSONLogic(jsonQuery, {
            ...formValues,
            ...initialFieldValues,
            ...updatedTagValues,
            ...defaultValues,
            ...gridCopyValues
          });
          return (
            (validationStatus && conditionsStatus[cond.code] === undefined) ||
            (conditionsStatus[cond.code] !== undefined &&
              conditionsStatus[cond.code] !== validationStatus)
          );
        });
        if (isAnyConditionChanged)
          validateFormDataAgainstConditions(
            stageConditions || [],
            {
              ...formValues,
              ...initialFieldValues,
              ...updatedTagValues,
              ...defaultValues,
              ...gridCopyValues
            },
            mode === "add" || mode === "edit",
            gridCode
          );
      }

      if (!computations) {
        // filter out fields which have computations
        // then group these fields by types which have grids comps and form comps likewise
        let [formComputations, gridComputations, mathematicalExpressions, concatenatedValuesWithSpace] = [
          [],
          [],
          [],
          []
        ];

        renderableFields.fields.forEach(field => {
          let computations = field.computations;
          if (computations) {
            if (field.computations?.form?.fieldCodes?.length) {
              const computationType = dropDownOptions.computationOptions.find(
                  compType => compType.code === field?.computations?.form?.operation
              )?.name;

              if (computationType === "Merge Values With Spaces") {
                concatenatedValuesWithSpace.push({
                  [field.code]: field?.computations?.form
                })
              }
            }
            if (Object.keys(computations?.grid)?.[0])
              gridComputations.push({ [field.code]: computations.grid });
            else if (computations?.form?.fieldCodes?.length)
              formComputations.push({ [field.code]: computations.form });
          }

          if (field.mathematicalExpression?.formula) {
            mathematicalExpressions.push({
              ...field.mathematicalExpression,
              resultFieldCode: field.code
            });
          }
        });

        setComputations({
          formComputations,
          gridComputations,
          mathematicalExpressions,
          concatenatedValuesWithSpace
        });
      }

      setGroupedByHeaderFields(tempGroupedByHeaderFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderableFields]);

  const getDropdownOptions = (masterDataType, code) => {
    const data =
      recordsState?.[masterDataType]?.records?.length ||
      fieldsDictionary?.[code]?.records?.length
        ? recordsState?.[masterDataType]?.records ||
          fieldsDictionary?.[code]?.records
        : dropdownOptions?.[masterDataType] || dropdownOptions?.[code];
    let modifiedData;
    if (uniqueSubFields?.includes(code)) {
      let alreadySelectedData = [];
      (gridData || []).forEach((data, index) => {
        if (data?.[code] && index !== modalCurrentIndex) {
          alreadySelectedData.push(data[code]);
        }
      });
      modifiedData = [];
      for (let i = 0; i < data.length; i++) {
        if (!alreadySelectedData.includes(data[i].code)) {
          modifiedData.push(data[i]);
        }
      }
    }

    return (modifiedData || data || [])
      .filter(option => option?.isHidden !== true)
      .map(({ name, code, entityName, entityCode, order }) => ({
        label:
          (!hideCodeInDropdownLabel ? (entityCode || code) + ": " : "") +
          (entityName || name),
        value: entityCode || code,
        order: order
      }));
  };

  const getDropdownSelectedValue = (options, selectedOption, dropdownType) => {
    if (selectedOption === null || selectedOption === undefined)
      return undefined;

    if (dropdownType === "single") {
      const selOption = options.find(item => item.value === selectedOption);
      return selOption || "undefined";
    }

    if (dropdownType === "multi") {
      let selectedOptionArray = IsJsonString(selectedOption)
        ? JSON.parse(selectedOption)
        : Array.isArray(selectedOption)
        ? selectedOption
        : selectedOption.split("!").filter(val => val !== "");

      const selOption = selectedOptionArray.map(item =>
        options.find(opt => opt?.value === item)
      );
      return selOption?.filter(option => option !== "all");
    }

    return undefined;
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };

  const onChangeDropdown = (
    options,
    fieldCode,
    setFieldValue,
    formValues,
    valueAsString
  ) => {
    if (!options) {
      setFieldValue(fieldCode, options);
      return;
    }
    if (Array.isArray(options)) {
      if (valueAsString) {
        let selOptions = "";
        options.forEach(opt => (selOptions += opt.value + "!"));
        setFieldValue(fieldCode, selOptions);
      } else {
        const selOptions = options.map(item => item.value);
        setFieldValue(fieldCode, selOptions);
      }
    } else {
      setFieldValue(fieldCode, options.value);
    }
  };

  const onChangeQueryBuilder = (
    { tree, config },
    code,
    representations,
    formik
  ) => {
    const expressionTree = JSON.stringify(QbUtils.getTree(tree));

    const expressionValue = {
      expressionTree,
      representations: (representations || queryBuilderDefaultExpressions).map(
        rep => {
          if (rep === "Mongo")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getMongoDBQuery(tree, config)
            };
          else if (rep === "SQL")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getSQLQuery(tree, config)
            };
          else if (rep === "HR")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getHumanReadableQuery(tree, config)
            };
          else if (rep === "JSONLogic")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getJSONLogicQuery(tree, config)
            };
          else if (rep === "Tree")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getExpressionTreeQuery(tree, config)
            };
          else
            return {
              representationType: queryBuilderQueryTypeEnums["HR"],
              representationValue: getHumanReadableQuery(tree, config)
            };
        }
      )
    };

    formik.setFieldValue(
      code,
      Object.keys(QbUtils.getTree(tree)?.children1)?.length !== 0
        ? expressionValue
        : null
    );
  };

  const getRenderableFields = (formik, fieldList) => {
    // // if fields have been calculated, return from here
    // if (renderableFields) return renderableFields;

    // calculate fields if not done before
    // will only come here when skeletons are not to be shown AND fields have not been calculated i.e
    // only on initial render!
    // setRenderableFields(fieldsToRender);
    return fieldList.map((field, index) => {
      const {
        name,
        type,
        dataType,
        masterDataType,
        isRequired,
        isReadOnly,
        isHidden,
        disabled,
        hidden,
        code,
        minLength,
        maxLength,
        valueAsString,
        defaultValue,
        description,
        representations,
        mathematicalExpression,
        layout
      } = field;

      const tags = field.tags || [];
      const inputType = inputTypesDictionary[dataType].type;
      const restrictions = getRestrictions(
        code,
        // {isRequired, disabled, hidden, tags},
        {
          isRequired,
          disabled: disabled || isReadOnly,
          hidden: hidden || isHidden,
          tags
        },
        isValidatingForm ? "view" : mode
      );
      const key = index + "-" + code + "-modal";
      let layoutClass = "col-lg-4 mb-3";

      if (layout !== null && layout !== undefined && layout !== "") {
        layoutClass = fieldLayoutTitles[layout] + " mb-3";
      }

      let richTextBoxLayoutClass;
      if (inputType === "richTextBox") {
        if (layout !== null && layout !== undefined && layout !== "") {
          richTextBoxLayoutClass = fieldLayoutTitles[layout] + " mb-3 mt-3";
        } else {
          richTextBoxLayoutClass = "col-lg-12 mb-3 mt-3";
        }
      }

      switch (inputType) {
        case "text":
          return (
            <div
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) =>
                  code === "code" ? (
                    <CodeInput
                      classes={code}
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      maxLength={maxLength}
                      onChange={field.onChange}
                      key={key}
                      {...restrictions}
                      defaultValue={defaultValue}
                      description={description}
                      showDescription={description?.length}
                      tags={tags}
                    />
                  ) : (tags || []).find(tag => tag.code === "maskedCNIC") !==
                      undefined ||
                    (tags || []).find(tag => tag.code === "oldCNIC") !==
                      undefined ? (
                    <MaskedInput
                      classes={code}
                      field={{ ...field }}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      key={key}
                      description={description}
                      showDescription={description?.length}
                      {...restrictions}
                    />
                  ) : (tags || []).find(tag => tag.code === "ContactNumber") !==
                      undefined ||
                    (tags || []).find(tag => tag.code === "LandlineNumber") !==
                      undefined ? (
                    <Phone
                      classes={code}
                      field={{ ...field }}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      description={description}
                      showDescription={description?.length}
                      key={key}
                      {...restrictions}
                    />
                  ) : (
                    <Input
                      classes={code}
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      tags={tags}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      maxLength={maxLength}
                      onChange={field.onChange}
                      description={description}
                      showDescription={description?.length}
                      onBlur={e => {
                        let val =
                          typeof e?.target?.value === "string"
                            ? e?.target?.value.replace(/\s+/g, " ").trim()
                            : e?.target?.value;
                        formik.setFieldValue(code, val);
                      }}
                      key={key}
                      {...restrictions}
                      defaultValue={defaultValue}
                    />
                  )
                }
              </Field>
            </div>
          );

        case "fileInput":
          return (
            <div
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DocumentUploadInput
                    field={{ ...field }}
                    form={form}
                    meta={meta}
                    code={code}
                    label={name}
                    identifier={workflow?.identifier}
                    productCode={workflow?.productCode}
                    description={description}
                    showDescription={description?.length}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    isRequired={isRequired}
                    touched={meta.touched}
                    error={meta.error}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "number":
          return (
            <div
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) =>
                  (tags || []).find(tag => tag.code === "amount") ||
                  (tags || []).find(tag => tag.code === "FormattedNumber") ? (
                    <NumberFormatter
                      classes={code}
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      {...restrictions}
                      setFieldValue={formik.setFieldValue}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      tags={tags}
                      prefix={
                        (tags || []).find(tag => tag.code === "amount")
                          ? "PKR "
                          : ""
                      }
                      description={description}
                      showDescription={description?.length}
                      mathematicalExpression={mathematicalExpression}
                    />
                  ) : (
                    <Input
                      classes={code}
                      field={field}
                      form={form}
                      meta={meta}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      label={name}
                      name={code}
                      type={inputType}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      {...restrictions}
                      tags={tags}
                      onChange={field.onChange}
                      defaultValue={defaultValue}
                      description={description}
                      showDescription={description?.length}
                      mathematicalExpression={mathematicalExpression}
                    />
                  )
                }
              </Field>
            </div>
          );

        case "select":
          const options = getDropdownOptions(masterDataType, code);
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {name && (
                      <label>
                        {name}{" "}
                        {isRequired ? (
                          <span className={"text-danger"}> *</span>
                        ) : null}{" "}
                      </label>
                    )}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        />
                      </Overlay>
                    ) : null}
                    {dependencyArray[code] ? (
                      <ReactSelectControlled
                        showSkeleton={false}
                        label={name}
                        name={code}
                        placeholder={name}
                        touched={meta.touched}
                        error={
                          meta.error ? (
                            <>
                              <b>{name}</b> is required
                            </>
                          ) : (
                            ""
                          )
                        }
                        value={field.value}
                        options={options || []}
                        parentWaitingMessage="Please select parent value(s) to fetch records"
                        emptyOptionsMessage="No records found for given values"
                        masterDataType={masterDataType}
                        onChange={options => {
                          formik.setFieldValue(code, options);
                        }}
                        dependencyTree={dependencyArray}
                        allValues={form.values}
                        allValuesMandatory={false}
                        isClearable={true}
                        isSearchable
                        withFeedbackLabel={false}
                        {...restrictions}
                        shouldUseLocalDropdownOptions={true}
                        hideCodeInDropdownLabel
                        setFieldValue={formik.setFieldValue}
                        overwriteSorting={true}
                      />
                    ) : (
                      <ReactSelect
                        field={{ ...field }}
                        form={form}
                        key={
                          getDropdownSelectedValue(
                            options,
                            field.value,
                            "single"
                          ) || code
                        }
                        classes={code}
                        label={name}
                        name={code}
                        disabled={
                          disabled ||
                          tags?.some(tag => tag?.code === "Customers")
                        }
                        placeholder={name}
                        isReadOnly={isReadOnly}
                        isRequired={isRequired}
                        error={
                          isRequired &&
                          !getDropdownSelectedValue(
                            options,
                            field.value || defaultValue,
                            "single"
                          )
                            ? `${name} is required`
                            : null
                        }
                        onError={formik.setFieldError}
                        value={getDropdownSelectedValue(
                          options,
                          field.value,
                          "single"
                        )}
                        options={options}
                        onChange={option => {
                          onChangeDropdown(
                            option || "",
                            code,
                            formik.setFieldValue,
                            form.values
                          );
                        }}
                        isClearable
                        isSearchable
                        withFeedbackLabel
                        {...restrictions}
                        getDropdownSelectedValue={getDropdownSelectedValue}
                        tags={tags}
                        customers={customers}
                        setCustomerModalMode={setCustomerModalMode}
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        modalCurrentIndex={modalCurrentIndex}
                        gridData={gridData || []}
                        widget={widget}
                        setFormValues={setFormValues}
                        overwriteSorting={true}
                      />
                    )}
                  </>
                )}
              </Field>
            </div>
          );

        case "multiselect":
          const multiOptions = [
            { label: "Select All", value: "all" },
            ...getDropdownOptions(masterDataType, code)
          ];
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {name && (
                      <label>
                        {name}{" "}
                        {isRequired ? (
                          <span className={"text-danger"}> *</span>
                        ) : null}{" "}
                      </label>
                    )}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        ></i>
                      </Overlay>
                    ) : null}
                    {dependencyArray[code] ? (
                      <ReactSelectControlled
                        showSkeleton={false}
                        label={name}
                        name={code}
                        placeholder={name}
                        touched={meta.touched}
                        error={
                          meta.error ? (
                            <>
                              <b>{name}</b> is required
                            </>
                          ) : (
                            ""
                          )
                        }
                        value={field.value}
                        options={options || []}
                        parentWaitingMessage="Please select parent value(s) to fetch records"
                        emptyOptionsMessage="No records found for given values"
                        masterDataType={masterDataType}
                        onChange={options => {
                          formik.setFieldValue(code, options);
                        }}
                        dependencyTree={dependencyArray}
                        allValues={form.values}
                        allValuesMandatory={false}
                        isClearable={true}
                        isMulti={true}
                        isSearchable
                        withFeedbackLabel={false}
                        {...restrictions}
                        shouldUseLocalDropdownOptions={true}
                        hideCodeInDropdownLabel
                        setFieldValue={formik.setFieldValue}
                        valueAsString={valueAsString}
                        overwriteSorting={true}
                      />
                    ) : (
                      <ReactSelectDraggable
                        key={
                          getDropdownSelectedValue(
                            multiOptions,
                            field.value,
                            "multi"
                          ) || code
                        }
                        classes={code}
                        label={name}
                        name={code}
                        disabled={disabled}
                        isReadOnly={isReadOnly}
                        isRequired={isRequired}
                        error={
                          isRequired &&
                          !getDropdownSelectedValue(
                            multiOptions,
                            field.value || defaultValue,
                            "multi"
                          )?.length
                            ? `${name} is required`
                            : null
                        }
                        onError={formik.setFieldError}
                        placeholder={name}
                        value={getDropdownSelectedValue(
                          multiOptions,
                          field.value,
                          "multi"
                        )}
                        options={
                          field?.value?.length === multiOptions?.length - 1
                            ? multiOptions?.filter(
                                data => data?.value !== "all"
                              )
                            : multiOptions
                        }
                        onChange={option => {
                          let isAllOption = option?.some(
                            option => option?.value === "all"
                          );
                          if (
                            maxLength > 0
                              ? getDropdownSelectedValue(
                                  multiOptions,
                                  option,
                                  "multi"
                                )?.length > maxLength ||
                                (isAllOption &&
                                  multiOptions?.length - 1 > maxLength)
                              : false
                          ) {
                            warningNotification(
                              `You can only select upto ${maxLength} options`
                            );
                          } else {
                            onChangeDropdown(
                              isAllOption
                                ? (multiOptions || [])?.filter(
                                    data => data?.value !== "all"
                                  )
                                : option || "",
                              code,
                              formik.setFieldValue,
                              form.values,
                              valueAsString
                            );
                          }
                        }}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          let newArray = arrayMove(
                            getDropdownSelectedValue(
                              multiOptions,
                              field.value,
                              "multi"
                            ) || "",
                            oldIndex,
                            newIndex
                          );
                          onChangeDropdown(
                            newArray,
                            code,
                            formik.setFieldValue,
                            form.values,
                            valueAsString
                          );
                        }}
                        isMulti
                        isClearable
                        isSearchable
                        closeMenuOnSelect={false}
                        withFeedbackLabel
                        overwriteSorting={true}
                        {...restrictions}
                      />
                    )}
                  </>
                )}
              </Field>
            </div>
          );

        case "switch":
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Switch
                    classNames={"checkbox-primary checkbox-lg"}
                    field={field}
                    form={form}
                    meta={meta}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    isRequired={isRequired}
                    tags={tags}
                    label={name}
                    name={code}
                    type={inputType}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                    defaultValue={defaultValue}
                    onError={formik.setFieldError}
                  />
                )}
              </Field>
            </div>
          );

        case "checkbox":
          return (
            <div
              className={layoutClass}
              code={code}
              key={key}
              hidden={restrictions?.hidden || isHidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Switch
                    classNames={"checkbox-primary checkbox-lg"}
                    field={{ ...field }}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    isRequired={isRequired}
                    tags={tags}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    type={inputType}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    defaultValue={defaultValue}
                    onError={formik.setFieldError}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "datePicker":
          return (
            <div
              className={layoutClass}
              code={code}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    classes={code}
                    field={field}
                    form={form}
                    meta={meta}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    label={name}
                    name={code}
                    type={type}
                    tags={tags}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    description={description}
                    showDescription={description?.length}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    dateFormat="dd-MMMM-yyyy"
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "dateTimePicker":
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    classes={code}
                    field={field}
                    form={form}
                    meta={meta}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    label={name}
                    name={code}
                    type={type}
                    tags={tags}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    showTimeSelect
                    description={description}
                    showDescription={description?.length}
                    dateFormat="hh:mm aa, dd-MMMM-yyyy"
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "colorPicker":
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code} validate={validateColorCode}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <ColorPicker
                    classes={code}
                    field={field}
                    form={form}
                    meta={meta}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    label={name}
                    name={code}
                    withFeedbackLabel={true}
                    onChange={field.onChange}
                    {...restrictions}
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "creatableSelect":
          return (
            <div
              code={code}
              className={layoutClass}
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {name && <label>Enter {name}</label>}
                    {isRequired ? (
                      <span className="text-danger"> *</span>
                    ) : null}
                    {description?.length ? (
                      <Overlay popoverContent={description}>
                        <i
                          className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                          }
                        />
                      </Overlay>
                    ) : null}
                    <ReactCreatableSelect
                      classes={code}
                      label={name}
                      name={code}
                      placeholder={name}
                      touched={meta.touched}
                      error={meta.error}
                      value={(field.value || []).map(item => ({
                        label: item,
                        value: item
                      }))}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      onChange={option => {
                        if (
                          maxLength > 0
                            ? (option || []).map(item => ({
                                label: item,
                                value: item
                              }))?.length > maxLength
                            : false
                        ) {
                          warningNotification(
                            `You can only select upto ${maxLength} options`
                          );
                        } else {
                          onChangeDropdown(
                            option || "",
                            code,
                            formik.setFieldValue,
                            form.values,
                            false
                          );
                        }
                      }}
                      isMulti
                      isClearable
                      // isValidNewOption={(inputValue, selectValue, selectOptions, accessor) => {
                      //   formik.errors[code] = "asdasdas";
                      //   return false;
                      // }}
                      noOptionsMessage={() => null}
                      isSearchable
                      closeMenuOnSelect={true}
                      formatCreateLabel={inputValue => `Create ${inputValue}`}
                      withFeedbackLabel
                      {...restrictions}
                      defaultValue={defaultValue}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "conditionBuilder":
          return (
            <div
              className="col-lg-12 mb-3 mt-3"
              key={key}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <>
                    <QueryBuilder
                      heading={name}
                      isRequired={isRequired}
                      key={"qb" + code}
                      fieldsList={allFields || dropdownOptions?.fields || []}
                      dropdownOptions={dropdownOptions}
                      expression={
                        field.value?.representations?.[0]?.representationValue
                      }
                      queryValue={field.value?.expressionTree}
                      onChange={props =>
                        onChangeQueryBuilder(
                          props,
                          code,
                          representations,
                          formik
                        )
                      }
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "richTextBox":
          return (
            <div
              code={code}
              key={key}
              className={richTextBoxLayoutClass}
              hidden={restrictions?.hidden || isHidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <RichTextBox
                    // prevState={props?.prevState ? props?.prevState : null}
                    classes={code}
                    field={{ ...field }}
                    disabled={disabled}
                    isReadOnly={isReadOnly}
                    tags={tags}
                    meta={meta}
                    form={form}
                    label={name}
                    name={code}
                    type={inputType}
                    placeholder={name || code}
                    showDescription={description?.length}
                    description={description}
                    {...restrictions}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    onChange={field.onChange}
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );
        default:
          return null;
      }
    });
  };

  const removeEmptyParameters = data => {
    return Object.fromEntries(
      Object.entries(data).filter(
        ([_, v]) => v !== null && v !== undefined && v !== ""
      )
    );
  };

  const getFieldFromExpressionTree = expressionTree => {
    // Parse the JSON string to an object
    const treeObject = JSON.parse(expressionTree);

    const traverseTree = tree => {
      if (tree && tree?.properties && tree?.properties?.field) {
        return tree.properties.field;
      }

      // If not, recursively traverse the tree
      for (const key in tree) {
        if (typeof tree[key] === "object") {
          const result = traverseTree(tree[key]);
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    return traverseTree(treeObject);
  };

  const enforceOutcomes = (
    allFields,
    fieldsOriginalState,
    validationStatus,
    outcomes,
    existingData,
    headerFields,
    headersUpdatedState
  ) => {
    let allStageFieldsTemp = [...allFields];
    let tempFieldsState = { ...fieldsOriginalState };
    let updatedValues = {};
    let dataFetchedTemp = { ...dataFetched };

    (outcomes || []).forEach(outcome => {
      let outcomeData = removeEmptyParameters(outcome);
      let thisOutcomeFieldIndex = allStageFieldsTemp.findIndex(
        field => field.code === outcomeData.code
      );

      let thisOutcomeHeaderIndex = allStageFieldsTemp?.findIndex(
        hdr => hdr?.header === outcomeData?.header
      );

      if (thisOutcomeHeaderIndex > -1) {
        let filteredFields = allStageFieldsTemp?.filter(
          data => data?.header === outcomeData?.header
        );
        // eslint-disable-next-line
        (filteredFields || []).map(fld => {
          let findIndexFld = allStageFieldsTemp?.findIndex(
            data => data?.code === fld?.code
          );
          fld.isHidden = validationStatus
            ? outcomeData.isHidden
            : fieldsDictionary?.[fld?.code]?.isHidden;
          fld.isReadOnly = validationStatus
            ? outcomeData.isReadOnly
            : fieldsDictionary?.[fld?.code]?.isReadOnly;
          fld.isRequired = validationStatus
            ? outcomeData.isRequired
            : fieldsDictionary?.[fld?.code]?.isRequired;

          allStageFieldsTemp[findIndexFld] = fld;

          if (validationStatus && outcomeData?.shouldClearValue) {
            updatedValues[
                fld?.code
                ] = getOutcomeValueInDesiredFormat(
                fld?.dataType,
                ""
            );
          }
        });

        if (validationStatus) {
          headersUpdatedState[outcomeData.header] = {
            isHidden: outcomeData?.isHidden,
            isReadOnly: outcomeData?.isReadOnly
          };
        }
      }

      if (thisOutcomeFieldIndex > -1) {
        delete outcomeData.code;
        delete outcomeData.priority;

        allFields.forEach((fld, index) => {
          if (fld.code === outcome.code) {
            if (!tempFieldsState[outcome.code]) {
              const originalFieldClone = clone(
                fieldsDictionary[outcome.code] || {}
              );
              let fieldsOriginalData = {};
              Object.keys(outcomeData).forEach(key => {
                fieldsOriginalData[key] = fld[key];
              });

              if (
                originalFieldClone.masterDataType &&
                !outcome.masterDataType
              ) {
                outcomeData.masterDataType = originalFieldClone.masterDataType;
                fieldsOriginalData.masterDataType =
                  originalFieldClone.masterDataType;
              }

              tempFieldsState[outcome.code] = fieldsOriginalData;
            }

            if (validationStatus && Boolean(outcomeData?.masterDataType)) {
              if (
                !recordsState?.[outcomeData.masterDataType]?.records &&
                !dataFetched[outcomeData.masterDataType]
              ) {
                dispatch(
                  fetchRecords({
                    entityCode: outcomeData.masterDataType,
                    paginationOverride: true
                  })
                );
                dataFetchedTemp[outcomeData.masterDataType] = true;
              }
            }
            let updatedFieldData = !validationStatus
              ? { ...(tempFieldsState[outcome.code] || {}) }
              : { ...outcomeData };

            allStageFieldsTemp[index] = {
              ...allFields[index],
              ...updatedFieldData
            };

            if (
              updatedFieldData?.isReadOnly &&
              !outcomeData?.value &&
              !validationStatus &&
              inputTypes.find(
                input => input?.id === allStageFieldsTemp[index]?.dataType
              )?.type !== "select"
            ) {
              delete updatedValues[fld.code];
            }
            if (validationStatus && outcomeData?.shouldClearValue) {
              updatedValues[fld.code] = getOutcomeValueInDesiredFormat(
                  fld.dataType,
                  ""
              );
            } else if (validationStatus && outcomeData?.value) {
              updatedValues[fld.code] = getOutcomeValueInDesiredFormat(
                fld.dataType,
                outcomeData?.value || existingData?.[outcomeData.code]
              );
            }

            if (
              (inputTypes.find(
                input => input?.id === allStageFieldsTemp[index]?.dataType
              )?.type === "checkbox" ||
                inputTypes.find(
                  input => input?.id === allStageFieldsTemp[index]?.dataType
                )?.type === "switch") &&
              !validationStatus
            ) {
              updatedValues[fld.code] = false;
            }
          }
        });
      }
    });
    setDataFetched(dataFetchedTemp);
    return {
      updatedFields: allStageFieldsTemp,
      updatedFieldsState: tempFieldsState,
      updatedValues,
      headersUpdatedState
    };
  };

  const validateFormDataAgainstConditions = (
    conditions,
    values,
    shouldSetValue,
    gridCode
  ) => {
    const applyCondition = cond => {
      const jsonQuery = JSON.parse(
        (cond?.expression?.representations || []).find(
          rep =>
            queryBuilderQueryTypeEnums["JSONLogic"] === rep.representationType
        )?.representationValue || "{}"
      );
      const validationStatus = validateJSONLogic(jsonQuery, updatedValues);
      if (
        (validationStatus && conditionsStatus[cond.code] === undefined) ||
        (conditionsStatus[cond.code] !== undefined &&
          conditionsStatus[cond.code] !== validationStatus)
      ) {
        if (!isValidatingForm) setIsValidatingForm(true);
        changedConditions.push({ ...cond, validationStatus });
      }
    };

    let updatedValues = { ...values };
    let newValues = {};
    let changedConditions = [];
    let allFields = [...(renderableFields?.fields || [])];
    let headerFields = { ...(groupedByHeaderFields || {}) };
    let fieldsStatus = { ...fieldsOriginalState };
    let conditionsStatusTemp = { ...conditionsStatus };

    conditions.forEach(cond => {
      if (cond?.gridCodes?.length) {
        if (cond?.gridCodes?.includes(gridCode)) {
          applyCondition(cond);
        }
      } else {
        applyCondition(cond);
      }
    });
    changedConditions = changedConditions.sort((x, y) => {
      const fieldX = getFieldFromExpressionTree(x.expression?.expressionTree);
      const fieldY = getFieldFromExpressionTree(y.expression?.expressionTree);

      if (fieldX === fieldY) {
        return x.validationStatus ? 1 : -1;
      } else {
        return x.priority - y.priority;
      }
    });

    // changedConditions = changedConditions.sort((a, b) => {
    //   let jsonQueryA = JSON.parse((a?.expression?.representations || []).find(rep => queryBuilderQueryTypeEnums["JSONLogic"] === rep.representationType)?.representationValue || "{}");
    //   let jsonQueryB = JSON.parse((b?.expression?.representations || []).find(rep => queryBuilderQueryTypeEnums["JSONLogic"] === rep.representationType)?.representationValue || "{}");
    //
    //   const validationStatusA = validateJSONLogic(jsonQueryA, updatedValues);
    //   const validationStatusB = validateJSONLogic(jsonQueryB, updatedValues);
    //   if (validationStatusA && !validationStatusB) return 1;
    //   else if (!validationStatusA && validationStatusB) return -1;
    //   else return -1;
    // })

    let headersCombinedState = {};

    changedConditions.forEach(cond => {
      let {
        updatedValues: tempUpdatedValues,
        updatedFields: tempAllFields,
        updatedFieldsState: tempFieldsStatus,
        headersUpdatedState
      } = enforceOutcomes(
        allFields,
        fieldsStatus,
        cond.validationStatus,
        cond.outcomes || [],
        updatedValues,
        headerFields,
        headersCombinedState
      );

      headersCombinedState = {
        ...headersCombinedState,
        ...headersUpdatedState
      };
      newValues = { ...newValues, ...tempUpdatedValues };
      allFields = [...tempAllFields];
      fieldsStatus = { ...fieldsStatus, ...tempFieldsStatus };
      conditionsStatusTemp[cond.code] = cond.validationStatus;
    });
    if (changedConditions.length) {
      setConditionsStatus(conditionsStatusTemp);
      setFieldsState(fieldsStatus);
      setRenderableFields(getFieldsWithValidation(allFields, mode));
      if (shouldSetValue)
        setFormValues(prevValues => ({
          ...prevValues,
          ...values,
          ...newValues
        }));
    }
    setIsValidatingForm(false);
    return null;
  };

  const applyComputationsOnFields = async fieldValues => {
    if (computations) {
      let currentValues = { ...fieldValues };
      let { formComputations, mathematicalExpressions, concatenatedValuesWithSpace } = computations;

      formComputations.forEach(comp => {
        let targetFieldCode = Object.keys(comp)[0];
        let { fieldCodes: sourceFieldCodes, operation } = comp[targetFieldCode];
        if (targetFieldCode) {
          const operationType = dropDownOptions.computationOptions.find(
            compType => compType.code === operation
          )?.name;
          let currentFieldValue = currentValues?.[targetFieldCode];
          let updatedValue = currentFieldValue;
          switch (operationType) {
            case "Add": {
              if (
                sourceFieldCodes.length === 2 &&
                ((fieldsDictionary[sourceFieldCodes[0]].dataType === 7 &&
                  fieldsDictionary[sourceFieldCodes[1]].dataType === 1) ||
                  (fieldsDictionary[sourceFieldCodes[0]].dataType === 1 &&
                    fieldsDictionary[sourceFieldCodes[1]].dataType === 7))
              ) {
                if (currentValues[sourceFieldCodes[0]]) {
                  updatedValue = moment(
                    new Date(currentValues[sourceFieldCodes[0]])
                  )
                    .add(currentValues[sourceFieldCodes[1]], "months")
                    .toDate();
                  if (currentFieldValue !== updatedValue) {
                    currentValues[targetFieldCode] = updatedValue;
                  }
                }
              } else {
                updatedValue = sourceFieldCodes
                  .map(
                    code =>
                      Number(currentValues[code]) ||
                      Number(formData?.[code]) ||
                      0
                  )
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  );
                if (currentFieldValue !== updatedValue) {
                  currentValues[targetFieldCode] = Number(
                    Number(updatedValue || 0).toFixed(5)
                  );
                }
              }
              return;
            }

            case "Subtract": {
              updatedValue = sourceFieldCodes
                .map(
                  code =>
                    Number(currentValues[code]) || Number(formData?.[code]) || 0
                )
                .reduce(
                  (accumulator, currentValue) => accumulator - currentValue
                );
              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = Number(
                  Number(updatedValue || 0).toFixed(5)
                );
              }
              return;
            }

            case "Multiply": {
              if (targetFieldCode === staticCodes.RRL_Field_ValueOfPassbook) {
                if (
                  conditionsStatus[
                    staticCodes.RRL_Condition_ProductIndexUnit002
                  ] === true
                )
                  updatedValue = sourceFieldCodes
                    .map(
                      code =>
                        Number(currentValues[code]) ||
                        Number(formData?.[code]) ||
                        0
                    )
                    .reduce(
                      (accumulator, currentValue) => accumulator * currentValue
                    );
              } else
                updatedValue = sourceFieldCodes
                  .map(
                    code =>
                      Number(currentValues[code]) ||
                      Number(formData?.[code]) ||
                      0
                  )
                  .reduce(
                    (accumulator, currentValue) => accumulator * currentValue
                  );
              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = Number(
                  Number(updatedValue || 0).toFixed(5)
                );
              }
              return;
            }

            case "Divide": {
              updatedValue = sourceFieldCodes.map(
                code =>
                  Number(currentValues[code]) || Number(formData?.[code]) || 0
              );
              updatedValue = updatedValue
                .reduce(
                  (accumulator, currentValue) => accumulator / currentValue || 1
                )
                .toFixed(5);
              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = replaceNotNumberValueWithZero(
                  Number(Number(updatedValue || 0).toFixed(5))
                );
              }
              return;
            }

            case "Average": {
              updatedValue = sourceFieldCodes.map(
                code =>
                  Number(currentValues[code]) || Number(formData?.[code]) || 0
              );
              updatedValue = (
                updatedValue.reduce((acc, value) => acc + value) /
                  updatedValue.length || 1
              ).toFixed(5);
              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = Number(
                  Number(updatedValue || 0).toFixed(5)
                );
              }
              return;
            }

            case "Range In Months": {
              updatedValue = sourceFieldCodes.map(
                code => currentValues[code] || formData?.[code] || new Date()
              );
              updatedValue = moment(new Date(updatedValue[1])).startOf('day').diff(
                  moment(new Date(updatedValue[0])).startOf('day'),
                  "months",
                  true
              );

              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = Number(
                  Number(updatedValue || 0).toFixed(5)
                );
              }
              return;
            }

            case "Range In Years": {
              updatedValue = sourceFieldCodes.map(
                code => currentValues[code] || formData?.[code] || new Date()
              );
              updatedValue = moment(new Date(updatedValue[1]))
                .diff(new Date(updatedValue[0]), "years", true)
                .toFixed(5);
              if (currentFieldValue !== updatedValue) {
                currentValues[targetFieldCode] = Number(
                  Number(updatedValue || 0).toFixed(5)
                );
              }
              return;
            }

            case "Range In Y,M,D": {
              updatedValue = sourceFieldCodes.map(
                code => currentValues[code] || new Date()
              );
              updatedValue = moment(new Date(updatedValue[1])).diff(
                new Date(updatedValue[0]),
                "years",
                true
              );
              if (currentFieldValue !== updatedValue) {
                const years = Math.floor(updatedValue);
                const months = Math.floor((updatedValue - years) * 12);
                const days = Math.floor(
                  (((updatedValue - years) * 12) % 1) * 30
                );

                currentValues[
                  targetFieldCode
                ] = `${years} years, ${months} months, ${days} days`;
              }
              return;
            }

            case "Gender": {
              let sourceFieldValue = sourceFieldCodes
                .map(code => currentValues[code])
                .join("")
                .replace(/-/g, "");

              if (isValueEmpty(currentValues[targetFieldCode])) {
                if (sourceFieldValue?.length === 13) {
                  const lastDigit = parseInt(
                      sourceFieldValue.toString().slice(-1)
                  );
                  if (fieldsDictionary[targetFieldCode]?.dataType === 2) {
                    const possibleFemaleNames = ["female", "fem", "woman"];
                    const possibleMaleNames = ["male", "mal", "man"];
                    const genderName = lastDigit % 2 === 0 ? "Female" : "Male";
                    const record = fieldsDictionary[
                        targetFieldCode
                        ]?.records.find(record => {
                      if (genderName === "Female") {
                        return possibleFemaleNames.includes(
                            record?.name.toLowerCase()
                        );
                      } else if (genderName === "Male") {
                        return possibleMaleNames.includes(
                            record?.name.toLowerCase()
                        );
                      }
                      return false;
                    });
                    currentValues[targetFieldCode] = record?.code || "";
                  } else {
                    currentValues[targetFieldCode] =
                        lastDigit % 2 === 0 ? "Female" : "Male";
                  }
                } else {
                  currentValues[targetFieldCode] = "";
                }
              }

              return;
            }

            default:
              return null;
          }
        }
      });

      mathematicalExpressions.forEach(comp => {
        let { formula, variables, resultFieldCode } = comp;
        let formulaWithFieldsValues = String(formula || "")
          .replace(/[ \n]/g, "")
          .replace(/[ \t]/g, "");

        for (const [variable, fieldCode] of Object.entries(variables)) {
          formulaWithFieldsValues = formulaWithFieldsValues.replaceAll(
              `$${variable}`,
              currentValues[fieldCode] || 0
          );
        }

        try {
          currentValues[resultFieldCode] = replaceNotNumberValueWithZero(
              evaluate(formulaWithFieldsValues)
          );
        } catch (e) {
          console.error({
            resultFieldCode,
            formula,
            formulaWithFieldsValues,
            e
          });
          errorNotification(
            `Incorrect formula found in mathematical expression of ${fieldsDictionary[resultFieldCode]?.name} (${resultFieldCode}) field.`
          );
        }
      });

      (concatenatedValuesWithSpace || []).forEach(comp => {
        let targetFieldCode = Object.keys(comp)[0];
        const { fieldCodes } = comp[targetFieldCode];
        let currentFieldValue = currentValues?.[targetFieldCode];
        let updatedValue = currentFieldValue;

        if (targetFieldCode) {
          updatedValue = fieldCodes.map(
              code => currentValues[code]
          ).reduce(
              (accumulator, currentValue) => accumulator ? `${accumulator} ${currentValue}` : currentValue,
              ''
          );
          if (currentFieldValue !== updatedValue) {
            currentValues[targetFieldCode] = updatedValue;
          }
        }

      });

      return currentValues || {};
    }
    return fieldValues;
  };

  const onFormChange = debounce(formik => {
    if (renderableFields?.fields?.length) {
      validateFormDataAgainstConditions(
        stageConditions || [],
        formik.values,
        true,
        gridCode
      );
    } else {
      setIsValidatingForm(false);
    }
  }, 250);

  const applyTagValues = async fields => {
    let allFields = [...(renderableFields?.fields || [])];
    let updatedTagValues = {};

    (allFields || []).forEach(fld => {
      let alwaysUpdatedCurrentDate =
        fld?.tags?.find(tag => tag?.SetAlwaysUpdatedCurrentDate)
          ?.SetAlwaysUpdatedCurrentDate === "True";
      let currentDateUpdateOnce =
        fld?.tags?.find(tag => tag?.SetUpdatedCurrentDateOnlyOnce)
          ?.SetUpdatedCurrentDateOnlyOnce === "True";
      let setNextNDate = fld?.tags?.find(tag => tag?.SetNextNDate)
        ?.SetNextNDate;
      let setLastNDate = fld?.tags?.find(tag => tag?.SetLastNDate)
        ?.SetLastNDate;
      let ceil = fld?.tags?.find(tag => tag?.code === "Ceil")?.code;
      let floor = fld?.tags?.find(tag => tag?.code === "Floor")?.code;
      let round = fld?.tags?.find(tag => tag?.code === "Round")?.code;
      let decimal = fld?.tags?.find(tag => tag?.code === "Decimal")?.code;
      let signedDecimal = fld?.tags?.find(tag => tag?.code === "SignedDecimal")
        ?.code;
      let wholeNumber = fld?.tags?.find(tag => tag?.code === "WholeNumber")
        ?.code;

      if (
        ceil &&
        typeof fields?.[fld?.code] === "number" &&
        fields?.[fld?.code]
      ) {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Math.ceil(fields?.[fld?.code])
        };
      }
      if (
        floor &&
        typeof fields?.[fld?.code] === "number" &&
        fields?.[fld?.code]
      ) {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Math.floor(fields?.[fld?.code])
        };
      }
      if (
        round &&
        typeof fields?.[fld?.code] === "number" &&
        fields?.[fld?.code]
      ) {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Math.round(fields?.[fld?.code])
        };
      }
      if (decimal && typeof fields?.[fld?.code] === "number") {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Number(Number(fields?.[fld?.code] || 0).toFixed(5))
        };
      }
      if (signedDecimal && typeof fields?.[fld?.code] === "number") {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Number(Number(fields?.[fld?.code] || 0).toFixed(5))
        };
      }
      if (
        wholeNumber &&
        typeof fields?.[fld?.code] === "number" &&
        fields?.[fld?.code]
      ) {
        updatedTagValues = {
          ...updatedTagValues,
          [fld?.code]: Math.trunc(fields?.[fld?.code])
        };
      }
      if (alwaysUpdatedCurrentDate) {
        if (setNextNDate) {
          updatedTagValues = {
            ...updatedTagValues,
            [fld?.code]: moment()
              .startOf("day")
              .add(setNextNDate, "d")
              .toDate()
          };
        } else if (setLastNDate) {
          updatedTagValues = {
            ...updatedTagValues,
            [fld?.code]: moment()
              .startOf("day")
              .subtract(setLastNDate, "d")
              .toDate()
          };
        } else {
          updatedTagValues = {
            ...updatedTagValues,
            [fld?.code]: moment()
              .startOf("day")
              .toDate()
          };
        }
      }
      if (currentDateUpdateOnce) {
        let data = fields?.[fld?.code] ? fields?.[fld?.code] : new Date();
        updatedTagValues = { ...updatedTagValues, [fld?.code]: data };
      }
    });
    return updatedTagValues || {};
  };

  const applyDefaultValues = async fields => {
    let allFields = [...(renderableFields?.fields || [])];
    let defaultValues = {};
    (allFields || []).forEach(fld => {
      defaultValues = { ...defaultValues, ...setDefaultValues(fld, fields) };
    });
  };

  const checkUniqueValue = async finalValues => {
    let currentGridData = [...(gridData || [])];
    let duplicatedItemsList = [];
    if (uniqueSubFields?.length && currentGridData?.length > 0) {
      uniqueSubFields.forEach(item => {
        if (finalValues?.[item]) {
          let matchedIndex = currentGridData
            .map((elm, index) =>
              elm?.[item] &&
              elm?.[item] === finalValues?.[item] &&
              index !== modalCurrentIndex
                ? index + 1
                : ""
            )
            .filter(String);
          if (matchedIndex?.length) {
            let message = `The value ${
              finalValues?.[item]
            } is duplicated and found in row(s) ${matchedIndex.join(
              ", "
            )} of this grid at ${fieldsDictionary?.[item]?.name} field`;
            errorNotification(message);
            duplicatedItemsList.push(message);
          }
        }
      });
    }

    return duplicatedItemsList;
  };

  // Commit code for Not applicable handling for Customers field in Form
  // const onClickSubmit = async (formik, customerNotApplicable) => {
  const onClickSubmit = async (formik, validate) => {
    let updatedValues = await applyComputationsOnFields(formik.values);
    let updatedTagValues = await applyTagValues(updatedValues);
    let defaultValues = await applyDefaultValues({
      ...updatedValues,
      ...updatedTagValues
    });
    updatedValues = await applyComputationsOnFields({
      ...updatedValues,
      ...updatedTagValues,
      ...defaultValues
    });
    updatedTagValues = await applyTagValues(updatedValues);
    defaultValues = await applyDefaultValues({
      ...updatedValues,
      ...updatedTagValues
    });

    const errorsState = await formik.validateForm({
      ...updatedValues,
      ...updatedTagValues,
      ...defaultValues
    });

    let duplicatedItemsList = await checkUniqueValue({
      ...updatedValues,
      ...updatedTagValues,
      ...defaultValues
    });
    // Commit code for Not applicable handling for Customers field in Form
    // if (Object.keys(errorsState)?.length && !customerNotApplicable) {

    let gridCopyData = recordsState?.["GridCopy"]?.records;
    gridCopyData = (gridCopyData || []).filter(
      item =>
        workflow?.gridFields?.[item?.values?.SourceGrid] ||
        (!item?.values?.SourceGrid && !item?.values?.SourceRow)
    );

    (gridCopyData || []).forEach(item => {
      let fieldExistsInStage = item?.values?.DestinationGrid === gridCode;

      if (fieldExistsInStage) {
        if (item?.values?.SourceGrid) {
        } else {
          let updatedValue =
            item?.values?.IsOverride === "True"
              ? formData?.[item?.values?.SourceField]
              : values[item?.values?.DestinationField] ||
                formData?.[item?.values?.SourceField];
          formik.setFieldValue([item?.values?.DestinationField], updatedValue);
        }
      }
    });

    if (!duplicatedItemsList?.length) {
      if (Object.keys(errorsState)?.length) {
        formik.handleSubmit();
      } else {
        if (validate) {
          setFormValues({
            ...updatedValues,
            ...updatedTagValues,
            ...defaultValues
          });
        } else {
          onSubmit &&
            onSubmit(
              { ...updatedValues, ...updatedTagValues, ...defaultValues },
              validate
            );
        }
      }
    }
  };

  const applyCssToComputedFields = () => {
    if (computations) {
      let {
        formComputations,
        gridComputations,
        mathematicalExpressions
      } = computations;

      gridComputations.forEach(comp => {
        let gridFieldCode = Object.keys(comp)[0];
        if (gridFieldCode) {
          let sourceFieldCode = Object.keys(comp[gridFieldCode])[0];
          let { fieldCode: targetFieldCode } = comp[gridFieldCode][
            sourceFieldCode
          ];

          if (targetFieldCode) {
            const fieldElement = document.querySelector("." + targetFieldCode);
            if (fieldElement) fieldElement.classList.add("computed-field");
          }
        }
      });

      formComputations.forEach(comp => {
        let targetFieldCode = Object.keys(comp)[0];
        if (targetFieldCode) {
          const fieldElement = document.querySelector("." + targetFieldCode);
          if (fieldElement) fieldElement.classList.add("computed-field");
        }
      });

      mathematicalExpressions.forEach(comp => {
        let { resultFieldCode } = comp;
        if (resultFieldCode) {
          const fieldElement = document.querySelector("." + resultFieldCode);
          if (fieldElement) fieldElement.classList.add("computed-field");
        }
      });
    }
  };

  const checkHeaderVisibility = headerFields => {
    let isHideHeader = true;

    if ((headerFields || []).length) {
      for (let index = 0; index < (headerFields || []).length; index++) {
        if (!headerFields[index].isHidden) {
          isHideHeader = false;
          break;
        }
      }
    } else {
      isHideHeader = true;
    }

    return !isHideHeader;
  };

  return (
    <>
      {
        <Formik
          initialValues={{ ...formValues } || {}}
          validationSchema={renderableFields?.validationSchema}
          // onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {formik => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              applyCssToComputedFields();
              // eslint-disable-next-line react-hooks/exhaustive-deps
            });
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              onFormChange(formik);
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [formik.values]);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setFormikInstance(formik);
              // eslint-disable-next-line
            }, [formik.errors]);

            return (
              <Form
                className="form form-label-right"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group row">
                  {Object.keys(groupedByHeaderFields || []).map(header => (
                    <React.Fragment key={header}>
                      {Object.keys(groupedByHeaderFields)?.length > 1 ? (
                        <br />
                      ) : null}
                      {header ? (
                        <div className="col-lg-12 mb-3">
                          <h5>
                            {checkHeaderVisibility(
                              groupedByHeaderFields[header]
                            ) && recordsState.StageHeaders?.records
                              ? recordsState.StageHeaders?.records.find(
                                  hdr => hdr.code === header
                                )?.name
                              : ""}
                          </h5>
                        </div>
                      ) : null}
                      {getRenderableFields(
                        formik,
                        groupedByHeaderFields[header]
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {formMode !== "view" ? (
                  <>
                    <button
                      className="btn btn-secondary ml-2 float-right"
                      type="button"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    {/*Commit code for Not applicable handling for Customers field in Form*/}
                    {/*{isCustomerField ? (*/}
                    {/*  <button*/}
                    {/*    className="btn btn-primary float-right ml-2"*/}
                    {/*    // type="submit"*/}
                    {/*    type="button"*/}
                    {/*    disabled={mode === "view" || isValidatingForm}*/}
                    {/*    onClick={() => onClickSubmit(formik, true)}*/}
                    {/*  >*/}
                    {/*    Not Applicable*/}
                    {/*  </button>*/}
                    {/*) : null}*/}

                    <button
                      className="btn btn-primary ml-2 float-right"
                      // type="submit"
                      type="button"
                      disabled={mode === "view" || isValidatingForm}
                      onClick={() => onClickSubmit(formik)}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-warning float-right"
                      // type="submit"
                      type="button"
                      disabled={mode === "view" || isValidatingForm}
                      onClick={() => onClickSubmit(formik, true)}
                    >
                      Validate
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-secondary ml-2 float-right"
                    type="button"
                    onClick={onCancel}
                  >
                    Back
                  </button>
                )}
              </Form>
            );
          }}
        </Formik>
      }
    </>
  );
}
