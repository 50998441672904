import React, { useState } from "react";
import { Overlay } from "./Overlay";
import { MathematicalExpressionDialog } from "./MathematicalExpressionDialog";

export function MathematicalExpression(props) {
  const {
    label,
    name,
    disabled,
    isReadOnly,
    isRequired,
    field: { value },
    setFieldValue
  } = props;

  const [showExpressionBuilder, setShowExpressionBuilder] = useState(false);

  const onOpenExpressionBuilder = () => {
    setShowExpressionBuilder(true);
  };

  return (
    <>
      <div
        className="mathematical-expression-container"
        onClick={onOpenExpressionBuilder}
      >
        <label title={label}>
          {label} {isRequired ? <p className={"text-danger"}> *</p> : null}
        </label>
        {value?.formula ? (
          <Overlay popoverContent={value?.formula}>
            <i className="fas fa-calculator text-success calculator-icon"></i>
          </Overlay>
        ) : null}
        <Overlay popoverContent="Mathematical Expression Builder">
          <i className="fas fa-long-arrow-alt-right arrow-icon"></i>
        </Overlay>
      </div>

      <MathematicalExpressionDialog
        show={showExpressionBuilder}
        setShow={setShowExpressionBuilder}
        disabled={disabled || isReadOnly}
        onChange={updatedValue => {
          setFieldValue(name, updatedValue);
        }}
        value={value}
      />
    </>
  );
}
