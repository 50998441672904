/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";


export const GenericDynamicFormatter = (
  cellContent,
  row,
  rowIndex,
  {parameters: {identifier, objectName, allItems, isSearchFromArray}}
) => {
  return (
    <span>{isSearchFromArray ? allItems?.find(value => value?.code === row?.[identifier])?.name : row?.[objectName]?.[identifier] || row?.[identifier] || "---"}</span>
  );
}

export const AssignToFormatter = (
  cellContent,
  row,
  rowIndex,
  {parameters: {allUsers}}
) => {
  return (
    <span>{allUsers?.find(value => value?.id === row?.assignTo)?.name || 'None'}</span>
  );
}
