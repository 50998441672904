import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const defaultTooltip = (props) => {
  let tempProps = {...props};
  delete tempProps.popoverContent;
  return<Tooltip id="button-tooltip" {...tempProps}>
    {props.popoverContent}
  </Tooltip>
};
export const Overlay = ({Popover, popoverContent, trigger, rootClose, placement, delay, children, onExit}) => {

  return (
    <OverlayTrigger
      rootClose={rootClose || true}
      trigger={trigger || ["hover", "focus"]}
      onExit={() => {onExit && onExit()}}
      delay={delay || {show: 150, hide: 500}}
      placement={placement || "top"}
      overlay={(props) => Popover !== undefined ? <Popover {...props}/> : defaultTooltip({...props, popoverContent})}
    >
      {children}
    </OverlayTrigger>
  )
}
