import React from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { getSelectRow } from "../../_helpers";

export function Memo({listData, listLoading, uiProps, totalCount}) {
  const columns = [
    {
      dataField: "values.CaseID",
      cellContent: 'value.CaseID',
      classes: "font-weight-bold",
      text: "Case ID",
    },
    {
      dataField: "values.CaseDTL_AGS_UserID",
      cellContent: 'value.CaseDTL_AGS_UserID',
      text: "Case DTL AGS User ID",
      classes: "font-weight-bold",
    },
    {
      dataField: "values.ModDate",
      text: "Mod Date",
      cellContent: 'value.ModDate',
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent)
    },

    {
      dataField: "values.CaseType",
      cellContent: 'value.CaseType',
      text: "Case Type",
      classes: "font-weight-bold",
    },

    {
      dataField: "values.CaseStatus",
      cellContent: 'value.CaseStatus',
      text: "Case Status",
      classes: "font-weight-bold",
    },
    {
      dataField: "values.CasePriority",
      cellContent: 'value.CasePriority',
      text: "Case Priority",
      classes: "font-weight-bold",
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };

  const renderPayments = (row) => {
    return row?.values?.CaseDTL_Event ? (
      <div className="card card-custom card-stretch gutter-b m-0">
        <div className="card-body p-0">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-center text-uppercase">
                  <th>Case DTL Event</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="font-weight-bold text-center">{row?.values?.CaseDTL_Event}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ) : (<></>)
  }

  const expandComponent = {
    renderer: row => renderPayments(row),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({isAnyExpands}) => {
      if (isAnyExpands) {
        return <i className="icon-md far fa-window-minimize"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    },
    expandColumnRenderer: ({expanded}) => {
      if (expanded) {
        return <i className="icon-md fas fa-angle-down"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    }
  }

  return (
    <DataGrid
      data={listData}
      listLoading={listLoading}
      columns={columns}
      paginationOptions={paginationOptions}
      type={uiProps.queryParams.type}
      onTableParamsChange={getHandlerTableChange(uiProps.setQueryParams)}
      keyField="id"
      select={getSelectRow({
        identifier: "id",
        entities: listData,
        selectedItems: [...uiProps.selectedItems],
        setSelectedItems: uiProps.setSelectedItems,
      })}
      isExpandableRow={true}
      expandRow={expandComponent}
    />
  );
}
