import * as requestFromServer from "./groupsCrud";
import { callTypes, groupsSlice } from "./groupsSlice";
import { getDictionaryForHeader } from "../../../../../_custom/_helpers/index";

const { actions } = groupsSlice;
export const fetchGroups = (params, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGroups(params, headers)
    .then(response => {
      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(actions.groupsFetched({ totalCount, entities }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch groups";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearGroupsList = () => dispatch => {
  dispatch(actions.clearGroupsList());
};

export const clearGroupsPermissions = () => dispatch => {
  dispatch(actions.clearGroupsPermissions());
};

export const fetchGroup = (params, headers) => dispatch => {
  if (!params) {
    return dispatch(actions.groupFetched({ groupForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGroupById(params, headers)
    .then(response => {
      const group = response.data;

      if (params?.type === "PrevState") {
        dispatch(actions.prevGroupFetched({ groupForEdit: group }));
      } else {
        dispatch(actions.groupFetched({ groupForEdit: group }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGroup = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGroup(queryParams)
    .then(response => {
      if (!queryParams?.isBulk) {
        dispatch(actions.groupDeleted({ queryParams }));
      }
      if (queryParams?.isBulk && queryParams?.lastCall) {
        dispatch(actions.groupDeleted({ queryParams }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGroup = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGroup(queryParams, headers)
    .then(response => {
      const { group } = response.data;
      dispatch(actions.groupCreated({ group }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateGroup = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGroup(queryParams, headers)
    .then(response => {
      dispatch(actions.groupUpdated(queryParams));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const actOnDraft = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams, headers)
    .then(response => {
      const { group } = response.data;
      dispatch(actions.actionDrafted({ group }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchGroupsByCodesList = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.initiate }));
  return requestFromServer
    .getGroupsByCodesList(params)
    .then(response => {
      const groups = response?.data;
      let dictionary = getDictionaryForHeader({ groups });
      dispatch(actions.groupsFetchedByCodeList({ dictionary }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch fields";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLoggedInUserGroups = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.initiate }));
  return requestFromServer
    .fetchLoggedInUserGroups()
    .then(response => {
      const groups = response?.data;
      let dictionary = getDictionaryForHeader({ groups });
      dispatch(actions.groupsFetchedByCodeList({ dictionary }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch fields";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearPermissionsDictionary = () => dispatch => {
  dispatch(actions.clearPermissionsDictionary());
};

export const auditGroup = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditGroup(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetched({ auditLogs }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAuditsById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAuditsById(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetchedById({ auditLogs, params }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
