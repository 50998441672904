import * as columnFormatters from "./index";
import { sortCaret } from "../../../../_metronic/_helpers";

export const ProductFormatters = [
  {
    dataField: "priority",
    text: "Priority",
    sort: false,
    sortCaret: sortCaret,
    formatter: columnFormatters.PriorityColumnFormatter,
    formatExtraData: {
      onClickSwap: () => {},
      onClickPlace: () => {},
    },
  },
  {
    dataField: "company",
    text: "Company",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "color",
    text: "Color",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "minimumScore",
    text: "Minimum Score",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "maximumScore",
    text: "Maximum Score",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "code",
    text: "Code",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "turnAroundTime",
    text: "Turn Around Time",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "fields",
    text: "Fields",
    // sort: true,
    // sortCaret: sortCaret,
    formatter: columnFormatters.FieldsColumnFormatter,
  },
  {
    dataField: "weightage",
    text: "Weightage",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "score",
    text: "Score",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "outOf",
    text: "Out Of",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "stage",
    text: "Stage",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "value",
    text: "Value",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "overrides",
    text: "Overrides",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.OverridesColumnFormatter,
  },
  {
    dataField: "checkList",
    text: "CheckList",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.ChecklistColumnFormatter,
  },
  {
    dataField: "approvalLevels",
    text: "Approval Levels",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.ApprovalLevelColumnFormatter,
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "frequency",
    text: "Frequency",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "penaltyAmount",
    text: "Penalty Amount",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "penaltyFrequency",
    text: "Penalty Frequency",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "maximumDefermentPeriod",
    text: "Maximum Deferment Period",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "appliedOn",
    text: "Applied On",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "numberOfAllowedInstallments",
    text: "Number of Allowed Installments",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "allowedPaymentsPerYear",
    text: "Allowed Payments Per Year",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "feesPercentage",
    text: "Fees Percentage",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.DescriptionColumnFormatter,
  },
  {
    dataField: "type",
    text: "Type",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "method",
    text: "Method",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: sortCaret,
    formatter: columnFormatters.StatusColumnFormatter,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: columnFormatters.ActionsColumnFormatter,
    formatExtraData: {
      openEditProductPage: () => {},
      openDeleteProductDialog: () => {},
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
  },
  {
    dataField: "name",
    text: "Name",
    formatter: columnFormatters.NameColumnFormatter,
  },
  {
    dataField: "expression",
    text: "Condition",
    formatter: columnFormatters.ConditionColumnFormatter,
    formatExtraData: {
      identifier: "expression"
    },
  },
  {
    dataField: "entryCondition",
    text: "Entry Condition",
    formatter: columnFormatters.ConditionColumnFormatter,
    formatExtraData: {
      identifier: "entryCondition"
    },
  },
  {
    dataField: "exitCondition",
    text: "Exit Condition",
    formatter: columnFormatters.ConditionColumnFormatter,
    formatExtraData: {
      identifier: "exitCondition"
    },
  },
  {
    dataField: "entranceCondition",
    text: "Entrance Condition",
    formatter: columnFormatters.ConditionColumnFormatter,
    formatExtraData: {
      identifier: "entranceCondition"
    },
  },
  {
    dataField: "group",
    text: "Group",
  },
  {
    dataField: "header",
    text: "Header",
  },
  {
    dataField: "isReadOnly",
    text: "Readonly",
    formatter: columnFormatters.YesNoColumnFormatter,
    formatExtraData: {
      identifier: "isReadOnly"
    },
  },
  {
    dataField: "isRequired",
    text: "Mandatory",
    formatter: columnFormatters.YesNoColumnFormatter,
    formatExtraData: {
      identifier: "isRequired"
    },
  },
  {
    dataField: "checkListType",
    text: "Checklist Type",
    formatter: columnFormatters.DropdownColumnFormatter,
    formatExtraData: {
      identifier: "checkListType",
      listCode: "checkListTypes",
    },
  },
  {
    dataField: "errorMessage",
    text: "Error Message"
  },
  {
    dataField: "deviationCode",
    text: "Deviation Code"
  },
  {
    dataField: "field",
    text: "Field"
  },
  {
    dataField: "fieldCodes",
    text: "Fields",
    formatter: columnFormatters.FieldCodesColumnFormatter,
  },
  {
    dataField: "fieldCode",
    text: "Field",
  },
  {
    dataField: "layout",
    text: "Layout",
    formatter: columnFormatters.LayoutColumnFormatter,
  },
  {
    dataField: "isHidden",
    text: "Hidden",
    formatter: columnFormatters.YesNoColumnFormatter,
    formatExtraData: {
      identifier: "isHidden"
    },
  },
  {
    dataField: "stageCode",
    text: "Stage"
  },
  {
    dataField: "schemeName",
    text: "Name"
  },
  {
    dataField: "isWeightageBased",
    text: "Weightage Based",
    formatter: columnFormatters.YesNoColumnFormatter,
    formatExtraData: {
      identifier: "isWeightageBased"
    },
  },
  {
    dataField: "levelNumber",
    text: "Deviation Level",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "levelCount",
    text: "Deviation Count",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "percentage",
    text: "Percentage",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "orrField",
    text: "ORR",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "frrField",
    text: "FRR",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "valueField",
    text: "Value Field",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "percentileValueField",
    text: "Percentile Value Field",
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: "percentileRankField Field",
    text: "Percentile Rank",
    sort: true,
    sortCaret: sortCaret,
  },
];

export const getFilteredColumns = (activeColumns = []) => ProductFormatters.filter((column) => activeColumns.find((col) => col === column.dataField));
