import { createSlice } from "@reduxjs/toolkit";

const initialAutoAssignmentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  autoAssignmentDeleted: false,
  allAutoAssignments: undefined,
  hierarchyUsers: null,
  productHierarchy: null
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "autoAssignments",
  initialState: initialAutoAssignmentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    autoAssignmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.autoAssignmentForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    autoAssignmentsFetched: (state, action) => {
      const {totalCount, entities, paginationOverride} = action.payload;
      state.listLoading = false;
      state.error = null
      state.makerCheckerAction = false;
      state.autoAssignmentDeleted = false;
      if (paginationOverride) {
        state.allAutoAssignments = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearAutoAssignmentsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    autoAssignmentCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    autoAssignmentUpdated: (state, action) => {
      const {autoAssignment} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = autoAssignment;
    },
    autoAssignmentDeleted: (state) => {
      state.autoAssignmentDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    hierarchyUsers: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.hierarchyUsers = action.payload;
    },
    productHierarchy: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.productHierarchy = action.payload;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    autoAssignmentUsers: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    }
  }
});
