import { createSlice } from "@reduxjs/toolkit";

const initialTemplateState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  entityForEdit: undefined,
  kiborDeleted: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "kibor",
  initialState: initialTemplateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    kiborFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.kiborDeleted = false;
    },
    kiborFetchedForEdit: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.kiborForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
    },
    kiborCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    kiborUpdated: (state, action) => {
      const { kibor } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = kibor;
    },
    kiborDeleted: state => {
      state.kiborDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    kiborUpdatedDataFetched: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      state.error = null;
    }
  }
});
