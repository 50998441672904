/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SVG from "react-inlinesvg";
import ReactHtmlParser from "react-html-parser";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  EntityTypeCssClasses,
  EntityTypeTitles,
  InterviewStatusCssClasses,
  InterviewStatusTitles,
  Status,
  StatusCssClasses,
  StatusTitles,
  Type,
  WorkflowStatusCssClasses,
  WorkflowStatusTitles
} from "./UIHelpers";
import moment from "moment";
import { callStatusEnums, workflowStatus } from "../../_helpers/enums";
import { dropDownOptions } from "../../_helpers/staticFields";
import { inputTypes } from "../../_helpers/commonStaticVariables";
import { ApplicationDynamicFormatter } from "./column-formatters";
import { Overlay } from "../controls";
import { getNumberInCurrencyFormat } from "../../_helpers";

export const IdentifierColumnFormatter = (
  cellContent,
  rowData,
  rowIndex,
  {
    openEditPage,
    openedRow,
    setOpenedRow,
    onViewPage,
    isWorkflowRoute,
    parameters: { identifier },
    openTrail,
    openIntegration,
    hideTrailForAllWorkflowsOption,
    isPostOriginationRoute,
    productDictionary,
    initiateChildProduct,
    createWorkflowLoader,
    childProductsList
  }
) => {
  let row = {
    ...rowData,
    status: rowData.status !== undefined ? rowData.status : 0
  };
  const priorityOverlayRef = React.createRef();
  const toggleOverlay = () => priorityOverlayRef.current?.click();

  const isHideActions = () =>
    row?.status === workflowStatus.Completed ||
    row?.status === workflowStatus.Cancelled ||
    row?.status === workflowStatus.Rejected ||
    row?.status === workflowStatus.Hold ||
    row?.status === workflowStatus.Freezed ||
    !isWorkflowRoute;

  const isIntegrationHideActions = () => {
    if (isWorkflowRoute) {
      return true;
    } else
      return (
        !isWorkflowRoute &&
        Object.keys(row?.["integrations"] || {})?.length === 0
      );
  };

  const popover = (
    <Popover id="name-popover">
      <Popover.Content className="p-0">
        <div className="pl-0">
          <button
            onClick={() => {
              toggleOverlay();
              openEditPage(row);
            }}
            hidden={isHideActions()}
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                title={"Edit"}
              />
            </span>
            Edit
          </button>
        </div>
      </Popover.Content>
      <Popover.Content className="p-0">
        <div className="pl-0">
          <button
            onClick={() => {
              toggleOverlay();
              openTrail(row);
            }}
            hidden={
              hideTrailForAllWorkflowsOption || isPostOriginationRoute || false
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-info mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
                title={"Trail"}
              />
            </span>
            Trail
          </button>
        </div>
      </Popover.Content>
      <Popover.Content className="p-0">
        <div className="pl-0">
          <button
            onClick={() => {
              toggleOverlay();
              openIntegration(row);
            }}
            hidden={isIntegrationHideActions()}
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-info mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")}
                title={"Intergrations"}
              />
            </span>
            Integrations
          </button>
        </div>
      </Popover.Content>

      {(childProductsList || []).map(code => {
        return (
          <Popover.Content className="p-0">
            <div className="pl-0">
              <button
                onClick={() => {
                  toggleOverlay();
                  initiateChildProduct({
                    row,
                    code,
                    identifier: row?.identifier
                  });
                }}
                // disabled={createWorkflowLoader}
                hidden={!isPostOriginationRoute}
                className="dropdown-item mr-2 "
              >
                <span className="svg-icon svg-icon-md svg-icon-success mr-2">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                    title={""}
                  />
                </span>
                {productDictionary[code]?.name}
              </button>
            </div>
          </Popover.Content>
        );
      })}
    </Popover>
  );
  return (
    <>
      {identifier ? (
        <span
          className={`custom_button ${
            row?.isEscalated ? "text-danger" : ""
          }font-weight-bolder`}
          style={{ verticalAlign: "sub" }}
          onClick={() => onViewPage(row)}
        >
          {row?.[identifier]}
          {row?.isEscalated ? (
            <Overlay popoverContent="Workflow has escalated!">
              <i
                className={
                  "fas fa-exclamation ml-2 text-danger font-size-sm icon-danger text-hover-danger"
                }
              />
            </Overlay>
          ) : null}
          {isPostOriginationRoute &&
          createWorkflowLoader.loading &&
          createWorkflowLoader.identifier === row?.identifier ? (
            <span className="ml-2 spinner spinner-success"></span>
          ) : null}
        </span>
      ) : (
        <span className="font-weight-bold">{row?.[identifier] || "N/A"}</span>
      )}

      {/*{!isHideActions() ? (*/}
      <OverlayTrigger
        rootClose
        trigger="click"
        onExit={() => {
          if (openedRow === rowIndex) {
            setOpenedRow(null);
          }
        }}
        overlay={popover}
        placement="top"
      >
        <a
          className={`${
            openedRow === rowIndex ? "btn-light" : "overlay-menu-action"
          } btn btn-xsm btn-hover-light float-right`}
          ref={priorityOverlayRef}
          onClick={() => setOpenedRow(rowIndex)}
          hidden={
            isPostOriginationRoute &&
            (!(childProductsList || [])?.length ||
              row?.status !== workflowStatus.Completed ||
              createWorkflowLoader.loading)
          }
        >
          <MoreVertIcon />
        </a>
      </OverlayTrigger>
      {/*) : null}*/}
    </>
  );
};

export const StatusColumnFormatter = (cellContent, row, rowIndex, { user }) => {
  return (
    <span
      className={`label label-lg label-light-${
        StatusCssClasses[row.status]
      } label-inline`}
    >
      {row.status === Status.SFA
        ? row.actor === user?.id
          ? "Sent for Approval"
          : "Awaiting Approval"
        : StatusTitles[row.status]}
    </span>
  );
};

export const WorkflowStatusColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { renamingDictionary }
) => {
  return (
    <span
      className={`label label-lg label-light-${
        WorkflowStatusCssClasses[row?.status]
      } label-inline`}
    >
      {WorkflowStatusTitles[row.status] === "Cancelled"
        ? renamingDictionary?.["Cancelled"] || "Cancelled"
        : WorkflowStatusTitles[row?.status]}
    </span>
  );
};

export const CreatedColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let creator =
    (allUsers || []).find(item => item?.id === (row?.actor || row?.createdBy))
      ?.name || "N/A";

  return (
    <div className="d-flex align-items-center fixed-width-created-row">
      <div style={{ width: "12.75rem" }}>
        <span className="text-success text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
          {row?.createdAt || row?.creationDateTime || row.schedule
            ? moment(
                row?.createdAt || row?.creationDateTime || row.schedule
              ).format("Do MMM YYYY, hh:mm a")
            : ""}
        </span>
      </div>
      <span className="label label-success label-dot mr-2" />
      <div className="ml-2">
        <span className="font-weight-bold fixed-width-created-user-row">
          {creator}
        </span>
      </div>
    </div>
  );
};

export const LastUpdatedColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let updater =
    (allUsers || []).find(
      item => item?.id === (row?.actor || row?.lastUpdatedBy)
    )?.name || "N/A";

  return (
    <div className="d-flex align-items-center fixed-width-created-row">
      <div style={{ width: "12.75rem" }}>
        <span className="text-success text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
          {row?.lastUpdated || row?.lastUpdateDateTime
            ? moment(row?.lastUpdated || row?.lastUpdateDateTime).format(
                "Do MMM YYYY, hh:mm a"
              )
            : "N/A"}
        </span>
      </div>
      <span className="label label-success label-dot mr-2" />
      <div className="ml-2">
        <span className="font-weight-bold fixed-width-created-user-row">
          {updater}
        </span>
      </div>
    </div>
  );
};

export const StageFormatter = (
  cellContent,
  row,
  rowIndex,
  { mainProduct, childProduct }
) => {
  let currentStageCode =
    (row?.stageDetails || []).find(item => item.isCurrent)?.stageCode || null;
  let stageName = "N/A";

  if (currentStageCode) {
    if (row.productCode === mainProduct.code) {
      stageName = (mainProduct?.stages || []).find(
        item => item?.code === currentStageCode
      )?.name;
    } else if (row.productCode === childProduct.code) {
      stageName = (childProduct?.stages || []).find(
        item => item?.code === currentStageCode
      )?.name;
    }
  }

  return (
    <span className="font-weight-bold">
      {row?.status === 1
        ? "All Stages Completed"
        : currentStageCode
        ? stageName
        : row.nextStageName}
    </span>
  );
};

export const WorkFlowFieldFormatter = (
  cellContent,
  row,
  rowIndex,
  dataField
) => {
  let code = dataField?.code;
  let field = dataField?.field;
  let records = dataField?.records;

  const getName = () => {
    let recordName =
      records[field?.masterDataType]?.records?.find(
        record => record.code === row?.fields?.[code]
      )?.name ||
      (field?.records || [])?.find(
        record => record?.code === row?.fields?.[code]
      )?.name ||
      row?.fields?.[code] ||
      "N/A";
    return recordName;
  };
  let name = field?.dataType === 2 ? getName() : row?.fields?.[code];

  const shortName = name && name.length > 20 ? `${name.slice(0, 20)}...` : name;

  return (
    <div>
      {name?.length > 20 ? (
        <Overlay popoverContent={name}>
          <div>
            <span className="font-weight-bold">{shortName || "N/A"}</span>
            <i
              className="fas fa-info-circle icon-nm text-hover-primary my-2 b-0"
              style={{ padding: "2px" }}
            />
          </div>
        </Overlay>
      ) : (
        <span className="font-weight-bold">{shortName || "N/A"}</span>
      )}
    </div>
  );
};

export const OwnershipColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let name =
    (allUsers || []).find(item => item?.id === row?.ownership)?.name || "N/A";

  return (
    <>
      <div className="d-flex align-items-center fixed-width-created-row">
        <div style={{ width: "12.75rem" }}>
          <span className="text-primary text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
            {row?.lastUpdatedAt || row?.lastUpdated
              ? moment(row?.lastUpdatedAt || row?.lastUpdated).format(
                  "Do MMM YYYY, hh:mm a"
                )
              : "N/A"}
          </span>
        </div>
        <span className="label label-primary label-dot mr-2" />
        <div className="ml-2">
          <span className="font-weight-bold fixed-width-created-user-row">
            {name}
          </span>
        </div>
      </div>
    </>
  );
};

export const AssignedToColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let assignedTo =
    row?.status === workflowStatus.InProgress
      ? (allUsers || []).find(item => item?.id === row?.assignedTo)?.name ||
        "N/A"
      : "N/A";

  return <span className="font-weight-bold">{assignedTo}</span>;
};

export const TypeColumnFormatter = (cellContent, row, rowIndex) => {
  let rowType = row?.isBlocked ? Type.Blocked : cellContent;
  return (
    <span
      className={`label label-lg label-light-${EntityTypeCssClasses[rowType]}  label-inline`}
    >
      {EntityTypeTitles[rowType]}
    </span>
  );
};

export const GenericFormatter = (cellContent, row, rowIndex) => {
  return <span>{cellContent || "N/A"}</span>;
};

export const NotificationTypeFormatter = (cellContent, row, rowIndex) => {
  let notificationType = dropDownOptions?.notificationType;
  return (
    <span>
      {(notificationType || []).find(item => item?.code === cellContent)
        ?.name || "N/A"}
    </span>
  );
};

export const DataTypeFormatter = (cellContent, row, rowIndex) => {
  return (
    <span>
      {(inputTypes || []).find(item => item?.id === cellContent)?.displayType ||
        "N/A"}
    </span>
  );
};

export const ProductFormatter = (
  cellContent,
  row,
  rowIndex,
  { allProducts }
) => {
  let productName = (allProducts || []).find(
    item => item?.code === row?.productCode
  )?.name;
  return <span className="font-weight-bold">{productName || "N/A"}</span>;
};

export const KycStatusColumnFormatter = (cellContent, row, rowIndex) => {
  return (
    <span
      className={`label label-lg label-light-${
        InterviewStatusCssClasses[cellContent || 0]
      } label-inline`}
    >
      {InterviewStatusTitles[cellContent || 0]}
    </span>
  );
};

export const KycNameColumnFormatter = (
  cellContent,
  rowData,
  rowIndex,
  {
    openVideoPlayer,
    openedRow,
    setOpenedRow,
    onViewPage,
    setShowCreateDialog,
    setReScheduleInterviewDetail,
    setShowInterviewDetails,
    setVideoData,
    parameters: {
      identifier,
      level1,
      isHideOptions,
      isCountColumn,
      copyCode,
      module,
      ...restParameters
    },
    permissionsObject,
    onClickDownloadDocument
  }
) => {
  let row = { ...rowData };

  const priorityOverlayRef = React.createRef();
  const toggleOverlay = () => priorityOverlayRef.current?.click();
  const popover = (
    <Popover id="name-popover">
      <Popover.Content className="p-0">
        <div className="pl-0">
          {permissionsObject?.viewvideo &&
            (row?.status === callStatusEnums.Done ||
              row?.status === callStatusEnums.InProgress) && (
              <button
                onClick={event => {
                  event.stopPropagation();
                  toggleOverlay();
                  openVideoPlayer(rowData);
                }}
                className="dropdown-item mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
                  <i className="fas fa-eye text-primary" />
                </span>
                View Video
              </button>
            )}
          {permissionsObject?.downloaddocument &&
            row?.status === callStatusEnums.Done && (
              <button
                onClick={event => {
                  event.stopPropagation();
                  toggleOverlay();
                  onClickDownloadDocument(rowData);
                }}
                className="dropdown-item mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
                  <i className="fas fa-file-download text-primary" />
                </span>
                Download Document
              </button>
            )}
          {row?.status === callStatusEnums.Pending ||
          row?.status === callStatusEnums.Failed ? (
            <button
              onClick={event => {
                event.stopPropagation();
                toggleOverlay();
                setShowCreateDialog(true);
                setReScheduleInterviewDetail(row);
              }}
              className="dropdown-item mr-2"
            >
              <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
                <i className="fas fa-history text-primary" />
              </span>
              ReSchedule
            </button>
          ) : null}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {level1 ? (
        <span
          className="custom_button font-weight-bold"
          onClick={() => {
            setShowInterviewDetails(true);
            setVideoData(row);
          }}
        >
          {ApplicationDynamicFormatter(cellContent, rowData, rowIndex, {
            parameters: {
              ...restParameters,
              identifier: level1,
              withSpan: false
            }
          })}
        </span>
      ) : !level1 ? (
        <span
          className="custom_button font-weight-bold"
          onClick={() => {
            setShowInterviewDetails(true);
            setVideoData(row);
          }}
        >
          {ApplicationDynamicFormatter(cellContent, rowData, rowIndex, {
            parameters: {
              ...restParameters,
              identifier: "name",
              withSpan: false
            }
          })}
          {permissionsObject?.view &&
          (row?.status === callStatusEnums.InProgress ||
            row?.status === callStatusEnums.Pending) ? (
            <button
              onClick={() => {
                onViewPage(row[identifier], row);
              }}
              className="btn btn-xs btn-icon btn-icon-primary btn-hover-primary ml-2"
            >
              {/*Rejected Application*/}
              <i className="fa fa-play" />
            </button>
          ) : null}
        </span>
      ) : (
        "N/A"
      )}

      {row?.status === callStatusEnums.Pending ||
      (row?.status === callStatusEnums.Failed &&
        permissionsObject?.reassign &&
        permissionsObject?.reschedule) ? (
        <OverlayTrigger
          rootClose
          trigger="click"
          onExit={() => {
            if (openedRow === rowIndex) {
              setOpenedRow(null);
            }
          }}
          overlay={popover}
          placement="top"
        >
          <a
            className={`${
              openedRow === rowIndex ? "btn-light" : "overlay-menu-action"
            } btn btn-xsm btn-hover-light float-right`}
            ref={priorityOverlayRef}
            onClick={event => {
              event.stopPropagation();
              setOpenedRow(rowIndex);
            }}
          >
            <MoreVertIcon />
          </a>
        </OverlayTrigger>
      ) : null}
    </>
  );
};

export const FileGeneratedFormatter = (cellContent, row, rowIndex) => {
  return (
    <>
      <span
        className={`label label-lg label-light-${
          row?.integrations?.IsFileGenerated ? "success" : "warning"
        } label-inline`}
      >
        {row?.integrations?.IsFileGenerated === "Yes" ? "Generated" : "Pending"}
      </span>
    </>
  );
};

export const RichTextFormatter = cellContent => {
  return (
    <Overlay popoverContent={ReactHtmlParser(cellContent || "N/A")}>
      <span className="font-weight-bold comment-text">
        {ReactHtmlParser(cellContent || "N/A")}
      </span>
    </Overlay>
  );
};

export const DateTimeFormatter = (cellContet, rowData) => {
  return (
    <span className="text-success text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
      {` ${
        rowData?.dateOnly ? moment(rowData?.dateOnly).format("Do MMM YYYY") : ""
      }, ${moment(rowData?.startTime, "h:mm A").format("h:mm A")}`}
    </span>
  );
};

export const KycAgentNameColumnFormatter = (cellContent, rowData) => {
  return <span>{rowData?.agent?.name || "N/A"}</span>;
};

export const KycCustomerEmailColumnFormatter = (cellContent, rowData) => {
  return <span>{rowData?.customer?.emailAddress || "N/A"}</span>;
};
export const KycCustomerCNICColumnFormatter = (cellContent, rowData) => {
  return <span>{rowData?.customer?.cnic || "N/A"}</span>;
};

export const CodeNameFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { dictionary }
) => {
  return (
    <Overlay popoverContent={dictionary?.[cellContent]?.name || "N/A"}>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column font-weight-bold">
          <div className="d-flex flex-row align-items-center">
            <span
              className="text-dark text-hover-primary mb-1 font-size-md custom-span-quick-panel-deviation"
              style={{ maxWidth: "11rem" }}
            >
              {dictionary?.[cellContent]?.name || "N/A"}
            </span>
          </div>
        </div>
      </div>
    </Overlay>
  );
};
export const UserNameFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { allUsers }
) => {
  let createdBy = allUsers?.find(user => user?.id === cellContent);

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column font-weight-bold">
        <div className="d-flex flex-row align-items-center">
          <span
            className="text-dark text-hover-primary mb-1 font-size-md custom-span-quick-panel-deviation"
            style={{ maxWidth: "11rem" }}
          >
            {createdBy?.name}
          </span>
        </div>
      </div>
    </div>
  );
};
export const CreatedDateTimeFormatter = cellContent => {
  return (
    <span className="text-success text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
      {moment(cellContent).format("Do MMM YYYY h:mm A")}
    </span>
  );
};
export const StatusFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { statusList, statusTitles }
) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column font-weight-bold">
        <div className="d-flex flex-row">
          <span
            className={`label label-lg label-inline ${
              cellContent === statusList.Pending
                ? "label-light-warning"
                : cellContent === statusList.Deleted
                ? "label-light-danger"
                : "label-light-success"
            } `}
            style={{ maxWidth: "11rem" }}
          >
            {statusTitles[cellContent]}
          </span>
        </div>
      </div>
    </div>
  );
};
export const GroupsFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { groupsDictionary }
) => {
  let approvalGroups = cellContent?.map(group => groupsDictionary[group]?.name);

  return (
    <Overlay popoverContent={approvalGroups?.join(", ")}>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column font-weight-bold">
          <div className="d-flex flex-row align-items-center">
            <span
              className="text-dark text-hover-primary mb-1 font-size-md custom-span-quick-panel-deviation"
              style={{ maxWidth: "11rem" }}
            >
              {approvalGroups?.join(", ")}
            </span>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export const ProductStageFormatter = (
  cellContent,
  rowData,
  rowIndex,
  { productStagesDictionary }
) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column font-weight-bold">
        <div className="d-flex flex-row align-items-center">
          <span
            className="text-dark text-hover-primary mb-1 font-size-md custom-span-quick-panel-deviation"
            style={{ maxWidth: "11rem" }}
          >
            {productStagesDictionary[cellContent]?.name || "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};
export const TypeFormatter = (cellContent, rowData, rowIndex, { types }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column font-weight-bold">
        <div className="d-flex flex-row">
          <span
            className="text-dark text-hover-primary mb-1 font-size-md custom-span-quick-panel-deviation"
            style={{ maxWidth: "11rem" }}
          >
            {types[cellContent]}
          </span>
        </div>
      </div>
    </div>
  );
};

export const OverlayTextColumnFormatter = cellContent => {
  return (
    <Overlay popoverContent={cellContent}>
      <span
        className="font-weight-bold comment-text"
        style={{ maxWidth: "10rem" }}
      >
        {cellContent}
      </span>
    </Overlay>
  );
};

export const KiborRatesColumnFormatter = cellContent => {
  return (
    <div>
      <p className="font-weight-bold mb-0 text-dark-50">
        Bid:{" "}
        <span className="font-weight-bolder text-dark">
          {cellContent?.bid || "-"}
        </span>
      </p>
      <p className="font-weight-bold mb-0 text-dark-50">
        Offer:{" "}
        <span className="font-weight-bolder text-dark">
          {cellContent?.offer || "-"}
        </span>
      </p>
    </div>
  );
};

export const PrecededByColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { allUsers }
) => {
  let precededBy =
    (allUsers || []).find(item => item?.id === (row?.actor || row?.precededBy))
      ?.name || "N/A";

  return (
    <div className="d-flex align-items-center fixed-width-created-row">
      <div className="ml-2">
        <span className="font-weight-bold fixed-width-created-user-row">
          {precededBy}
        </span>
      </div>
    </div>
  );
};

export const AmountFormatter = cellContent => {
  return (
    <span>
      {getNumberInCurrencyFormat({
        number: cellContent || 0,
        appendCurrency: true
      })}
    </span>
  );
};

export const LMSDateTimeFormatter = cellContent => {
  return (
    <span className="text-success text-right font-weight-bold mr-2 fixed-width-created-datetime-row">
      {cellContent
        ? moment
            .utc(cellContent, "ddd, DD MMM YYYY HH:mm:ss z")
            .format("DD MMMM YYYY, hh:mm a")
        : "N/A"}
    </span>
  );
};

export const RecordFieldFormatter = (cellContent, row, rowIndex, dataField) => {
  let code = dataField?.code;
  let field = dataField?.field;
  let records = dataField?.records;
  let products = dataField?.productDictionary;
  let users = dataField?.allUsers;
  let codeValuesString = row?.values?.[code];
  let productNames = [];
  let userNames = [];

  const getName = () => {
    let codeValuesArray = codeValuesString
      ?.split("!")
      ?.filter(codeValue => codeValue?.trim() !== "");
    if (
      code === "ProductCode" ||
      code === "ProductCodes" ||
      code === "ProductCodesMandatory"
    ) {
      if (codeValuesArray && codeValuesArray?.length > 0) {
        codeValuesArray.forEach(codeValue => {
          if (codeValue in products) {
            let productName = products[codeValue]?.name;
            productNames.push(productName);
          }
        });
      }
      return `${productNames.join(", ")}`;
    } else if (code === "allUsers") {
      if (codeValuesArray?.length > 0) {
        codeValuesArray.forEach(codeValue => {
          let userObject = users?.find(user => user.code === codeValue);
          if (userObject) {
            userNames.push(userObject?.name);
          }
        });
      }
      return `${userNames.join(", ")}`;
    } else {
      let recordName =
        records?.find(record => record.code === row?.values?.[code])?.name ||
        (field?.records || [])?.find(
          record => record?.code === row?.values?.[code]
        )?.name ||
        row?.values?.[code] ||
        "N/A";
      return recordName;
    }
  };

  let name =
    field?.dataType === 2 || field?.dataType === 3
      ? getName()
      : row?.values?.[code];

  const shortName = name && name.length > 20 ? `${name.slice(0, 20)}...` : name;
  return (
    <div>
      {name?.length > 20 ? (
        <Overlay popoverContent={name}>
          <div>
            <span className="font-weight-bold">{shortName || "N/A"}</span>
            <i
              className="fas fa-info-circle icon-nm text-hover-primary my-2 b-0"
              style={{ padding: "2px" }}
            />
          </div>
        </Overlay>
      ) : (
        <span className="font-weight-bold">{shortName || "N/A"}</span>
      )}
    </div>
  );
};

export const StatusCodeColumnFormatter = (cellContent, row) => {
  return (
    <div>
      <p className="font-weight-bold mb-0 text-dark-50">
        {row?.statusCode === 200 ? (
          <span className="label label-lg label-light-success label-inline">
            {row?.statusCode}
          </span>
        ) : row?.statusCode >= 400 && row?.statusCode <= 500 ? (
          <span className="label label-lg label-light-danger label-inline">
            {row?.statusCode}
          </span>
        ) : (
          <span className="font-weight-bolder">-</span>
        )}
      </p>
    </div>
  );
};

export const LogTypeColumnFormatter = (cellContent, row) => {
  return (
    <div>
      <p className="font-weight-bold mb-0 text-dark-50">
        {row?.type === "Request" ? (
          <span className="label label-lg label-light-success label-inline">
            {row?.type}
          </span>
        ) : row?.type === "Response" ? (
          <span className="label label-lg label-light-info label-inline">
            {row?.type}
          </span>
        ) : (
          <span className="font-weight-bolder">-</span>
        )}
      </p>
    </div>
  );
};
