import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";

const initialGroupsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  prevEntity: undefined,
  auditLogs: [],
  lastError: null,
  makerCheckerAction: false,
  groupDeleted: false,
  permissionsDictionary: null,
  initiationLoading: false,
  groupsDictionary: {}
};
export const callTypes = {
  list: "list",
  action: "action",
  initiate: "initiate",
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState: initialGroupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.initiate) {
        state.initiationLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.initiate) {
        state.initiationLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    groupFetched: (state, action) => {
      const {groupForEdit} = action.payload;
      state.actionsLoading = false;
      state.entityForEdit = groupForEdit;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;

    },
    prevGroupFetched: (state, action) => {
      const {groupForEdit} = action.payload;
      state.actionsLoading = false;
      state.prevEntity = groupForEdit;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    groupsFetched: (state, action) => {
      const {totalCount, entities} = action.payload;
      state.groupsDictionary = createDictionary(entities || [], "code");
      state.listLoading = false;
      state.error = null
      state.entityForEdit = undefined;
      state.prevEntity = undefined;
      state.auditLogs = [];
      state.entities = entities;
      state.totalCount = totalCount;
      state.makerCheckerAction = false;
      state.groupDeleted = false;
    },
    clearGroupsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    clearGroupsPermissions: (state) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = 0;
      state.entities = null;
      state.entityForEdit = undefined;
      state.lastError = null;
      state.makerCheckerAction = false;
      state.groupDeleted = false;
      state.permissionsDictionary = null
    },
    groupCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    groupUpdated: (state, action) => {
      const {group} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = group;
    },
    groupDeleted: (state) => {
      state.groupDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    groupsFetchedByCodeList: (state, action) => {
      const {dictionary} = action.payload;
      state.actionsLoading = false;
      state.listLoading = false;
      state.initiationLoading = false;
      state.error = null;
      state.permissionsDictionary = dictionary;
    },
    clearPermissionsDictionary: (state) => {
      state.permissionsDictionary = null
    },
    auditLogsFetched: (state, action) => {
      const {auditLogs} = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const {auditLogs, params} = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state.actionsLoading = false;
      state.makerCheckerAction = false;
      state.entityForEdit = toLog?.payload;
      state.prevEntity = fromLog?.payload;
    },
  }
});
