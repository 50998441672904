export const ImportInstructions = {
	Users: {
		requiredFields: "name & code.",
		form: "/media/misc/User_f.png",
		excel: "/media/misc/User_e.png",
		edit: "/media/misc/User_edit.png",
		file: "/files/Users.xlsx",
		fileEdit: "/files/UsersEdit.xlsx",
	},
	Fields: {
		file: "/files/Fields.xlsx",
		form: "/media/misc/Field_f.png",
		excel: "/media/misc/Field_e.png",
		enum: "/media/misc/Field_enum.png"
	},
	Entities: {
		file: "/files/Entities.xlsx",
		form: "/media/misc/Entity_f.png",
		excel: "/media/misc/Entity_e.png",
	},
	Modules: {
		file: "/files/Modules.xlsx",
		form: "/media/misc/Module_f.png",
		excel: "/media/misc/Module_e.png",
		edit: "/media/misc/Module_edit.png",
		enum: "/media/misc/Module_enum.png",
		fileEdit: "/files/ModulesEdit.xlsx",
	},
	Groups: {
		file: "/files/Groups.xlsx",
		form: "/media/misc/Group_f.png",
		excel: "/media/misc/Group_e.png",
		edit: "/media/misc/Group_edit.png",
		fileEdit: "/files/GroupsEdit.xlsx",
	},
	Records: {
		file: "/files/Records.xlsx",
		form: "/media/misc/Record_f.png",
		excel: "/media/misc/Record_e.png",
		edit: "/media/misc/Record_edit.png",
		fileEdit: "/files/RecordsEdit.xlsx",
	},
	Workflows: {
		file: "/files/Workflow.xlsx",
		form: "/media/misc/Workflow_f.png",
		excel: "/media/misc/Workflow_e.png",
	},
	Reports: {
		form: "/media/misc/Reports_f.png",
		excel: "/media/misc/Reports_e.png",
		file: "/files/Reports.xlsx"
	},
}
