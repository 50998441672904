import { createSlice } from "@reduxjs/toolkit";

const initialSetFlowsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  externalSystemSetFlowForEdit: undefined,
  integrationLoggerForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "setFlows",
  initialState: initialSetFlowsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setFlowFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.setFlowForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
    },
    setFlowsFetched: (state, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null
      state.setFlowDeleted = false;
      state.entities = entities;
      state.totalCount = totalCount;
      state.entityForEdit = undefined;
      state.externalSystemSetFlowForEdit = undefined;
      state.integrationLoggerForEdit = undefined;
    },
    externalSystemSetFlowFetched: (state, action) => {
      state.actionsLoading = false;
      state.externalSystemSetFlowForEdit = action.payload.externalSystemSetFlowForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
    },
    integrationLoggerFetched: (state, action) => {
      state.actionsLoading = false;
      state.integrationLoggerForEdit = action?.payload?.integrationLoggerForEdit;
      state.error = null;
    },
    clearSetFlowsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
      state.externalSystemSetFlowForEdit = undefined;
      state.integrationLoggerForEdit = undefined;
    },
  }
});
