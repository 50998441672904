import React from "react";
import Modal from "react-bootstrap/Modal";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";

export const Dialog = ({size, backdrop, isCentered, show, onHide, modalTitle, modalBody, modalBodyClasses, modalFooter, noFooter, isLoading, modalMainClasses, overrideModalHeaderBodyFooter, overrideContent, bodyId, enforceFocus}) => {
  return (
    <>
      <Modal
        size={size}
        backdrop={backdrop}
        className={modalMainClasses}
        centered={isCentered}
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-sm"
        enforceFocus={enforceFocus !== undefined ? enforceFocus : true}
      >
        {isLoading && <ModalProgressBar variant="info"/>}
        {overrideModalHeaderBodyFooter ? (
          <>
            {overrideContent}
          </>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={modalBodyClasses || ""} id={bodyId || ""}>
              {modalBody}
            </Modal.Body>
            {noFooter ? null :
              (<Modal.Footer>
                  {modalFooter}
                </Modal.Footer>
              )
            }
          </>
        )}


      </Modal>
    </>
  )
}
