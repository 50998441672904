import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";

const initialEntitiesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  entityDeleted: false,
  auditLogs: [],
  entitiesDictionary: {}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const entitiesSlice = createSlice({
  name: "entities",
  initialState: initialEntitiesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    entityFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.entityForEdit;
      state.error = null;
      // state.entities = null;
      // state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    entitiesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      let entitiesDictionary = createDictionary(entities || [], "code");
      state.listLoading = false;
      state.error = null;
      state.entityForEdit = undefined;
      state.entities = entities;
      state.totalCount = totalCount;
      state.makerCheckerAction = false;
      state.entityDeleted = false;
      state.entitiesDictionary = entitiesDictionary;
    },
    clearEntitiesList: state => {
      state.entities = null;
      state.totalCount = 0;
      state.prevEntity = undefined;
    },
    entityCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    entityUpdated: (state, action) => {
      const { entity } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = entity;
    },
    entityDeleted: state => {
      state.entityDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    prevEntityFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.prevEntity = action.payload.entityForEdit;
      state.error = null;
      state.makerCheckerAction = false;
    },
    auditLogsFetched: (state, action) => {
      const { auditLogs } = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const { auditLogs, params } = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state.actionsLoading = false;
      state.makerCheckerAction = false;
      state.entityForEdit = toLog?.payload;
      state.prevEntity = fromLog?.payload;
    }
  }
});
