import React, {useEffect} from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { fetchRecords } from "../../../../app/modules/MasterData/_redux/records/recordsActions";
// import MaterialWidgets from 'react-awesome-query-builder/lib/components/widgets/material';
import AntWidgets  from 'react-awesome-query-builder/lib/components/widgets/antd';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 80,
  },
}));

export function QueryBuilderSelect(props) {
  const masterDataType = props?.fieldDefinition?.["masterDataType"];
  const classes = useStyles();
  const {dropdownOptions} = useSelector(
    (state) => ({
      dropdownOptions: state.records[masterDataType]?.records
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if(!dropdownOptions?.length && props.isFetchRecords && masterDataType) {
      dispatch(fetchRecords({entityCode: masterDataType, paginationOverride: true}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormControl className={classes.formControl}>
        {
          dropdownOptions?.length > 0 && !props.isMulti ? <AntWidgets.SelectWidget {...props} items={props?.listValues} /> : null
        }
        {
          dropdownOptions?.length > 0 && props.isMulti ? <AntWidgets.MultiSelectWidget {...props} items={props?.listValues} /> : null
        }
        {
          dropdownOptions === undefined && <p className="text-muted mt-2">Please wait while records are being fetched</p>
        }
        {
          dropdownOptions?.length === 0 && <p className="text-muted mt-2">No records found</p>
        }
      </FormControl>
    </div>
  );
}
