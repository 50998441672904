export const createPaginationData = (paginationOptions, preservePaginationStatus) => {
  const { allData, page, sizePerPage } = paginationOptions;
  if (!allData || !allData.length) {
    return {
      ...paginationOptions,
      data: [],
      page: 1,
      sizePerPage: sizePerPage || 10,
      totalSize: allData?.length || 0,
    };
  }

  let newPage = preservePaginationStatus === true ? Math.max(page, 1) : 1;
  let endDataIndex = newPage * sizePerPage;
  // let startDataIndex = (newPage - 1) * sizePerPage;
  let startDataIndex = endDataIndex - sizePerPage;

  if(startDataIndex >= allData.length) {
    newPage = (Math.max(newPage - 1, 1));
    endDataIndex = newPage * sizePerPage;
    startDataIndex = endDataIndex - sizePerPage;
  }

  return {
    ...paginationOptions,
    data: allData.slice(startDataIndex, endDataIndex),
    page: newPage,
    sizePerPage,
    totalSize: allData?.length || 0,
  };
};

export const onChangePage = (paginationOptions) => {
  const { allData, page, sizePerPage } = paginationOptions;

  const startDataIndex = (Math.max(page - 1, 0)) * sizePerPage;
  const endDataIndex = page * sizePerPage;
  return {
    ...paginationOptions,
    data: allData.slice(startDataIndex, endDataIndex),
    page,
    sizePerPage,
  };
};

export const onClickNextPage = (paginationOptions) => {
  const { allData, page, sizePerPage } = paginationOptions;

  const startDataIndex = page * sizePerPage;
  const endDataIndex = (page + 1) * sizePerPage;
  return {
    ...paginationOptions,
    data: allData.slice(startDataIndex, endDataIndex),
    page: page + 1,
    sizePerPage,
  };
};

export const onClickPreviousPage = (paginationOptions) => {
  const { allData, page, sizePerPage } = paginationOptions;
  if (page <= 0) return paginationOptions;

  const startDataIndex = (page - 1) * sizePerPage;
  const endDataIndex = page * sizePerPage;
  return {
    ...paginationOptions,
    data: allData.slice(startDataIndex, endDataIndex),
    page: page - 1,
    sizePerPage,
  };
};

export const onChangeSizePerPage = (paginationOptions) => {
  const { allData, page, sizePerPage } = paginationOptions;

  const startDataIndex = page * sizePerPage;
  const endDataIndex = (page + 1) * sizePerPage;
  return {
    ...paginationOptions,
    data: allData.slice(startDataIndex, endDataIndex),
    page,
    sizePerPage,
  };
};
