import React from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { getSelectRow } from "../../_helpers";

export function Installments({listData, listLoading, uiProps, totalCount}) {
  const columns = [
    {
      dataField: "CardProductPlan",
      text: "Card Product Plan",
      classes: "font-weight-bold",
    },
    {
      dataField: "PlanID",
      text: "Plan ID",
      classes: "font-weight-bold",
    },
    {
      dataField: "Status",
      text: "Status",
      classes: "font-weight-bold",
    },
    {
      dataField: "InstallmentNumber",
      text: "Installment Number",
      classes: "font-weight-bold",
    },
    {
      dataField: "NumberToDate",
      text: "Number To Date",
      classes: "font-weight-bold",
    },
    {
      dataField: "CaptureDate",
      text: "Capture Date",
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "ProcessingDate",
      text: "Processing Date",
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "StartDate",
      text: "Start Date",
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "EndDate",
      text: "End Date",
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },

    // {
    //   dataField: "paymentAmount",
    //   text: "Due Minimum Payment",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.QuantitativeColumnFormatter,
    //   formatExtraData: {
    //     parameters: {
    //       level1: "paymentAmount"
    //     },
    //     unit: "PKR",
    //     classes: "text-danger"
    //   },
    // },
    // {
    //   dataField: "custBillingCycle",
    //   text: "Billing Cycle",
    //   sort: true,
    //   sortCaret: sortCaret
    // },
    // {
    //   dataField: "openingBalance",
    //   text: "Opening Balance",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.QuantitativeColumnFormatter,
    //   formatExtraData: {
    //     parameters: {
    //       level1: "openingBalance"
    //     },
    //     unit: "PKR",
    //     // classes: "text-right"
    //   },
    // },
    // {
    //   dataField: "closingBalance",
    //   text: "Closing Balance",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.QuantitativeColumnFormatter,
    //   formatExtraData: {
    //     parameters: {
    //       level1: "closingBalance"
    //     },
    //     unit: "PKR",
    //     // classes: "text-right"
    //   }
    // }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };

  const renderPayments = (row) => {
    return (
      <div className="card card-custom card-stretch gutter-b m-0">
        <div className="card-body p-0">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-center text-uppercase">
                  <th>Installment Total</th>
                  <th>Principal To Date</th>
                  <th>Total Principal</th>
                  <th>Interest To Date</th>
                  <th>Total Interest</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="font-weight-bold text-center">{Number(row?.InstallmentTotal)?.toFixed(2) || "-"} PKR</td>
                  <td className="font-weight-bold text-center">{Number(row?.PrincipalToDate)?.toFixed(2) || "-"} PKR</td>
                  <td className="font-weight-bold text-center">{Number(row?.TotalPrincipal)?.toFixed(2) || "-"} PKR</td>
                  <td className="font-weight-bold text-center">{Number(row?.InterestToDate)?.toFixed(2) || "-"} PKR</td>
                  <td className="font-weight-bold text-center">{Number(row?.TotalInterest)?.toFixed(2) || "-"} PKR</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const expandComponent = {
    renderer: row => renderPayments(row),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({isAnyExpands}) => {
      if (isAnyExpands) {
        return <i className="icon-md far fa-window-minimize"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    },
    expandColumnRenderer: ({expanded}) => {
      if (expanded) {
        return <i className="icon-md fas fa-angle-down"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    }
  }

  return (
    <DataGrid
      data={listData}
      listLoading={listLoading}
      columns={columns}
      paginationOptions={paginationOptions}
      type={uiProps.queryParams.type}
      onTableParamsChange={getHandlerTableChange(uiProps.setQueryParams)}
      keyField="_id"
      select={getSelectRow({
        identifier: "_id",
        entities: listData,
        selectedItems: [...uiProps.selectedItems],
        setSelectedItems: uiProps.setSelectedItems,
      })}
      isExpandableRow={true}
      expandRow={expandComponent}
    />
  );
}
