/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// import { AuthInit } from './modules/Auth';
import { Routes } from "../app/Routes";

import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { NotificationsContainer } from "../_custom/_partials/notifications/NotificationsContainer";
import { ErrorHandler } from "./modules/ErrorHandler";
import { ImportPortal } from "../_custom/_partials/Dialog";
import withClearCache from "../ClearCache";

Object.freeze = function(obj) { return obj; };
Array.freeze = function(arr) { return arr; };

function MainApp({ store, persistor, basename, ...restProps }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                {/*Auth Init was introduced in version 7.2.8 of theme, added the code and commented it for now.*/}
                {/*<AuthInit>*/}
                <Routes />
                {/*</AuthInit>*/}
                <ErrorHandler/>
                <NotificationsContainer/>
                <ImportPortal/>
                {/*<ImportDialog*/}
                {/*  isLoading={modalObject?.isLoading}*/}
                {/*  title={modalObject?.title}*/}
                {/*  name={modalObject?.name}*/}
                {/*  items={modalObject?.items}*/}
                {/*  createMethod={eval('(' + modalObject?.createMethod +')')}*/}
                {/*  updateMethod={eval('(' + modalObject?.updateMethod +')')}*/}
                {/*  onImport={eval('(' + modalObject?.onImport +')')}*/}
                {/*  url={modalObject?.url}*/}
                {/*/>*/}
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

const ClearCacheComponent = withClearCache(MainApp);
function App(props) {
  return <ClearCacheComponent {...props}/>;
}

export default App;
