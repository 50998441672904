import React from 'react';
import { Overlay } from "../../controls";

export const DocumentCodeColumnFormatter = (
  cellContent,
  rowData,
  rowIndex
) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column font-weight-bold">
        <div className='d-flex flex-row'>
          <span className="text-dark text-hover-primary mb-1 font-size-lg custom-span-quick-panel-deviation" style={{maxWidth: '5rem'}}>{rowData?.code}</span>
          <Overlay popoverContent={rowData?.code}>
            <i className={"fas fa-info-circle icon-nm text-hover-primary"} style={{padding: "5px"}}/>
          </Overlay>
        </div>
      </div>
    </div>
  )
}
