import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { emailRegex } from "../../../_helpers/staticFields";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ImportModalBody } from "./ImportModalBody";
import { useHistory } from "react-router-dom";
import { mapKeys } from "lodash";
import * as XLSX from "xlsx";
import ReactExport from "react-data-export";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  closeModal,
  hiddenModal,
  openModal,
  removeExcelData,
  visibleModal
} from "./_redux/importExcelActions";

const visibleModalClass = "visible-modal";
const hideModalClass = "hide-modal";
const initCssClass = ["custom-modal"];

export function ImportPortal() {
  const { modal, user } = useSelector(
    state => ({
      modal: state.modal,
      user: state.auth
    }),
    shallowEqual
  );

  const {
    title,
    onImport,
    url,
    name,
    createMethod,
    items,
    updateMethod,
    entityCode,
    show,
    hidden
  } = modal;

  const [cssClasses, setCssClasses] = useState(initCssClass);
  const [modalHidden, setModalHidden] = useState(false);

  const [added, setHiddenAdded] = useState(0);
  const [failed, setHiddenFailed] = useState(0);
  const [edited, setHiddenEdited] = useState(0);
  const [showEdit, setHiddenShowEdit] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  let history = useHistory();
  const [disable, setDisable] = useState(0);
  const [importItems, setImportItems] = useState([]);
  const [invalidExtension, setInvalidExtension] = useState(false);
  const [failedImport, setFailedImport] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [pauseDataImport, setPauseDataImport] = useState(false);
  const [stopDataImport, setStopDataImport] = useState(false);
  const [pendingState, setPendingState] = useState(false);

  const dispatch = useDispatch();

  let pending = importItems.length - (added + failed + edited);
  let addedPercent = Math.floor((added / importItems?.length) * 100);
  let failedPercent = Math.floor((failed / importItems?.length) * 100);
  let editedPercent = Math.floor((edited / importItems?.length) * 100);
  let pendingPercent = Math.floor((pending / importItems?.length) * 100);

  useEffect(() => {
    // for toggle 'dialog-hidden' css class
    setCssClasses(initCssClass);

    let modalToggle = modalHidden ? hideModalClass : visibleModalClass;
    setCssClasses([...initCssClass, modalToggle]);
  }, [modalHidden]);

  useEffect(() => {
    var fileExtension = items?.name?.split(".").pop();
    if (["xlsx", "xls", "csv"].includes(fileExtension)) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(items);

        fileReader.onload = e => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          let colValues = [];
          let cells = Object.keys(ws);

          for (let i = 0; i < Object.keys(cells).length; i++) {
            // if (cells[i].indexOf('1') > -1) {
            if (cells[i].match(/[A-Z]*[A-Z][1]$/)) {
              colValues.push(ws[cells[i]].v); //Contains all Header column names
            }
          }
          setColumnNames(colValues);

          const data = XLSX.utils
            .sheet_to_json(ws)
            .map(row => mapKeys(row, (value, key) => key.trim()));
          resolve(data);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });

      promise.then(d => {
        if (
          !user?.user?.groupCodes?.includes("SetDefaultPasswordOnUserCreation")
        ) {
          const newData = d.map(item => {
            const { defaultPassword, ...rest } = item;
            return rest;
          });
          setImportItems(newData);
        } else {
          setImportItems(d);
        }

        dispatch(openModal());
      });
    } else if (fileExtension) {
      setInvalidExtension(true);
      dispatch(openModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (importItems?.length) {
      setDisable(0);
    } else {
      setDisable(1);
    }

    if (pendingPercent === 0) {
      setPendingState(true);
    } else {
      setPendingState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importItems, pending]);

  useEffect(() => {
    if (pendingState && failedImport.length) {
      let columns = [];
      for (let i = 0; i < columnNames.length; i++) {
        columns.push({
          title: columnNames[i],
          style: { font: { bold: true } },
          width: { wpx: 150 }
        });
      }
      let dataList = [];
      for (let i = 0; i < failedImport.length; i++) {
        let data = [];
        for (let j = 0; j < columnNames.length; j++) {
          data.push({
            value: failedImport[i][columnNames[j]] || "",
            ...(!isValid(
              columnNames[j],
              failedImport[i][columnNames[j]],
              failedImport[i]
            )
              ? {
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } }
                  }
                }
              : {})
          });
        }
        dataList.push(data);
      }
      setDataSet([
        {
          columns: columns,
          data: dataList
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedImport, pendingState]);

  useEffect(() => {
    if (show) {
      window.addEventListener("beforeunload", stopRefreshPage, {
        capture: true
      });
    }

    if (pendingState) {
      window.removeEventListener("beforeunload", stopRefreshPage, {
        capture: true
      });
    }

    return () => {
      window.removeEventListener("beforeunload", stopRefreshPage, {
        capture: true
      });
    };
  }, [pendingState, show]);

  const isValid = (title, value, row) => {
    switch (title) {
      case "name":
        return value && String(value || "")?.trim() !== "";
      case "code":
        return value && String(value || "")?.trim() !== "";
      case "emailAddress":
        return (
          value && String(value || "")?.trim() !== "" && emailRegex.test(value)
        );
      case "groupCodes":
        return value && String(value || "")?.trim() !== "";
      case "moduleCode":
        return value.split(",").length === row.permissions.split(",").length;
      case "permissions":
        return value.split(",").length === row.moduleCode.split(",").length;
      default:
        return true;
    }
  };

  const cancelModal = () => {
    setImportItems([]);
    setInvalidExtension(false);
    setDisable(0);
    setFailedImport([]);
    setPauseDataImport(false);
    setStopDataImport(false);
    setPendingState(false);
    dispatch(removeExcelData());
    dispatch(closeModal());
    if (url) {
      history.push(`${url}/pending`);
    }
  };

  const pauseImport = () => {
    setPauseDataImport(true);
  };
  const resumeImport = () => {
    setPauseDataImport(false);
  };
  const stopImport = () => {
    setStopDataImport(true);
    setPauseDataImport(false);
  };
  const hideModal = () => {
    setModalHidden(true);
    dispatch(hiddenModal());
  };
  const showModal = () => {
    setModalHidden(false);
    dispatch(visibleModal());
  };
  const stopRefreshPage = e => {
    const message =
      "This function returns a message for call but It can't replace the default browser value because this feature is not more supported in Browsers.";
    (e || window.e).returnValue = message;
    return message;
  };

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      {show && (
        <>
          {" "}
          {!hidden && <div className="custom-modal-backdrop" />}
          <div className={cssClasses.join(" ")}>
            <div
              className={`modal-dialog ${
                importItems.length ? "modal-lg" : "modal-sm"
              }`}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Importing {title}
                  </h5>
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-bg-success btn-icon-light btn-hover-success"
                    onClick={hideModal}
                  >
                    <i className="fas fa-compress" />
                  </button>
                </div>
                <div className="modal-body">
                  {invalidExtension === true ? (
                    <>
                      <img
                        className="d-block mx-auto"
                        alt="Invalid Excel"
                        src={toAbsoluteUrl(
                          "/media/error/invalid-file-format.jpg"
                        )}
                      />
                      <p className="text-center">
                        {" "}
                        The Selected file is Invalid.{" "}
                      </p>
                    </>
                  ) : (
                    <>
                      {importItems?.length ? (
                        <ImportModalBody
                          importItems={importItems}
                          updateDisable={setDisable}
                          createMethod={createMethod}
                          updatedMethod={updateMethod}
                          name={name}
                          entityCode={entityCode}
                          title={title}
                          failedImport={failedImport}
                          setFailedImport={setFailedImport}
                          pause={pauseDataImport}
                          stop={stopDataImport}
                          showModal={showModal}
                          modalHidden={modalHidden}
                          setHiddenAdded={setHiddenAdded}
                          setHiddenFailed={setHiddenFailed}
                          setHiddenEdit={setHiddenEdited}
                          setHiddenShowEdit={setHiddenShowEdit}
                        />
                      ) : (
                        <>
                          <img
                            className="h-180px w-100"
                            alt="No data found"
                            src={toAbsoluteUrl(
                              "/media/svg/illustrations/copy.svg"
                            )}
                          />
                          <p className="text-center my-3">
                            {" "}
                            No Data Found to Import{" "}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <div
                    className={`d-flex w-100 ${
                      !stopDataImport
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {!stopDataImport && (
                      <div>
                        {!pendingState && (
                          <>
                            {disable === 0 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={stopImport}
                                >
                                  Stop
                                </button>
                                {pauseDataImport ? (
                                  <button
                                    type="button"
                                    className="btn btn-success ml-2"
                                    onClick={resumeImport}
                                  >
                                    Resume
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-info ml-2"
                                    onClick={pauseImport}
                                  >
                                    Pause
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div>
                      <input
                        type="file"
                        className="d-none"
                        id="getFile"
                        accept=".xls, .xlsx, .csv"
                        onChange={e => {
                          const file = e.target.files[0];
                          onImport(file);
                          e.target.value = null;
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-light"
                        disabled={
                          !stopDataImport &&
                          !pendingState &&
                          (disable === 0 ? "disabled" : null)
                        }
                        onClick={cancelModal}
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                      {failedImport.length ? (
                        <ExcelFile
                          filename="FailedImportData"
                          element={
                            <button
                              className="btn btn-primary ml-2"
                              disabled={!stopDataImport && !dataSet.length}
                            >
                              Download failed data
                            </button>
                          }
                        >
                          <ExcelSheet dataSet={dataSet} name="Employees" />
                        </ExcelFile>
                      ) : null}

                      {!stopDataImport && (
                        <button
                          type="button"
                          className="btn btn-primary ml-2"
                          disabled={
                            !stopDataImport &&
                            !pendingState &&
                            (disable === 0 ? "disabled" : null)
                          }
                          onClick={function() {
                            document.getElementById("getFile").click();
                          }}
                        >
                          Import from Excel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {hidden && (
        <div className="minimize-modal-div">
          <div className="d-flex justify-content-between align-items-end p-3">
            <span className="font-weight-bold">Importing {title}</span>
            <button
              type="button"
              className="btn btn-xs btn-icon btn-bg-success btn-icon-light"
              onClick={showModal}
            >
              <i className="fas fa-expand" />
            </button>
          </div>
          <hr className="m-0" />
          <div className="d-flex justify-content-even p-2">
            <div className="d-flex flex-column align-items-center">
              <span className="text-primary">Added</span>
              <CircularProgressbar
                value={addedPercent}
                text={
                  <tspan
                    dy={10}
                    dx={
                      addedPercent === 100 ? -25 : addedPercent < 10 ? -10 : -20
                    }
                  >
                    {addedPercent}%
                  </tspan>
                }
                // maxValue={importItems?.length}
                strokeWidth={5}
                styles={{
                  root: {
                    width: `${showEdit ? "70%" : "50%"}`
                  },
                  path: {
                    stroke: `${importItems?.length ? "#3699FF" : "#d6d6d6"}`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    // Rotate the path
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  trail: {
                    stroke: "#d6d6d6",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  text: {
                    fill: "#3699FF",
                    // Text size
                    fontSize: "25px"
                  },
                  background: {
                    fill: "#3e98c7"
                  }
                }}
              />
            </div>
            {showEdit && (
              <div className="d-flex flex-column align-items-center">
                <span className="text-primary">Edited</span>
                <CircularProgressbar
                  value={editedPercent}
                  text={
                    <tspan
                      dy={10}
                      dx={
                        editedPercent === 100
                          ? -25
                          : editedPercent < 10
                          ? -10
                          : -20
                      }
                    >
                      {editedPercent}%
                    </tspan>
                  }
                  // maxValue={importItems?.length}
                  strokeWidth={5}
                  styles={{
                    root: {
                      width: "70%"
                    },
                    path: {
                      stroke: `${importItems?.length ? "#3699FF" : "#d6d6d6"}`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "round",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      // Rotate the path
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center"
                    },
                    trail: {
                      stroke: "#d6d6d6",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "round",
                      // Rotate the trail
                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center"
                    },
                    text: {
                      fill: "#3699FF",
                      // Text size
                      fontSize: "25px"
                    },
                    background: {
                      fill: "#3e98c7"
                    }
                  }}
                />
              </div>
            )}

            <div className="d-flex flex-column align-items-center">
              <span className="text-danger">Failed</span>
              <CircularProgressbar
                value={failedPercent}
                text={
                  <tspan
                    dy={10}
                    dx={
                      failedPercent === 100
                        ? -25
                        : failedPercent < 10
                        ? -10
                        : -20
                    }
                  >
                    {failedPercent}%
                  </tspan>
                }
                // maxValue={importItems?.length}
                strokeWidth={5}
                styles={{
                  root: {
                    width: `${showEdit ? "70%" : "50%"}`
                  },
                  path: {
                    stroke: `${importItems?.length ? "#F64E60" : "#d6d6d6"}`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    // Rotate the path
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  trail: {
                    stroke: "#d6d6d6",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  text: {
                    fill: "#F64E60",
                    // Text size
                    fontSize: "25px"
                  },
                  background: {
                    fill: "#3e98c7"
                  }
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <span className="text-warning">Pending</span>
              <CircularProgressbar
                value={pendingPercent}
                text={
                  <tspan
                    dy={10}
                    dx={
                      pendingPercent === 100
                        ? -25
                        : pendingPercent < 10
                        ? -10
                        : -20
                    }
                  >
                    {pendingPercent}%
                  </tspan>
                }
                // text={`${importItems.length - (added + failed + edited)}`}
                // maxValue={100}
                strokeWidth={5}
                styles={{
                  root: {
                    width: `${showEdit ? "70%" : "50%"}`
                  },
                  path: {
                    stroke: `${importItems?.length ? "#FFAE10" : "#d6d6d6"}`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    // Rotate the path
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  trail: {
                    stroke: "#d6d6d6",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center"
                  },
                  text: {
                    fill: "#FFAE10",
                    // Text size
                    fontSize: "25px",
                    marginRight: "50px",
                    transformOrigin: "200px 200px"
                  },
                  background: {
                    fill: "#3e98c7"
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
}
