import React from 'react';
import { dropDownOptions } from "../../../_helpers/staticFields";

export const DropdownColumnFormatter = (cellContent, row, rowIndex, {identifier, listCode}) => {
  let displayValue = row[identifier];

  if(listCode) {
    const options = dropDownOptions[listCode];
    if(options) displayValue = options.find(item => item.code === displayValue)?.name || displayValue;
  }

  return (
    <span>{displayValue}</span>
  )
};
