import React from "react";

export const CurrentExternalSystemFormatter = (
  cellContent,
  row,
  rowIndex,
  { currentExternalSystems }
) => {
  let item =
    (currentExternalSystems?.setExternalSystems || []).find(
      item => item?.code === cellContent
    ) || "";
  return (
    <span>
      {row?.status === 1
        ? "All External System Completed"
        : item?.name || "N/A"}
    </span>
  );
};
