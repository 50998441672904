import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import ru_RU from "antd/lib/locale-provider/ru_RU";
import { ruRU } from "@material-ui/core/locale";
// import throttle from "lodash/throttle";
import {
  Builder,
  Query,
  Utils as QbUtils,
  BasicFuncs
} from "react-awesome-query-builder";
// import MaterialConfig from "react-awesome-query-builder/lib/config/material";

// For AntDesign widgets only:
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import "antd/dist/antd.css"; // or import "react-awesome-query-builder/css/antd.less";

// For Material-UI widgets only:
import { QueryBuilderSelect } from "./QueryBuilderSelect";
import { inputTypes } from "../../../_helpers/commonStaticVariables";

import "react-awesome-query-builder/lib/css/styles.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

let availableRecordsList = [];

const conjunctions = {
  ...AntdConfig.conjunctions
};

const proximity = {
  ...AntdConfig.operators.proximity,
  valueLabels: [
    { label: "Word 1", placeholder: "Enter first word" },
    { label: "Word 2", placeholder: "Enter second word" }
  ],
  textSeparators: [
    //'Word 1',
    //'Word 2'
  ],
  options: {
    ...AntdConfig.operators.proximity.options,
    optionLabel: "Near", // label on top of "near" selectbox (for config.settings.showLabels==true)
    optionTextBefore: "Near", // label before "near" selectbox (for config.settings.showLabels==false)
    optionPlaceholder: "Select words between", // placeholder for "near" selectbox
    minProximity: 2,
    maxProximity: 10,
    defaults: {
      proximity: 2
    },
    customProps: {}
  }
};

const operators = {
  ...AntdConfig.operators,
  // examples of  overriding
  proximity,
  between: {
    ...AntdConfig.operators.between,
    valueLabels: ["Value from", "Value to"],
    textSeparators: ["from", "to"]
  },
  multiselect_equals: {
    ...AntdConfig.operators.multiselect_equals,
    label: "Contains",
    labelForFormat: "contains",
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} contains ${value}`,
    sqlOp: "=",
    mongoOp: "=",
    jsonLogic: function jsonLogic(field, op, val) {
      return {
        in: [{ ...field }, val || []]
      };
    }
  }
};

const widgets = {
  ...AntdConfig.widgets,
  // examples of  overriding
  text: {
    ...AntdConfig.widgets.text
  },
  textarea: {
    ...AntdConfig.widgets.textarea,
    maxRows: 3
  },
  slider: {
    ...AntdConfig.widgets.slider
  },
  rangeslider: {
    ...AntdConfig.widgets.rangeslider
  },
  date: {
    ...AntdConfig.widgets.date,
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD"
  },
  time: {
    ...AntdConfig.widgets.time,
    timeFormat: "HH:mm",
    valueFormat: "HH:mm:ss"
  },
  datetime: {
    ...AntdConfig.widgets.datetime,
    timeFormat: "HH:mm",
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD HH:mm:ss"
  },
  func: {
    ...AntdConfig.widgets.func,
    lower: {
      label: "Lowercase",
      sqlFunc: "LOWER",
      mongoFunc: "$toLower",
      returnType: "text",
      args: {
        str: {
          type: "text",
          valueSources: ["value", "field"]
        }
      }
    },
    customProps: {
      showSearch: true
    }
  },
  select: {
    ...AntdConfig.widgets.select,
    factory: props => {
      const masterDataType = props?.fieldDefinition?.["masterDataType"];
      let isFetchRecords = false;

      if (!availableRecordsList.includes(masterDataType)) {
        availableRecordsList.push(masterDataType);
        isFetchRecords = true;
      }

      return (
        <QueryBuilderSelect
          {...props}
          showSearch={true}
          isMulti={false}
          isFetchRecords={isFetchRecords}
        />
      );
    }
  },
  multiselect: {
    ...AntdConfig.widgets.multiselect,
    factory: props => {
      const masterDataType = props?.fieldDefinition?.["masterDataType"];
      let isFetchRecords = false;

      if (!availableRecordsList.includes(masterDataType)) {
        availableRecordsList.push(masterDataType);
        isFetchRecords = true;
      }

      return (
        <QueryBuilderSelect
          {...props}
          isMulti={true}
          isFetchRecords={isFetchRecords}
        />
      );
    }
  },
  treeselect: {
    ...AntdConfig.widgets.treeselect,
    customProps: {
      showSearch: true
    }
  }
};

const types = {
  ...AntdConfig.types,
  // examples of  overriding
  boolean: merge(AntdConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: "is"
        },
        opProps: {
          equal: {
            label: "is"
          },
          not_equal: {
            label: "is not"
          }
        }
      }
    }
  })
};

const localeSettings = {
  locale: {
    moment: "ru",
    antd: ru_RU,
    material: ruRU
  },
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  funcLabel: "Function",
  fieldPlaceholder: "Select field",
  funcPlaceholder: "Select function",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule",
  addSubRuleLabel: "Add sub rule",
  delGroupLabel: null,
  notLabel: "Not",
  valueSourcesPopupTitle: "Select value source"
};

const settings = {
  ...AntdConfig.settings,
  ...localeSettings,

  defaultSliderWidth: "200px",
  defaultSelectWidth: "200px",
  defaultSearchWidth: "100px",
  defaultMaxRows: 5,

  valueSourcesInfo: {
    value: {
      label: "Value"
    },
    field: {
      label: "Field",
      widget: "field"
    },
    func: {
      label: "Function",
      widget: "func"
    }
  },
  // canReorder: true,
  // canRegroup: true,
  // showNot: true,
  // showLabels: true,
  showErrorMessage: true,
  canLeaveEmptyGroup: true
  // renderField: (props) => <FieldCascader {...props} />,
  // renderOperator: (props) => <FieldDropdown {...props} />,
  // renderFunc: (props) => <FieldSelect {...props} />,
  // maxNumberOfRules: 10 // number of rules can be add ed to the query builder
};

const funcs = {
  ...BasicFuncs
};

const InitialConfig = {
  ...AntdConfig,
  conjunctions,
  operators,
  widgets,
  types,
  funcs,
  settings
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const staticValue = { id: QbUtils.uuid(), type: "group" };

const getPredefinedField = predefinedFields => {
  let result = null;
  if (Array.isArray(predefinedFields) && predefinedFields?.length) {
    result = {};
    predefinedFields.forEach(predefinedField => {
      result[predefinedField.code] = predefinedField.name;
    });
  }
  return result;
};

const fieldMapping = (fieldsList, dropdownData) => {
  var dynamicFields = {};

  fieldsList &&
    fieldsList.forEach(field => {
      // if (field.type === "multiselect") return;
      const type = inputTypes.find(
        inputField => inputField.id === field.dataType
      ).queryBuilderType;
      if (type === "select") {
        dynamicFields[field.code] = {
          label: field.name,
          type,
          // listValues: getPredefinedField(dropdownData?.[field.masterDataType]?.records || []),
          listValues: getPredefinedField(
            dropdownData?.[field.masterDataType]?.records ||
              dropdownData?.[field.masterDataType]
          ),
          masterDataType: field.masterDataType
        };
      } else if (type === "multiselect") {
        dynamicFields[field.code] = {
          label: field.name,
          type: "multiselect",
          // listValues: getPredefinedField(dropdownData?.[field.masterDataType]?.records || []),
          listValues: getPredefinedField(
            dropdownData?.[field.masterDataType]?.records ||
              dropdownData?.[field.masterDataType]
          ),
          masterDataType: field.masterDataType
        };
      } else {
        dynamicFields[field.code] = {
          label: field.name,
          type,
          disabled: false,
          excludeOperators: ["proximity"]
        };
      }
    });
  return dynamicFields;
};

const serializeTree = (treeExp, config) =>
  QbUtils.checkTree(QbUtils.loadTree(treeExp), config);

// const throttledOnChange = throttle(
//   (parentCallback, params) => {
//     parentCallback(params);
//   },
//   500,
//   {leading: true, trailing: true}
// );

export function QueryBuilder(props) {
  const [config, setConfig] = useState({ ...InitialConfig, fields: {} });
  const [fieldsMapped, setFieldsMapped] = useState(false);
  const [tree, setTree] = useState(QbUtils.loadTree(staticValue));
  const [treeMounted, setTreeMounted] = useState(false);
  const [viewTree, setViewTree] = useState({});

  useEffect(() => {
    if (props.fieldsList && !fieldsMapped) {
      const dynamicFields = fieldMapping(
        props.fieldsList,
        props.dropdownOptions
      );
      setConfig(currentConfig => ({
        ...currentConfig,
        fields: dynamicFields
      }));
      setFieldsMapped(true);
    }
  }, [props.fieldsList, fieldsMapped, props.dropdownOptions]);

  // when dropdown options are loaded from api, map them to fields listValues
  useEffect(() => {
    if (props.dropdownOptions) {
      const dynamicFields = fieldMapping(
        props.fieldsList,
        props.dropdownOptions
      );
      setConfig(currentConfig => ({
        ...currentConfig,
        fields: dynamicFields
      }));
    }
  }, [props.fieldsList, props.dropdownOptions]);

  // if loading existing query value, mount it appropriately
  useEffect(() => {
    if (props.queryValue && !treeMounted && fieldsMapped) {
      setTreeMounted(true);
      setTree(serializeTree(JSON.parse(props.queryValue), config));
    }
  }, [props.queryValue, treeMounted, config, fieldsMapped]);

  useEffect(() => {
    setViewTree(QbUtils.sqlFormat(tree, config, true));
  }, [config, tree]);

  const renderBuilder = props => (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );

  const renderResult = () => {
    return (
      <div className="query-builder-result mt-5">
        <p className="query-builder-result-text">
          <div className="query-result">
            {/*<pre>{JSON.stringify(QbUtils.queryString(tree, config, true)) || "No queries"}</pre>*/}
            {JSON.stringify(viewTree) || "No queries"}
          </div>
        </p>
      </div>
    );
  };

  const onChange = (immTree, config) => {
    setTree(immTree);
    setConfig(config);
    setViewTree(QbUtils.queryString(immTree, config, true));
    props.onChange({ tree: immTree, config });
    // throttledOnChange(props.onChange, {tree: immTree, config});
  };

  const copyExpression = async () => {
    await navigator.clipboard.writeText(JSON.stringify(QbUtils.getTree(tree)));
  };

  const pasteExpression = async () => {
    let copiedExpression = await navigator.clipboard.readText();

    if (copiedExpression) {
      copiedExpression = serializeTree(JSON.parse(copiedExpression), config);
      setTreeMounted(true);
      setTree(copiedExpression);
      setViewTree(QbUtils.queryString(copiedExpression, config, true));
    }
  };

  return (
    <div className="qb-container mb-7">
      {props.isHeading ? (
        <h5 className="expression-tree-heading">
          {props.heading || "Condition Builder"}
          {props.isRequired && <span className="text-danger"> *</span>}
        </h5>
      ) : null}

      <div className="ant-btn-group ant-btn-group-sm copy-paste-expression">
        <button
          type="button"
          className="ant-btn ant-btn-default ant-btn-sm action"
          onClick={copyExpression}
        >
          <span>Copy</span>
        </button>
        <button
          type="button"
          className="ant-btn ant-btn-default ant-btn-sm action"
          onClick={pasteExpression}
        >
          <span>Paste</span>
        </button>
      </div>

      <Query
        {...config}
        value={tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      {renderResult()}
      {props.invertCondition ? (
        <div
          className={`d-flex align-items-center bg-light-warning rounded p-2 gutter-b mt-5`}
        >
          <span className={`svg-icon svg-icon-lg svg-icon-warning mr-3`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}
              className="svg-icon svg-icon-lg"
            ></SVG>
          </span>
          <div className="d-flex align-items-center mr-2">
            <p className="mb-0">
              <span className="text-dark-50">
                This condition builder takes invert condition.
              </span>
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
