import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";
import { defaultValueTypes } from "../../../../../_custom/_helpers/enums";

const initialFieldsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  fieldsWithDetailsTotalCount: 0,
  fieldsWithDetails: null,
  fieldForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  fieldDeleted: false,
  fieldsDictionary: {},
  prevEntity: undefined,
  auditLogs: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const fieldsSlice = createSlice({
  name: "fields",
  initialState: initialFieldsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fieldFetched: (state, action) => {
      state.actionsLoading = false;
      state.fieldForEdit = action.payload.fieldForEdit;

      // state.fieldForEdit = {
      //   ...(action.payload.fieldForEdit),
      //   computations: {
      //     RRLFLD0166: {
      //       field: "RRLDETAILOFPREVEXISTFACILITYGRID",
      //       type: 0
      //     }
      //   }
      // };
      state.error = null;
      // state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    fieldsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      // state.fieldForEdit = undefined;
      state.entities = entities;
      // state.prevEntity = undefined;
      state.totalCount = totalCount;
      state.makerCheckerAction = false;
      state.fieldDeleted = false;
    },
    appendFields: (state, action) => {
      const { entities, isWithDetailsCall } = action.payload;
      state.listLoading = false;
      state.error = null;
      // state.fieldForEdit = undefined;
      let combinedEntities = [
        ...(isWithDetailsCall
          ? state?.fieldsWithDetails || []
          : state?.entities || [] || [])
      ];
      entities.forEach(entity => {
        const fieldIndex = (combinedEntities || []).findIndex(
          fld => fld.code === entity.code
        );
        if (fieldIndex > -1) combinedEntities[fieldIndex] = entity;
        else combinedEntities.push(entity);
      });
      // getting unique set of fields by code
      // state.entities = combinedEntities.filter((e, i) => combinedEntities.findIndex(a => a.code === e.code) === i);
      if (isWithDetailsCall) {
        let fieldsDictionary = createDictionary(entities || [], "code");

        combinedEntities.forEach(entity => {
          if (
            entity?.defaultValueType === defaultValueTypes.Field &&
            entity?.defaultValue &&
            !entity?.defaultValueExpression
          ) {
            if (fieldsDictionary[entity.defaultValue]) {
              fieldsDictionary[entity.defaultValue] = {
                ...fieldsDictionary[entity.defaultValue],
                copyValueTo: entity.code
              };
            }
          }
        });

        state.fieldsDictionary = {
          ...state.fieldsDictionary,
          ...fieldsDictionary
        };
        state.fieldsWithDetails = combinedEntities;
        state.fieldsWithDetailsTotalCount = combinedEntities.length;
        state.entities = null;
        state.totalCount = 0;
      } else {
        state.entities = combinedEntities;
        state.totalCount = combinedEntities.length;
        state.fieldsWithDetails = undefined;
        state.fieldsWithDetailsTotalCount = undefined;
      }
      state.makerCheckerAction = false;
      state.fieldDeleted = false;
    },
    fieldCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.fieldForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    fieldUpdated: (state, action) => {
      const { field } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      // state.entities = null;
      state.totalCount = 0;
      state.fieldForEdit = field;
    },
    fieldDeleted: state => {
      state.fieldDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    clearIndividualField: state => {
      state.fieldForEdit = undefined;
    },
    clearFieldsList: state => {
      state.entities = null;
      state.totalCount = 0;
      state.prevEntity = undefined;
    },
    prevFieldFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.prevEntity = action.payload.fieldForEdit;
      state.error = null;
      state.makerCheckerAction = false;
    },
    auditLogsFetched: (state, action) => {
      const { auditLogs } = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const { auditLogs, params } = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state.actionsLoading = false;
      state.makerCheckerAction = false;
      state.fieldForEdit = toLog?.payload;
      state.prevEntity = fromLog?.payload;
    }
  }
});
