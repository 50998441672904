import React from "react";
import { Overlay } from "../../controls";

export const ConditionColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { identifier }
) => {
  return (
    <span>
      {row[identifier]?.representations?.[0].representationValue || "None"}
    </span>
  );
};

export const OverlayConditionColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { identifier }
) => {
  return (
    <Overlay
      popoverContent={
        row[identifier]?.representations?.[0].representationValue || "None"
      }
    >
      <span
        className="font-weight-bold comment-text"
        style={{ maxWidth: "10rem" }}
      >
        {row[identifier]?.representations?.[0].representationValue || "None"}
      </span>
    </Overlay>
  );
};
