import { createSlice } from "@reduxjs/toolkit";

const initialTemplateState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  prevEntity: undefined,
  lastError: null,
  makerCheckerAction: false,
  templateDeleted: false,
  allTemplates: undefined,
  userStatusChanged: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "notificationTemplates",
  initialState: initialTemplateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    templateFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.templateForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    templatesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.makerCheckerAction = false;
      state.templateDeleted = false;
      state.entities = entities;
      state.totalCount = totalCount;
      state.entityForEdit = undefined;
    },
    clearTemplatesList: state => {
      state.entities = null;
      state.totalCount = 0;
    },
    templateCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    templateUpdated: (state, action) => {
      const { template } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = template;
    },
    templateDeleted: state => {
      state.templateDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    productStagesFetched: state => {
      state.actionsLoading = false;
      state.listLoading = false;
    }
  }
});
