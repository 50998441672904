import React, { useState } from "react";
import { SketchPicker } from 'react-color';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, isHideValidations, field, prevState) => {

  const classes = ["form-control"];

  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }

  if (prevState && prevState[field?.name] !== field.value) {
    classes.push("field-modified");
  }
  return classes.join(" ");
};

export function ColorPicker(
  {
    form,
    field, // { name, value, onChange, onBlur }
    // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    defaultValue,
    description,
    meta,
    disabled,
    isReadOnly,
    classes,
    showDescription,
    prevState,
    ...props
  }
) {
  const {touched, error} = meta;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorPickerModal = (isOpen) => {
    setDisplayColorPicker(Boolean(isOpen) && !disabled);
  }

  const handleChange = (color) => {
    form.setFieldTouched(field?.name, true)
    props.onChange({
      target: {
        name: field.name,
        value: color.hex
      }
    })
  };

  return (
    <>
      {label && <label>{label}{props.required ? <span className='text-danger'> *</span> : null}</label>}
      <input
        type={type}
        className={`${getFieldCSSClasses(touched, error, props.isHideValidations, field, prevState)}`}
        {...field}
        {...props}
        disabled={isReadOnly || disabled || field.disabled || (props?.name === 'defaultValue' && form?.values['defaultValueType'] === 1)}
        value={(props?.name ? props.overrideDefaultValues?.[props?.name] : "") || field.value || ""}
        defaultValue={(props?.name ? props.overrideDefaultValues?.[props?.name] : "") || defaultValue}
        onChange={props.onChange}
      />

      <div className="w-36px color-div">
        <div onClick={() => handleColorPickerModal(true)} className="d-flex justify-content-end mb-5">
          <button
            type="button"
            style={{background: `${field.value}`}}
            className="color-toggle-btn"
          ></button>
        </div>

        {displayColorPicker ? (
          <div className="color-popover">
            <div onClick={() => handleColorPickerModal(false)} className="color-cover"/>
            <SketchPicker color={field.value || ''} onChange={handleChange} disableAlpha/>
          </div>
        ) : null}
      </div>

      {!props.isHideValidations && withFeedbackLabel && touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={"text"}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
