import React from "react";
import moment from "moment";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange, sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { getSelectRow } from "../../_helpers";

export function Statements({listData, listLoading, uiProps, totalCount}) {
  const columns = [
    {
      dataField: "paymentDueDate",
      text: "Due Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "statementDate",
      text: "Statement Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "statementMonth",
      text: "Statement Month",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, 'YYYYMM', 'MMMM YYYY')
    },
    {
      dataField: "billingStartDate",
      text: "Billing Start Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "nextStatementDate",
      text: "Next Statement Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "paymentAmount",
      text: "Due Minimum Payment",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.QuantitativeColumnFormatter,
      formatExtraData: {
        parameters: {
          level1: "paymentAmount"
        },
        unit: "PKR",
        classes: "text-danger"
      },
    },
    {
      dataField: "custBillingCycle",
      text: "Billing Cycle",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "openingBalance",
      text: "Opening Balance",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.QuantitativeColumnFormatter,
      formatExtraData: {
        parameters: {
          level1: "openingBalance"
        },
        unit: "PKR",
        // classes: "text-right"
      },
    },
    {
      dataField: "closingBalance",
      text: "Closing Balance",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.QuantitativeColumnFormatter,
      formatExtraData: {
        parameters: {
          level1: "closingBalance"
        },
        unit: "PKR",
        // classes: "text-right"
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };

  const renderPayments = (payments) => {
    return payments ? (
      <div className="card card-custom card-stretch gutter-b m-0">
        <div className="card-body p-0">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-center text-uppercase">
                  {/*<th>Amount Paid</th>*/}
                  <th>Transaction Amount</th>
                  {/*<th>Payment Date</th>*/}
                  <th>Transaction Date</th>
                  {/*<th>Paid Components</th>*/}
                </tr>
                </thead>
                <tbody>
                {payments.map(payment => (
                  <tr>
                    {/*<td className="text-dark-75 font-weight-bolder text-right">{payment.amountPaid || "0.00"} PKR</td>*/}
                    <td className="font-weight-bold text-center">{payment?.transactionAmount || "0.00"} PKR</td>
                    {/*<td className="text-dark-75 font-weight-bolder">{payment.paymentDate ? moment(payment.paymentDate).format('DD MMMM YYYY, hh:mm a') : '-'}</td>*/}
                    <td className="font-weight-bold text-center">{payment?.transactionDate ? moment(payment?.transactionDate)?.format('Do MMMM YYYY, hh:mm a') : '-'}</td>
                    {/*<td className="text-dark-75 font-weight-bolder">{payment.paidComponents || "N/A"}</td>*/}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ) : (<></>)
  }

  const expandComponent = {
    renderer: row => renderPayments(row.payments),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({isAnyExpands}) => {
      if (isAnyExpands) {
        return <i className="icon-md far fa-window-minimize"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    },
    expandColumnRenderer: ({expanded}) => {
      if (expanded) {
        return <i className="icon-md fas fa-angle-down"/>;
      }
      return <i className="icon-md fas fa-angle-right"/>;
    }
  }

  return (
    <DataGrid
      data={listData}
      listLoading={listLoading}
      columns={columns}
      paginationOptions={paginationOptions}
      type={uiProps.queryParams.type}
      onTableParamsChange={getHandlerTableChange(uiProps.setQueryParams)}
      keyField="statementDate"
      select={getSelectRow({
        identifier: "statementDate",
        entities: listData,
        selectedItems: [...uiProps.selectedItems],
        setSelectedItems: uiProps.setSelectedItems,
      })}
      isExpandableRow={true}
      expandRow={expandComponent}
    />
  );
}
