import axios from "axios";

const Records = "/quantum/api/v1.0/records";
const AuditURL = "/fury/api/v1.0/AuditLogs";
const falconURL = "/falcon/api/v1.0/creditline/upload/eligibility-criteria";

export function createRecord({ entityCode, record }) {
  return axios.post(Records + `/${entityCode}`, { ...record });
}

export function getAllRecords({ entityCode, forGrid, ...params }) {
  return axios.get(Records + `/${entityCode}`, { params });
}

export function getRecordById({ entityCode, recordId, category }) {
  return axios.get(Records + `/${entityCode}/${recordId}?category=${category}`);
}

export function updateRecord({ entityCode, record }) {
  return axios.put(
    `${Records}/${entityCode}${
      record?.type === 0 ? `/${record.code}` : `/Drafts/${record?.id}`
    }`,
    { ...record }
  );
}

export function deleteRecord({ entityCode, id, isDraft = false }) {
  return axios.delete(
    `${Records}/${entityCode}/${isDraft ? "Drafts/" + id : id}`
  );
}

export function actOnDraft({ entityCode, id, body }) {
  return axios.post(Records + `/${entityCode}/Drafts/${id}/act`, { ...body });
}
export function auditRecords(params) {
  return axios.get(AuditURL + `/${params.entityCode}/Records/${params.code}`);
}

export function fetchAuditsById(params) {
  console.log(
    AuditURL +
      `/${params.entityCode}/Records/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`
  );
  return axios.get(
    AuditURL +
      `/${params.entityCode}/Records/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`
  );
}

export function getImportFile(file, channelId, productCode, traceId) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(falconURL, formData, {
    headers: {
      channelId,
      productCode,
      traceId
    }
  });
}
