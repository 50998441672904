export function useCombinedModulePermissions (permissionsDictionary = {}, codes = []) {
  if (permissionsDictionary && Array.isArray(codes)) {
    const combinedPermissions = codes.reduce((result, code) => {
      const permissions = permissionsDictionary[code] || [];
      permissions.forEach(permission => result.add(permission));
      return result;
    }, new Set());

    return Array.from(combinedPermissions);
  } else {
    return [];
  }
}

