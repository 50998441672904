import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customersWithDetailsTotalCount: 0,
  customersWithDetails: null,
  customerForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  customerDeleted: false,
  customersDictionary: {}
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "pensieve",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    customerFetched: (state, action) => {
      state.actionsLoading = false;
      state.customerForEdit = action.payload.customerForEdit;

      // state.customerForEdit = {
      //   ...(action.payload.customerForEdit),
      //   computations: {
      //     RRLFLD0166: {
      //       customer: "RRLDETAILOFPREVEXISTFACILITYGRID",
      //       type: 0
      //     }
      //   }
      // };
      state.error = null;
      // state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    customersFetched: (state, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null
      // state.customerForEdit = undefined;
      state.entities = entities;
      state.totalCount = totalCount;
      state.makerCheckerAction = false;
      state.customerDeleted = false;
    },
    customerCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      state.error = null;
    },
    customerUpdated: (state, action) => {
      const {customer} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      // state.entities = null;
      state.totalCount = 0;
      state.customerForEdit = customer;
    },
    customerDeleted: (state) => {
      state.customerDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    clearIndividualCustomer: (state) => {
      state.customerForEdit = undefined;
    },
    clearCustomersList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
  }
});
