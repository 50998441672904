import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { getAllRoutes, getNavbarModules } from "../_custom/_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { IdleTimerContainer } from "../_custom/_partials/notifications/IdleTimerContainer";
import ProtectedRoute from "./ProtectedRoute";
import { errorNotification } from "../_custom/_partials/notifications";
import { useHistory } from "react-router";

const ProdRoutes = lazy(() => import("./modules/ProdFactory/pages"));
// const MasterDataConfiguration/ = lazy(() =>
//   import("./modules/QuantumAdministration/pages/QuantumAdministrationPage")
// );
const MasterDataConfiguration = lazy(() =>
  import("./modules/MasterDataConfiguration/pages")
);
const MasterDataRecords = lazy(() => import("./modules/MasterData/pages"));
// const MasterDataRecords = lazy(() =>
//   import("./modules/QuantumMDM/pages/QuantumMDMPage")
// );
const IdentityRoutes = lazy(() =>
  import("./modules/Identity/pages/IdentityPage")
);
const WorkflowRoutes = lazy(() => import("./modules/Workflows/Routes"));
const CollectionRoutes = lazy(() => import("./modules/Collections/Routes"));
const CustomerRoutes = lazy(() => import("./modules/Customers/index"));
const Customer360ViewRoutes = lazy(() =>
  import("./modules/Customer360View/Routes")
);
const ReportsRoutes = lazy(() => import("./modules/ReportsFactory/Routes"));
const ReportingRoutes = lazy(() => import("./modules/Reporting/Routes"));
const EnsembleRoutes = lazy(() => import("./modules/Ensemble/Routes"));
const KYCRoutes = lazy(() => import("./modules/Kyc/Routes"));
const DashboardRoutes = lazy(() => import("./modules/Dashboard/Routes"));
const AutoAssignmentsRoutes = lazy(() =>
  import("./modules/AutoAssignment/Routes")
);
const DocumentsControllerRoutes = lazy(() =>
  import("./modules/DocumentsController/Routes")
);
const LogsManagerRoutes = lazy(() => import("./modules/LogsManager/Routes"));
const NotificationTemplateRoutes = lazy(() =>
  import("./modules/NotificationTemplates/Routes")
);

const KiborRoutes = lazy(() => import("./modules/Kibor/Routes"));
const RepricingRoutes = lazy(() => import("./modules/LMS/Routes"));

export default function BasePage({ prevUrl, location }) {
  let history = useHistory();
  const [modules, setModules] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const [navBarModulesFetched, setNavBarModulesFetched] = useState(false);

  const { permissionsDictionary, allModules, initiationLoading } = useSelector(
    state => ({
      permissionsDictionary: state.groups.permissionsDictionary,
      allModules: state.modules.allModules,
      initiationLoading: state.groups.initiationLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    if (allModules?.length && permissionsDictionary) {
      setNavBarModulesFetched(false);
      let navBarModules = getNavbarModules(allModules, permissionsDictionary);
      if (navBarModules?.length) {
        setModules(navBarModules);
        setNavBarModulesFetched(true);
      } else {
        errorNotification("You do not have any module permission.");
        setNavBarModulesFetched(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allModules, permissionsDictionary]);

  useEffect(() => {
    if (!allRoutes?.length && modules?.length > 0) {
      setAllRoutes(getAllRoutes(modules));
    }
  }, [allRoutes, modules]);

  const urlModulePermissions = () => {
    return modules.some(module => prevUrl?.includes(module?.route));
  };

  useEffect(() => {
    if (
      prevUrl &&
      prevUrl !== "/" &&
      modules.length &&
      !urlModulePermissions()
    ) {
      errorNotification("You are not allowed to access this module.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, prevUrl]);

  useEffect(() => {
    if (
      location?.pathname !== "/" &&
      modules?.length > 0 &&
      location?.pathname?.includes("/") &&
      allRoutes?.length
    ) {
      let isRouteExists = (allRoutes || []).find(m =>
        location?.pathname?.includes(m)
      );
      if (!isRouteExists) {
        errorNotification("You are not allowed to access this module.");
        history.push(modules?.[0]?.route || "/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, modules, allRoutes]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {(initiationLoading || !modules?.[0]?.route) &&
        !navBarModulesFetched ? (
          <LayoutSplashScreen />
        ) : (
          <Redirect
            exact
            from="/"
            to={
              prevUrl && urlModulePermissions()
                ? prevUrl
                : modules?.[0]?.route || "/"
            }
          />
        )}

        <ContentRoute path="/dashboard-old" component={DashboardPage} />
        <ContentRoute path="/dashboard" component={DashboardRoutes} />
        <ContentRoute
          path="/autoAssignments"
          component={AutoAssignmentsRoutes}
        />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />

        {/*Protected routes*/}
        <ProtectedRoute path="/workflows" component={WorkflowRoutes} />
        <ProtectedRoute path="/enquiry" component={WorkflowRoutes} />

        <ProtectedRoute path="/lms" component={WorkflowRoutes} />

        <Route path="/products" component={ProdRoutes} />
        <Route path="/configuration" component={MasterDataConfiguration} />
        <Route path="/records" component={MasterDataRecords} />
        <Route path="/identity" component={IdentityRoutes} />
        <Route path="/collections" component={CollectionRoutes} />
        <Route path="/customers" component={CustomerRoutes} />
        <Route path="/customer360View" component={Customer360ViewRoutes} />
        <Route path="/reports" component={ReportsRoutes} />
        <Route path="/reporting" component={ReportingRoutes} />
        <Route path="/ensemble" component={EnsembleRoutes} />
        <Route path="/kyc" component={KYCRoutes} />
        <Route
          path="/documentsController"
          component={DocumentsControllerRoutes}
        />
        <Route path="/logsManager" component={LogsManagerRoutes} />
        <Route
          path="/notificationTemplates"
          component={NotificationTemplateRoutes}
        />
        <Route path="/kibor" component={KiborRoutes} />
        <Route path="/postOrigination" component={WorkflowRoutes} />
        <Route path="/repricingBalloon" component={RepricingRoutes} />
        <Redirect to="/error/error-v1" />
      </Switch>
      <IdleTimerContainer />
    </Suspense>
  );
}
