import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
import { Logout } from "../../../app/modules/Auth/_redux/authCrud";
import * as modulesActions from "../../../app/modules/Identity/_redux/modules/modulesActions";
import * as groupActions from "../../../app/modules/Identity/_redux/groups/groupsActions";

export function IdleTimerContainer() {
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const history = useHistory();

  const {user, uniqueIdentifier, inactivityTimeout} = useSelector(
    (state) => ({
      user: state.auth.user,
      uniqueIdentifier: state.auth.uniqueIdentifier,
      inactivityTimeout: state?.auth?.inactivityTimeout
    }),
    shallowEqual
  );

  const [openModal, setOpenModal] = useState(false);
  //Default set Timeout to 30 mins as fallback
  const [timeout, setTimeout] = useState(1800);
  const [isTimeoutSet, setIsTimeoutSet] = useState(false);

  useEffect(() => {
    if (inactivityTimeout && !isTimeoutSet) {
      setTimeout(inactivityTimeout || 1800);
      setIsTimeoutSet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactivityTimeout])

  // 10 seconds grace time
  const modalTimeout = 10;
  return (
    <>
      <div>
        <IdleTimer
          ref={idleTimerRef}
          timeout={timeout * 1000}
          onIdle={() => {
            setOpenModal(true);
          }}
        />
      </div>
      <Modal
        size="sm"
        show={openModal}
      >
        <div className="text-center p-4">
          <div className="mt-7">
            <Countdown
              date={Date.now() + (modalTimeout * 1000)}
              renderer={({minutes, seconds}) => (
                <span className="timer-container">{zeroPad(minutes)}:{zeroPad(seconds)}</span>
              )}
              onComplete={() => {
                setOpenModal(false);
                dispatch(modulesActions.clearModulesPermissions());
                dispatch(groupActions.clearGroupsPermissions());
                Logout(user.id, uniqueIdentifier).then((params) => {
                  if (params?.status === 200) {
                    history.push("/logout");
                  }
                })
              }}
            />
          </div>
          <div className="my-4">
            <span className="d-block text-danger font-weight-bold font-size-h4 mb-2">Idle Timeout</span>
            <span>You have been idle for too long. Please click stay to stop this timer.</span>
          </div>
          <div className="mt-8 mb-8">
            <button type="button" onClick={() => {
              setOpenModal(false);
            }} className="btn btn-light-danger">
              Stay
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
