import React from 'react';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
export default function ({resetErrorBoundary}) {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div style={{display:"grid",placeItems:"center"}}>
              <div className="four_zero_four_bg" >
                <img src={toAbsoluteUrl("/media/error/error-page.gif")} alt=""/>
              </div>
              <div className="content_box_404">
                <h3 className="h2">
                  Oops, that didn't go as planned.
                </h3>
                <p>Luckily, there's always a second chance.</p>
                <button onClick={resetErrorBoundary} className="link_404">Go to Home</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
