export function useCurrentUserFieldsPermission(records, product, user) {
  // Initialize a Set to store unique non-empty field codes
  const uniqueFieldCodes = new Set();

  (records || []).forEach(record => {
    // Extract the 'values' object from the record
    const { values } = record;
    // Check if the current user's 'code' is included in the 'allUsers' field in the 'values' object
    if (
      values?.allUsers?.includes(user.code) &&
      values?.ProductCodesMandatory?.includes(product?.code)
    ) {
      // Extract the 'allFields' value from the 'values' object
      const { allFields } = values;

      // Split the 'allFields' value into an array of field codes
      const fieldCodesArray = allFields?.split("!");

      // Filter out empty or undefined field codes and add the rest to the Set
      (fieldCodesArray || [])
        .filter(fieldCode => fieldCode?.trim() !== "")
        .forEach(fieldCode => {
          uniqueFieldCodes.add(fieldCode);
        });
    }
  });

  // Convert the Set back to an array if needed
  const fieldCodes = Array.from(uniqueFieldCodes);
  return fieldCodes;
}
