import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../../../_metronic/layout";
import { SearchResult } from "./SearchResult";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../../../../_metronic/_partials/dropdowns";
import { getNavbarModules } from "../../../../../_helpers";
import { useHistory } from "react-router-dom";

export function SearchDropdown() {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [modules, setModules] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [recentHistory, setRecentHistory] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [combinedModules, setCombinedModules] = useState([]);
  const [uniqueSearch, setUniqueSearch] = useState()

  const resultContainer = useRef(null)
  let history = useHistory();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const {permissionsDictionary, allModules} = useSelector(
    (state) => ({
      permissionsDictionary: state.groups.permissionsDictionary,
      allModules: state.modules.allModules,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (allModules?.length && permissionsDictionary && !modules.length) {
      let moduleArray = getNavbarModules(allModules, permissionsDictionary);
      setModules(moduleArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allModules, permissionsDictionary]);

  useEffect(() => {
    if (modules.length && !combinedModules.length) {

      let parents = [];
      modules.forEach((e) => {
        parents.push({
          name: e.name,
          route: e.route
        });
      });

      let children = [];
      modules.forEach((e) => {
        e.children.forEach((e) => {
          children.push({
            name: e.name,
            route: e.route
          });
        });
      });

      setCombinedModules([...parents, ...children]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  useEffect(() => {
    if (!resultContainer.current) return;
    resultContainer.current.scrollIntoView({
      block: "center"
    });
  }, [focusedIndex]);

  useEffect(() => {
    const Unique = recentHistory.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
    setUniqueSearch(Unique)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])


  useEffect(() => {
    setSearchResult(combinedModules?.filter(e => e?.name?.toLowerCase().includes(searchValue?.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);


  useEffect(() => {
    if (recentHistory.length > 3) {
      recentHistory.pop();
    }
  }, [recentHistory])

  const handleKeyDown = (e) => {
    const {key} = e;
    let nextIndexCount = 0;

    if (key === "ArrowDown") {
      if (focusedIndex === (searchResult.length - 1)) {
        e.preventDefault();
        return;
      }
      nextIndexCount = (focusedIndex + 1);
    }

    if (key === "ArrowUp") {
      if (focusedIndex === 0) {
        e.preventDefault();
        return;
      }
      nextIndexCount = (focusedIndex - 1);
    }
    if (key === "ArrowUp") {
      e.preventDefault()
    }
    if (key === 'Enter') {
      history.push(searchResult[focusedIndex]?.route)
      setRecentHistory([{name: searchResult[focusedIndex]?.name, route: searchResult[focusedIndex]?.route}, ...recentHistory])
    }
    setFocusedIndex(nextIndexCount);
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const clear = () => {
    setSearchValue("");
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} title={"Search"}/>
            </span>
          </div>
        </div>
      )}

      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={() => {
            setLoading(false);
            setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="search-panel-tooltip">Search</Tooltip>}
            >
              <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    title={"Search"}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              tabIndex={1} onKeyDown={handleKeyDown}
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": searchValue.length > 0,
              })}
            >
              <form className="quick-search-form" onSubmit={(event) => event.preventDefault()}>
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                          title={"Search"}
                        />
                      </span>
                    </span>
                  </div>

                  <input
                    type="text"
                    autoFocus={true}
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                  />

                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : "true"
                    }")}`}
                  >
                    <span className="input-group-text">

                      <i style={{
                        display:
                          searchValue && searchValue.length ? "flex" : "none",
                      }}
                         onClick={clear}
                         className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>


              <SearchResult
                searchResult={searchResult}
                recentHistory={recentHistory}
                setRecentHistory={setRecentHistory}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                resultContainer={resultContainer}


              />

              {recentHistory.length ? (
                <>
                  <div className=" mt-2 p-2 text-primary">Recent Search</div>
                  {((uniqueSearch || []).map((e, i) => {
                    return (
                      <div className="d-flex flex-column bg-hover-light-primary rounded p-2">
                        <h6 className="font-weight-bold mt-2 font-size-sm" onClick={() => history.push(e.route)}>
                          <span key={i}> <i className="fas fa-history font-size-sm mr-2"></i> {e.name} </span>
                        </h6>
                      </div>
                    )
                  }))
                  }
                </>
              ) : null}
            </div>

          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
