import * as requestFromServer from "./modulesCrud";
import { callTypes, modulesSlice } from "./modulesSlice";

const { actions } = modulesSlice;
export const fetchModules = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllModules(params)
    .then(response => {
      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.modulesFetched({
          totalCount,
          entities,
          isHeader: params?.isHeader
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch modules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchParentModules = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllModules(params)
    .then(response => {
      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.parentModulesFetched({
          totalCount,
          entities,
          isHeader: params?.isHeader
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch modules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearModulesList = () => dispatch => {
  dispatch(actions.clearModulesList());
};

export const clearModulesPermissions = () => dispatch => {
  dispatch(actions.clearModulesPermissions());
};

export const fetchModule = id => dispatch => {
  if (!id) {
    return dispatch(actions.moduleFetched({ moduleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getModuleById(id)
    .then(response => {
      const module = response.data;
      if (id?.type === "PrevState") {
        dispatch(actions.prevModuleFetched({ moduleForEdit: module }));
      } else {
        dispatch(actions.moduleFetched({ moduleForEdit: module }));
      }
      // dispatch(actions.moduleFetched({moduleForEdit: module}));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find module";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteModule = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteModule(queryParams)
    .then(response => {
      if (!queryParams?.isBulk) {
        dispatch(actions.moduleDeleted({ queryParams }));
      }
      if (queryParams?.isBulk && queryParams?.lastCall) {
        dispatch(actions.moduleDeleted({ queryParams }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete module";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createModule = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createModule(queryParams)
    .then(response => {
      const { module } = response.data;
      dispatch(actions.moduleCreated({ module }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create module";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateModule = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateModule(queryParams)
    .then(response => {
      dispatch(actions.moduleUpdated(queryParams));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update module";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const actOnDraft = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams)
    .then(response => {
      const { module } = response.data;
      dispatch(actions.actionDrafted({ module }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const auditModule = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditModule(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetched({ auditLogs }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAuditsById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAuditsById(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetchedById({ auditLogs, params }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
