import React, {  useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { clientCredentials, forgetPassword } from "../_redux/authCrud";
import { successNotification } from "../../../../_custom/_partials/notifications";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";

const initialValues = {
  email: "",
  captcha: ""
};

function ForgotPassword(props) {
  let history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [forgetPasswordValue, setForgetPasswordValues] = useState({});
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(150, "Maximum 150 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const CaptchaSchema = Yup.object().shape({
    captcha: Yup.string()
      .max(6, "Maximum 6 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setShowCaptcha(true);
      setForgetPasswordValues(values);
      loadCaptchaEnginge(6, "transparent");
    }
  });

  const captchaFormik = useFormik({
    initialValues,
    validationSchema: CaptchaSchema,
    onSubmit: (values, { setStatus, setSubmitting, setFieldValue }) => {
      if (validateCaptcha(values?.captcha, false) === true) {
        setSubmitting(true);
        enableLoading();
        clientCredentials()
          .then(response => {
            if (response?.status !== 200) {
              disableLoading();
              setSubmitting(false);
              setShowCaptcha(false);
              setStatus(response?.data?.message || "Unexpected error occurred");
            } else {
              let headers = {
                Authorization: `Bearer ${response?.data?.access_token}`
              };
              forgetPassword(forgetPasswordValue?.email, headers)
                .then(response => {
                  if (response?.status !== 200) {
                    // setShowCaptcha(false);
                    setSubmitting(false);
                    setStatus(
                      response?.data?.message || "Unexpected error occurred"
                    );
                    disableLoading();
                  } else {
                    setShowCaptcha(false);
                    successNotification(
                      "Instructions for resetting password have been mailed to your email account"
                    );
                    history.push("/auth/login");
                    setSubmitting(false);
                    disableLoading();
                  }
                })
                .catch(error => {
                  // setShowCaptcha(false);
                  setSubmitting(false);
                  setStatus(
                    error?.response?.data?.message ||
                      "Unexpected error occurred"
                  );
                  disableLoading();
                });
            }
          })
          .catch(error => {
            // setShowCaptcha(false);
            setSubmitting(false);
            setStatus(
              error?.response?.data?.message || "Unexpected error occurred"
            );
            disableLoading();
          });
        // setShowCaptcha(false);
        // setSubmitting(false);
      } else {
        setStatus("Invalid captcha entered, please try again");
        loadCaptchaEnginge(6, "transparent");
        setFieldValue("captcha", "");
        setSubmitting(false);
      }
    }
  });

  // useEffect(() => {
  //   loadCaptchaEnginge(6, "transparent");
  // }, []);
  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgotten Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your email to reset your password
          </div>
        </div>
        {!showCaptcha && (
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                placeholder="Email*"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Submit
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )}

        {showCaptcha && (
          <form
            onSubmit={captchaFormik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {captchaFormik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {captchaFormik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <div className="mb-5">
                <LoadCanvasTemplate />
              </div>
              <input
                type="text"
                placeholder="Captcha"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "captcha"
                )}`}
                name="captcha"
                {...captchaFormik.getFieldProps("captcha")}
              />
              {captchaFormik.touched.captcha && captchaFormik.errors.captcha ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">
                    {captchaFormik.errors.captcha}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={captchaFormik.isSubmitting || !captchaFormik.isValid}
              >
                Proceed
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
