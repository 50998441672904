import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  productForEdit: undefined,
  productDeleted: false,
  lastError: null,
  makerCheckerAction: false,
  deviationDictionary: null,
  discrepancyDictionary: null,
  paginationData: {},
  productDictionary: null,
  childProducts: {}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        // state.productForEdit = undefined;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductByCode
    productFetched: (state, action) => {
      state.actionsLoading = false;
      state.deviationDictionary = null;
      state.discrepancyDictionary = null;
      state.productForEdit = action.payload.productForEdit;
      state.error = null;
      state.makerCheckerAction = false;
      state.stagesDictionary = createDictionary(
        action.payload.productForEdit?.stages || [],
        "code"
      );
    },
    // findProducts
    productsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      // state.deviationDictionary = null;
      // state.discrepancyDictionary = null;
      state.listLoading = false;
      state.error = null;
      state.productDeleted = false;
      state.entities = entities;
      state.totalCount = totalCount;
      state.makerCheckerAction = false;
      state.productDictionary = createDictionary(entities || [], "code");
    },
    clearProductsList: state => {
      state.entities = null;
      state.totalCount = 0;
      state.productDictionary = null;
    },
    clearIndividualProduct: state => {
      state.productForEdit = null;
      state.childProducts = {};
      state.deviationDictionary = null;
      state.discrepancyDictionary = null;
    },
    // createProduct
    productCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.productForEdit = { ...(action.payload.product || {}) };
    },
    // updateProduct
    productUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if (Object.keys(action.payload.product || {}).length > 0)
        state.productForEdit = { ...action.payload.product };
    },
    // deleteProduct
    productDeleted: state => {
      state.error = null;
      state.actionsLoading = false;
      state.productDeleted = true;
      // state.entities = state.entities.filter(el => action.payload.isDraft ? el.id !== action.payload.productId : el.code !== action.payload.productId);
    },
    // deleteProducts
    productsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.codes.includes(el.code)
      );
    },
    // productsUpdateState
    productsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { codes, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (codes.findIndex(code => code === entity.code) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // on sfa action complete
    onDraftActionComplete: state => {
      state.error = null;
      state.actionsLoading = false;
      state.makerCheckerAction = true;
    },
    deviationDictionarySet: (state, action) => {
      state.deviationDictionary = action.payload;
    },
    discrepancyDictionarySet: (state, action) => {
      state.discrepancyDictionary = action.payload;
    },
    updatePagination: (state, action) => {
      let { gridCode, pagination } = action.payload;
      delete pagination.allData;
      state.paginationData[gridCode] = pagination;
    },
    resetPagination: state => {
      state.paginationData = {};
    },
    childProductFetched: (state, action) => {
      const { productCode, childProduct } = action.payload;
      state.childProducts[productCode] = childProduct || {};
    },
    auditLogsFetched: (state, action) => {
      const { auditLogs } = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    }
  }
});
