import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";

const initialRecordsState = {};
export const callTypes = {
  list: "list",
  action: "action"
};

export const recordsSlice = createSlice({
  name: "records",
  initialState: initialRecordsState,
  reducers: {
    catchError: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state[entityCode].listLoading = false;
      } else {
        state[entityCode].actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].error = null;
      if (action.payload.callType === callTypes.list) {
        state[entityCode].listLoading = true;
      } else {
        state[entityCode].actionsLoading = true;
      }
    },
    recordFetched: (state, action) => {
      const { entityCode, recordForEdit } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].actionsLoading = false;
      state[entityCode].recordForEdit = recordForEdit;
      state[entityCode].error = null;
      // state[entityCode].records = null;
      state[entityCode].makerCheckerAction = false;
    },
    recordsFetched: (state, action) => {
      const {
        entityCode,
        paging,
        records,
        forGrid,
        fieldCode
      } = action.payload;
      if (forGrid) {
        state[entityCode].recordsForGrid = records;
      } else if (fieldCode) {
        state[entityCode][fieldCode] = records;
        state[entityCode].listLoading = false;
      } else {
        state[entityCode] = { ...(state[entityCode] || {}) };
        state[entityCode].actionsLoading = false;
        state[entityCode].listLoading = false;
        state[entityCode].error = null;
        // state[entityCode].recordForEdit = undefined;
        state[entityCode].records = records;
        state[entityCode].paging = paging;
        state[entityCode].makerCheckerAction = false;
        state[entityCode].recordDeleted = false;
        state[entityCode].recordsDictionary = createDictionary(
          records || [],
          "code"
        );
      }
    },
    recordCreated: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].actionsLoading = false;
      // state[entityCode].recordForEdit = undefined;
      // state[entityCode].records = allRecords;
      state[entityCode].error = null;
    },
    recordUpdated: (state, action) => {
      const { entityCode, record } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].error = null;
      state[entityCode].actionsLoading = false;
      state[entityCode].records = null;
      state[entityCode].totalCount = 0;
      state[entityCode].recordForEdit = record;
      state[entityCode].recordsDictionary = null;
    },
    recordDeleted: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].recordDeleted = true;
      state[entityCode].error = null;
      state[entityCode].actionsLoading = false;
    },
    actonDrafted: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].actionsLoading = false;
      state[entityCode].makerCheckerAction = true;
      state[entityCode].error = null;
    },
    clearIndividualRecord: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].recordForEdit = undefined;
      state[entityCode].prevEntity = undefined;
    },
    clearRecordsList: (state, action) => {
      const { entityCode } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].records = null;
      state[entityCode].paging = undefined;
      state[entityCode].recordsDictionary = null;
    },
    prevRecordFetched: (state, action) => {
      const { entityCode, recordForEdit } = action.payload;
      state[entityCode] = { ...(state[entityCode] || {}) };
      state[entityCode].actionsLoading = false;
      state[entityCode].listLoading = false;
      state[entityCode].prevEntity = recordForEdit;
      state[entityCode].error = null;
      state[entityCode].makerCheckerAction = false;
    },
    auditLogsFetched: (state, action) => {
      const { entityCode, auditLogs } = action.payload;
      state[entityCode].actionsLoading = false;
      state[entityCode].auditLogs = auditLogs;
      state[entityCode].makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const { entityCode, auditLogs, params } = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state[entityCode].actionsLoading = false;
      state[entityCode].makerCheckerAction = false;
      state[entityCode].recordForEdit = toLog?.payload;
      state[entityCode].prevEntity = fromLog?.payload;
    },
    allRecordsUpdated: (state, action) => {
      (action.payload || []).forEach(entityCode => {
        if (state[entityCode]) {
          delete state[entityCode];
        }
      });
    },
    clearParentFieldRecords: (state, action) => {
      const { entityCode, parentField } = action.payload;
      state[entityCode][parentField] = undefined;
      state[entityCode].listLoading = false;
    }
  }
});
