import { createSlice } from "@reduxjs/toolkit";

const initialPlatformState = {
  listLoading: false,
  actionsLoading: false,
  holidays: undefined,
  slots: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "platform",
  initialState: initialPlatformState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    holidaysFetched: (state, action) => {
      const { holidays } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.holidays = holidays;
    },
    holidaysUpdated: (state, action) => {
      const { holidays } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.holidays = holidays;
    },
    slotsFetched: (state, action) => {
      const { slots } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.slots = slots;
    },
    slotsUpdated: (state, action) => {
      const { slots } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.slots = slots;
    },
    generalFetched: (state, action) => {
      const { general } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.general = general;
    },
    generalUpdated: (state, action) => {
      const { general } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.general = general;
    }
  }
});
