import React from 'react';
import { Overlay } from "../../controls";
import {imageForDocument} from "../UIHelpers"
import {toAbsoluteUrl} from "../../../../_metronic/_helpers"

export const DocumentNameFormatter = (
  cellContent,
  rowData,
  rowIndex
) => {
  let image = imageForDocument.find(a => a?.extension === rowData?.extension)?.image || '/media/svg/files/default.svg';

  return (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-40 symbol-light-primary mr-5">
														<span className="symbol-label">
															<span className="svg-icon svg-icon-xl svg-icon-primary">
                                  <img className="h-50px" alt="No data found" src={toAbsoluteUrl(image)}/>
															</span>
														</span>
      </div>
      <div className="d-flex flex-column font-weight-bold">
        <div className='d-flex flex-row'>
          <span className="text-dark text-hover-primary mb-1 font-size-lg custom-span-quick-panel-deviation" style={{maxWidth: '11rem'}}>{rowData?.name}</span>
          <Overlay popoverContent={rowData?.name}>
            <i className={"fas fa-info-circle icon-nm text-hover-primary"} style={{padding: "5px"}}/>
          </Overlay>
        </div>
        <span className="text-muted">Version Number: {rowData?.versionCount}</span>
      </div>
    </div>
  )
}
