import React, { useEffect, useState } from "react";
import { AddEditViewForm } from "../../../../_partials/controls";
import { evaluate } from "mathjs";

export const FormulaTesting = ({
  mathematicalExpression,
  validateBtn,
  fieldsDictionary
}) => {
  const [renderFields, setRenderFields] = useState([]);
  const [computationResult, setComputationResult] = useState(0);

  useEffect(() => {
    if (
      Object.entries(mathematicalExpression?.variables || {}).length &&
      fieldsDictionary
    ) {
      let tempRenderFields = [];

      for (const [key, value] of Object.entries(
        mathematicalExpression?.variables || {}
      )) {
        if (value) {
          tempRenderFields.push({
            name: `${key} (${fieldsDictionary[value]?.name || ""} - ${value ||
              ""})`,
            ...(fieldsDictionary[value] || {}),
            dataType: fieldsDictionary[value]?.dataType === undefined ? 1 : 0,
            isRequired: true
          });
        }
      }
      setRenderFields(tempRenderFields);
    }
  }, [fieldsDictionary, mathematicalExpression.variables]);

  const onValidate = value => {
    let { formula, variables } = mathematicalExpression;
    let formulaWithFieldsValues = String(formula || "")
        .replace(/[ \n]/g, "")
        .replace(/[ \t]/g, "");

    for (const [variable, fieldCode] of Object.entries(variables)) {
      formulaWithFieldsValues = formulaWithFieldsValues.replaceAll(
          `$${variable}`,
          value[fieldCode] || 0
      );
    }

    setComputationResult(evaluate(formulaWithFieldsValues));
  };

  return (
    <>
      <AddEditViewForm
        layout={"col-sm-6 mb-2"}
        showSkeleton={true}
        skeletonLength={renderFields.length}
        fieldsValues={{}}
        fieldsList={renderFields || []}
        btnRef={validateBtn}
        // onChangeMethods={{ ...fieldChangeHandlers }}
        enableReinitialize={true}
        saveField={onValidate}
        overrideMode={"edit"}
      />

      <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mr-6 text-center">
        <h1 className="m-0">{computationResult || 0}</h1>
        <div className="text-muted font-weight-bold">Expression Result</div>
      </div>
    </>
  );
};
