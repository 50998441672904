import axios from "axios";

const qs = require("qs");

const GroupsURL = "/identity/api/v1.0/groups";
const AuditURL = "/fury/api/v1.0/AuditLogs/Groups";
const oauthURL = "/identity/api/v1.0/clientCredentialsBasedUsers";

export function createGroup({ group }, headers) {
  return axios.post(GroupsURL, { ...group }, headers);
}

export function getAllGroups(params, headers) {
  return axios.get(GroupsURL, { params }, headers);
}

export function getGroupById({ groupId, category }, headers) {
  return axios.get(GroupsURL + `/${groupId}?category=${category}`, headers);
}

export function updateGroup({ group, groupType, id }, headers) {
  return axios.put(
    `${GroupsURL}${groupType === 0 ? `/${group.code}` : `/Drafts/` + id}`,
    { ...group }
  );
}

export function deleteGroup({ id, isDraft = false }) {
  return axios.delete(`${GroupsURL}/${isDraft ? "Drafts/" + id : id}`);
}

export function actOnDraft({ id, body }, headers) {
  return axios.post(GroupsURL + `/Drafts/${id}/act`, { ...body }, headers);
}

export function getGroupsByCodesList(params) {
  return axios.get(GroupsURL, {
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function fetchLoggedInUserGroups() {
  return axios.get(`${oauthURL}/GetUserGroups`);
}

export function auditGroup(params) {
  return axios.get(AuditURL + `/${params.code}`);
}

export function fetchAuditsById(params) {
  return axios.get(
    AuditURL + `/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`
  );
  // return axios.get(AuditURL + `/${params.code}`, {
  //   params,
  //   paramsSerializer: function(params) {
  //     return qs.stringify(params, {arrayFormat: 'comma'})
  //   },
  // });
}
