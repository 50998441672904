import axios from "axios";

export const PRODUCTS_URL = "friday/api/v1.0/Products";
const AuditURL = "/fury/api/v1.0/AuditLogs/Products";

export function createProduct(product) {
  return axios.post(PRODUCTS_URL, product);
}

export function fetchProducts(params) {
  const { filter, ...requestParams } = params;
  return axios.get(PRODUCTS_URL, {
    params: { ...requestParams, ...params.filter }
  });
}

export function getDraftProduct(code) {
  return axios.get(`${PRODUCTS_URL}/${code}`, { params: { category: 1 } });
}

export function getProductByCode({ code, version, type, category }) {
  return axios.get(`${PRODUCTS_URL}/${code}`, {
    params: { version, category, type }
  });
}

export function findProducts(queryParams) {
  return axios.post(`${PRODUCTS_URL}`, { queryParams });
}

export function updateProduct({ product, isDraft = false }) {
  return axios.put(
    `${PRODUCTS_URL}/${isDraft ? "Drafts/" + product.id : product.code}`,
    product
  );
}

export function updateStatusForProducts(codes, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    codes,
    status
  });
}

export function deleteProduct({ id, isDraft = false }) {
  return axios.delete(`${PRODUCTS_URL}/${isDraft ? "Drafts/" + id : id}`);
}

export function deleteProducts(codes) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { codes });
}

// ACTION => POST: act on draft entity
export function actOnDraft({ entityId, record }) {
  return axios.post(PRODUCTS_URL + `/Drafts/${entityId}/act`, { ...record });
}

export function auditProducts(params) {
  return axios.get(AuditURL + `/${params.code}`);
}
