import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { productsSlice } from "../app/modules/ProdFactory/_redux/products/productsSlice";
import { recordsSlice } from "../app/modules/MasterData/_redux/records/recordsSlice";
import { fieldsSlice } from "../app/modules/MasterDataConfiguration/_redux/fields/fieldsSlice";
import { entitiesSlice } from "../app/modules/MasterDataConfiguration/_redux/entities/entitiesSlice";
import { usersSlice } from "../app/modules/Identity/_redux/users/usersSlice";
import { modulesSlice } from "../app/modules/Identity/_redux/modules/modulesSlice";
import { groupsSlice } from "../app/modules/Identity/_redux/groups/groupsSlice";
import { errorsSlice } from "../app/modules/ErrorHandler/_redux/errorsSlice";
import { slice as collectionsSlice } from "../app/modules/Collections/_redux/slice";
import { slice as workflowsSlice } from "../app/modules/Workflows/_redux/slice";
import { slice as reportsSlice } from "../app/modules/ReportsFactory/_redux/slice";
import { slice as setsSlice } from "../app/modules/Ensemble/_redux/SetConfigurations/setsConfigurationSlice";
import { slice as setFlowsSlice } from "../app/modules/Ensemble/_redux/SetFlows/setFlowsSlice";
import { slice as agentsSlice } from "../app/modules/Kyc/_redux/agents/slice";
import { slice as importExcelSlice } from "../_custom/_partials/Dialog/ImportDialog/_redux/importExcelSlice";
import { slice as interviewSlice } from "../app/modules/Kyc/_redux/interviews/slice";
import { slice as customerSlice } from "../app/modules/Kyc/_redux/customers/slice";
import { slice as pensieveSlice } from "../app/modules/MasterDataConfiguration/_redux/customers/slice";
import { slice as allocationsSlice } from "../app/modules/Collections/_redux/allocations/slice";
import { slice as templateSlice } from "../app/modules/NotificationTemplates/_redux/slice";
import { slice as kiborSlice } from "../app/modules/Kibor/_redux/slice";
import { slice as platformSlice } from "../app/modules/MasterDataConfiguration/_redux/platform/slice";
import { slice as autoAssignmentSlice } from "../app/modules/AutoAssignment/redux/slice";
import { slice as logsManagerSlice } from "../app/modules/LogsManager/_redux/slice";
import {slice as dynamicSearchSlice} from "../app/modules/Customer360View/redux/slice";

const appReducer = combineReducers({
  auth: auth.reducer,
  products: productsSlice.reducer,
  fields: fieldsSlice.reducer,
  records: recordsSlice.reducer,
  users: usersSlice.reducer,
  entities: entitiesSlice.reducer,
  modules: modulesSlice.reducer,
  groups: groupsSlice.reducer,
  errors: errorsSlice.reducer,
  collections: collectionsSlice.reducer,
  workflows: workflowsSlice.reducer,
  reports: reportsSlice.reducer,
  sets: setsSlice.reducer,
  setFlows: setFlowsSlice?.reducer,
  agents: agentsSlice?.reducer,
  modal: importExcelSlice.reducer,
  interviews: interviewSlice?.reducer,
  customers: customerSlice?.reducer,
  pensieve: pensieveSlice?.reducer,
  allocations: allocationsSlice?.reducer,
  notificationTemplates: templateSlice?.reducer,
  kibor: kiborSlice?.reducer,
  platform: platformSlice.reducer,
  autoAssignments: autoAssignmentSlice.reducer,
  logsManager: logsManagerSlice.reducer,
  dynamicSearch: dynamicSearchSlice.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === "[Logout] Action") {
    localStorage.clear();

    return appReducer(
      {
        auth: {
          _persist: {
            version: -1,
            rehydrated: true
          }
        }
      },
      action
    );
  }
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
