import React from "react";
import { useField, useFormikContext } from "formik";
import { Overlay } from "../..//controls/forms/Overlay";
import { IconPicker } from "react-fa-icon-picker";

const getFieldCSSClasses = (touched, errors, isHideValidations) => {
    const classes = ["form-control"];

    if (!isHideValidations) {
        if (touched && errors) {
            classes.push("is-invalid");
        }

        if (touched && !errors) {
            classes.push("is-valid");
        }
    }

    return classes.join(" ");
};

export function IconPickerField(props) {
    const { setFieldValue, errors, touched } = useFormikContext();
    const [field] = useField(props);

    const handleRemoveIcon = () => {
        setFieldValue(field.name, ""); // Clear the value
    };


    return (
        <>
            {props.label && (
                <label>
                    {props.label}{" "}
                    {props.required ? <span className={"text-danger"}> *</span> : null}
                </label>
            )}
            {props.showDescription ? (
                <Overlay popoverContent={props.description}>
                    <i
                        className={
                            "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
                        }
                    />
                </Overlay>
            ) : null}
            <div className={`${getFieldCSSClasses(
                touched[field.name],
                errors[field.name],
                props.isHideValidations
            )} d-flex flex-row` }>
                <IconPicker
                    value={field?.value}
                    onChange={(v) => {
                        setFieldValue(field.name, v);
                    }}
                    disabled={props.isReadOnly || props.disabled}
                    containerStyles={{justifyContent: "start", width: "100%", border: "1px solid hsl(0,0%,76%)"}}
                    pickerIconStyles={{color: "grey", padding: "10px"}}
                    searchInputStyles={{border: "1px solid hsl(0,0%,76%)", backgroundColor: "hsl(0,0%,100%)", padding: "6.1px", width: "100%", borderRadius: "5px"}}
                />
                {field.value && (
                        <i className="icon icon-lg text-danger fa fa-trash d-flex align-items-center font-size-lg cursor-pointer" onClick={handleRemoveIcon}></i>
                )}
            </div>
            {!props.isHideValidations ? (
                <>
                    {errors[field.name] && touched[field.name] ? (
                        <div className="invalid-feedback d-block">
                            {errors[field.name].toString()}
                        </div>
                    ) : null}
                </>
            ) : null}
        </>
    );
}
