import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import ReactExport from "react-export-excel";
import moment from "moment";
import {
  AccountStatusTitles,
  InterviewStatusTitles,
  Status,
  StatusTitles
} from "../../grids/UIHelpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getFormattedNumber,
  getNumberInCurrencyFormat
} from "../../../_helpers";
import { inputTypes } from "../../../_helpers/commonStaticVariables";

export function Export({
  names,
  lists,
  user,
  loader,
  overrideData,
  overrideSheetName = "",
  testUserModal
}) {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { impersonateUser, allUsers, entityConfiguration } = useSelector(
    state => ({
      impersonateUser: state.users.impersonateUser,
      allUsers: state.users.allUsers || [],
      entityConfiguration: state.entities.entityForEdit
    }),
    shallowEqual
  );

  const createFormattedArray = () => {
    let tempData = [...lists];
    let exportDataTemp = [];
    (tempData || []).forEach(item => {
      let dataUpdated = {};
      for (let [key, value] of Object.entries(item)) {
        let valueUpdated = value;
        let fieldType = inputTypes.find(
          field => field.id === overrideData?.fieldsDictionary?.[key]?.dataType
        )?.type;
        let masterDataType = (overrideData?.fieldsConfig || []).find(
          item => item?.code === key
        )?.masterDataType;
        if (fieldType === "checkbox") {
          valueUpdated = Boolean(valueUpdated) ? "Yes" : "No";
        } else if (["select", "multiselect"].includes(fieldType)) {
          let allFieldRecords =
            overrideData?.fieldsDictionary?.[key]?.records ||
            overrideData?.allRecords?.[masterDataType]?.records ||
            overrideData?.allRecords?.[masterDataType]?.recordsForGrid ||
            (Array.isArray(overrideData?.allRecords?.[masterDataType])
              ? overrideData?.allRecords?.[masterDataType]
              : []);
          if (fieldType === "select") {
            valueUpdated = allFieldRecords.find(
              rec => rec.code === valueUpdated
            )?.name;
            if (valueUpdated === false) {
              valueUpdated =
                (valueUpdated || "N/A") +
                `${valueUpdated ? " (" + valueUpdated + ")" : ""}`;
            }
          } else if (fieldType === "multiselect") {
            while (typeof valueUpdated !== "object")
              valueUpdated = JSON.parse(valueUpdated);
            let filteredRecords = allFieldRecords.filter(rec =>
              (valueUpdated || []).find(item => item === rec.code)
            );
            valueUpdated = filteredRecords.map(
              (eachRec, index) =>
                `${eachRec.code}${
                  index < filteredRecords.length - 1 ? ", " : ""
                }`
            );
          }
        } else if (fieldType === "datePicker") {
          valueUpdated =
            valueUpdated && moment(valueUpdated)?.isValid()
              ? moment(valueUpdated)
                  .format("LL")
                  ?.toString()
              : "";
        } else if (["timePicker", "dateTimePicker"].includes(fieldType)) {
          valueUpdated =
            valueUpdated && moment(valueUpdated)?.isValid()
              ? moment
                  .utc(valueUpdated)
                  .toDate()
                  .toString()
              : "";
        } else if (
          valueUpdated !== null &&
          valueUpdated !== undefined &&
          typeof valueUpdated === "object"
        ) {
          if (overrideData.exportParameters) {
            overrideData.exportParameters.forEach(item => {
              if (item.code === key) {
                valueUpdated =
                  value[item.valueCode] ||
                  (valueUpdated || "")?.toString() ||
                  "";
              }
            });
          } else {
            valueUpdated = (valueUpdated || "")?.toString() || "";
          }
        }
        if (key === "status") {
          valueUpdated = InterviewStatusTitles[value || 0];
        }
        let tags = (overrideData?.fieldsConfig || []).find(
          item => item?.code === key
        )?.tags;
        if (!tags) tags = [];
        if ((tags || []).find(tg => tg === "maskedCNIC")) {
          valueUpdated = valueUpdated || "N/A";
        } else {
          if ((tags || []).find(tg => tg === "FormattedNumber")) {
            valueUpdated = getFormattedNumber(valueUpdated || 0);
          }
          if ((tags || []).find(tg => tg === "amount")) {
            valueUpdated = getNumberInCurrencyFormat({
              number: valueUpdated || 0,
              appendCurrency: true,
              isWholeNumber: !!(tags || []).find(item => item === "WholeNumber")
            });
          }
        }
        value = valueUpdated || "N/A";
        dataUpdated = { ...dataUpdated, [key]: value };
      }
      exportDataTemp.push(dataUpdated);
    });
    return exportDataTemp || [];
  };
  const generatePDF = () => {
    if (overrideData?.formattedList) {
      lists = createFormattedArray();
    }
    // define the columns we want and their titles
    const tableColumn = overrideData?.override
      ? overrideData.exportParameters
          .filter(data => !data?.isHiddenPdf)
          .map(data => data.code)
      : ["Name", "Code", "Order", "Status", "Created", "Updated", "Modified"];
    // define an empty array of rows
    const tableRows = [];
    //Override Object is used to do custom pdf and excel otherwise standard export options
    lists.forEach(item => {
      let listData = [];
      if (overrideData?.override) {
        overrideData.exportParameters = overrideData.exportParameters.filter(
          data => !data?.isHiddenPdf
        );
        overrideData.exportParameters.forEach(data => {
          if (
            data.code === "status" &&
            (item?.[data?.code] === 1 ||
              item?.[data?.code] === 2 ||
              item?.[data?.code] === 0)
          ) {
            listData.push(AccountStatusTitles[item?.[data?.code]]);
          } else if (data?.code === "assignTo") {
            listData.push(
              overrideData?.allUsers?.find(
                user => user?.id === item?.[data?.code]
              )?.code || ""
            );
          } else {
            listData.push(item[data?.code]);
          }
        });
      } else {
        listData = [
          item.name,
          item.code,
          item.order,
          item.status === Status.SFA
            ? item.actor === user?.id
              ? "Sent for Approval"
              : "Awaiting Approval"
            : StatusTitles[item.status],
          moment(item.createdAt).format("DD MMMM YYYY, hh:mm a"),
          item?.lastUpdated || item?.lastUpdateDateTime
            ? moment(item?.lastUpdated || item?.lastUpdateDateTime).format(
                "Do MMM YYYY, hh:mm a"
              )
            : "N/A",
          (allUsers || []).find(
            user => user?.id === item?.actor || user?.id === item?.lastUpdatedBy
          )?.code
        ];
      }
      tableRows.push(listData);
    });

    var doc = new jsPDF(tableColumn?.length > 5 ? "l" : "p");
    var header = function() {
      doc.setFontSize(20);
      doc.setTextColor(100);
      doc.text(names, 16, 14);
    };
    var options = {
      beforePageContent: header,
      margin: {
        top: 25
      },
      columnStyles: { text: { cellWidth: "auto" } },
      theme: "grid",
      startY: 25,
      bodyStyles: { valign: "top" },
      headStyles: { fillColor: "#006e99" }
    };

    if (tableColumn?.length > 6) {
      options = {
        ...options,
        horizontalPageBreak: "auto",
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        rowPageBreak: "auto"
      };
    }
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, options);

    // we define the name of our PDF file.
    doc.save(`${names}-List.pdf`);
  };
  const copiedListData = JSON.stringify(lists);

  const createCollectionsList = () => {
    let tempData = [...lists];
    tempData.forEach(item => {
      if (item?.assignTo) {
        item["assignTo"] =
          overrideData?.allUsers?.find(value => value?.id === item?.assignTo)
            ?.code || item?.assignTo;
      }
      if (item?.status === 1 || item?.status === 2 || item?.status === 0) {
        item["status"] = AccountStatusTitles[item?.status || 0];
      }
    });
    return tempData;
  };
  // console.log(lists)
  return (
    <>
      <div className="btn-group" style={{ paddingLeft: "5px" }}>
        <button
          type="button"
          className={`btn btn-primary ${
            overrideData?.smallButton ? "btn-sm" : ""
          } dropdown-toggle ${loader || !lists?.length ? "disabled" : ""}`}
          data-toggle="dropdown"
        >
          Export
        </button>
        <ul className="dropdown-menu">
          {impersonateUser ? (
            <li onClick={testUserModal}>
              <span className="dropdown-item cursor-pointer">Excel</span>
            </li>
          ) : (
            <>
              {!overrideData?.override ? (
                <ExcelFile
                  filename={names}
                  element={
                    <li>
                      <span className="dropdown-item cursor-pointer">
                        Excel
                      </span>
                    </li>
                  }
                >
                  <ExcelSheet data={lists} name={overrideSheetName || names}>
                    <ExcelColumn label="name" value="name" />
                    <ExcelColumn label="code" value="code" />
                    <ExcelColumn label="status" value="status" />
                    <ExcelColumn label="isHidden" value="isHidden" />
                    <ExcelColumn label="order" value="order" />
                    {(entityConfiguration?.fields || []).map(field => (
                      <ExcelColumn
                        key={field}
                        label={"fields_" + field}
                        value={col => col?.values?.[field] || ""}
                      />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              ) : (
                <ExcelFile
                  filename={names}
                  element={
                    <li>
                      <span className="dropdown-item cursor-pointer">
                        Excel
                      </span>
                    </li>
                  }
                >
                  <ExcelSheet
                    data={
                      overrideData?.formattedList
                        ? createFormattedArray
                        : overrideData?.collectionList
                        ? createCollectionsList
                        : lists
                    }
                    name={overrideSheetName || names}
                  >
                    {overrideData?.exportParameters.map(data => {
                      return (
                        <ExcelColumn
                          label={
                            overrideData?.shouldUseNameInLabel
                              ? data?.name
                              : data?.code
                          }
                          value={data?.code}
                        />
                      );
                    })}
                  </ExcelSheet>
                </ExcelFile>
              )}
            </>
          )}

          <li>
            <span
              className="dropdown-item cursor-pointer"
              onClick={!impersonateUser ? generatePDF : testUserModal}
            >
              PDF
            </span>
          </li>
          <CopyToClipboard text={copiedListData}>
            <li>
              <span className="dropdown-item cursor-pointer">Copy</span>
            </li>
          </CopyToClipboard>
        </ul>
      </div>
    </>
  );
}
