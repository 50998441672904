import React, { useState } from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange } from "../../../_metronic/_helpers";
// import { sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";

export function Relations({listData, listLoading, totalCount = 0, user, permissionsObject = {}, queryParams, setQueryParams, uiEvents}) {
  const [openedRow, setOpenedRow] = useState(null);
  const columns = [
    {
      dataField: "code",
      text: "Card Number",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        parameters: {
          identifier: "code",
          level1: "code",
          isHideOptions: true
        },
        onViewPage: uiEvents.openCollection,
        openedRow: openedRow,
        setOpenedRow: setOpenedRow,
        permissionsObject: permissionsObject
      }
    }, {
      dataField: "Name",
      text: "Name",
      formatter: columnFormatters.GenericDynamicFormatter,
      formatExtraData: {
        parameters: {
          identifier: "NameOnCard",
          objectName: "values"
        },
      }
    },
    {
      dataField: "CardCustId",
      text: "Customer Id",
      formatter: columnFormatters.GenericDynamicFormatter,
      formatExtraData: {
        parameters: {
          identifier: "CardCustID",
          objectName: "values"
        },
      }
    }, {
      dataField: "Card Indicator",
      text: "Card Indicator",
      formatter: columnFormatters.GenericDynamicFormatter,
      formatExtraData: {
        parameters: {
          identifier: "CardBSInd",
          objectName: "values"
        },
      }
    },
  ];
  let paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams?.pageSize,
    page: queryParams?.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };


  return (
    <>
      <DataGrid
        data={listData}
        listLoading={listLoading}
        columns={columns}
        paginationOptions={paginationOptions}
        type={queryParams.type}
        onTableParamsChange={getHandlerTableChange(setQueryParams)}
        isCheckbox={false}
        keyField='code'
      />
    </>
  );
}
