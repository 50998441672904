import * as requestFromServer from "./recordsCrud";
import { callTypes, recordsSlice } from "./recordsSlice";

const { actions } = recordsSlice;
export const fetchRecords = params => dispatch => {
  const orderingParams = params.paginationOverride
    ? { orderBy: "order", orderType: 1 }
    : {};
  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.list
    })
  );

  let { fieldCode, ...rest } = params;
  return requestFromServer
    .getAllRecords({ ...rest, ...orderingParams })
    .then(response => {
      const { items: records, paging } = response?.data;
      dispatch(
        actions.recordsFetched({
          entityCode: params.entityCode,
          paging,
          records,
          forGrid: params.forGrid,
          fieldCode
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch records";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.list
        })
      );
    });
};

export const clearRecordsList = params => dispatch => {
  dispatch(actions.clearRecordsList({ entityCode: params.entityCode }));
};

export const clearIndividualRecord = params => dispatch => {
  dispatch(actions.clearIndividualRecord({ entityCode: params.entityCode }));
};

export const fetchRecord = params => dispatch => {
  if (!params || !params.recordId) {
    return dispatch(
      actions.recordFetched({
        entityCode: params.entityCode,
        recordForEdit: undefined
      })
    );
  }

  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.action
    })
  );
  return requestFromServer
    .getRecordById(params)
    .then(response => {
      const record = response.data;
      if (params?.type === "PrevState") {
        dispatch(
          actions.prevRecordFetched({
            entityCode: params.entityCode,
            recordForEdit: record
          })
        );
      } else {
        dispatch(
          actions.recordFetched({
            entityCode: params.entityCode,
            recordForEdit: record
          })
        );
      }

      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find record";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.action
        })
      );
    });
};

export const deleteRecord = params => dispatch => {
  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.action
    })
  );
  return requestFromServer
    .deleteRecord(params)
    .then(response => {
      if (!params?.isBulk) {
        dispatch(actions.recordDeleted({ entityCode: params.entityCode }));
      }
      if (params?.isBulk && params?.lastCall) {
        dispatch(actions.recordDeleted({ entityCode: params.entityCode }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete record";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.action
        })
      );
    });
};

export const createRecord = params => dispatch => {
  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.action
    })
  );
  return requestFromServer
    .createRecord(params)
    .then(response => {
      const { record } = response?.data || {};
      dispatch(
        actions.recordCreated({ entityCode: params.entityCode, record })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create record";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.action
        })
      );
      return error?.response;
    });
};

export const updateRecord = params => dispatch => {
  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.action
    })
  );
  return requestFromServer
    .updateRecord(params)
    .then(response => {
      dispatch(actions.recordUpdated(params));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update record";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.action
        })
      );
      return error?.response;
    });
};

export const actOnDraft = params => dispatch => {
  dispatch(
    actions.startCall({
      entityCode: params.entityCode,
      callType: callTypes.action
    })
  );
  return requestFromServer
    .actOnDraft(params)
    .then(response => {
      const { record } = response.data;
      dispatch(actions.actonDrafted({ entityCode: params.entityCode, record }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(
        actions.catchError({
          entityCode: params.entityCode,
          error,
          callType: callTypes.action
        })
      );
    });
};

export const auditRecords = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditRecords(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(
        actions.auditLogsFetched({ entityCode: params.entityCode, auditLogs })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch trail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAuditsById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAuditsById(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(
        actions.auditLogsFetchedById({
          entityCode: params?.entityCode,
          auditLogs,
          params
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch trail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const overrideRecords = params => dispatch => {
  dispatch(actions.allRecordsUpdated(params));
};
export const clearParentFieldRecords = params => dispatch => {
  const { entityCode, parentField } = params;
  dispatch(
    actions.clearParentFieldRecords({
      entityCode,
      parentField
    })
  );
};

export const getSelectedFile = (
  file,
  channelId,
  productCode,
  traceId
) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getImportFile(file, channelId, productCode, traceId)
    .then(response => {
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
