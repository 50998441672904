import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  clientCredentials,
  forceChangePassword,
  getPasswordPolicy,
  Logout,
  userActivation
} from "../_redux/authCrud";
import { useHistory, useLocation } from "react-router-dom";
import { successNotification } from "../../../../_custom/_partials/notifications";
import ReactHtmlParser from "react-html-parser";
import { passwordHelper } from "../../../../_custom/_helpers";
import { debounce } from "lodash";

const initialValues = {
  password: ""
};

function Password(props) {
  const { intl } = props;
  let PasswordSchema = Yup.object().shape({
    password: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    )
  });

  const { changePassword, uniqueIdentifier } = useSelector(
    state => ({
      changePassword: state.auth?.changePassword,
      uniqueIdentifier: state.auth.uniqueIdentifier
    }),
    shallowEqual
  );

  const [schema, setSchema] = useState(PasswordSchema);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    localStorage.clear();
    if (!changePassword && location?.pathname?.includes("change-password")) {
      history.push("/auth/login");
    }
    if (
      changePassword &&
      history?.action === "POP" &&
      location?.pathname?.includes("change-password")
    ) {
      localStorage.clear();
      props.logout();
      history.push("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedEffect = debounce(() => {
    clientCredentials().then(response => {
      if (response?.status === 200) {
        let headers = {
          authorization: `Bearer ${response?.data?.access_token}`,
          accessControlAllowOrigin: "*",
          "Content-type": "application/json"
        };
        getPasswordPolicy({
          entityCode: "pass_policy",
          type: 0,
          paginationOverride: true,
          headers
        }).then(res => {
          if (res?.status === 200) {
            let helperText = res?.data?.items?.[0]?.values?.helperText;
            let minLength = res?.data?.items?.[0]?.values?.min_length;
            let maxLength = res?.data?.items?.[0]?.values?.max_length;
            let helperTextUpdated = passwordHelper(
              helperText,
              minLength,
              maxLength
            );
            setHelperText(helperTextUpdated || "");

            let schema = Yup.object().shape({
              password: Yup.string()
                .min(minLength, `Minimum ${minLength} characters`)
                .max(maxLength, `Maximum ${maxLength} characters`)
                .required("Required Field")
            });
            setSchema(schema);
            setIsFetching(false);
          } else {
            setIsFetching(false);
          }
        });
      } else {
        setIsFetching(false);
      }
    });
  }, 0);

  useEffect(() => {
    debouncedEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [helperText, setHelperText] = useState("");

  let location = useLocation();
  let history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      if (location?.pathname?.includes("change-password")) {
        let headers = {
          Authorization: `Bearer ${changePassword?.accessToken}`
        };
        forceChangePassword(
          changePassword?.currentPassword,
          values?.password,
          uniqueIdentifier,
          headers
        )
          .then(response => {
            if (response?.status !== 200) {
              disableLoading();
              setSubmitting(false);
              setStatus(response?.data?.message || "Unexpected error occurred");
            } else {
              successNotification("Password changed successfully");
              disableLoading();
              setSubmitting(false);
              Logout(changePassword?.userId, uniqueIdentifier);
              props.logout();
              localStorage.clear();
              history.push("/auth/login");
            }
          })
          .catch(error => {
            setSubmitting(false);
            setStatus(
              error?.response?.data?.message || "Unexpected error occurred"
            );
            disableLoading();
          });
      } else {
        clientCredentials()
          .then(response => {
            if (response?.status !== 200) {
              disableLoading();
              setSubmitting(false);
              setStatus(response?.data?.message || "Unexpected error occurred");
            } else {
              let headers = {
                Authorization: `Bearer ${response?.data?.access_token}`
              };
              userActivation(
                location?.pathname?.split("/")?.reverse()?.[0],
                values?.password,
                headers
              )
                .then(response => {
                  if (response?.status !== 200) {
                    setSubmitting(false);
                    setStatus(
                      response?.data?.message ||
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN"
                        })
                    );
                    disableLoading();
                  } else {
                    successNotification(
                      location?.pathname?.includes("activate")
                        ? "Your account password has been set successfully"
                        : "Your password has been changed successfully"
                    );
                    history.push("/auth/login");
                    setSubmitting(false);
                    disableLoading();
                  }
                })
                .catch(error => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    error?.response?.data?.message ||
                      "Unexpected error occurred"
                  );
                });
            }
          })
          .catch(error => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              error?.response?.data?.message || "Unexpected error occurred"
            );
          });
      }
    }
  });

  return (
    <div>
      {isFetching ? (
        <div className="customer-loader">
          <div className="spinner spinner-track spinner-primary" />
        </div>
      ) : (
        <div className="login-form login-signin" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-10">
            <h3 className="font-size-h1">
              {location?.pathname?.includes("activate") && "Activate Account"}
            </h3>
            <p className="text-muted font-weight-bold">
              {location?.pathname?.includes("activate")
                ? "Enter a password to activate your account"
                : location?.pathname?.includes("change-password")
                ? "Current Password has expired. Please enter a new password"
                : "Please enter a new password"}
            </p>
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password*"
                disabled={isFetching}
                type={showPassword ? "text" : "password"}
                className={`form-control h-auto form-control-solid py-4 px-8 override-padding-right-eye ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />

              <button
                hidden={!formik?.values?.password}
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className={"float-right password-eye"}
              >
                {!showPassword ? (
                  <i className="fa fa-eye-slash" aria-hidden="true" />
                ) : (
                  <i className="fa fa-eye" aria-hidden="true" />
                )}
              </button>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
              {helperText && (
                <div className="mt-5 ml-0 alert alert-custom alert-light-warning alert-dismissible">
                  <div className="alert-text text-left font-weight-bold ml-n5">
                    {ReactHtmlParser(helperText || "")}
                  </div>
                </div>
              )}
            </div>
            {/* end: Password */}

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Password));
