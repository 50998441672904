import { createSlice } from "@reduxjs/toolkit";

const initialReportsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  reportDeleted: false,
  allReports: undefined
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reportFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.reportForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    reportsFetched: (state, action) => {
      const {totalCount, entities, paginationOverride} = action.payload;
      state.listLoading = false;
      state.error = null
      state.makerCheckerAction = false;
      state.reportDeleted = false;
      if (paginationOverride) {
        state.allReports = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearReportsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    reportCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    reportUpdated: (state, action) => {
      const {report} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = report;
    },
    reportDeleted: (state) => {
      state.reportDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
  }
});
