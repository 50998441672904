import axios from "axios";

const REGISTER_URL = "api/auth/register";
const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const IDENTITY_URL = "/identity/api/v1.0";
const QUANTUM_URL = "/quantum/api/v1.0";
export const AUTH_BODY = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  institutionId: process.env.REACT_APP_INSTITUTION_ID,
  provisionKey: process.env.REACT_APP_PROVISION_KEY
};

export function login(userName, password) {
  return axios.post(`${IDENTITY_URL}/oauth/userLogin`, {
    grantType: "password",
    ...AUTH_BODY,
    userName,
    password
  });
}

export function validateOTP(Otp) {
  return axios.post(`${IDENTITY_URL}/oauth/OtpValidation`, {
    grantType: "password",
    ...AUTH_BODY,
    Otp
  });
}

export function resendOTP() {
  return axios.post(`${IDENTITY_URL}/oauth/resendotp`);
}

export function Logout(userId, uniqueIdentifier) {
  return axios.post(`${IDENTITY_URL}/oauth/Logout`, {
    userId,
    uniqueIdentifier
  });
}

export function clientCredentials() {
  return axios.post(`${IDENTITY_URL}/oauth/token`, {
    grantType: "client_credentials",
    ...AUTH_BODY
  });
}

export function getPasswordPolicy({ entityCode, paginationOverride, headers }) {
  return axios.get(
    `${QUANTUM_URL}/records/${entityCode}?paginationOverride=${paginationOverride}`,
    { headers }
  );
}

export function userActivation(activationCode, password, headers) {
  return axios.post(
    `${IDENTITY_URL}/oauth/Activate`,
    { activationCode, password },
    { headers }
  );
}

export function forgetPassword(email, headers) {
  return axios.post(
    `${IDENTITY_URL}/clientCredentialsBasedUsers/ForgetPassword`,
    { email },
    { headers }
  );
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(id) {
  return axios.get(`${IDENTITY_URL}/users/${id}`);
}

export function forceChangePassword(
  oldPassword,
  newPassword,
  uniqueIdentifier,
  headers
) {
  return axios.post(
    `${IDENTITY_URL}/users/ChangePassword`,
    { oldPassword, newPassword, uniqueIdentifier },
    { headers }
  );
}
