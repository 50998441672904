/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, FormControl, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { errorNotification } from "../../notifications";

export const PriorityColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {totalRows, viewType, onClickSwap, onClickPlace}
) => {

  const priorityOverlayRef = React.createRef()

  const toggleOverlay = () => priorityOverlayRef.current?.click();

  const popover = (
    <Popover id="priority-popover">
      <Popover.Title as="h3">Adjust Priority</Popover.Title>
      <Popover.Content>
        {
          totalRows.length >= 2
            ?
            <>
              <InputGroup size={"sm"} className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Swap With</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl id={"swapPriorityInput"} defaultValue={row.priority} min={1} max={totalRows.length} required type="number" placeholder="enter priority to swap with"
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={e => {
                    const numberRegex = /^\d+$/;
                    let value = document.getElementById("swapPriorityInput").value;
                    if (!Number(value) || !numberRegex.test(value)) errorNotification("Please enter valid whole number");
                    else if (value < 0) errorNotification("Number cannot be lesser than 0");
                    else {
                      if(Number(value + "") > totalRows.length) value = totalRows.length;
                      onClickSwap(row.priority - 1, Number(value - 1))
                      toggleOverlay();
                    }
                    document.getElementById("swapPriorityInput").value = row.priority;
                  }}>Swap</Button>
                </InputGroup.Append>
              </InputGroup>

              <InputGroup size={"sm"} className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Place At</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl id={"placePriorityInput"} defaultValue={row.priority} min={1} max={totalRows.length} required type="number" placeholder="enter priority to place at"/>
                <InputGroup.Append>
                  <Button variant="primary" onClick={e => {
                    const numberRegex = /^\d+$/;
                    let value = document.getElementById("placePriorityInput").value;
                    if (!Number(value) || !numberRegex.test(value)) errorNotification("Please enter valid whole number");
                    else if (value < 0) errorNotification("Number cannot be lesser than 0");
                    else {
                      if(Number(value + "") > totalRows.length) value = totalRows.length;
                      onClickPlace(row.priority - 1, Number(value - 1))
                      toggleOverlay();
                    }
                    document.getElementById("placePriorityInput").value = row.priority;
                  }}>Place</Button>
                </InputGroup.Append>
              </InputGroup>
            </>
            :
            <p>This tool can be used to adjust <strong>priority</strong> when two or more rows are available.</p>
        }
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <span className="font-weight-normal">{row.priority}</span>
      {
        viewType !== "view" ?
          <OverlayTrigger rootClose trigger="click" placement="top" overlay={popover}>
            <a className="float-right mr-5 svg-icon svg-icon-primary svg-icon-lg"
               ref={priorityOverlayRef}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-down.svg")}/>
            </a>
          </OverlayTrigger>
          :
          null
      }
    </>
  )
}
