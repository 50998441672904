// packages
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Utils as QbUtils } from "react-awesome-query-builder";

// custom components
import {
  CodeInput,
  ColorPicker,
  DatePickerField,
  Input,
  ReactCreatableSelect,
  ReactSelect,
  ReactSelectDraggable,
  Switch
} from "../../../../_custom/_partials/controls";

// functions
import { getRestrictions, validateColorCode } from "../../../_helpers";
import { inputTypes } from "../../../_helpers/commonStaticVariables";
import {
  queryBuilderDefaultExpressions,
  queryBuilderQueryTypeEnums
} from "../../../_helpers/staticFields";
import { QueryBuilder } from "./QueryBuilder";
import { errorNotification, warningNotification } from "../../notifications";
import { shallowEqual, useSelector } from "react-redux";
import { fieldLayoutTitles } from "../../grids/UIHelpers";
import {IconPickerField} from "./IconPickerField";

const getHumanReadableQuery = (tree, config) =>
  QbUtils.queryString(tree, config, true);
const getSQLQuery = (tree, config) => QbUtils.sqlFormat(tree, config);
const getMongoDBQuery = (tree, config) => QbUtils.mongodbFormat(tree, config);
const getExpressionTreeQuery = (tree, config) =>
  QbUtils.queryString(tree, config);
const getJSONLogicQuery = (tree, config) =>
  JSON.stringify(QbUtils.jsonLogicFormat(tree, config)?.logic || {});

export default function DynamicForm({
  viewType,
  allFields,
  fieldsList,
  formValues,
  validationSchema,
  dropdownOptions,
  submitButtonText,
  onSubmit,
  cancelButtonText,
  onClickCancel,
  saveButtonId,
  onSaveFormCallback,
  handleDynamicTab,
  filterAuthenticationFromStages,
  invertCondition
}) {
  const { fieldsDictionary } = useSelector(
    state => ({
      fieldsDictionary: state.fields?.fieldsDictionary
    }),
    shallowEqual
  );
  const [formKey, setFormKey] = useState(Math.random());
  useEffect(() => {
    setFormKey(Math.random());
  }, [formValues]);

  const getDropdownOptions = (masterDataType, code) =>
    (
      dropdownOptions?.[masterDataType]?.records ||
      fieldsDictionary?.[code]?.records ||
      []
    ).map(({ name, code, entityName, entityCode }) => ({
      label: (entityCode || code) + ": " + (entityName || name),
      value: entityCode || code
    }));

  const getDropdownSelectedValue = (options, selectedOption, dropdownType) => {
    if (selectedOption === null || selectedOption === undefined)
      return undefined;

    if (dropdownType === "single") {
      const selOption = options.find(item => item.value === selectedOption);
      return selOption || "undefined";
    }

    if (dropdownType === "multi") {
      let selectedOptionArray = Array.isArray(selectedOption)
        ? selectedOption
        : selectedOption.split("!").filter(val => val !== "");
      const selOption = selectedOptionArray.map(item =>
        (options || []).find(opt => opt?.value === item)
      );
      return selOption?.filter(option => option !== "all");
    }

    return undefined;
  };

  const onChangeDropdown = (
    options,
    fieldCode,
    setFieldValue,
    formValues,
    valueAsString,
    valueAsInt = false
  ) => {
    if (!options) {
      setFieldValue(fieldCode, options);
      return;
    }
    if (Array.isArray(options)) {
      if (valueAsString) {
        let selOptions = "";
        options.forEach(opt => (selOptions += opt.value + "!"));
        setFieldValue(fieldCode, selOptions);
      } else if (valueAsInt) {
        const selOptions = options.map(item => Number(item.value));
        setFieldValue(fieldCode, selOptions);
      } else {
        const selOptions = options.map(item => item.value);
        setFieldValue(fieldCode, selOptions);
      }
    } else {
      setFieldValue(fieldCode, options.value);
    }
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };

  const onChangeQueryBuilder = (
    { tree, config },
    code,
    representations,
    formik
  ) => {
    const expressionTree = JSON.stringify(QbUtils.getTree(tree));
    const expressionValue = {
      expressionTree,
      representations: (representations || queryBuilderDefaultExpressions).map(
        rep => {
          if (rep === "Mongo")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getMongoDBQuery(tree, config)
            };
          else if (rep === "SQL")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getSQLQuery(tree, config)
            };
          else if (rep === "HR")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getHumanReadableQuery(tree, config)
            };
          else if (rep === "JSONLogic")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getJSONLogicQuery(tree, config)
            };
          else if (rep === "Tree")
            return {
              representationType: queryBuilderQueryTypeEnums[rep],
              representationValue: getExpressionTreeQuery(tree, config)
            };
          else
            return {
              representationType: queryBuilderQueryTypeEnums["HR"],
              representationValue: getHumanReadableQuery(tree, config)
            };
        }
      )
    };
    formik.setFieldValue(
      code,
      Object.keys(QbUtils.getTree(tree)?.children1)?.length !== 0
        ? expressionValue
        : null
    );
  };

  const getRenderableFields = (formik, fieldList) => {
    // // if fields have been calculated, return from here
    // if (renderableFields) return renderableFields;

    // calculate fields if not done before
    // will only come here when skeletons are not to be shown AND fields have not been calculated i.e
    // only on initial render!
    // setRenderableFields(fieldsToRender);
    return fieldList.map(field => {
      const {
        label,
        name,
        type,
        dataType,
        masterDataType,
        isReadOnly,
        isRequired,
        disabled,
        hidden,
        tags,
        code,
        minLength,
        maxLength,
        valueAsString,
        representations,
        defaultValue,
        valueAsInt,
        forDynamicTab,
        dataSource,
        filterAuthentication,
        layout
      } = field;

      const inputType = inputTypes[dataType].type;
      const restrictions = getRestrictions(
        code,
        { isRequired, disabled, hidden, tags },
        viewType,
        true
      );
      switch (inputType) {
        case "conditionBuilder":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-12"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <>
                    <QueryBuilder
                      heading={name}
                      isHeading
                      isRequired={isRequired}
                      key={"qb" + code}
                      fieldsList={allFields}
                      dropdownOptions={dropdownOptions}
                      expression={
                        field.value?.representations?.[0]?.representationValue
                      }
                      queryValue={field.value?.expressionTree}
                      onChange={props =>
                        onChangeQueryBuilder(
                          props,
                          code,
                          representations,
                          formik
                        )
                      }
                      invertCondition={invertCondition}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "text":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) =>
                  code === "code" ? (
                    <CodeInput
                      field={field}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={type}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      maxLength={maxLength}
                      onChange={field.onChange}
                      {...restrictions}
                    />
                  ) : (
                    <Input
                      field={field}
                      form={form}
                      meta={meta}
                      label={name}
                      name={code}
                      type={type}
                      placeholder={name || code}
                      withFeedbackLabel={true}
                      minLength={minLength}
                      maxLength={maxLength}
                      onChange={field.onChange}
                      {...restrictions}
                    />
                  )
                }
              </Field>
            </div>
          );

        case "number":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Input
                    field={field}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    type={type}
                    tags={tags}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                  />
                )}
              </Field>
            </div>
          );

        case "select":
          let options = getDropdownOptions(masterDataType, code);
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {/*{name && <label>{name}</label>}*/}
                    {name && <label>{name} </label>}
                    {isRequired ? (
                      <span className={"text-danger"}> *</span>
                    ) : null}
                    <ReactSelect
                      label={name}
                      name={code}
                      placeholder={name}
                      touched={meta.touched}
                      disabled={disabled}
                      isReadOnly={isReadOnly}
                      isRequired={isRequired}
                      meta={meta}
                      error={
                        isRequired &&
                        !getDropdownSelectedValue(
                          options,
                          field.value,
                          "single"
                        )
                          ? `${name} is required`
                          : null
                      }
                      onError={formik.setFieldError}
                      value={getDropdownSelectedValue(
                        options,
                        field.value,
                        "single"
                      )}
                      options={options}
                      onChange={option => {
                        onChangeDropdown(
                          option || "",
                          code,
                          formik.setFieldValue,
                          form.values
                        );
                        if (filterAuthentication) {
                          formik.setFieldValue("authenticationCode", "");
                          filterAuthenticationFromStages(option);
                        }
                      }}
                      isClearable
                      isSearchable
                      withFeedbackLabel
                      {...restrictions}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "multiselect":
          const multiOptions = [
            { label: "Select All", value: "all" },
            ...getDropdownOptions(masterDataType, code)
          ];
          if (dataSource === "friday" && masterDataType === "reports") {
            // multiOptions = multiOptions.filter(item => item.)
          }
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {/*{name && <label>{name}</label>}*/}
                    {name && <label>{name} </label>}
                    {isRequired ? (
                      <span className={"text-danger"}> *</span>
                    ) : null}
                    <ReactSelectDraggable
                      label={name}
                      name={code}
                      placeholder={name}
                      touched={meta.touched}
                      isReadOnly={isReadOnly}
                      isRequired={isRequired}
                      meta={meta}
                      error={
                        isRequired &&
                        !getDropdownSelectedValue(
                          options,
                          field.value || defaultValue,
                          "multi"
                        )?.length
                          ? `${name} is required`
                          : null
                      }
                      onError={formik.setFieldError}
                      value={getDropdownSelectedValue(
                        multiOptions,
                        field.value,
                        "multi"
                      )}
                      options={
                        field?.value?.length === multiOptions?.length - 1
                          ? multiOptions?.filter(data => data?.value !== "all")
                          : multiOptions
                      }
                      onBlur={() => {
                        let options = getDropdownSelectedValue(
                          multiOptions,
                          field.value,
                          "multi"
                        );
                        if (forDynamicTab) {
                          let selOptions = (options || [])?.map(
                            item => item?.value
                          );
                          handleDynamicTab(selOptions || []);
                        }
                      }}
                      onChange={option => {
                        let isAllOption = option?.some(
                          option => option?.value === "all"
                        );
                        if (
                          maxLength > 0
                            ? getDropdownSelectedValue(
                                multiOptions,
                                option,
                                "multi"
                              )?.length > maxLength ||
                              (isAllOption &&
                                multiOptions?.length - 1 > maxLength)
                            : false
                        ) {
                          warningNotification(
                            `You can only select upto ${maxLength} options`
                          );
                        } else {
                          onChangeDropdown(
                            isAllOption
                              ? (multiOptions || [])?.filter(
                                  data => data?.value !== "all"
                                )
                              : option || "",
                            code,
                            formik.setFieldValue,
                            form.values,
                            valueAsString
                          );
                        }
                      }}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        let newArray = arrayMove(
                          getDropdownSelectedValue(
                            multiOptions,
                            field.value,
                            "multi"
                          ) || "",
                          oldIndex,
                          newIndex
                        );
                        onChangeDropdown(
                          newArray,
                          code,
                          formik.setFieldValue,
                          form.values,
                          valueAsString
                        );
                      }}
                      isMulti
                      isClearable
                      isSearchable
                      closeMenuOnSelect={false}
                      withFeedbackLabel
                      {...restrictions}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        case "switch":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <Switch
                    classNames={"checkbox-primary checkbox-lg"}
                    field={field}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    type={inputType}
                    onError={formik.setFieldError}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "datePicker":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    field={field}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    type={type}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    dateFormat="dd-MMMM-yyyy"
                    defaultValue={defaultValue}
                  />
                )}
              </Field>
            </div>
          );

        case "dateTimePicker":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <DatePickerField
                    field={field}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    type={type}
                    placeholder={name || code}
                    withFeedbackLabel={true}
                    {...restrictions}
                    onChange={field.onChange}
                    dropdownMode={"select"}
                    showTimeSelect
                    dateFormat="hh:mm aa, dd-MMMM-yyyy"
                  />
                )}
              </Field>
            </div>
          );

        case "colorPicker":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code} validate={validateColorCode}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta // {touched, error}
                }) => (
                  <ColorPicker
                    field={field}
                    form={form}
                    meta={meta}
                    label={name}
                    name={code}
                    withFeedbackLabel={true}
                    onChange={field.onChange}
                    {...restrictions}
                  />
                )}
              </Field>
            </div>
          );

        case "iconPicker":
          return (
              <div
                  className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
                  key={code}
                  hidden={restrictions?.hidden}
              >
                <Field name={code}>
                  {({
                      field, // { name, value, onChange, onBlur }
                      form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta // {touched, error}
                    }) => (
                      <IconPickerField
                          field={field}
                          form={form}
                          meta={meta}
                          label={name}
                          name={code}
                          withFeedbackLabel={true}
                          onChange={field.onChange}
                          {...restrictions}
                      />
                  )}
                </Field>
              </div>
          );

        case "creatableSelect":
          return (
            <div
              className={`${fieldLayoutTitles[layout] || "col-lg-3"} mb-3`}
              key={code}
              hidden={restrictions?.hidden}
            >
              <Field name={code}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // { values, setXXX, handleXXX, dirty, isValid etc }
                  meta // {touched, error}
                }) => (
                  <>
                    {name && <label>{name}</label>}
                    <ReactCreatableSelect
                      label={name}
                      name={code}
                      placeholder={name}
                      touched={meta.touched}
                      error={meta.error}
                      value={(field.value || []).map(item => ({
                        label: item,
                        value: item
                      }))}
                      onChange={option => {
                        if (
                          maxLength > 0
                            ? (option || []).map(item => ({
                                label: item,
                                value: item
                              }))?.length > maxLength
                            : false
                        ) {
                          warningNotification(
                            `You can only select upto ${maxLength} options`
                          );
                        } else {
                          onChangeDropdown(
                            option || "",
                            code,
                            formik.setFieldValue,
                            form.values,
                            false,
                            valueAsInt
                          );
                        }
                      }}
                      isMulti
                      isClearable
                      // isValidNewOption={(inputValue, selectValue, selectOptions, accessor) => {
                      //   formik.errors[code] = "asdasdas";
                      //   return false;
                      // }}
                      noOptionsMessage={() => null}
                      isSearchable
                      closeMenuOnSelect={true}
                      formatCreateLabel={inputValue => `Create ${inputValue}`}
                      withFeedbackLabel
                      {...restrictions}
                    />
                  </>
                )}
              </Field>
            </div>
          );

        default:
          return null;
      }
    });
  };

  const onSubmitForm = (
    { values, errors, setFieldTouched, setFieldError },
    submitCallback,
    isSaveFormOnlyCall = false
  ) => {
    let fields = [];

    if (saveButtonId === "basicInformation_formSaveOnlyButton") {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(fieldsList)) {
        if (value === null || value === undefined || value === "") {
          fields = [...fields, ...value];
        }
      }
    } else {
      fields = fieldsList;
    }

    let formValidity = !Object.keys(errors).length;
    fields.forEach(field => {
      if (
        field.isRequired &&
        (values[field.code] === null || values[field.code] === undefined)
      ) {
        formValidity = false;
        setFieldTouched(field.code, true);
        setFieldError(field.code, `${field.name} is required!`);
      }
    });

    if (formValidity) {
      let tempValues = { ...values };
      Object.keys(values).forEach(key => {
        if (fields.find(field => field.code === key) === undefined)
          delete tempValues[key];
      });
      submitCallback(tempValues);
    } else {
      if (!formValidity && isSaveFormOnlyCall === true)
        errorNotification(
          "Please remove form errors before entering internal data"
        );
    }
  };

  const onSaveFormOnly = (
    { values, errors, setFieldTouched, setFieldError },
    submitCallback
  ) => {
    const fields = fieldsList;
    let formValidity = !Object.keys(errors).length;
    fields.forEach(field => {
      if (
        field.isRequired &&
        (values?.[field.code] === null || values?.[field.code] === undefined)
      ) {
        formValidity = false;
        setFieldTouched(field.code, true);
        setFieldError(field.code, `${field.name} is required!`);
      }
    });
    if (formValidity) {
      let tempValues = { ...values };
      if (!tempValues?.["code"])
        return errorNotification(
          "Please enter code before entering internal data"
        );
      Object.keys(values).forEach(key => {
        if (fields.find(field => field.code === key) === undefined)
          delete tempValues[key];
      });
      onSaveFormCallback(tempValues);
    } else {
      if (!formValidity)
        errorNotification(
          "Please remove form errors before entering internal data"
        );
    }
  };

  return (
    <Formik
      key={formKey}
      initialValues={{
        ...(formValues || {}),
        status: formValues?.status || 0,
        ...(viewType === "clone"
          ? {
              name: `${formValues?.name} Clone`,
              code: `${formValues?.code}Clone`
            }
          : {})
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {formik => (
        <Form className="form form-label-right" onSubmit={formik.handleSubmit}>
          <div className="form-group row">
            {Array.isArray(fieldsList) ? (
              <> {getRenderableFields(formik, fieldsList)}</>
            ) : (
              <>
                {Object.keys(fieldsList)?.map((key, index) => {
                  return (
                    <>
                      {key !== "undefined" ? (
                        <div className="col-lg-12">
                          <h4 className="font-italic">{key}</h4>
                        </div>
                      ) : null}

                      {getRenderableFields(formik, fieldsList[key])}

                      {index < Object.keys(fieldsList)?.length - 1 ? (
                        <div className="col-lg-12">
                          <hr className="solid" />
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </>
            )}
          </div>
          {/*{JSON.stringify(formik.errors)}*/}
          {submitButtonText && viewType !== "view" ? (
            <>
              <button
                id={saveButtonId}
                className="btn btn-primary d-none"
                type="button"
                // disabled={
                //   formik.errors.length ||
                //   (viewType === "new" && !formik.touched.length)
                // }
                onClick={() => onSaveFormOnly(formik, formik.handleSubmit)}
              >
                Save Form Values Only
              </button>
              <button
                className="btn btn-primary"
                type="button"
                // disabled={
                //   formik.errors.length ||
                //   (viewType === "new" && !formik.touched.length)
                // }
                onClick={() => onSubmitForm(formik, formik.handleSubmit)}
              >
                {submitButtonText}
              </button>
            </>
          ) : null}
          {cancelButtonText ? (
            <button
              className="btn btn-light ml-5"
              type="button"
              onClick={onClickCancel}
            >
              {cancelButtonText}
            </button>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}
