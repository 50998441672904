import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Overlay } from "./Overlay";
import { GenericFileUpload } from "./GenericFileUpload";
import { imageForDocument } from "../../grids/UIHelpers";

export function DocumentUploadCard({
  document,
  handleView,
  isAllowUpload,
  acceptFileType,
  handleFileSelected,
  uploadingFile,
  maxSizeOfFile
}) {
  const image =
    imageForDocument.find(a => a?.extension === document?.extension)?.image ||
    "/media/svg/files/default.svg";
  return (
    <div key={document.id} className="col-lg-4 md-4 sm-12">
      <div className="card card-custom gutter-b ribbon ribbon-right">
        {document?.versionCount ? (
          <div
            className="ribbon-target bg-success "
            style={{
              right: "-2px",
              borderTopRightRadius: "6px"
            }}
          >
            <small className="text-white">
              Version {document.versionCount}
            </small>
          </div>
        ) : null}

        {/*</div>*/}
        <div className="card-body p-2 d-flex align-items-center">
          <div
            className="symbol symbol-50 mr-3 align-self-start align-self-xxl-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              document["group"] = document.groupName;
              handleView(document, document.versionCount);
            }}
          >
            <div
              className="symbol-label"
              style={{
                border: "1px solid #e5e5e5",
                overflow: "hidden"
              }}
            >
              {document.thumbnail ? (
                <img
                  src={`data:image/png;base64, ${document.thumbnail}`}
                  style={{ width: "100%" }}
                  alt={document.name}
                />
              ) : (
                <img
                  src={toAbsoluteUrl(image)}
                  width="45"
                  alt={document.name}
                />
              )}
            </div>
          </div>

          <div className="w-75">
            {document?.name ? (
              <Overlay popoverContent={document.name}>
                <p className="font-weight-bolder max-w-50 segmented-text-ellipsis mb-2">
                  {document?.name}
                </p>
              </Overlay>
            ) : null}

            <div className="d-flex  align-items-center">
              <Overlay popoverContent={document.groupName}>
                <span className="font-weight-bolder max-w-75 segmented-text-ellipsis mb-0 mr-2">
                  {document?.groupName}
                </span>
              </Overlay>
              {isAllowUpload ? (
                <GenericFileUpload
                  acceptFileType={acceptFileType}
                  code={document?.code}
                  handleFileSelected={handleFileSelected}
                  uploadingFile={uploadingFile}
                  maxSizeOfFile={maxSizeOfFile}
                  groupCode={document?.groupCode}
                  uploadBtnSize={"xs"}
                  additionalInformation={document?.additionalInformation}
                />
              ) : null}

              {/*<button className="btn btn-outline-danger">Button danger</button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
