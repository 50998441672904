import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

import { NoRecordsFoundMessage, PleaseWaitMessage } from "../../_helpers";
import { Pagination } from "../controls";

export function DataGrid({
  caption,
  paginationOptions,
  listLoading,
  keyField,
  data,
  columns,
  defaultSorted,
  onTableParamsChange,
  isCheckbox,
  select,
  rowKey,
  isExpandableRow,
  expandRow,
  wrapperClasses,
  classes,
  rowEvents,
  rowStyle
}) {
  return (
    <>
      {isCheckbox ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses={wrapperClasses || "table-responsive"}
                  classes={
                    classes ||
                    "table table-head-custom table-vertical-center table-hover overflow-hidden"
                  }
                  bootstrap4
                  caption={caption}
                  remote={{ filter: true, pagination: true, sort: true }}
                  bordered={false}
                  key={rowKey || "code"}
                  filter={filterFactory()}
                  keyField={keyField || "code"}
                  data={
                    !data
                      ? []
                      : typeof data === "string"
                      ? JSON.parse(data)
                      : data
                  }
                  columns={columns}
                  defaultSorted={defaultSorted}
                  onTableChange={onTableParamsChange}
                  selectRow={select}
                  rowEvents={rowEvents}
                  rowStyle={rowStyle}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage products={data} />
                  <NoRecordsFoundMessage products={data} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      ) : (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses={wrapperClasses || "table-responsive"}
                  classes={
                    classes ||
                    "table table-head-custom table-vertical-center table-hover overflow-hidden"
                  }
                  bootstrap4
                  caption={caption}
                  filter={filterFactory()}
                  bordered={false}
                  remote={{ filter: true, pagination: true, sort: true }}
                  key={rowKey || "code"}
                  keyField={keyField || "code"}
                  data={
                    !data
                      ? []
                      : typeof data === "string"
                      ? JSON.parse(data)
                      : data
                  }
                  columns={columns}
                  defaultSorted={defaultSorted}
                  onTableChange={onTableParamsChange}
                  expandableRow={isExpandableRow}
                  expandRow={expandRow}
                  expandColumnOptions={{ expandColumnVisible: true }}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage products={data} />
                  <NoRecordsFoundMessage products={data} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
}
