import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { errorNotification, infoNotification, successNotification } from "../../notifications";
import { downloadDocument, uploadDocument } from "../../../../app/modules/Workflows/_redux/actions";
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from "../../controls/forms/Overlay";
// import { FieldFeedbackLabel } from "../../../../_custom/_partials/controls/forms/FieldFeedbackLabel";
import { extensionTypes } from "../../../../_custom/_helpers/staticFields";
import { toBase64 } from "../../../../app/helper/functions";

export function DocumentUploadInput(props) {
  const [uploadingFile, setUploadingFile] = useState(null);
  // const [downloadingFile, setDownloadingFile] = useState(null);

  const {documentUploading} = useSelector(
    (state) => ({
      documentUploading: state.workflows.documentUploading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleFileSelected = async (e, files) => {
    let file = Array.prototype.slice.call(files)[0];
    const result = await toBase64(file).catch(e => Error(e));
    if (result instanceof Error) {
      console.log('Error: ', result.message);
      return;
    }

    if (result) {
      infoNotification("Uploading document....");
      setUploadingFile(props.field.name);
      let code = props?.field?.value || props?.code?.replace(/[^a-zA-Z 0-9]/g, "") + "_" + uuidv4();
      let extension = extensionTypes.find(fld => file?.type?.split("/")[1] === fld?.mimeType)?.extension || file?.type?.split("/")[1] || file?.name?.split(".")?.[1];
      const payload = {
        bucketCode: props.productCode.replaceAll("-", "").toLowerCase(),
        identifier: props?.identifier,
        name: file.name,
        code: code,
        description: "",
        file: result.split(",")[1],
        extension: extension,
        isComplete: true,
      }
      dispatch(uploadDocument(payload)).then(res => {
        if (res?.status === 200) {
          successNotification("Document uploaded successfully.");
          props.form.setFieldValue(props.field.name, code);
          setUploadingFile(null);
        } else {
          errorNotification("Document upload failed.");
          setUploadingFile(null);
        }
      })
    }
  }

  const onClickDownloadDocument = async () => {
    infoNotification("Downloading document...");
    const params = {
      bucketCode: props.productCode.replaceAll("-", "").toLowerCase(),
      identifier: props?.field?.value,
    }
    const response = await dispatch(downloadDocument(params));
    if (response?.status === 200) {
      const downloadLink = response.data;
      const link = document.createElement("a");
      link.download = props?.field?.value;
      link.href = downloadLink;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <>
      {props?.label && <label>{props?.label}</label>}
      {props?.isRequired ? <span className='text-danger'> *</span> : null}
      {props?.showDescription ? (
        <Overlay popoverContent={props?.description}>
          <i className={"fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"}/>
        </Overlay>
      ) : null}
      <div className={`form-control mt-0 custom-file-upload ${props?.disabled || props?.isReadOnly || !props?.identifier ? "disabled-file-input" : ""}`}>
        <input type="file" className={`custom-file-input ${props?.disabled || props?.isReadOnly || !props?.identifier ? "disabled-file-input" : ""}`} id={`file-upload`}
               accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf, .csv, .msg, .eml"
               disabled={props?.disabled || props?.isReadOnly || !props?.identifier}
               onChange={(e) => {
                 handleFileSelected(e, e.target.files)
               }}
        />
        {props?.field?.value ? <label>{props?.field?.value}</label> : "Upload file"} {props?.field?.value && <i style={{marginTop: "-5px", marginRight: "-9px"}} className={"fas fa-cloud-download-alt text-hover-success cursor-pointer float-right"} onClick={onClickDownloadDocument}/>}
      </div>
      {(documentUploading && (uploadingFile === props?.field?.name)) ? <label>Uploading document....</label> : null}
      {(!props?.field?.value && !documentUploading && props.isRequired) && !props.isHideValidations ? <label className="text-danger">Must contain at least 1 document </label> : null}

      {/*{props?.error && !props.isHideValidations && (*/}
      {/*  <FieldFeedbackLabel*/}
      {/*    error={props?.error}*/}
      {/*    touched={props?.touched}*/}
      {/*    label={props?.label.toUpperCase()}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  )
}
