/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {Status} from "../UIHelpers";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditPage, onDelete, user }
) => (
  <>
    <OverlayTrigger
      overlay={<Tooltip id="records-edit-tooltip">Edit</Tooltip>}
    >
      <button
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditPage(row.code, row)}
        disabled={row.status === Status.Pending || (row.status === Status.SFA && row.actor === user?.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            title={""}
          />
        </span>
      </button>
    </OverlayTrigger>

    <> </>
    <OverlayTrigger
      overlay={<Tooltip id="records-delete-tooltip">Delete</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => onDelete(row.code, row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}  title={"Delete"} />
        </span>
      </a>
    </OverlayTrigger>
  </>
);
