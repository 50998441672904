import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "../../../Dialog";
import { FieldsMapping } from "./FieldsMapping";
import { ExpressionBuilder } from "./ExpressionBuilder";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { FormulaTesting } from "./FormulaTesting";
import { shallowEqual, useSelector } from "react-redux";
import { createDictionary } from "../../../../../app/helper/functions";

export const MathematicalExpressionDialog = ({
  show,
  setShow,
  disabled,
  onChange,
  value
}) => {
  const { allFields } = useSelector(
    state => ({
      allFields: state.fields.entities
    }),
    shallowEqual
  );
  const [documentationLink] = useState(
    "https://mathjs.org/docs/expressions/syntax.html"
  );
  const nextBtn = useRef();
  const addBtn = useRef();
  const validateBtn = useRef();
  const [step, setStep] = useState(1);
  const [variables, setVariables] = useState(null);
  const [fieldsDictionary, setFieldsDictionary] = useState(null);
  const [mathematicalExpression, setMathematicalExpression] = useState({
    formula: "",
    variables: {}
  });

  useEffect(() => {
    if (show) {
      if (allFields?.length) {
        const tempFieldsDictionary = createDictionary(allFields || [], "code");
        setFieldsDictionary(tempFieldsDictionary);
      }

      setStep(1);
      setMathematicalExpression(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const updateFormula = values => {
    let tempVariableToFieldCodeMapping = {};
    let tempVariables = Array.from(
      new Set(
        values?.mathematicalExpression?.match(/\$\w+/g)?.map(v => {
          return v.replace(/\$/gi, "");
        }) || []
      )
    );

    tempVariables.forEach(variable => {
      tempVariableToFieldCodeMapping = {
        ...tempVariableToFieldCodeMapping,
        [variable]: mathematicalExpression?.variables?.[variable] || null
      };
    });

    setMathematicalExpression({
      variables: tempVariableToFieldCodeMapping,
      formula: values?.mathematicalExpression || ""
    });

    setVariables(tempVariables);
  };

  const updateVariablesValues = values => {
    let updatedMathematicalExpression = {
      ...mathematicalExpression,
      variables: values
    };

    setMathematicalExpression(updatedMathematicalExpression);
    onChange(updatedMathematicalExpression);
  };

  return (
    <Dialog
      size="lg"
      isCentered={true}
      show={show}
      onHide={() => setShow(false)}
      modalTitle={
        step === 1
          ? `Expression Builder`
          : step === 2
          ? `Field Mapping`
          : `Expression Validation`
      }
      // isLoading={isLoading}
      modalBody={
        <div
          className="mx-4"
          style={{
            height: "auto"
            // height: `${step === 1 ? "auto" : "50vh"}`
          }}
        >
          {step === 1 ? (
            <>
              <ExpressionBuilder
                nextBtn={nextBtn}
                mode={disabled ? "View" : "Edit"}
                values={{
                  mathematicalExpression: mathematicalExpression?.formula || ""
                }}
                updateFormula={updateFormula}
              />

              <div
                className={`d-flex align-items-center bg-light-warning rounded p-2 mb-4`}
              >
                <span className={`svg-icon svg-icon-sm svg-icon-warning mr-3`}>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Warning-1-circle.svg"
                    )}
                    className="svg-icon svg-icon-lg"
                  ></SVG>
                </span>
                <div className="d-flex align-items-center mr-2">
                  <p className="mb-0">
                    <span className="text-dark-50 font-size-sm">
                      Add "$" before variables name.
                    </span>
                  </p>
                </div>
              </div>

              <div
                className={`d-flex align-items-center bg-light-warning rounded p-2 gutter-b`}
              >
                <span className={`svg-icon svg-icon-sm svg-icon-warning mr-3`}>
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Warning-1-circle.svg"
                    )}
                    className="svg-icon svg-icon-sm"
                  ></SVG>
                </span>
                <div className="d-flex align-items-center mr-2">
                  <p className="mb-0">
                    <span className="text-dark-50 font-size-sm">
                      Refer this{" "}
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href={documentationLink} target="_blank">
                        <u>documentation</u>
                      </a>{" "}
                      to understand the syntax of this expression.
                    </span>
                  </p>
                </div>
              </div>
            </>
          ) : step === 2 ? (
            <FieldsMapping
              variables={variables}
              addBtn={addBtn}
              updateVariablesValues={updateVariablesValues}
              values={mathematicalExpression?.variables || {}}
              mode={disabled ? "view" : "edit"}
            />
          ) : (
            <FormulaTesting
              mathematicalExpression={mathematicalExpression}
              validateBtn={validateBtn}
              fieldsDictionary={fieldsDictionary || null}
            />
          )}
        </div>
      }
      modalBodyClasses="px-4 pt-2 pb-0"
      modalFooter={
        <div className="d-flex w-100 justify-content-end">
          <div>
            {step > 1 ? (
              <>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setStep(prevState => prevState - 1);
                  }}
                  hidden={step === 1}
                >
                  Back
                </button>

                <button
                  type="button"
                  className="btn btn-warning ml-2"
                  hidden={step === 3}
                  onClick={() => {
                    addBtn.current.click();
                    setTimeout(() => {
                      setStep(3);
                    }, 500);
                  }}
                >
                  Evaluate Expression
                </button>
              </>
            ) : null}

            {step === 3 ? (
              <button
                type="button"
                className="btn btn-warning ml-2"
                onClick={() => {
                  validateBtn.current.click();
                }}
              >
                Get Result
              </button>
            ) : null}

            <button
              type="button"
              className="btn btn-success ml-2"
              hidden={(step === 1 && disabled) || step === 3}
              onClick={() => {
                if (step === 1) {
                  nextBtn.current.click();
                  setStep(2);
                } else {
                  addBtn.current.click();
                  setShow(false);
                }
              }}
            >
              Continue
            </button>

            <button
              type="button"
              className="btn btn-light ml-2"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      }
    />
  );
};
