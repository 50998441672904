import React, { useState } from "react";
import { AddEditViewForm } from "./../../../controls";
import {
  dropDownOptions,
  getFieldsByModule
} from "../../../../_helpers/staticFields";
import { shallowEqual, useSelector } from "react-redux";

export const ExpressionBuilder = ({ nextBtn, updateFormula, values, mode }) => {
  const { allFields } = useSelector(
    state => ({
      allFields: state.fields.entities
    }),
    shallowEqual
  );

  const [renderFields, setRenderFields] = useState(
    getFieldsByModule({
      moduleCode: "mathematicalExpressionBuilder",
      excludeStaticFields: true
    })
  );

  return (
    <>
      <AddEditViewForm
        layout={"col-sm-6"}
        showSkeleton={false}
        skeletonLength={renderFields?.length}
        fieldsValues={values}
        fieldsList={renderFields}
        dropdownOptions={{
          fields: allFields,
          fieldTypes: dropDownOptions.fieldTypes
        }}
        btnRef={nextBtn}
        setFieldsList={setRenderFields}
        enableReinitialize={true}
        saveField={updateFormula}
        overrideMode={mode || "edit"}
      />
    </>
  );
};
