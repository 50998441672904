import * as requestFromServer from "./productsCrud";
import { callTypes, productsSlice } from "./productsSlice";

const { actions } = productsSlice;

export const fetchProducts = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchProducts(queryParams)
    .then(response => {
      const {
        paging: { totalItems: totalCount },
        items
      } = response.data;
      dispatch(actions.productsFetched({ totalCount, entities: items }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearProductsList = () => dispatch => {
  dispatch(actions.clearProductsList());
};

export const fetchProduct = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductByCode(params)
    .then(response => {
      if (params.notSaveInStore) return response;
      dispatch(actions.productFetched({ productForEdit: response.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearIndividualProduct = () => dispatch => {
  dispatch(actions.clearIndividualProduct());
};

export const getDraftProduct = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDraftProduct(params)
    .then(response => {
      dispatch(actions.productFetched({ productForEdit: response.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProduct = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProduct(params)
    .then(response => {
      if (params?.isBulk) {
        dispatch(actions.productDeleted());
      }
      if (params?.isBulk && params?.lastCall) {
        dispatch(actions.productDeleted());
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};

export const createProduct = product => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProduct({ ...product })
    .then(response => {
      dispatch(actions.productCreated({ product: response.data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProduct = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProduct(params)
    .then((response, error) => {
      if (response) {
        dispatch(actions.productUpdated({ product: response.data }));
      } else {
        error.clientMessage = "Can't update product";
        dispatch(
          actions.catchError({ error: response, callType: callTypes.action })
        );
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error;
    });
};

export const updateProductsStatus = (codes, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForProducts(codes, status)
    .then(() => {
      dispatch(actions.productsStatusUpdated({ codes, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update products status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProducts = codes => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProducts(codes)
    .then(() => {
      dispatch(actions.productsDeleted({ codes }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const actOnDraft = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams)
    .then(response => {
      dispatch(actions.onDraftActionComplete());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setDeviationDataDictionary = params => dispatch => {
  dispatch(actions.deviationDictionarySet(params));
};

export const setDiscrepancyDataDictionary = params => dispatch => {
  dispatch(actions.discrepancyDictionarySet(params));
};

export const updatePagination = params => dispatch => {
  dispatch(actions.updatePagination(params));
};

export const resetPagination = params => dispatch => {
  dispatch(actions.resetPagination());
};
export const fetchChildProduct = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductByCode(params)
    .then(response => {
      dispatch(
        actions.childProductFetched({
          productCode: params?.code,
          childProduct: response.data
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const auditProducts = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditProducts(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetched({ auditLogs }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
