import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Logout } from "../../../app/modules/Auth/_redux/authCrud";
import * as modulesActions from "../../../app/modules/Identity/_redux/modules/modulesActions";
import * as groupActions from "../../../app/modules/Identity/_redux/groups/groupsActions";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return ;
  }
  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );
};

export function ForceLogoutNotification({setForceLogout}) {
  const history = useHistory();
  const {user, uniqueIdentifier} = useSelector(
    (state) => ({
      user: state.auth.user,
      uniqueIdentifier: state.auth.uniqueIdentifier
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const logoutClick = () => {
    dispatch(modulesActions.clearModulesPermissions());
    dispatch(groupActions.clearGroupsPermissions());
    Logout(user.id, uniqueIdentifier).then((params) => {
      if(params?.status === 200 ){
        history.push("/logout");
      }
    })
    setForceLogout(false)
  };
  return (
    <div style={{
      maxWidth: "60rem",
      position: "fixed",
      right: "15px",
      top: "15px",
      zIndex: 10,
      boxShadow: "0px 0px 5px 5px #d2d2d28c",
      borderRadius: "4px"
    }}>
      <div className="card card-custom bg-primary gutter-b wave wave-animate-slow wave-primary m-0">
        <div className="card-body p-3 d-flex">
          <CountdownCircleTimer
            isPlaying
            duration={10}
            size={40}
            strokeWidth={5}
            colors={[["#3699ff", 1]]}
            onComplete={() => logoutClick()}
          >
            {renderTime}
          </CountdownCircleTimer>
          <p className="mb-0 pt-3 px-4 align-middle font-weight-bold" >
            Your account has been logged in from somewhere else.
          </p>
        </div>
      </div>
    </div>
  )
}
