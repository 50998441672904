import React from "react";
import { Overlay } from "../../controls/forms/Overlay";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"; // ES6
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export function RichTextBox({
  form,
  field, // { name, value, onChange, onBlur }
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  defaultValue,
  description,
  meta,
  disabled,
  isReadOnly,
  classes,
  showDescription,
  setFieldValue,
  name,
  prevState,
  ...props
}) {
  const { touched, error } = meta;

  return (
    <>
      {label && <label>{label}</label>}
      {props.required ? <span className="text-danger"> *</span> : null}
      {showDescription ? (
        <Overlay popoverContent={description}>
          <i
            className={
              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
            }
          />
        </Overlay>
      ) : null}

      {prevState && prevState?.[field?.name] !== field.value ? (
        <span className="field-modified-tag">Modified</span>
      ) : null}

      <SunEditor
        setContents={field?.value || ""}
        id={name}
        defaultValue={field.value || ""}
        disable={isReadOnly || disabled || field.disabled}
        hideToolbar={isReadOnly || disabled || field.disabled}
        style={{ userSelect: "text" }}
        setOptions={{
          stickyToolbar: true,
          height: "100px",
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript"
            ],
            ["removeFormat"],
            "/",
            ["fontColor", "hiliteColor", "outdent", "indent"],
            ["align", "horizontalRule", "list", "table"],
            ["link", "image", "video"],
            ["fullScreen", "showBlocks", "codeView"],
            ["dir", "dir_ltr", "dir_rtl"]
          ]
        }}
        onChange={value => {
          // Check if the value is an empty paragraph tag with a line break
          const isEmpty = /^<p><br><\/p>$/i.test(value);
          setFieldValue(name, isEmpty ? "" : value.toString());
        }}
      />

      {prevState && prevState?.[field?.name] !== field.value ? (
        <p className="pt-1 mb-0">
          <span style={{ fontWeight: "bolder" }}>Before: </span>
          <span>
            {prevState?.[field?.name] || (
              <i style={{ fontSize: "13px" }}>No Data</i>
            )}
          </span>
        </p>
      ) : null}

      {!props.isHideValidations &&
        withFeedbackLabel &&
        props.required &&
        !field.value && (
          <FieldFeedbackLabel
            error={error}
            touched={touched}
            label={label}
            type="text"
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
    </>
  );
}
