import React, { useEffect, useState, Children, useCallback } from "react";
import Select, { components, createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { Overlay } from "./Overlay";
import { sortDropdownsOptions } from "../../../_helpers";
import VirtualList from "react-tiny-virtual-list";
import lodash from "lodash";

const DefaultItemHeight = 40;

const animatedComponents = makeAnimated();

const SortableSelect = SortableContainer(Select);

export function ReactSelectDraggable(props) {
  let {
    label,
    disabled,
    isReadOnly,
    value,
    isRequired,
    meta,
    masterDataType,
    overwriteSorting = false
  } = props;
  const [isErrorPropagated, setIsErrorPropagated] = useState(false);
  const [index, setIndex] = useState(0);
  const [maxToShow, setMaxToShow] = useState(Number(value?.length));

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      background: "#E6E6E6",
      borderRadius: "2px",
      fontSize: "85%",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
      pointerEvents: "auto"
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `${length} item${length !== 1 ? "s" : ""} selected`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    let overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);

    // const title = [props.children].join(", ");

    return (
      <div>
        {index === maxToShow ? (
          <div>
            <MoreSelectedBadge items={overflow} />
          </div>
        ) : null}
      </div>
    );
  };

  const handleExpandCollapse = () => {
    if (maxToShow !== 0) {
      setMaxToShow(0);
    } else {
      setMaxToShow(Number(value?.length));
    }
  };

  const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example

    const onMouseDown = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  });

  const SortableMultiValueLabel = sortableHandle(props => (
    <div
      onClick={() => {
        if (props?.selectProps?.name === "subFields") {
          props.selectProps.openComputationModal({
            ...props.data,
            type: "grid",
            show: true
          });
        }
      }}
    >
      <span title={props.children}>
        <components.MultiValueLabel {...props} />
      </span>
    </div>
  ));

  useEffect(() => {
    if (
      (!value?.toString()?.length || value === "undefined") &&
      isRequired &&
      !isErrorPropagated
    ) {
      setIsErrorPropagated(true);
      props.onError(props.name, label + " is required.");
    }
    setIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isRequired]);

  const customStyles = {
    control: styles => ({
      ...styles,
      border: "2px solid #3699FF"
    }),
    multiValue: (styles, { data }) => {
      // console.log("data: ", data)
      return {
        ...styles,
        backgroundColor: data.bgColor ? data.bgColor : "#E6E6E6"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.bgColor,
      color: data.color,
      fontWeight: 500
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,

      ":hover": {
        backgroundColor: data.color,
        color: "white"
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2 // Set your desired zIndex value here
    })
  };

  const customStyle = {
    control: styles => ({
      ...styles,
      // border: "2px solid #3699FF",
      maxHeight: "200px",
      scrollX: "scroll",
      overflow: "auto",
      pointerEvents: "auto"
    }),
    valueContainer: styles => ({
      ...styles,
      pointerEvents: disabled || isReadOnly ? "none" : "auto"
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.bgColor ? data.bgColor : "#E6E6E6"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.bgColor,
      color: data.color,
      fontWeight: 500,
      maxWidth: "125px",
      pointerEvents: "auto"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2 // Set your desired zIndex value here
    })
  };

  const Option = props => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    return (
      <>
        <components.Option
          className={"overflow-prevent-react-select"}
          {...newProps}
        >
          <span>{newProps?.data?.label}</span>
          {newProps?.data?.tooltip ? (
            <Overlay popoverContent={newProps?.data?.tooltip}>
              <i
                className={
                  "fas fa-info-circle icon-nm text-hover-primary mr-n2  float-right"
                }
              />
            </Overlay>
          ) : null}
        </components.Option>
      </>
    );
  };

  const renderItem = useCallback(props => {
    const { children } = props;
    if (Array.isArray(children)) {
      return (
        <li
          style={props.style}
          key={props.index}
          title={children?.[props.index]?.props?.children || ""}
        >
          {children[props.index]}
        </li>
      );
    }
    return (
      <li
        key={props.index}
        className="react-virtualized-menu-placeholder"
        title={children.props?.children || ""}
      >
        {children.props.children}
      </li>
    );
  }, []);

  const handleKeyDown = e => {
    let options;
    value?.length > 0
      ? (options = Number(props.options?.length - value?.length))
      : (options = Number(props.options?.length));
    if (e.key === "ArrowDown" && e.keyCode === 40) {
      if (index === Number(options || 0) - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
    if (e.key === "ArrowUp" && e.keyCode === 38) {
      if (index === 0) {
        setIndex(Number(options || 0) - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  useEffect(() => {
    // Handle the arrow key events for scrolling
    const handleKeyDownEvent = e => {
      handleKeyDown(e);
    };

    window.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      window.removeEventListener("keydown", handleKeyDownEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, props.options]);
  const CustomMenuList = useCallback(
    props => {
      // const { options, children, maxHeight, getValue } = props;
      const { children, maxHeight } = props;

      // const [value] = getValue();
      // const initialOffset = options.indexOf(value) * DefaultItemHeight;
      const childrenOptions = Children.toArray(children);
      const wrapperHeight =
        maxHeight < childrenOptions.length * DefaultItemHeight
          ? maxHeight
          : childrenOptions.length * DefaultItemHeight;

      return (
        <span className="react-virtualized-list-wrapper">
          <VirtualList
            width="100%"
            height={wrapperHeight + 6}
            // scrollOffset={initialOffset}
            scrollToIndex={index}
            scrollDirection="vertical"
            scrollToAlignment="auto"
            itemCount={childrenOptions.length}
            itemSize={DefaultItemHeight}
            renderItem={innerProps => renderItem({ ...props, ...innerProps })}
          />
        </span>
      );
    },
    [index, renderItem]
  );

  return (
    <>
      {props?.prevState &&
      !lodash.isEqual(props?.field?.value, props?.prevState) ? (
        <>
          <span className="field-modified-tag">Modified</span>
          <Select
            styles={customStyles}
            key={"perm"}
            isMulti
            isDisabled={isReadOnly || disabled}
            isSearchable
            isClearable
            options={
              overwriteSorting
                ? props.options
                : sortDropdownsOptions(props.options)
            }
            hideSelectedOptions={true}
            closeMenuOnSelect={false}
            value={props?.getDropdownComparisonValue(
              props?.prevState,
              props?.field?.value,
              masterDataType
            )}
            captureMenuScroll={false}
            components={{
              animatedComponents,
              Option,
              MenuList: CustomMenuList
            }}
            classNamePrefix="custom-select"
            filterOption={createFilter({ ignoreAccents: false })}
          />
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: 1 }}>
            <SortableSelect
              {...props}
              styles={customStyle}
              useDragHandle
              // onKeyDown={handleKeyDown}
              openMenuOnClick={maxToShow === 0 || !value?.length}
              // react-sortable-hoc props:
              axis="xy"
              distance={4}
              closeMenuOnSelect={false}
              options={
                overwriteSorting
                  ? props.options
                  : sortDropdownsOptions(props.options)
              }
              // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              // react-select props:
              isDisabled={isReadOnly || disabled}
              components={{
                animatedComponents,
                MultiValue: maxToShow !== 0 ? SortableMultiValue : MultiValue,
                MultiValueLabel:
                  maxToShow !== 0 ? SortableMultiValueLabel : null,
                Option,
                MenuList: CustomMenuList
              }}
              // components={animatedComponents}
              onMenuOpen={() => {
                setIndex(0);
              }}
              captureMenuScroll={false}
              classNamePrefix="custom-select"
              filterOption={createFilter({ ignoreAccents: false })}
            />
          </div>

          <button
            type="button"
            style={{
              display:
                value !== undefined && value?.length > 0 ? "flex" : "none",
              marginLeft: "5px",
              boxSizing: "border-box",
              border: "1px solid hsl(0,0%,80%)",
              borderRadius: "4px",
              background: "white",
              padding: "18.34px"
            }}
            className="btn btn-sm btn-icon btn-light btn-hover-light"
            onClick={handleExpandCollapse}
          >
            {maxToShow !== 0 ? (
              <i className="fas fa-compress" title={"minimize items"} />
            ) : (
              <i className="fas fa-expand" title={"show selected items"} />
            )}
          </button>
        </div>
      )}
      {!props.isHideValidations ? (
        <>
          {(!props.value?.toString()?.length || props.value === "undefined") &&
          props.isRequired ? (
            <div className="feedback text-danger">
              <b>{label}</b> is required
            </div>
          ) : (
            meta?.error &&
            isErrorPropagated && (
              <div className="feedback text-danger">{meta.error}</div>
            )
          )}
        </>
      ) : null}

      {/*{*/}
      {/*  meta?.error && <div className="feedback text-danger">{meta.error}</div>*/}
      {/*}*/}
    </>
  );
}
