import React from "react";
import { Dialog } from "./Dialog";
import { shallowEqual, useSelector } from "react-redux";

export function FormulaViewer({ label, mathematicalExpression, show, onHide }) {
  const { fieldsDictionary } = useSelector(
    state => ({
      fieldsDictionary: state.fields?.fieldsDictionary
    }),
    shallowEqual
  );

  return (
    <Dialog
      show={show}
      size="md"
      isCentered={false}
      onHide={onHide}
      modalTitle={`${label} Computations`}
      modalBody={
        <div className="row">
          {/*<div className="col-md-12 text-center">*/}
          {/*  <code>{mathematicalExpression.formula.replaceAll("$", "")}</code>*/}
          {/*</div>*/}
          <div className="col-md-12">
            <label>Mathematical Expression</label>
            <textarea
              rows={5}
              cols={5}
              className="form-control text-area"
              value={mathematicalExpression.formula.replaceAll("$", "")}
              disabled
            />
          </div>
          {Object.entries(mathematicalExpression.variables || {}).map(
            ([key, value]) => (
              <div className="col-md-12">
                <div className="formula-definition-container">
                  <span>{key} </span>
                  <i className="fas fa-arrow-right"></i>
                  <span>
                    {" "}
                    {value} ({fieldsDictionary[value]?.name || "N/A"})
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      }
      modalBodyClasses={"text-justify"}
      modalFooter={
        <>
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={onHide}
          >
            Close
          </button>
        </>
      }
    />
  );
}
