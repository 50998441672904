import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  callStatus: null,
  compareImagesData: null,
  ocrData: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: "customers",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    imagesCompared: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.compareImagesData = action.payload;
    },
    // ocrPerformed: (state, action) => {
    //   state.actionsLoading = false;
    //   state.callStatus = null;
    //   state.error = null;
    //   state.ocrData = action.payload;
    // },
    fetchDocument: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    livenessPerformed: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.compareImagesData = null;
    }
  }
});
