import React from "react";
import moment from "moment";

export const DateColumnFormatter = (
  cellContent,
  currentFormat,
  desiredFormat,
  ignoreTimeZone,
  startOf
) => (
  <>
    <div className="d-flex align-items-center" style={{ width: "165px" }}>
      <div className="d-flex flex-column">
        <span className="text-primary text-right font-weight-bold font-size-lg mr-2">
          {cellContent
            ? !ignoreTimeZone
              ? moment(
                  cellContent,
                  currentFormat ? currentFormat : moment.defaultFormat
                ).format(
                  typeof desiredFormat === "string"
                    ? desiredFormat
                    : "Do MMMM YYYY, hh:mm a"
                )
              : moment(
                  cellContent,
                  currentFormat ? currentFormat : moment.defaultFormat
                )
                  .startOf(startOf ? startOf : "day")
                  .format(
                    typeof desiredFormat === "string"
                      ? desiredFormat
                      : "Do MMMM YYYY, hh:mm a"
                  )
            : "-"}
        </span>
      </div>
    </div>
  </>
);

export const DateColumnFormatterWithoutStyle = (
  cellContent,
  currentFormat,
  desiredFormat,
  ignoreTimeZone,
  startOf
) => (
  <>
    <span className="text-primary font-weight-bold">
      {cellContent
        ? !ignoreTimeZone
          ? moment(
              cellContent,
              currentFormat ? currentFormat : moment.defaultFormat
            ).format(
              typeof desiredFormat === "string"
                ? desiredFormat
                : "Do MMMM YYYY, hh:mm a"
            )
          : moment(
              cellContent,
              currentFormat ? currentFormat : moment.defaultFormat
            )
              .startOf(startOf ? startOf : "day")
              .format(
                typeof desiredFormat === "string"
                  ? desiredFormat
                  : "Do MMMM YYYY, hh:mm a"
              )
        : "-"}
    </span>
  </>
);
export const DateFormatterStyle = (
  cellContent,
  currentFormat,
  desiredFormat,
  ignoreTimeZone,
  startOf
) => {
  return (
    <span className="text-success font-weight-bold ">
      {cellContent
        ? !ignoreTimeZone
          ? moment(
              cellContent,
              currentFormat ? currentFormat : moment.defaultFormat
            ).format(
              typeof desiredFormat === "string"
                ? desiredFormat
                : "Do MMMM YYYY, hh:mm a"
            )
          : moment(
              cellContent,
              currentFormat ? currentFormat : moment.defaultFormat
            )
              .startOf(startOf ? startOf : "day")
              .format(
                typeof desiredFormat === "string"
                  ? desiredFormat
                  : "Do MMMM YYYY, hh:mm a"
              )
        : "-"}
    </span>
  );
};
