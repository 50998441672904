import React, { useEffect, useState } from 'react';
import ReactExport from "react-export-excel";
import { Dialog } from "../Dialog";
import { ImportHelperBody } from './ImportHelperBody';
import { ImportInstructions } from "../../../../_custom/_helpers/ImportInstructions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const ImportHelperDialog = ({show, onHide, title, onCancel, isLoading, product, fields}) => {

  const [excelData, setExcelData] = useState([])

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getField = (code) => fields?.find(field => field?.code === code)?.name;

  useEffect(() => {
    if (product && fields?.length > 0) {
      let data = [
        {
          productCode: `${product.code}`,
          hierarchy: ""
        }
      ]
      for (let i = 0; i < product?.applicationCreationCriteria?.length; i++) {
        data[0][product?.applicationCreationCriteria[i]] = getField(product?.applicationCreationCriteria[i])
      }
      setExcelData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, fields])

  return (
    <Dialog
      size="xl"
      isCentered
      show={show}
      onHide={onHide}
      modalTitle={`Instructions for Importing ${title}`}
      isLoading={isLoading}
      modalBody={<ImportHelperBody name={title}/>}
      modalFooter={(
        <>
          <button type="button" className="btn btn-light" onClick={onCancel}>
            Close
          </button>
          {title === "Workflows" ?
            <ExcelFile filename="workflow" element={<button type="button" className="btn btn-primary"> Download Demo File </button>}>
              <ExcelSheet data={excelData} name="Workflow">
                <ExcelColumn label="productCode" value="productCode"/>
                <ExcelColumn label="hierarchy" value="hierarchy"/>
                {
                  product?.applicationCreationCriteria?.map((col, index) =>
                    <ExcelColumn key={index} label={`fields_${col}`} value={col}/>
                  )
                }
              </ExcelSheet>
            </ExcelFile>
            :
            ImportInstructions?.[title]?.fileEdit && ImportInstructions?.[title]?.file ? (
              <div className="btn-group" style={{paddingLeft: '5px'}}>
                <button type="button" className={`btn btn-primary  dropdown-toggle`} data-toggle="dropdown">
                  Download Demo File
                </button>
                <ul className="dropdown-menu">
                  {ImportInstructions?.[title]?.file ? (
                    <a href={toAbsoluteUrl(ImportInstructions[title]?.file)} download>
                    <span className="dropdown-item cursor-pointer">
                      For Adding Data
                    </span>
                    </a>
                  ) : null}

                  {ImportInstructions?.[title]?.fileEdit ? (
                    <a href={toAbsoluteUrl(ImportInstructions[title]?.fileEdit)} download>
                    <span className="dropdown-item cursor-pointer">
                      For Editing Data
                    </span>
                    </a>
                  ) : null}
                </ul>
              </div>
            ) : (
              ImportInstructions?.[title]?.file ? (
                <a href={toAbsoluteUrl(ImportInstructions[title]?.file)} download>
                  <button type="button" className="btn btn-primary">
                    Download Demo File
                  </button>
                </a>
              ) : null
            )
          }
        </>
      )}
    />
  )
}
