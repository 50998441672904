import React from 'react';
import { AccountStatusCssClasses, AccountStatusTitles } from '../UIHelpers';

export const AccountStatusColumnFormatter = (cellContent, row) => {
  return (
    <span className={`label label-lg label-light-${AccountStatusCssClasses[row.status]} label-inline`} style={{minWidth: "100px"}}>
      {AccountStatusTitles[row.status]}
    </span>
  )
};
