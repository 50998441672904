import React from 'react';

export const QuantitativeColumnFormatter = (cellContent, row, rowIndex, {parameters: {level1, level2, level3 }, classes, unit, formatter, formatterParam}) => {
  const displayData = (level3 ? row?.[level1]?.[level2]?.[level3] :  level2 ? row?.[level1]?.[level2] : level1 ? row[level1] : 0.00) || 0.00;

  return (
      <span className={`font-weight-bold ${classes} d-block`}>
        {
          formatter ? formatter(formatterParam ? {[formatterParam]: displayData} : displayData) : displayData
        }
        {unit ? " " + unit : ""}
        {/*{formatter ? formatter((level3 ? row?.[level1]?.[level2]?.[level3] :  level2 ? row?.[level1]?.[level2] : level1 ? row[level1] : 0.00) || 0.00) : (level3 ? row?.[level1]?.[level2]?.[level3] :  level2 ? row?.[level1]?.[level2] : level1 ? row[level1] : 0.00) || 0.00} {unit || ""}*/}
      </span>
  )
};
