export const inputTypes = [
  {
    id: 0,
    type: "text",
    displayType: "String",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 1,
    type: "number",
    displayType: "Number",
    validationType: "number",
    queryBuilderType: "number"
  },
  {
    id: 2,
    type: "select",
    displayType: "Select",
    validationType: "string",
    queryBuilderType: "select",
    onlyRequiredCheck: true
  },
  {
    id: 3,
    type: "multiselect",
    displayType: "Multi Select",
    validationType: "string",
    queryBuilderType: "multiselect",
    onlyRequiredCheck: true
  },
  {
    id: 4,
    type: "checkbox",
    displayType: "Checkbox",
    validationType: "string",
    queryBuilderType: "bool",
    onlyRequiredCheck: true
  },
  {
    id: 5,
    type: "radio",
    displayType: "Radio Button",
    validationType: "string",
    queryBuilderType: "select"
  },
  {
    id: 6,
    type: "switch",
    displayType: "Toggle",
    validationType: "boolean",
    queryBuilderType: "boolean",
    onlyRequiredCheck: true
  },
  {
    id: 7,
    type: "datePicker",
    displayType: "Date Picker",
    validationType: "string",
    queryBuilderType: "date"
  },
  {
    id: 8,
    type: "timePicker",
    displayType: "Time Picker",
    validationType: "string",
    queryBuilderType: "time"
  },
  {
    id: 9,
    type: "dateTimePicker",
    displayType: "Date Time Picker",
    validationType: "string",
    queryBuilderType: "datetime"
  },
  {
    id: 10,
    type: "colorPicker",
    displayType: "Color Picker",
    validationType: "string",
    queryBuilderType: "number",
    onlyRequiredCheck: true
  },
  {
    id: 11,
    type: "dualListBox",
    displayType: "Fields List",
    validationType: "string",
    queryBuilderType: "number",
    onlyRequiredCheck: true
  },
  {
    id: 12,
    type: "conditionBuilder",
    displayType: "Condition Builder",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 13,
    type: "creatableSelect",
    displayType: "Creatable Select",
    validationType: "string",
    queryBuilderType: "select",
    onlyRequiredCheck: true
  },
  {
    id: 14,
    type: "list",
    displayType: "List",
    validationType: "array",
    queryBuilderType: "select"
  },
  {
    id: 15,
    type: "fileInput",
    displayType: "File Input",
    validationType: "string",
    queryBuilderType: "text",
    onlyRequiredCheck: true
  },
  {
    id: 16,
    type: "richTextBox",
    displayType: "Rich Text Box",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 17,
    type: "emailEditor",
    displayType: "Email Editor",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 18,
    type: "textArea",
    displayType: "Text Area",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 19,
    type: "mathematicalExpression",
    displayType: "Mathematical Expression",
    validationType: "string",
    queryBuilderType: "text"
  },
  {
    id: 20,
    type: "iconPicker",
    displayType: "Icon Picker",
    validationType: "string",
    queryBuilderType: "string"
  },
];

export const hideFieldsInEntity = [5, 8];
