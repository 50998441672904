import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

export function TextArea(
  {
    field, // { name, value, onChange, onBlur }
    // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    defaultValue,
    meta,
    ...props
  }
) {
  const {touched, error} = meta;


  return (
    <>
      {label && <label>{label}{props.required ? <span className='text-danger'> *</span> : null}</label>}
      <textarea
        type={type}
        {...field}
        {...props}
        rows={5}
        cols={5}
        className="form-control text-area"
        value={field.value !== null && field.value !== undefined ? field.value : ""}
        defaultValue={defaultValue}
        // onChange={props.onChange ? props.onChange : props.field ? props.field.onChange : null}
        onChange={props.onChange}
      />
      {touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
