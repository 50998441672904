import { createSlice } from "@reduxjs/toolkit";

const initialAgentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  agentDeleted: false,
  allAgents: undefined
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "agents",
  initialState: initialAgentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    agentFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.agentForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    agentsFetched: (state, action) => {
      const {totalCount, entities, paginationOverride} = action.payload;
      state.listLoading = false;
      state.error = null
      state.makerCheckerAction = false;
      state.agentDeleted = false;
      if (paginationOverride) {
        state.allAgents = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearAgentsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    agentCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    agentUpdated: (state, action) => {
      const {agent} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = agent;
    },
    agentDeleted: (state) => {
      state.agentDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
  }
});
