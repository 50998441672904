/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2, wheelPropagation: false,
};

export function SearchResult({searchResult, recentHistory, setRecentHistory, focusedIndex, resultContainer}) {

  let history = useHistory();

  const RecentHistory = (val) => {
    setRecentHistory([val, ...recentHistory])
  };


  return (
    <>
      <div
        style={{maxHeight: "325px", overflow: "hidden"}}
        className=" quick-search-wrapper show scroll ps ps--active-y">
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          className="scroll"
          style={{maxHeight: "325px", position: "relative"}}>

          <div className="quick-search-result">
            {(searchResult || [])?.map((e, i) => {
              return (
                <div className={`rounded ${i === focusedIndex ? 'bg-light-primary' : null}`}  ref={i === focusedIndex ? resultContainer : null}
                     onClick={() => RecentHistory({name: e.name, route: e.route})}>
                  <div className="d-flex mb-2 mt-2 rounded bg-hover-light flex-column" onClick={() => history.push(e.route)}>
                    <p className="p-2 mt-2 font-weight-bold text-hover-primary mb-0 font-size-md">
                      <span>{e.name}</span>
                    </p>
                  </div>
                </div>
              )
            })}
          </div>

        </PerfectScrollbar>
      </div>
    </>);
}
