import React, { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Select } from 'antd';
import { fetchRecords } from "../../../../app/modules/MasterData/_redux/records/recordsActions";

const {Option} = Select;


export const AntdSelect = (props) => {

  const {label, name, disabled, isReadOnly, value, isRequired, meta, field, form, masterDataType = {}} = props;


  const [isErrorPropagated, setIsErrorPropagated] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [optionsData, setOptionsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [pageNo, setPage] = useState(1);
  const [searchPageNo, setSearchPage] = useState(1);
  const [savedFieldValue, setSavedFieldValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const [hasResponse, setHasResponse] = useState(false);
  const [totalItems, setTotalItems] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRequired) return;

    if ((!value?.toString()?.length || value === "undefined") && isRequired && !isErrorPropagated) {
      setIsErrorPropagated(true);
      props.onError(props.name, label + " is required.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isRequired]);

  useEffect(() => {
    if (form?.values[name]) {
      let savedOption;
      if (masterDataType !== undefined) {
        dispatch(fetchRecords({entityCode: masterDataType, paginationOverride: true, code: form?.values[name]})).then((response) => {
          savedOption = response?.data?.items.at(0)
          setSavedFieldValue({
            value: savedOption?.code, label: savedOption?.name,
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterDataType])

  useEffect(() => {
    if (searchOptions?.length && searchValue) {
      setOptions(searchOptions)
    } else {
      setOptions(optionsData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData, searchOptions, searchValue])

  useEffect(() => {
    if (searchValue && form?.values[name]) {
      setSearchPage(1)
      setSearchOptions([])
      setHasResponse(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue?.length, form?.values[name], searchValue]);


  const onChange = (value) => {
    setSelectedValue(value)
    form.setFieldValue(name, value || "")
  };

  const loadOptions = (value, updatedPageNumber) => {
    let pageNumber;
    if (typeof value === 'string' && value?.length) {
      setSearchValue(value || '')
      pageNumber = updatedPageNumber || 1
    } else {
      pageNumber = pageNo || 1
    }

    setSearchPage(Number(updatedPageNumber || 1));

    setHasResponse(true)
    if (props?.masterDataType !== undefined) {
      dispatch(fetchRecords({entityCode: props?.masterDataType, pageNumber, pageSize: 10, name: typeof value === 'string' && value?.length ? value : ''})).then(response => {
        if (response) {
          let data = response?.data?.items;
          setTotalItems(response?.data?.paging?.totalItems)

        if (typeof value === 'string' && value?.length) {
          let searchDataOptionsList = data?.map(({code, name}) => ({
            label: name, value: code
          }));
          if (response?.data?.paging?.pageNumber === 1) {
            setSearchOptions(searchOptions => [...searchDataOptionsList])
          } else {
            setSearchOptions(searchOptions => [...searchOptions, ...searchDataOptionsList])
          }

          } else {
            let dataOptions = data?.map(({code, name}) => ({
              label: name, value: code
            }));
            setOptionsData(optionsData => [...optionsData, ...dataOptions])
            setPage(pageNo + 1);
            setSearchOptions([])
            setSearchValue(null)
            setSearchPage(1)
            setHasResponse(false)
          }
          setHasResponse(false)
        } else {
          setHasResponse(false)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  };


  return (<>
    {props?.prevState && props?.prevState?.[field?.name] !== field.value ? <span className="field-modified-tag">Modified</span> : null}
    <Select
      {...props}
      showSearch
      allowClear
      placeholder={label}
      value={selectedValue !== undefined ? selectedValue || savedFieldValue : null}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={loadOptions}
      loading={hasResponse}
      bordered={false}
      disabled={isReadOnly || disabled}
      dropdownStyle={{
        padding: '0px'
      }}
      style={{
        width: '100%', border: '1px solid  #CCCCCC', borderRadius: '4px',
      }}
      onFocus={pageNo === 1 ? loadOptions : () => {
      }}
      filterOption={(input, option) => option.children?.toLowerCase().includes(input?.toLowerCase())}
      dropdownRender={(menu) => {
        return (<>
          {menu}
          {(totalItems && options?.length !== totalItems) ? (<>
            <div className="separator separator-solid separator-border-2"></div>
            {hasResponse ? <p className="loading">
              <Spinner animation="grow" size="sm"/> <Spinner animation="grow" size="sm"/> <Spinner animation="grow" size="sm"/></p> : (<button className="btn btn-text-primary btn-hover-light-primary font-weight-bold mb-0 w-100 btn-square" disabled={hasResponse} onClick={() => {
              loadOptions(searchValue, searchPageNo + 1);
            }}>
              Load more options
            </button>)}</>) : null}

        </>)
      }}
      // notFoundContent={searchDataLoading ? <div className="option-spinner">
      //   <div className="spinner spinner-primary spinner-lg"/>
      // </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    >
      {options?.map(({label, value}) => {
        return <Option key={value} value={value}>{label}</Option>
      })}
    </Select>
    {!props.isHideValidations ? (<>
      {((!props.value?.toString()?.length || props.value === "undefined") && props.isRequired) ? (<div className="invalid-feedback d-block">{label} is required</div>) : meta?.error && !((!props.value?.toString()?.length || props.value === "undefined") && props.isRequired) ? (<div className="invalid-feedback d-block">{meta.error}</div>) : null}
    </>) : null}

  </>)
};

