import React, { useState } from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange, sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { getSelectRow } from "../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { FilterAdvanced as Filter } from "../filters";
import { OwnershipColumnFormatter } from "./ColumnFormatters";
import { textFilter } from "react-bootstrap-table2-filter";

export function Pending({
  listData,
  listLoading,
  uiProps,
  totalCount,
  user = [],
  extraColumns = [],
  filters,
  SortFilters,
  hideFilter = false,
  keyword = "keyword",
  permissionsObject = {},
  showTrail,
  module,
  hideColumns
}) {
  const [openedRow, setOpenedRow] = useState(null);
  // Getting ALl Users from State
  const { allUsers } = useSelector(
    state => ({
      allUsers: state.users.allUsers
    }),
    shallowEqual
  );
  let columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter(),
      sortCaret: sortCaret,
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        parameters: {
          identifier: "id",
          showTrail,
          module
        },
        onViewPage: uiProps.openViewPage,
        openEditPage: uiProps.openEditPage,
        openClonePage: uiProps.openClonePage,
        openTrailDialog: uiProps.openTrailDialog,
        onDelete: uiProps.onDelete,
        openedRow: openedRow,
        setOpenedRow: setOpenedRow,
        permissionsObject: permissionsObject
      }
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      filter: textFilter(),
      classes: "font-weight-bold",
      sortCaret: sortCaret
    },
    ...extraColumns,
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        user: user
      },
      hidden: hideColumns?.status || false
    },
    {
      dataField: "lastAction",
      text: "Action Performed",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.EntityActionFormatter
    },
    {
      dataField: "lastUpdated",
      text: "Modified",
      sort: true,
      sortCaret: sortCaret,
      formatter: OwnershipColumnFormatter,
      formatExtraData: {
        allUsers: allUsers
      }
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true
  };

  hideFilter = true;
  return (
    <>
      {hideFilter ? null : (
        <Filter
          filters={SortFilters ? SortFilters : filters}
          uiProps={uiProps}
          keyword={keyword}
        />
      )}
      <DataGrid
        data={
          uiProps?.queryParams?.sortedData
            ? uiProps?.queryParams?.sortedData
            : listData || []
        }
        keyField={"id"}
        isPending={true}
        listLoading={listLoading}
        columns={columns}
        paginationOptions={paginationOptions}
        type={uiProps.queryParams.type}
        onTableParamsChange={getHandlerTableChange(
          uiProps.setQueryParams,
          uiProps
        )}
        isCheckbox={true}
        select={getSelectRow({
          entities: listData,
          selectedItems: [...uiProps.selectedItems],
          setSelectedItems: uiProps.setSelectedItems
        })}
      />
    </>
  );
}
