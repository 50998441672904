import { createSlice } from "@reduxjs/toolkit";

const initialAllocationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  allocationDeleted: false,
  allAllocations: undefined
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "allocations",
  initialState: initialAllocationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    allocationFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.allocationForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    allocationsFetched: (state, action) => {
      const {totalCount, entities, paginationOverride} = action.payload;
      state.listLoading = false;
      state.error = null
      state.makerCheckerAction = false;
      state.allocationDeleted = false;
      if (paginationOverride) {
        state.allAllocations = entities;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearAllocationsList: (state) => {
      state.entities = null;
      state.totalCount = 0;
    },
    allocationCreated: (state) => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    allocationUpdated: (state, action) => {
      const {allocation} = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = allocation;
    },
    allocationDeleted: (state) => {
      state.allocationDeleted = true
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: (state) => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
  }
});
