import { createSlice } from "@reduxjs/toolkit";
import { createDictionary } from "../../../../helper/functions";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entityForEdit: undefined,
  prevEntity: undefined,
  auditLogs: [],
  lastError: null,
  makerCheckerAction: false,
  userDeleted: false,
  allUsers: undefined,
  userStatusChanged: false,
  usersDictionary: {},
  impersonateUser: undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    userFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.userForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    impersonateUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.impersonateUser = action.payload;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    prevUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.prevEntity = action.payload.userForEdit;
      state.error = null;
      state.entities = null;
      state.totalCount = 0;
      state.makerCheckerAction = false;
    },
    usersFetched: (state, action) => {
      const { totalCount, entities, paginationOverride } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.makerCheckerAction = false;
      state.userDeleted = false;
      state.prevEntity = undefined;
      state.auditLogs = [];
      if (paginationOverride) {
        let usersDictionary = createDictionary(entities || [], "id");
        state.allUsers = entities;
        state.usersDictionary = usersDictionary;
      } else {
        state.entities = entities;
        state.totalCount = totalCount;
        state.entityForEdit = undefined;
      }
    },
    clearUsersList: state => {
      state.entities = null;
      state.totalCount = 0;
    },
    userCreated: state => {
      state.actionsLoading = false;
      state.totalCount = 0;
      // state.entityForEdit = undefined;
      // state.entities = null;
      state.error = null;
    },
    userUpdated: (state, action) => {
      const { user } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.entities = null;
      state.totalCount = 0;
      state.entityForEdit = user;
    },
    userDeleted: state => {
      state.userDeleted = true;
      state.error = null;
      state.actionsLoading = false;
    },
    actionDrafted: state => {
      state.actionsLoading = false;
      state.makerCheckerAction = true;
      state.error = null;
    },
    activationCode: state => {
      state.error = null;
      state.actionsLoading = false;
    },
    changePassword: state => {
      state.error = null;
      state.actionsLoading = false;
    },
    changeUserStatus: state => {
      state.error = null;
      state.actionsLoading = false;
      state.userStatusChanged = true;
    },
    auditLogsFetched: (state, action) => {
      const { auditLogs } = action.payload;
      state.actionsLoading = false;
      state.auditLogs = auditLogs;
      state.makerCheckerAction = false;
    },
    auditLogsFetchedById: (state, action) => {
      const { auditLogs, params } = action.payload;
      let fromLog = auditLogs.find(log => log.id === params.Ids[0]);
      let toLog = auditLogs.find(log => log.id === params.Ids[1]);
      state.actionsLoading = false;
      state.makerCheckerAction = false;
      state.entityForEdit = toLog?.payload;
      state.prevEntity = fromLog?.payload;
    },
    usersAgainstWorkflow: state => {
      state.actionsLoading = false;
      state.error = null;
    },
    forceChangePassword: state => {
      state.error = null;
      state.actionsLoading = false;
    }
  }
});
