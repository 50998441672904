/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export const PrimaryKeyColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {onChangeKey}
) => {

  return (
    <>
      <i className={`fa fa-key cursor-pointer ${row.primaryKey === "True" ? "primary-key-icon" : ""}`} aria-hidden="true"
         onClick={() => {
           onChangeKey(row, rowIndex);
         }}
      >
      </i>
    </>
  );
}
