import React, { useState } from "react";
import ItemsCarousel from 'react-items-carousel';
import { ImportInstructions } from "../../../../_custom/_helpers/ImportInstructions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const ImportHelperBody = ({ name }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <ItemsCarousel
        infiniteLoop
        gutter={0}
        activePosition={'center'}
        chevronWidth={10}
        disableSwipe={true}
        alwaysShowChevrons={true}
        numberOfCards={1}
        slidesToScroll={1}
        showSlither={false}
        firstAndLastGutter={false}
        activeItemIndex={activeIndex}
        requestToChangeActive={setActiveIndex}
        outsideChevron={true}
        rightChevron={
          <button className="btn btn-icon btn-light h-100">
            <i className="flaticon2-right-arrow" />
          </button>
        }
        leftChevron={
          <button className="btn btn-icon btn-light h-100">
            <i className="flaticon2-left-arrow" />
          </button>
        }
      >
        <div className="px-4">
          <p className="h4 mb-2">Adding data through Form:</p>
          <img className={`d-block ${name === "Workflows" ? "mx-auto" : "w-100"}`} alt="" src={toAbsoluteUrl(ImportInstructions?.[name]?.form || '')} />
        </div>
        <div className="px-4">
          <p className="h4">Adding data through Excel: </p>
          <img className={`d-block ${name === "Fields" ? "w-100" : "mx-auto"}`} alt="" src={toAbsoluteUrl(ImportInstructions?.[name]?.excel || '')} />
        </div>
        {ImportInstructions?.[name]?.edit ? (
          <div className="px-4">
            <p className="h4">Editing data through Excel: </p>
            <img className={`d-block ${name === "Fields" ? "w-100" : "mx-auto"}`} alt="" src={toAbsoluteUrl(ImportInstructions?.[name]?.edit || '')} />
          </div>
        ) : null}
        {ImportInstructions[name]?.enum ?
          <div className="px-4">
            <p className="h4">Required enums: </p>
            <img className="d-block mx-auto" alt="" src={toAbsoluteUrl(ImportInstructions?.[name]?.enum || '')} />
          </div>
          : null}
      </ItemsCarousel>
    </div>
  )
}
