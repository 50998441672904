/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { clientCredentials } from "./modules/Auth/_redux/authCrud";

export function Routes() {
  const excludeList = ["reset", "activate", "customer-interview"];

  const [prevUrl, setPrevUrl] = useState(null);

  const location = useLocation();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  useEffect(() => {
    clientCredentials().then(response => {
      if (response?.status === 200) {
        localStorage.setItem("cc", response?.data?.access_token);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthorized && !location?.pathname?.includes("/auth")) {
      if (location?.pathname !== "/logout") {
        setPrevUrl(location?.pathname);
      } else if (location?.pathname === "/logout") {
        setPrevUrl(null);
      } else if (location?.pathname === "/") {
        setPrevUrl(null);
      }
    }
  }, [isAuthorized, location]);

  const KYCRoutes = lazy(() => import("./modules/Kyc/CustomerRoutes"));
  const MailApprovalRoutes = lazy(() =>
    import("./modules/Workflows/MailApprovalRoutes")
  );

  return (
    <Switch>
      <Route path="/customer-interview" component={KYCRoutes} />
      <Route path="/mail-approval" component={MailApprovalRoutes} />

      {!isAuthorized || excludeList?.includes(location?.pathname) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized || excludeList?.includes(location?.pathname) ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage location={location} prevUrl={prevUrl} />
        </Layout>
      )}
    </Switch>
  );
}
