import axios from "axios";
const qs = require("qs");

const UsersURL = "/identity/api/v1.0/users";
const AuditURL = "/fury/api/v1.0/AuditLogs/Users";
const oauthURL = "/identity/api/v1.0/clientCredentialsBasedUsers";
const workflowsURL = "/edith/api/v1.0/workflows";

export function createUser({ user }, headers) {
  return axios.post(UsersURL, { ...user }, headers);
}

export function getAllUsers(params, headers) {
  // params.groupCodes = params.groupCodes ? params.groupCodes.join(",") : null;
  return axios.get(UsersURL, {
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    headers
  });
}

export function fetchUsersMinimals(params) {
  return axios.get(`${oauthURL}/GetAllUsersMinimal`, {
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export function getUserById({ userId, category }, headers) {
  return axios.get(UsersURL + `/${userId}?category=${category}`, headers);
}

export function updateUser({ user, userType, id }, headers) {
  return axios.put(
    `${UsersURL}${userType === 0 ? `/${user.code}` : `/Drafts/` + id}`,
    { ...user },
    headers
  );
}

export function deleteUser({ id, isDraft = false }) {
  return axios.delete(`${UsersURL}/${isDraft ? "Drafts/" + id : id}`);
}

export function actOnDraft({ id, body }, headers) {
  return axios.post(UsersURL + `/Drafts/${id}/act`, { ...body }, headers);
}

export function postActivation({ id, body }) {
  return axios.post(UsersURL + `/ResendActivation?id=${id}`, { ...body, id });
}

export function changePassword(params) {
  return axios.post(UsersURL + `/ChangePassword`, { ...params });
}

export function changeUserStatus(params, headers) {
  return axios.post(UsersURL + `/BlockOrUnblockUsers`, { ...params }, headers);
}

export function auditUsers(params) {
  return axios.get(AuditURL + `/${params.code}`);
}

export function fetchAuditsById(params) {
  return axios.get(
    AuditURL + `/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`
  );
  // return axios.get(AuditURL + `/${params.code}`, {
  //   params,
  //   paramsSerializer: function(params) {
  //     return qs.stringify(params, {arrayFormat: 'comma'})
  //   },
  // });
}

export function getUsersAgainstWorkflows(params, headers) {
  return axios.get(workflowsURL + `/UsersAgainstWorkflows`, {
    params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    headers
  });
}
export function forceChangePassword(params, headers) {
  return axios.post(UsersURL + `/BulkPasswordReset`, { ...params }, headers);
}
