/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-2.scss";
import Password from "./Password";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import { version } from "../../../../../package.json";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-4 login-signin-on d-flex flex-row-fluid"
          id="kt_login"
        >
          <div className="context d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
            <div className="login-form text-center p-7 position-relative overflow-hidden">
              <div className="d-flex flex-center mb-15">
                <Link to="/" className="flex-column-auto mt-5">
                  <img
                    alt="Logo"
                    className="max-h-50px"
                    src={toAbsoluteUrl("/media/logos/logo-main.png")}
                  />
                </Link>
              </div>

              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/activate" component={Password} />
                <ContentRoute path="/auth/reset" component={Password} />
                <ContentRoute
                  path="/auth/change-password"
                  component={Password}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/two-factor-authentication"
                  component={TwoFactorAuthentication}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>

              <div className="text-dark order-2 order-md-1">
                <span
                  // href=""
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                >
                  {`Version ${version}`}
                </span>
              </div>
            </div>
          </div>

          <div className="area">
            <ul className="circles">
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
