import React, { useEffect, useState } from "react";
import { AddEditViewForm } from "../../../../_partials/controls";
import { inputTypes } from "../../../../_helpers/commonStaticVariables";
import { shallowEqual, useSelector } from "react-redux";

export const FieldsMapping = ({
  variables,
  addBtn,
  values,
  mode,
  updateVariablesValues
}) => {
  const [renderFields, setRenderFields] = useState([]);

  const { allFields } = useSelector(
    state => ({
      allFields: state.fields.entities
    }),
    shallowEqual
  );

  useEffect(() => {
    if (variables?.length) {
      let tempRenderFields = variables?.map(variable => {
        return {
          name: variable,
          dataType: inputTypes.findIndex(field => field.type === "select"),
          type: "select",
          code: variable,
          isRequired: true,
          masterDataType: "fields"
        };
      });

      setRenderFields(tempRenderFields);
    }
  }, [variables]);

  return (
    <AddEditViewForm
      layout={"col-sm-6 mb-2"}
      showSkeleton={true}
      skeletonLength={variables?.length}
      fieldsValues={values}
      fieldsList={renderFields}
      dropdownOptions={{
        fields: allFields || []
      }}
      btnRef={addBtn}
      // onChangeMethods={{ ...fieldChangeHandlers }}
      enableReinitialize={true}
      saveField={updateVariablesValues}
      overrideMode={mode || "edit"}
    />
  );
};
