import * as requestFromServer from "./usersCrud";
import { callTypes, usersSlice } from "./usersSlice";

const { actions } = usersSlice;

export const fetchUsersMinimals = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchUsersMinimals(params)
    .then(response => {
      if (params.notSaveInStore) return response;

      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.usersFetched({
          totalCount,
          entities,
          paginationOverride: true
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUsers = (params, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUsers(params, headers)
    .then(response => {
      if (params.notSaveInStore) return response;

      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.usersFetched({
          totalCount,
          entities,
          paginationOverride: params?.paginationOverride
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearUsersList = () => dispatch => {
  dispatch(actions.clearUsersList());
};

export const fetchUser = (id, headers) => dispatch => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserById(id, headers)
    .then(response => {
      const user = response.data;
      if (id?.type === "PrevState") {
        dispatch(actions.prevUserFetched({ userForEdit: user }));
      } else {
        dispatch(actions.userFetched({ userForEdit: user }));
      }
      // dispatch(actions.userFetched({userForEdit: user}));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchImpersonateUser = (userId, category) => dispatch => {
  if (!userId) {
    return dispatch(actions.impersonateUserFetched(undefined));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserById({ userId, category })
    .then(response => {
      const user = response.data;
      dispatch(actions.impersonateUserFetched(user));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const removeImpersonate = () => dispatch => {
//   dispatch(actions.removeImpersonateUser());
// }

export const deleteUser = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUser(queryParams)
    .then(response => {
      if (!queryParams?.isBulk) {
        dispatch(actions.userDeleted({ queryParams }));
      }
      if (queryParams?.isBulk && queryParams?.lastCall) {
        dispatch(actions.userDeleted({ queryParams }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUser = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createUser(queryParams, headers)
    .then(response => {
      const { user } = response.data;
      dispatch(actions.userCreated({ user }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateUser = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(queryParams, headers)
    .then(response => {
      dispatch(actions.userUpdated(queryParams));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const actOnDraft = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams, headers)
    .then(response => {
      const { user } = response.data;
      dispatch(actions.actionDrafted({ user }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAllUsersWithoutPagination = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUsers(params)
    .then(response => {
      const entities = response?.data?.items;
      dispatch(actions.allUsersFetched({ entities }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const resendActivation = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .postActivation(queryParams)
    .then(response => {
      const { user } = response?.data;
      dispatch(actions.activationCode({ user }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const changePassword = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePassword(queryParams)
    .then(response => {
      const data = response?.data;
      dispatch(actions.changePassword({ data }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const changeUserStatus = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changeUserStatus(queryParams, headers)
    .then(response => {
      dispatch(actions.changePassword({ response }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update user status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const auditUsers = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .auditUsers(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetched({ auditLogs }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAuditsById = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAuditsById(params)
    .then(response => {
      const auditLogs = response?.data;
      dispatch(actions.auditLogsFetchedById({ auditLogs, params }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUsersAgainstWorkflows = (params, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUsersAgainstWorkflows(params, headers)
    .then(response => {
      dispatch(actions.usersAgainstWorkflow());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const forceChangePassword = (queryParams, headers) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .forceChangePassword(queryParams, headers)
    .then(response => {
      dispatch(actions.forceChangePassword({ response }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update user status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
