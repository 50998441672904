import axios from "axios";
import qs from "qs";

const detailfieldsURL = "/friday/api/v1.0/fields/Records";
const fieldsURL = "/friday/api/v1.0/fields";
const AuditURL = "/fury/api/v1.0/AuditLogs/Fields";

export function createField({ field }) {
  return axios.post(fieldsURL, { ...field });
}

export function getAllFields(params) {
  return axios.get(fieldsURL, {
    params,
    paramsSerializer: paramsInner =>
      qs.stringify(paramsInner, { arrayFormat: "comma", encode: false })
  });
}

export function getFieldsWithDetails(params) {
  params.fieldCodes = params.fieldCodes ? params.fieldCodes.join(",") : null;
  return axios.get(detailfieldsURL, { params });
}

export function getFieldById({ fieldId, category }) {
  return axios.get(fieldsURL + `/${fieldId}?category=${category}`);
}

export function updateField({ field, fieldType, id }) {
  return axios.put(
    `${fieldsURL}${fieldType === 0 ? `/${field.code}` : `/Drafts/` + id}`,
    { ...field }
  );
}

export function deleteField({ id, isDraft = false }) {
  return axios.delete(`${fieldsURL}/${isDraft ? "Drafts/" + id : id}`);
}
export function actOnDraft({ id, body }) {
  return axios.post(fieldsURL + `/Drafts/${id}/act`, { ...body });
}
export function auditFields(params) {
  return axios.get(AuditURL + `/${params.code}`);
}

export function fetchAuditsById(params) {
  return axios.get(
    AuditURL + `/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`
  );
}
