import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import InputMask from "react-input-mask";

export function Filter({filters = [], keyword, onChange, masked = false, placeholderOverride = "", key}) {
  return (
    <div className={`row ${keyword !== "identifier" ? "mb-5" : ""}`} key={key}>
      <div className="col-md-12">
        <div className="form-control h-100 p-2">
          <div className="d-flex justify-content-between">
            <div className="symbol symbol-40">
                <span className="symbol-label bg-transparent">
                  <span className="svg-icon svg-icon-lg svg-icon-light-dark">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} className="h-75 align-self-end"/>
                  </span>
                </span>
            </div>
            {masked ? (
              <InputMask
                mask="99999-9999999-9"
                maskChar={null}
                className="form-control bg-transparent border-0 pl-0"
                placeholder={`Filter by ${placeholderOverride}`}
                onChange={event => onChange(keyword, event?.target?.value)}
              />
            ) : (
              <input
                type="text"
                onChange={event => onChange(keyword, event.target.value)}
                placeholder={`Filter by ${placeholderOverride || keyword}`}
                className="form-control bg-transparent border-0 pl-0"
              />
            )}

            {filters.map(item => item.type === "select" ? (
              <select
                key={item.code}
                aria-label={item.name}
                onChange={event => onChange(item.code, event.currentTarget.value)}
                className="form-control form-select bg-transparent mr-2">
                {item.options.map(option => <option key={option.value} value={option.value}>{option.title}</option>)}
              </select>
            ) : null)}
          </div>
        </div>
      </div>
    </div>
  );
}
