export const workflowStatus = {
  InProgress: 0,
  Completed: 1,
  Unassigned: 2,
  AwaitingAuthentication: 3,
  Cancelled: 4,
  Rejected: 5,
  AwaitingPreChecksVerification: 6,
  Discrepant: 7,
  Withdrawn: 8,
  Hold: 9,
  Freezed: 10
};

export const externalSystem = {
  Cascade: 0,
  EasyPaisa: 1
};

export const workflowStageStatus = {
  InProgress: 0,
  Completed: 1,
  AwaitingAuthentication: 2,
  Archived: 3,
  AwaitingPreChecksVerification: 4,
  Discrepant: 5
};

export const workflowActivityType = {
  WorkflowInitiated: 0,
  StageInitiated: 1,
  StageCompleted: 2,
  StagePreceded: 3,
  BypassStageAuthentication: 4,
  ApproveStageAuthentication: 5,
  RejectStageAuthentication: 6,
  RecommendedApprove: 7,
  RecommendedBypass: 8,
  WorkflowCancelled: 9,
  WorkflowRejected: 10,
  WorkflowRecalled: 11,
  PreCheckApproved: 12,
  PreCheckRejected: 13,
  Rectification: 14,
  Escalated: 15,
  Resumed: 16,
  WorkflowReassigned: 17,
  WorkflowHold: 18,
  WorkflowFroze: 19,
  WorkflowAssigned: 20,
  DraftStageAuthentication: 21,
  AutoAssignment: 22,
  MaintainedPreviousAssignment: 23,
  StageProceed: 24,
  AuthenticationMoveBack: 25,
  ExceptionApproved: 26,
  ExceptionRejected: 27,
  DiscrepancyCreated: 28,
  DiscrepancyRecreated: 29,
  DiscrepancyDeleted: 30,
  DiscrepancyResolved: 31,
  WorkflowCompleted: 32
};

export const setFlowsCurrentStatus = {
  InProgress: 0,
  Completed: 1,
  Failed: 2
};

export const setFlowsExternalSystemStatus = {
  Pending: 0,
  InProgress: 1,
  Completed: 2,
  Failed: 3
};

export const exceptionType = {
  Authorization: 0,
  Precheck: 1
};

export const discrepancyStatus = {
  New: 0,
  PendingApproved: 1,
  Approved: 2,
  Settled: 3
};

export const actionTaken = {
  Created: 0,
  Resolved: 1,
  Approved: 2,
  Rejected: 3,
  Settled: 4
};

export const callStatusEnums = {
  Pending: 0,
  InProgress: 1,
  Done: 2,
  Expire: 3,
  Failed: 4
  // BeforeScheduleTime: 4,
  // Rescheduled: 5,
  // Reassigned:6,
  // Cancelled:7,
  // AwaitingLiveness:8,
  // LivenessFailed:9,
  // AwaitingExcel: 10,
  // FacialComparisonFailed: 11
};

export const imageExtensions = [
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif"
];

export const interviewActions = {
  Success: 0,
  Failed: 1
};

export const discrepancyTypes = {
  General: 0,
  Document: 1
};

export const adviceTypes = {
  General: 0,
  Document: 1,
  Both: 2
};

export const createdDiscrepancyStatus = {
  Pending: 0,
  Resolved: 1,
  Deleted: 2
};

export const createdAdviceStatus = {
  Pending: 0,
  Resolved: 1,
  Deleted: 2
};

export const trailActivityTypes = {
  Created: 0,
  Resolved: 1,
  Recreated: 2,
  Deleted: 3
};

export const adviceTrailActivityTypes = {
  Created: 0,
  Advised: 1,
  Renewed: 2,
  Deleted: 3
};

export const discrepancyActions = {
  Created: 0,
  Resolved: 1,
  Deleted: 2
};

export const adviceActions = {
  Created: 0,
  Advised: 1,
  Deleted: 2
};

export const kiborUnits = {
  Week: 0,
  Month: 1,
  Year: 2
};

export const kiborTenors = {
  One: 1,
  Two: 2,
  Three: 3,
  Six: 6,
  Nine: 9
};

export const fieldLayout = {
  ColMd12: 0,
  ColMd6: 1,
  ColMd4: 2,
  ColMd3: 3
};

export const documentTypes = {
  CheckList: 0,
  AdditionalDocument: 1
};

export const checkListTypes = {
  Document: 0,
  ExternalSystem: 1,
  General: 2,
  Biometric: 3
};

export const transactionTypes = {
  LimitApproved: 0,
  Disbursement: 1,
  Repayment: 2,
  Closure: 3,
  Accrual: 4,
  DPD: 5,
  Bill: 6
};

export const defaultValueTypes = {
  Static: 0,
  Field: 1
};

export const screeningCheckTypes = {
  Conditional: 0,
  List: 1
};

export const accountStatementStatus = {
  Pending: 0,
  Completed: 1,
  Error: 2
};

export const proceedResponseType = {
  Success: 0,
  Error: 1,
  Warning: 2
};

export const AuthenticationOriginActivityType = {
  Proceed: 0,
  Recommend: 1,
  Bypass: 2,
  MoveBack: 3
};
