import React from 'react';
import { Status, StatusCssClasses, StatusTitles } from '../UIHelpers';
import { Overlay } from "../../controls";

export const StatusColumnFormatter = (cellContent, row, rowIndex, {user, allUsers, showOwnership}) => {
  let owner = (allUsers || []).find(item => item.id === row?.ownership)?.name || 'N/A'
  return (
    <>
      {row?.status === Status?.Pending && showOwnership ? (
        <Overlay popoverContent={`Currently in the bucket of ${owner}`}>
        <span className={`label label-lg label-light-${StatusCssClasses[row.status]} label-inline`}>
          {row.status === Status.SFA ? (row.actor === user?.id ? "Sent for Approval" : "Awaiting Approval") : StatusTitles[row.status]}
				</span>
        </Overlay>
      ) : (
        <span className={`label label-lg label-light-${StatusCssClasses[row.status]} label-inline`}>
          {row.status === Status.SFA ? (row.actor === user?.id ? "Sent for Approval" : "Awaiting Approval") : StatusTitles[row.status]}
				</span>
      )}
    </>
  )
};
