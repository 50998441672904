import React, { useState } from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange, sortCaret } from "../../../_metronic/_helpers";
// import { sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { DataGrid } from "../../../_metronic/_partials/lists";
import { textFilter } from 'react-bootstrap-table2-filter';

export function Customers({listData, listLoading, uiProps, totalCount, user, permissionsObject = {}, filters, SortFilters, hideFilter = false, keyword = "keyword"}) {
  const [openedRow, setOpenedRow] = useState(null);

  const columns = [
    {
      dataField: "code",
      text: "National ID",
      sort: true,
      sortCaret: sortCaret,
      filter: textFilter({
        className: 'font-weight-bold ',
      }),
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        parameters: {
          identifier: "code",
          level1: "code",
          isHideOptions: true
        },
        onViewPage: uiProps.openViewPage,
        openedRow: openedRow,
        setOpenedRow: setOpenedRow,
        permissionsObject: permissionsObject,

      }
    },
    {
      dataField: "CardCustID",
      text: "Customer Id",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.GenericDynamicFormatter,
      classes: "font-weight-bold",
      filter: textFilter({
        className: 'font-weight-bold ',
      }),
      formatExtraData: {
        parameters: {
          identifier: "CardCustID",
          objectName: "values"
        },
      }
    }, {
      dataField: "values.EmailAddress",
      text: "Email Address",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.GenericDynamicFormatter,
      classes: "font-weight-bold",
      formatExtraData: {
        parameters: {
          identifier: "EmailAddress",
          objectName: "values"
        },
      }
    },
    {
      dataField: "values.DOB",
      sort: true,
      sortCaret: sortCaret,
      text: "Date of Birth",
      formatter: (cellContent) => columnFormatters.DateColumnFormatter(cellContent, '', 'Do MMM YYYY', true)
    },
    {
      dataField: "values.MainAddress",
      text: "Main Address",
      sort: true,
      sortCaret: sortCaret,
      classes: "font-weight-bold",
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true,
  };

  return (
    <>
      {/*<Filter filters={SortFilters ? SortFilters : filters} masked={true} placeholderOverride={'National Id'} uiProps={uiProps} keyword={keyword}/>*/}
      <DataGrid
        data={uiProps?.queryParams?.sortedData || listData || []}
        listLoading={listLoading}
        columns={columns}
        paginationOptions={paginationOptions}
        type={uiProps.queryParams.type}
        onTableParamsChange={getHandlerTableChange(uiProps.setQueryParams)}
        isCheckbox={false}
        keyField='code'
      />
    </>
  );
}
