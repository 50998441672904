import * as requestFromServer from "./crud";
import { callTypes, slice } from "./slice";

const { actions } = slice;

export const fetchTemplates = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllTemplates(params)
    .then(response => {
      const entities = response?.data?.items;
      const totalCount = response.data?.paging?.totalItems;
      dispatch(
        actions.templatesFetched({
          totalCount,
          entities,
          paginationOverride: params?.paginationOverride
        })
      );
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch template";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearTemplatesList = () => dispatch => {
  dispatch(actions.clearTemplatesList());
};

export const fetchTemplate = id => dispatch => {
  if (!id) {
    return dispatch(actions.templateFetched({ templateForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTemplateById(id)
    .then(response => {
      const template = response.data;
      dispatch(actions.templateFetched({ templateForEdit: template }));
      // dispatch(actions.userFetched({userForEdit: user}));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find template";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTemplate = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTemplate(queryParams)
    .then(response => {
      if (!queryParams?.isBulk) {
        dispatch(actions.templateDeleted({ queryParams }));
      }
      if (queryParams?.isBulk && queryParams?.lastCall) {
        dispatch(actions.templateDeleted({ queryParams }));
      }
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't delete template";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTemplate = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTemplate(queryParams)
    .then(response => {
      const { template } = response.data;
      dispatch(actions.templateCreated({ template }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create template";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const updateTemplate = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTemplate(queryParams)
    .then(response => {
      dispatch(actions.templateUpdated(queryParams));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't update template";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return error?.response;
    });
};

export const actOnDraft = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .actOnDraft(queryParams)
    .then(response => {
      const { user } = response.data;
      dispatch(actions.actionDrafted({ user }));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't act on draft";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProductStages = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductStages(id)
    .then(response => {
      dispatch(actions.productStagesFetched());
      return response.data;
    })
    .catch(error => {
      error.clientMessage = "Can't find Stages";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
