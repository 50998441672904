import React from 'react';
import * as usersActions from "../Identity/_redux/users/usersActions";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { Dialog } from "../../../_custom/_partials/Dialog";


export function TestUserModal({show, onHide}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {user} = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );
  const removeImpersonateUser = () => {
    localStorage.removeItem('ImpersonateUser')
    dispatch(usersActions.fetchImpersonateUser())
    onHide()
    let redirectPath = localStorage.getItem('OriginalUserPath')
    history.push(redirectPath);
    localStorage.removeItem('OriginalUserPath')
  }
  return (
    <Dialog
      size={'md'}
      isCentered
      show={show}
      onHide={onHide}
      modalTitle={
        <h4 id="contained-modal-title-vcenter" className="text-danger text-center">
          You are in Test Mode
        </h4>
      }
      modalBody={
        <>
          <h4 className="text-center text-danger">Warning!</h4>
          <p className="text-center">
            If you want to make changes in the Cascade. Kindly switch to <br/> <b>{user?.name}</b>.
          </p>
        </>
      }
      modalFooter={
        <>
          <button type="button" className="btn btn-light btn-xs" onClick={onHide}>
            Close
          </button>
          <button type="button" className="btn btn-success" onClick={removeImpersonateUser}>
            <i className="fa fa-redo"/>
            {user?.name}
          </button>
        </>
      }
    />
  )
}
