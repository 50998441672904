import React, { useEffect } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const getFieldCSSClasses = (
  touched,
  errors,
  isHideValidations,
  field,
  prevState
) => {
  const classes = ["form-control"];

  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }
  if (prevState && prevState[field?.name] !== field.value) {
    classes.push("field-modified");
  }

  return classes.join(" ");
};

export function Phone({
  form,
  field, // { name, value, onChange, onBlur }
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  defaultValue,
  meta,
  name,
  isReadOnly,
  disabled,
  prevState,
  ...props
}) {
  const { touched, error } = meta;

  useEffect(() => {
    const inputElement = document.querySelector(
      `input[name="${name}"][type="tel"]`
    );

    if (inputElement) {
      const handlePasteEvent = event => handlePaste(event, inputElement);
      inputElement.addEventListener("paste", handlePasteEvent);

      return () => {
        inputElement.removeEventListener("paste", handlePasteEvent);
      };
    }
  });

  const handlePaste = event => {
    let element = event.target;
    let pasteValue = event.clipboardData.getData("text");
    let currentValue = element.value;

    if (pasteValue.startsWith("92") && currentValue.startsWith("+92")) {
      pasteValue = pasteValue.substring(0).replace(/^../, "");
      pasteValue = currentValue + "" + pasteValue;
    }

    if (pasteValue.startsWith("0")) {
      pasteValue = "+92" + pasteValue.slice(1);
    }

    element.value = pasteValue;

    props.onChange({
      target: {
        name,
        value: pasteValue
      }
    });
    event.preventDefault();
  };

  return (
    <>
      {label && (
        <label>
          {label}
          {props.required ? <span className="text-danger"> *</span> : null}
        </label>
      )}

      {prevState && prevState?.[field?.name] !== field.value ? (
        <span className="field-modified-tag">Modified</span>
      ) : null}

      {props?.tags?.find(tag => tag?.code === "ContactNumber" || tag === "ContactNumber") !== undefined ? (
        <PhoneInput
          inputClass={`form-control py-2 px-6 w-100 pl-15 ${getFieldCSSClasses(
            touched,
            error,
            props.isHideValidations,
            field,
            prevState
          )}`}
          buttonClass={`${
            prevState && prevState[field?.name] !== field.value
              ? "field-modified"
              : ""
          }`}
          // buttonClass="field-modified"
          {...field}
          {...props}
          disabled={isReadOnly || disabled || field.disabled}
          value={String(field.value)}
          inputProps={{
            name,
            required: props.required
          }}
          onBlur={() => {
            let value = field.value;

            if (value.startsWith("+")) {
              value = value.slice(1);
            }

            if (value && value.length === 10 && !value.startsWith("923")) {
              if (value && value.startsWith("3")) {
                value = value.slice(1);
              }
              value = "923" + value;
            }
            props.onChange({
              target: {
                name,
                value
              }
            });
          }}
          onChange={(event, country) => {
            let value = event;
            if (value.startsWith("+")) {
              value = value.slice(1);
            }

            const firstThreeDigits = value.slice(0, 3);
            const newFirstThreeDigits = "923";
            value = value.replace(firstThreeDigits, newFirstThreeDigits);
            if (firstThreeDigits === "") {
              value = "";
            }

            props.onChange({
              target: {
                name,
                value
              }
            });
          }}
          country="pk"
          onlyCountries={["pk"]}
          countryCodeEditable={true}
        />
      ) : props?.tags?.find(tag => tag?.code === "LandlineNumber" || tag === "LandlineNumber") !== undefined ?  (
        <PhoneInput
          inputClass={`form-control py-2 px-6 w-100 pl-15 ${getFieldCSSClasses(
            touched,
            error,
            props.isHideValidations,
            field,
            prevState
          )}`}
          buttonClass={`${
            prevState && prevState[field?.name] !== field.value
              ? "field-modified"
              : ""
          }`}
          // buttonClass="field-modified"
          {...field}
          {...props}
          disabled={isReadOnly || disabled || field.disabled}
          value={String(field.value)}
          inputProps={{
            name,
            required: props.required
          }}
          onBlur={() => {
            let value = field.value;

            if (value && value.length === 8 && !value.startsWith("92")) {
              value = "92" + value;
            }
            props.onChange({
              target: {
                name,
                value
              }
            });
          }}
          onChange={(event, country) => {
            let value = event;
            const firstTwoDigits = value.slice(0, 2);
            const newFirstTwoDigits = "92";
            value = value.replace(firstTwoDigits, newFirstTwoDigits);
            if (firstTwoDigits === "") {
              value = "";
            }

            props.onChange({
              target: {
                name,
                value
              }
            });
          }}
          country="pk"
          onlyCountries={["pk"]}
          countryCodeEditable={true}
        />
      ) : null}

      {prevState && prevState?.[field?.name] !== field.value ? (
        <p className="pt-1 mb-0">
          <span style={{ fontWeight: "bolder" }}>Before: </span>
          <span>
            {prevState?.[field?.name] || (
              <i style={{ fontSize: "13px" }}>No Data</i>
            )}
          </span>
        </p>
      ) : null}

      {!props.isHideValidations && withFeedbackLabel && touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
