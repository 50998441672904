

export const onSetPriority = (type, rowIndex, swapIndex, data) => {


  if(type !== "swap" && type !== "place") {
    console.error("Invalid operation type for priority setting provided");
    return data;
  }

  if(swapIndex > data.length - 1) {
    console.error("Swap index out of range");
    return data;
  }

  let tempData = [...data];

  // update priority as per new index
  tempData[rowIndex] = {
    ...tempData[rowIndex],
    priority: swapIndex + 1
  }
  if(type === "swap") {
    // es6 short hand to swap two array elements
    [tempData[rowIndex], tempData[swapIndex]] = [tempData[swapIndex], tempData[rowIndex]];
  }
  else if(type === "place") {
    // copy the element to be placed in temp variable
    // delete it from its position
    // insert into swapping index, insertion will automatically increment an index

    let tempRowData = {...tempData[rowIndex]};
    // delete from actual position
    tempData.splice(rowIndex, 1);
    // insert in new position
    tempData.splice(swapIndex, 0,  tempRowData);
  }
  // return new array with appropriate priority remapping
  return tempData.map((item, index) => ({...item, priority: index + 1}))
}
