import axios from 'axios';
// const qs = require('qs');
const ModulesURL = '/identity/api/v1.0/modules';
const AuditURL = '/fury/api/v1.0/AuditLogs/Modules'

export function createModule({module}) {
  return axios.post(ModulesURL, {...module});
}

export function getAllModules(params) {
  return axios.get(ModulesURL, {params});
}

export function getModuleById({moduleId, category}) {
  return axios.get(ModulesURL + `/${moduleId}?category=${category}`);
}

export function updateModule({module, moduleType, id}) {
  return axios.put(`${ModulesURL}${moduleType === 0 ? `/${module.code}` : `/Drafts/` + id}`, {...module});
}

export function deleteModule({id, isDraft = false}) {
  return axios.delete(
    `${ModulesURL}/${
      isDraft ? "Drafts/" + id : id
    }`
  );
}

export function actOnDraft({id, body}) {
  return axios.post(ModulesURL + `/Drafts/${id}/act`, {...body});
}

export function auditModule(params) {
  return axios.get(AuditURL + `/${params.code}`);
}

export function fetchAuditsById(params) {
  return axios.get(AuditURL + `/${params.code}?Ids=${params.Ids[0]},${params.Ids[1]}`);
  // return axios.get(AuditURL + `/${params.code}`, {
  //   params,
  //   paramsSerializer: function(params) {
  //     return qs.stringify(params, {arrayFormat: 'comma'})
  //   },
  // });
}
