import React from 'react';
import DualListBox from 'react-dual-listbox';
import { sortDropdownsOptions } from "../../../_helpers";

export function ListBox ({name, touched, error, options, selected, onChange, ...props}) {
    return (
        <DualListBox
            {...props}
            name={name}
            options={sortDropdownsOptions(options)}
            selected={selected}
            onChange={onChange}
        />
    )
}
