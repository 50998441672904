import React, { useState } from "react";
import * as uiHelpers from "./UIHelpers";
import { getHandlerTableChange, sortCaret } from "../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import {
  LastUpdatedColumnFormatter,
  TypeColumnFormatter
} from "./ColumnFormatters";
import { DataGrid } from "../../../_metronic/_partials/lists/index";
import { shallowEqual, useSelector } from "react-redux";
import { FilterAdvanced as Filter } from "../filters";
import { getSelectRow } from "../../_helpers";
import { textFilter } from "react-bootstrap-table2-filter";

export function Approved({
  listData,
  listLoading,
  uiProps,
  totalCount,
  user,
  filters,
  SortFilters,
  hideFilter = false,
  keyword = "keyword",
  permissionsObject = {},
  isCheckbox,
  extraColumns = [],
  typeFilter,
  showTrail,
  module,
  hideColumns
}) {
  const [openedRow, setOpenedRow] = useState(null);

  // Getting ALl Users from State
  const { allUsers } = useSelector(
    state => ({
      allUsers: state.users.allUsers
    }),
    shallowEqual
  );

  let columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      filter: textFilter(),
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        parameters: {
          identifier: "id",
          showTrail,
          module
        },
        onViewPage: uiProps.openViewPage,
        openClonePage: uiProps.openClonePage,
        openCopyPage: uiProps.openCopyPage,
        openPastePage: uiProps.openPastePage,
        openEditPage: uiProps.openEditPage,
        openTrailDialog: uiProps.openTrailDialog,
        onDelete: uiProps.onDelete,
        openedRow: openedRow,
        setOpenedRow: setOpenedRow,
        permissionsObject: permissionsObject
      }
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      classes: "font-weight-bold",
      filter: textFilter({
        className: "font-weight-bold"
      }),
      sortCaret: sortCaret
    },
    ...extraColumns,
    {
      dataField: "type",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      filter: typeFilter,
      formatter: TypeColumnFormatter
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        user: user,
        allUsers: allUsers,
        showOwnership: true
      },
      hidden: hideColumns?.status || false
    },
    // {
    //   dataField: "createdAt",
    //   text: "Created",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: CreatedColumnFormatter,
    //   formatExtraData: {
    //     allUsers: allUsers,
    //   },
    // },
    {
      dataField: "lastUpdated",
      text: "Modified",
      sort: true,
      sortCaret: sortCaret,
      formatter: LastUpdatedColumnFormatter,
      formatExtraData: {
        allUsers: allUsers
      }
    }

    // {
    //   dataField: "dates",
    //   text: "Dates",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.DateColumnFormatter,
    // },
    // {
    //   dataField: "lastAction",
    //   text: "Last Updated By",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.LastActionColumnFormatter,formatExtraData: {
    //     allUsers: allUsers,
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: uiProps.queryParams.pageSize,
    page: uiProps.queryParams.pageNumber,
    hideStartingDots: true,
    hideEndingDots: true
  };
  hideFilter = true;
  return (
    <>
      {hideFilter ? null : (
        <Filter
          filters={SortFilters ? SortFilters : filters}
          uiProps={uiProps}
          keyword={keyword}
        />
      )}
      {isCheckbox ? (
        <DataGrid
          isCheckbox={true}
          keyField={"id"}
          data={
            uiProps?.queryParams?.sortedData
              ? uiProps?.queryParams?.sortedData
              : listData || []
          }
          listLoading={listLoading}
          columns={columns}
          paginationOptions={paginationOptions}
          onTableParamsChange={getHandlerTableChange(
            uiProps.setQueryParams,
            uiProps
          )}
          type={uiProps.queryParams.type}
          select={getSelectRow({
            entities: listData,
            selectedItems: [...uiProps.selectedItems],
            setSelectedItems: uiProps.setSelectedItems
          })}
        />
      ) : (
        <DataGrid
          data={listData}
          keyField={"id"}
          listLoading={listLoading}
          columns={columns}
          paginationOptions={paginationOptions}
          onTableParamsChange={getHandlerTableChange(
            uiProps.setQueryParams,
            uiProps
          )}
          type={uiProps.queryParams.type}
        />
      )}
    </>
  );
}
