import React from "react";
import { Input } from "./Input";
import { codeRegexPattern } from "../../../_helpers/staticFields";

export function CodeInput(props) {
  const getFieldCSSClasses = (touched, errors, isHideValidations) => {
    const classes = ["form-control"];

    if (!isHideValidations) {
      if (touched && errors) {
        classes.push("is-invalid");
      }

      if (touched && !errors) {
        classes.push("is-valid");
      }
    }

    return classes.join(" ");
  };

  const onChangeCode = e => {
    let { target } = e;
    target.value = (target.value || "").replace(codeRegexPattern, "");
    e.target = target;
    props.field.onChange(e);
  };

  const { touched, error } = props.meta;

  return (
    <>
      <Input
        {...props}
        className={`${getFieldCSSClasses(
          touched,
          error,
          props.isHideValidations
        )} + " " + ${props.classes}`}
        disabled={props.isReadOnly || props.disabled}
        onChange={onChangeCode}
      />
    </>
  );
}
