import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import sha256 from "crypto-js/sha256";
import { enc } from "crypto-js";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { hubConnection } from "../../../../_custom/_helpers";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return;
  }
  return (
    <div className="timer">
      <div className="value" style={{ fontSize: "25px" }}>
        {remainingTime}
      </div>
    </div>
  );
};

function Login(props) {
  const { intl } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginPaused, setLoginPaused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(
        values.email,
        process.env?.REACT_APP_USE_ACTIVE_DIRECTORY === "true"
          ? values?.password
          : sha256(values?.password).toString(enc.Base64)
      )
        .then(params => {
          // disableLoading();
          const { status, data } = params;
          let oAuthModel = params?.data?.oAuthModel;
          if (status !== 200) {
            setSubmitting(false);
            setStatus(
              data?.message ||
                intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" })
            );
          } else {
            if (oAuthModel?.twoFactor) {
              props.setUserId({
                ...(oAuthModel || {}),
                currentPassword: sha256(values?.password).toString(enc.Base64)
              });
              history.push("/auth/two-factor-authentication");
            } else {
              if (oAuthModel?.passwordExpired) {
                let payload = {
                  passwordExpired: oAuthModel?.passwordExpired,
                  accessToken: oAuthModel?.access_token,
                  currentPassword: sha256(values?.password).toString(
                    enc.Base64
                  ),
                  userId: oAuthModel?.user_id,
                  uniqueIdentifier: oAuthModel?.uniqueIdentifier
                };
                props.changePassword({ ...payload });
                history.push("/auth/change-password");
              } else {
                if (oAuthModel?.multi_user_login) {
                  setLoginPaused(true);
                  setTimeout(function() {
                    hubConnection(oAuthModel?.access_token, oAuthModel?.user_id)
                      .then(() => {
                        props.login(data);
                      })
                      .catch(err => {
                        disableLoading();
                        setLoginPaused(false);
                        setSubmitting(false);
                        console.log("error while connecting signalR..", err);
                      });
                  }, 10000);
                } else {
                  hubConnection(oAuthModel?.access_token, oAuthModel?.user_id)
                    .then(() => {
                      props.login(data);
                    })
                    .catch(err => {
                      disableLoading();
                      setLoginPaused(false);
                      setSubmitting(false);
                      console.log("error while connecting signalR..", err);
                    });
                }
              }
            }
          }
        })
        .catch(error => {
          disableLoading();
          setLoginPaused(false);
          setSubmitting(false);
          setStatus(
            error?.response?.data?.message ||
              intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" })
          );
        });
    }
  });

  return (
    <div className="login-signin" id="kt_login_signin_form">
      {loginPaused ? (
        <div className="text-center mb-5 mb-10 mb-lg-20">
          <p className="font-size-h4 font-weight-bold">Please wait for</p>
          <div className="d-flex justify-content-center">
            <CountdownCircleTimer
              isPlaying
              duration={10}
              size={65}
              strokeWidth={5}
              colors={[["#3699ff", 1]]}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          <p className="font-size-h4 font-weight-bold mt-2">seconds.</p>

          <p className="text-muted font-size-h5 font-weight-bold">
            We are logging you out from another browser.
          </p>
        </div>
      ) : (
        <>
          <div className="mb-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              Enter your username and password
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : null}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email*"
                type="email"
                className={`form-control h-auto form-control-solid py-4 px-8 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />

              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container mb-0">
              <input
                placeholder="Password*"
                type={showPassword ? "text" : "password"}
                className={`form-control h-auto form-control-solid py-4 px-8 override-padding-right-eye ${getInputClasses(
                  "password"
                )}`}
                name="password"
                onChange={formik.handleChange}
              />

              <button
                hidden={!formik?.values?.password}
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className={"float-right password-eye"}
              >
                {!showPassword ? (
                  <i className="fa fa-eye-slash" aria-hidden="true" />
                ) : (
                  <i className="fa fa-eye" aria-hidden="true" />
                )}
              </button>

              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>

            {process.env?.REACT_APP_USE_ACTIVE_DIRECTORY !== "true" ? (
              <div className="form-group my-4">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>
              </div>
            ) : null}

            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                className={`btn btn-primary w-100 font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
