/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import { checkIsActive, getNavbarModules } from "../../../../_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSubheader } from "../../../../../_metronic/layout";
import { hubConnection } from "../../../../_helpers/common";
import { ForceLogoutNotification } from "../../../../_partials/notifications/ForceLogoutNotification";
import * as modulesActions from "../../../../../app/modules/Identity/_redux/modules/modulesActions";
import * as groupActions from "../../../../../app/modules/Identity/_redux/groups/groupsActions";
import { Logout } from "../../../../../app/modules/Auth/_redux/authCrud";

// import { login } from "../../../../../app/modules/Auth/_redux/authCrud";

export function HeaderMenu({ layoutProps }) {
  const [modules, setModules] = useState([]);
  const [forceLogout, setForceLogout] = useState(false);
  const [width, setWidth] = useState(0);
  const [moreModules, setMoreModules] = useState([]);
  const [sortModules, setSortModules] = useState([]);

  const {
    permissionsDictionary,
    allModules,
    userId,
    userToken,
    uniqueIdentifier
    // impersonateUser
  } = useSelector(
    state => ({
      permissionsDictionary: state.groups.permissionsDictionary,
      allModules: state.modules.allModules,
      userId: state.auth.user.id,
      userToken: state.auth.authToken,
      uniqueIdentifier: state.auth.uniqueIdentifier
      // impersonateUser: state.users.impersonateUser
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const subheader = useSubheader();
  useEffect(() => {
    if (allModules?.length && permissionsDictionary) {
      let moduleCount;
      if (width >= 992 && width <= 1280) {
        moduleCount = 3;
      } else if (width > 1280 && width <= 1336) {
        moduleCount = 4;
      } else if (width > 1336 && width <= 1420) {
        moduleCount = 6;
      } else if (width > 1420 && width <= 1641) {
        moduleCount = 7;
      } else if (width > 1641 && width <= 1720) {
        moduleCount = 8;
      } else if (width > 1720 && width < 1800) {
        moduleCount = 9;
      } else {
        moduleCount = 10;
      }

      let moduleArray = getNavbarModules(allModules, permissionsDictionary);
      const splicedItems = moduleArray.slice(0, moduleCount);
      let remainingItems = moduleArray.slice(moduleCount);
      let childModules = [];
      splicedItems.forEach(item => {
        childModules.push(...item?.children);
      });

      remainingItems = remainingItems.map(item => {
        childModules.push(...item?.children);
        return {
          ...item,
          parentCode: "more"
        };
      });

      let tempUpdatedModules = [
        ...splicedItems,
        ...remainingItems,
        ...childModules,
        {
          parentCode: null,
          code: "more",
          name: "More",
          route: "/more"
        }
      ];

      let updatedPermissionsDictionary = {
        ...permissionsDictionary,
        more: ["View"]
      };

      let updatedModules = getNavbarModules(
        tempUpdatedModules,
        updatedPermissionsDictionary
      );

      updatedModules = updatedModules.filter(
        item =>
          !(
            item.code === "more" &&
            (!item.children || item.children.length === 0)
          )
      );

      if (
        width < 992 ||
        (width > 1439 && moduleArray.length <= 9) ||
        (width > 1600 && moduleArray.length <= 12)
      ) {
        setModules(getNavbarModules(allModules, permissionsDictionary));
      } else {
        setModules(updatedModules);
      }
      subheader.setTitle("   ");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allModules, permissionsDictionary, width]);

  useEffect(() => {
    modules.forEach(m => {
      if (m?.code === "more") {
        setMoreModules(m?.children);
      }
    });
  }, [modules, setMoreModules]);

  useEffect(() => {
    let moreIndex;
    let moreModule;
    modules.forEach((mod, i) => {
      if (mod.route === "/more") {
        moreIndex = i;
        moreModule = mod;
      }
    });

    if (moreModule && moreIndex) {
      modules.splice(moreIndex, 1);
      modules.splice(modules.length, 0, moreModule);
    }

    setSortModules(modules);
  }, [modules]);

  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url, moreModules) ? "menu-item-active" : "";
  };

  useEffect(() => {
    hubConnection(userToken, userId)
      .then(connection => {
        connection.on("UserConnected", () => {
          console.log(
            `%c socket connection established `,
            "background: blue; color: white"
          );
        });

        connection.on("ChangePasswordMessage", response => {
          if (response?.action === 2) {
            dispatch(modulesActions.clearModulesPermissions());
            dispatch(groupActions.clearGroupsPermissions());
            Logout(userId, uniqueIdentifier).then(params => {
              if (params?.status === 200) {
                history.push("/logout");
              }
            });
          }
        });

        connection.on("ReceiveMessage", response => {
          if (response.action === 0) {
            setForceLogout(true);
          }
        });
      })
      .catch(err => {
        console.log("error while connecting signalR..", err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModuleHierarchy = module => {
    return (
      <li
        key={module?.code}
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `${module?.route}`
        )}`}
      >
        <NavLink className="menu-link menu-toggle" to={module?.route}>
          <span className="menu-text">{module?.name}</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-right">
          <ul className="menu-subnav">
            {(module?.children || []).map(childNode => {
              if (!childNode?.children?.length)
                return (
                  <li
                    key={childNode?.code}
                    className={`menu-item ${getMenuItemActive(
                      `${childNode?.route}`
                    )}`}
                  >
                    <NavLink className="menu-link" to={childNode?.route}>
                      <span className="menu-text">{childNode?.name}</span>
                    </NavLink>
                  </li>
                );
              else return getModuleHierarchy(childNode);
            })}
          </ul>
        </div>
      </li>
    );
  };

  const getRenderableNavigation = modulesList => {
    return (modulesList || []).map(module => {
      if (!module?.children?.length)
        return (
          // begin::1 Level
          <li
            key={module?.code}
            className={`menu-item menu-item-rel ${getMenuItemActive(
              `${module?.route}`
            )} `}
          >
            <NavLink className="menu-link" to={module?.route}>
              <span className="menu-text">{module?.name}</span>
            </NavLink>
          </li>
          // end::1 Level
        );
      else return getModuleHierarchy(module);
    });
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {forceLogout ? (
        <ForceLogoutNotification setForceLogout={setForceLogout} />
      ) : null}

      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {getRenderableNavigation(sortModules)}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
