import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback d-block">{error}</div>;
  }

  if (touched && !error && label) {
    return <div className="valid-feedback">{label} was entered correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          Please enter <b>{label}</b>
        </>
      )}
    </div>
  );
};

const selectLabel = ({
  label,
  touched,
  error,
  customFeedbackLabel,
  forceShowError
}) => {
  if (touched && error) {
    return (
      <div className={`invalid-feedback ${forceShowError ? "d-block" : ""}`}>
        {error}
      </div>
    );
  }

  return (
    <div className="feedback text-danger">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          Please select <b>{label}</b>
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel(props) {
  switch (props.type) {
    case "text":
      return inputLabel(props);
    case "email":
      return inputLabel(props);
    case "password":
      return inputLabel(props);
    case "textArea":
      return inputLabel(props);
    default:
      return selectLabel(props);
  }
}
