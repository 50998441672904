/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Status, Type } from "../UIHelpers";
import { ApplicationDynamicFormatter } from "./ApplicationDynamicFormatter";
import { warningNotification } from "../../notifications";
import { copyTextToClipboard } from "../../../_helpers";

export const NameColumnFormatter = (
  cellContent,
  rowData,
  rowIndex,
  {
    openEditPage,
    openClonePage,
    openCopyPage,
    openPastePage,
    onDelete,
    openedRow,
    setOpenedRow,
    openTrailDialog,
    onViewPage,
    parameters: {
      identifier,
      level1,
      isHideOptions,
      isCountColumn,
      copyCode,
      isProductFactoryGrid = false,
      showTrail,
      module,
      ...restParameters
    },
    permissionsObject
  }
) => {
  let row = {
    ...rowData,
    status: rowData.status !== undefined ? rowData.status : 0
  };
  const priorityOverlayRef = React.createRef();
  const toggleOverlay = () => priorityOverlayRef.current?.click();

  const popover = (
    <Popover id="name-popover">
      <Popover.Content className="p-0">
        <div className="pl-0">
          <button
            onClick={() => {
              toggleOverlay();
              openTrailDialog(row.code);
            }}
            hidden={!showTrail}
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
                title={"Trail"}
              />
            </span>
            Trail
          </button>
          <button
            onClick={() => {
              toggleOverlay();
              openEditPage(row.code || row?.id, row);
            }}
            hidden={
              ((row?.status !== Status.Approved ||
                row?.isBlocked ||
                (!isProductFactoryGrid && row?.type === Type.Deleted)) &&
                row?.status !== Status.Draft &&
                !isProductFactoryGrid &&
                !isProductFactoryGrid) ||
              !permissionsObject?.update
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                title={"Edit"}
              />
            </span>
            Edit
          </button>

          <button
            onClick={() => {
              toggleOverlay();
              openClonePage(row.code, row);
            }}
            hidden={
              ((row?.status !== Status.Approved || row?.isBlocked) &&
                row?.status !== Status.Draft &&
                !isProductFactoryGrid) ||
              !permissionsObject?.clone
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")}
                title={"Clone"}
              />
            </span>
            Clone
          </button>

          <button
            onClick={() => {
              toggleOverlay();
              openCopyPage(row.code, row);
            }}
            hidden={
              ((row?.status !== Status.Approved || row?.isBlocked) &&
                row?.status !== Status.Draft &&
                !isProductFactoryGrid) ||
              !permissionsObject?.copy
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-info mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                title={"Copy"}
              />
            </span>
            Copy
          </button>

          <button
            onClick={() => {
              toggleOverlay();
              openPastePage(row.code, row);
            }}
            hidden={
              ((row?.status !== Status.Approved || row?.isBlocked) &&
                row?.status !== Status.Draft &&
                !isProductFactoryGrid) ||
              !permissionsObject?.paste
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-success mr-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Clipboard-check.svg"
                )}
                title={"Paste"}
              />
            </span>
            Paste
          </button>

          <button
            onClick={() => {
              toggleOverlay();
              if (
                window.confirm("Are you sure you want to delete this row?") ===
                true
              )
                onDelete(row.code, row);
            }}
            hidden={
              ((row?.status !== Status.Approved ||
                row?.isBlocked ||
                (!isProductFactoryGrid && row?.type === Type.Deleted)) &&
                row?.status !== Status.Draft &&
                !isProductFactoryGrid) ||
              !permissionsObject?.delete
            }
            className="dropdown-item mr-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-danger mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}  title={"Delete"} />
            </span>
            <span>Delete</span>
          </button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {level1 ? (
        <span
          className="custom_button font-weight-bolder"
          onClick={() => {
            if (permissionsObject?.view) {
              onViewPage(row[identifier], row);
            } else {
              warningNotification("You do not have permission to view this");
            }
          }}
        >
          {ApplicationDynamicFormatter(cellContent, rowData, rowIndex, {
            parameters: {
              ...restParameters,
              identifier: level1,
              withSpan: false
            }
          })}
        </span>
      ) : !level1 ? (
        <span
          className="custom_button font-weight-bolder"
          onClick={() => {
            if (permissionsObject?.view) {
              onViewPage(row?.code, row);
            } else {
              warningNotification("You do not have permission to view this");
            }
          }}
        >
          {ApplicationDynamicFormatter(cellContent, rowData, rowIndex, {
            parameters: {
              ...restParameters,
              identifier: "name",
              withSpan: false
            }
          })}
        </span>
      ) : (
        "N/A"
      )}

      {copyCode && row?.[copyCode] ? (
        <OverlayTrigger
          rootClose
          trigger={["hover", "focus"]}
          onExit={() => {}}
          overlay={<Tooltip id="copy-popover">Copy {row?.[copyCode]}</Tooltip>}
          placement="top"
        >
          <i
            className="fas fa-copy ml-3 mt-1 overlay-menu-action cursor-pointer"
            onClick={() => copyTextToClipboard(row?.[copyCode])}
          ></i>
        </OverlayTrigger>
      ) : null}

      {module === "identity" ||
      (((row?.status === Status.Approved &&
        row?.type !== Type.AwaitingActivation) ||
        row?.status === Status.Draft ||
        isProductFactoryGrid === true) &&
        !isHideOptions) ? (
        <OverlayTrigger
          rootClose
          trigger="click"
          onExit={() => {
            if (openedRow === rowIndex) {
              setOpenedRow(null);
            }
          }}
          overlay={popover}
          placement="top"
        >
          <a
            className={`${
              openedRow === rowIndex ? "btn-light" : "overlay-menu-action"
            } btn btn-xsm btn-hover-light float-right`}
            ref={priorityOverlayRef}
            onClick={() => {
              setOpenedRow(rowIndex);
            }}
            hidden={!permissionsObject?.update && !permissionsObject?.delete}
          >
            <MoreVertIcon />
          </a>
        </OverlayTrigger>
      ) : null}
    </>
  );
};
